import {types} from "mobx-state-tree";

export const  RuleType = types.model('RuleType',{
  uuid: types.identifier,
  label: types.optional(types.string,""),
  labelGroup: types.optional(types.string,""),
  name: types.optional(types.string,""),
  type: types.optional(types.string,""),
  ruleSchema:types.optional(types.string,""),
  ruleUiSchema: types.optional(types.string, ""),
  data: types.optional(types.string, ""),
}).actions((self) => ({
  addDataToSelectedRuleType(schema){
    self.ruleSchema = JSON.stringify(schema)
  }
})).views((self) => ({}))
