import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {Utm} from "../../../models/utms/UtmModel";
import Notification from "../../../utils/Notification";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {DimensionsMultiSelectState} from "../../elements/dimensions/multi-select/dimensionsMultiSelectState";
import lodash from "lodash";


export const CreateUtmState = types.model("CreateUtmState", {
  utm: types.maybe(Utm),
  isLoading: types.optional(types.boolean, false),
  isMadeDefault:types.optional(types.boolean, false),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  isExclusivelyGroupOwned: types.optional(types.boolean, true),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
})
  .actions(self => ({
    setOnSelectChanges() {
      self.groupSelectStore?.setConsumer(self.selectGroup)
    },
    selectGroup(obj) {
      self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid)
    },
    handleIsDefault(){
      self.isMadeDefault = !self.isMadeDefault
    },
    validateForm() {
      let isValid = true;
      isValid = self.utm.validateUtmModel() // validate fields other than name
      if(self.utm.name === ""){
        self.utm.setNameIsInvalid(true)
        isValid = false;
      }
      return isValid;
    },
    toggleIsExclusivelyGroupOwned(){
      self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
    },
    toggleDimensionAll(){
      self.dimensionAll = !self.dimensionAll
      self.toggleIsDimensionSelectVisible(!self.dimensionAll)
      self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
    },
    toggleIsDimensionSelectVisible(visible){
      self.isDimensionSelectVisible = visible
    },
    hydrateDimension(dimensions = []){
      const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
      self.dimensionAll = !isDimensionAll
      self.toggleDimensionAll()
      if(!self.dimensionAll){
        self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
      }
    },
    addUtm: flow(function* f() {
      const DimensionIsSelected = !self.isDimensionSelectVisible || !lodash.isEmpty(self.dimensionsMultiSelectStore.getTranspiledDimensions())

      if(self.validateForm() && DimensionIsSelected) {
      self.isLoading = true
      const manager = getEnv(self).utmManager
        const resp = yield manager.create(self);
        if (resp.status === 201) {
          new Notification()
            .setType("success")
            .setMessage(`${resp?.data?.name} UTM created`)
            .send();
          return resp
        } else {
          new Notification()
            .setType("error")
            .setMessage(`${self?.utm?.name} UTM failed creating`)
            .send();
        }
      self.isLoading = false
      }
    }),

  })).views((self) => ({}))
// toastr.error(`Failure`, `${self.errorMessage}`)
