import { getType, types } from "mobx-state-tree";
import CodeType from "../../../models/CodeType";
import CodeTypeStore from "../../../stores/domain/CodeTypeStore";
import Notification from "../../../utils/Notification";
import {codeBehavior} from "../../../utils/constants";


export const CodeTypeSelectState = types.model('CodeTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(CodeTypeStore))),
  selected: types.maybe(types.safeReference(CodeType)),
  readonly: types.optional(types.boolean, false),
  isEditMode: types.optional(types.boolean, false)
}).volatile(self => ({
  notifySelect: types.function,
  fsm: types.function,
  // selectedSchema: types.object
})).actions((self)=>({
  search(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
    //self.groupStore.groups
  },
  setConsumer(f){
    self.notifySelect = f
  },
  setDefaultCodeType(codeType) {
    self.notifySelect(codeType)
    self.selected = codeType;
  },
  setEditMode(mode) {
    self.isEditMode = mode;
  },
  setFsm(fsm) {
    self.fsm = fsm;
  },
  returnDynamic(behavior) {
    const filteredBehavior = behavior?.filter(b => b !== codeBehavior.STATIC);
    if(filteredBehavior?.length === 1) {
      return filteredBehavior[0];
    } else {
      return "";
    }
  },
  execute(codeType) {
    if(self.fsm) {
      const targetTransition = codeType.uuid + "|" +self.returnDynamic(codeType.behavior)
      return !!self.fsm.can(targetTransition);
    }
  },
  select(codeType) {
    if(self.isEditMode) {
      if(self.execute(codeType)) {
        self.notifySelect(codeType)
        self.selected = codeType
      } else {
        new Notification()
          .setType("error")
          .setMessage(`GS1 codes cannot be altered and converting other types to GS1 or static codes is not allowed. The selected code type is ${codeType.name} with behavior ${codeType.behavior}.`)
          .send();
      }
    } else {
      console.log(codeType)
      self.notifySelect(codeType)
      self.selected = codeType
      console.log(self.selectedSchema)
    }
  },
  initDefaults(){
    //the url is the default type for every tenant.
    self.store.codeTypes.forEach((codeType) => {
      if (codeType.name ==='url') {
        self.select(codeType);
      }
    })
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => { //TODO
      console.log("placeholder notifySelect")
    }

    self.selectedSchema = {}

  }

})).views( (self) => ({
  options() {
    const { codeTypes } = self.store || [];
    return codeTypes.slice().sort((a,b)=> a?.label?.localeCompare(b?.label))
  },
  // selectedSchemaView(){
  //   return JSON.parse(self.selectedSchema)
  // }
}))
