import {flow, getEnv, getType, types} from "mobx-state-tree";
import {ExtendedDataModel, createExtendedDataModel} from "../../models/extendedData/ExtendedData";

const ExtendedDataStore = types.model('ExtendedDataStore',{
  storeUuid: types.identifier,
  extendedData: types.optional(types.array(ExtendedDataModel), []),
}).volatile((self)=>({
  filterListOn: types.fn,
})).actions((self) => ({
  setFilterOn(funct) {
    self.filterListOn = funct;
  },
  setExtendedData(data) {
    self.extendedData = data?.map((item) => {return createExtendedDataModel(item, self)});
    self.sortExtendedData();
  },
  initWithData(data = []) {
    try {
      if (data) {
        self.setExtendedData(data);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to initWithData`, e);
    }
  },
  init: flow(function* (queryParams = {}) {
    const response = yield getEnv(self).extendedDataManager.fetchAllExtendedData(queryParams, self);
    self.initWithData(response?.results);
  }),
  sortExtendedData() {
    try {
      self.extendedData?.sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      console.warn(`${getType(self).name} Warn: Failed to sort`, e);
    }
  }
})).views((self) => ({
  getExtendedData() {
    if (self.filterListOn) {
      return self.extendedData.filter(self.filterListOn);
    } else {
      return self.extendedData;
    }
  }
}));

export default ExtendedDataStore;
