import { flow, getEnv, types } from 'mobx-state-tree'
import Group from '../../models/Group'

/**
 * Base Layer of Persistence
 *
 * Shared Values across the session.
 *
 * CRUD operations go through Domain store.
 *
 * Search operations go through domain store.
 *
 *
 */
const GroupStore = types.model('GroupStore',{
  storeUuid: types.identifier,
  groups: types.optional(types.array(Group), []),
  //isLoading..
}).actions((self)=>({
  searchFor: flow(function* f(txt) {
    const manager = getEnv(self).groupManager;
    const found = yield manager.searchFor(txt);

    console.log(found);

  }),
  init: flow(function* f(params = {}) {
    console.log("load group store from API");
    const manager = getEnv(self).groupManager;
    const groupsResponse = yield manager.fetchAll(params);
    console.log(groupsResponse);
    if(groupsResponse?.results) {
      self.groups = groupsResponse.results.map(g => Group.create({uuid: g?.uuid, name: g?.name, label: g?.name}))
    } else {
      //TODO: to be removed later placeholder options
      self.groups = [
        Group.create({ uuid: '1', name: 'default', label: 'Default' }),
        Group.create({ uuid: '2', name: 'admin', label: 'Admin' }),
        Group.create({ uuid: '3', name: 'Manager', label: 'Manager' }),
      ];
    }
  }),
  initWithData(groups = []) {
    if(groups) {
      self.groups = groups.map(g => Group.create({uuid: g?.uuid, name: g?.name, label: g?.name}))
      self.groups.sort((a,b)=> a.label.localeCompare(b.label))
    } else {
      self.groups = []
    }
  },

  reload(){

  },
  save(group){

  },
  update(group){

  },
  add(data){

  },
  delete(group){

  }

}))



export default GroupStore;
