import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useIntl } from 'react-intl';
import {buildInfo} from "./BuildInfo";
const Footer = () => {
  const intl = useIntl();
  return( <footer className="footer">
  <Container fluid>
    <Row className="text-muted">
      <Col xs={8} className="text-left">
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="mailto:support@scanbuy.com" className="text-muted mr-1">
            {intl.formatMessage({ id: "dashboard.footer.support.defaultMessage"})}
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://scanbuy.com/site-privacy-policy" target="_blank" className="text-muted mr-1">
            {intl.formatMessage({ id: "dashboard.footer.privacy.defaultMessage"})}
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://scanbuy.com/terms-of-use"  target="_blank" className="text-muted mr-1">
            {intl.formatMessage({ id: "dashboard.footer.termsOfService.defaultMessage"})}
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://scanbuy.com/contact"  target="_blank" className="text-muted">
            {intl.formatMessage({ id: "dashboard.footer.contact.defaultMessage"})}
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={4} className="text-right">

        <p className="mb-0">
          <span className="text-muted" >build:{buildInfo.buildVersion}&nbsp;&nbsp;</span>
          &copy; {new Date().getFullYear()} -{" "}
          <Link to="/" className="text-muted">
            Scanbuy
          </Link>

        </p>
      </Col>
    </Row>
  </Container>
</footer>
);


};

export default Footer;
