import { flow, getEnv, types } from "mobx-state-tree";
import CodeType from "../../models/CodeType";
import lodash from "lodash";



/**
 * Base Layer of Persistence
 *
 * Shared Values across the session.
 *
 * CRUD operations go through Domain store.
 *
 * Search operations go through domain store.
 *
 *
 */
const CodeTypeStore = types.model('CodeTypeStore',{
  storeUuid: types.identifier,
  codeTypes: types.optional(types.array(CodeType), []),
}).actions((self)=>({
  initWithData(codeType = [], enabledTypes = ["url"]) {
    try {
      if (codeType) {
        codeType = lodash.filter(codeType, (t) => enabledTypes.includes(t.type));
        self.codeTypes = codeType.map(t => CodeType.create({
          uuid: t.type,
          label: t?.label,
          behavior: t?.behavior,
          icon: t?.icon,
          name: t?.type,
          schema: JSON.stringify(t?.qrschema),
          uiSchema: JSON.stringify(t?.uischema)
        }))
        try {
          self.codeTypes.sort((a, b) => a.label.localeCompare(b.label));
        }catch (e) {
          console.warn("failed to sort codeTypes", e);
        }
      }
    }catch (e) {
      console.warn("Failed to initWithData", e);
    }
  },
  init: flow(function* f(params = {}) {
    console.log("load CodeTypeStore from API");
    yield self.getFromServer(params);
    const response = yield self.getFromServer(params)||{results:[]};
    self.codeTypes = response.results.map(t => CodeType.create({ uuid: t.type, label: t?.label, name: t?.type,behavior: t?.behavior ,schema: JSON.stringify(t?.qrschema), uiSchema: JSON.stringify(t?.uischema) }))
  }),
  getFromServer: flow(function* getTags(params = {}) {
    try {
      const response = yield getEnv(self).codeTypeManager.fetchAll(params);
      if(response && response.results && response.results.length > 0) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch tags", error);
      return null;
    }
  }),
  searchFor(txt){

  },
  reload(){

  },
  save(group){

  },
  update(group){

  },
  add(data){

  },
  delete(group){

  }

}))



export default CodeTypeStore;
