import {flow, getEnv, types} from "mobx-state-tree";
import {toastr} from "react-redux-toastr";

const DeleteParameterSetState = types.model('DeleteParameterSetState', {
  parameterSetName: types.optional(types.string, ""),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
   .volatile(self => ({
     toggleModal: types.fn,
     refreshList: types.fn,
  }))
  .actions((self) => ({
     handleHasDeleteErrors(boolean) {
        self.formHasErrors = boolean
    },
     handleDeleteErrorMessage(message) {
        self.errorMessage = message
    },
     setToggleListener(listener) {
        self.toggleModal = listener
    },
     setRefreshListener(listener) {
        self.refreshList = listener
    },

    deleteParameterSet: flow(function* () {

        const manager = getEnv(self).parameterSetManager;
        const resp = yield manager.delete(self)
        !self.formHasErrors && toastr.success(`Delete ${resp.status} status.`, `${self.parameterSetName} has been archived`)
        !self.formHasErrors && self.refreshList()
        !self.formHasErrors && self.toggleModal()
    }),

 }))

export default DeleteParameterSetState;
