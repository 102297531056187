import { getType, types } from "mobx-state-tree";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";

const GroupModel = types.model('GroupModel',{
  uuid: types.identifier,
  name: types.maybe(types.string),
  isDisabled: types.optional(types.boolean, false),
})
export const GroupFilterSelectState = types.model('GroupFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  groupList: types.optional(types.array(GroupModel), []),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
})).actions((self)=>({
  reset(){
    self.selected = []
    self.setIsDisabled(false);
  },
  setGroups(groups){
    self.groupList = groups
    self.groupList.sort((a, b) => a?.name?.localeCompare(b?.name));
  },
  setIsDisabled(d) {
    self.isDisabled = d;
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["groupUuid"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  optionValues() {
    const groupOptions = (self.groupList || []).flatMap( (group) => {
      return {
        label: group?.name,
        value: "groupUuid."+group.uuid
      }
    });

    const options = [
      {
        label: "",
        options: groupOptions
      },
    ];
    return options;
  }
}))
