import {types} from "mobx-state-tree";
import {Rule} from "./Rule";
import {AuditMetaData} from "./AuditMetaData";
import {TagModel} from "./tags/TagModel";

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string)
})
export const  RuleSet = types.model('RuleSet',{
  uuid: types.identifier,
  label: types.string,
  name: types.string,
  rules:types.maybe(types.array(types.late(() =>Rule))),
  _audit: types.maybeNull(AuditMetaData),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  tags: types.optional(types.array(types.safeReference(types.late(() => TagModel))), []),
  owner: types.maybe(GroupData),
  isExclusivelyGroupOwned: types.maybe(types.boolean),
})











