import {types} from "mobx-state-tree";


export const AuditMetaData = types.model('_audit', {
  //createdAt: types.maybeNull(types.string),
  //updatedAt: types.maybeNull(types.string)
  //other _audit fields
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  email: types.maybeNull(types.string)
})
