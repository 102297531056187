import React from "react";
import {Button, Col, CustomInput, Row} from "reactstrap";
import LidAndBallListItem from "./LidAndBallListItem";
import {modules, eyeLids, eyeballs} from "../../../../assets/img/eyesAndModules/key";
import {observer} from "mobx-react-lite";
import upperLeft from "../../../../assets/img/eyesAndModules/signalEmoji/QRCodeTopLeft.png"
import lowerLeft from "../../../../assets/img/eyesAndModules/signalEmoji/QRCodeBottom.png"
import upperRight from "../../../../assets/img/eyesAndModules/signalEmoji/QRCodeTopRight.png"
import allThree from "../../../../assets/img/eyesAndModules/signalEmoji/QRCodeAllThree.png"
import modulesIcon from "../../../../assets/img/eyesAndModules/signalEmoji/modulesIcon.png"
import ModulesListItem from "./ModulesListItem";
import Slider from '../../../Slider';
import { FormattedMessage } from "react-intl";
import "../../../../assets/scss/qr-designer.scss"
import EyesRotateItem from "./EyesRotateItem";
import DisableWrapper from "../../../../utils/disableWrapper";

const EyesAndModulesView = observer(({EyesAndModulesStore, logoStore, isDisabled = false}) => {
  // console.log("EyesAndModulesStore", JSON.stringify(EyesAndModulesStore))
  const primaryEyelidImage = eyeLids.find(el => el.key === EyesAndModulesStore["eye1Shape"]).image
  const primaryEyeBallImage = eyeballs.find(el => el.key === EyesAndModulesStore["ball1Shape"]).image

  return (
    <DisableWrapper isDisabled={isDisabled}>
      <>
    <Row>
      <Col sm={2}>
        <Button id="modules-btn" onClick={() => EyesAndModulesStore.toggle("openModule")} style={{marginBottom: "2px"}}>
        <FormattedMessage id="qrDesign.create.modules.defaultMessage"/>
        </Button>
      </Col>
      {/*<Button onClick={() => EyesAndModulesStore.toggle("openModule")} style={{marginBottom: "2px"}}><img src={modulesIcon} style={{width:"30px"}}/></Button>*/}

    </Row>
    {EyesAndModulesStore.openModule &&
      <Row>
        {modules.map((module, index) => {
          return (<Col sm={2}>
            <ModulesListItem
              itemID = {"module-item-" + index}
              EyesAndModulesStore={EyesAndModulesStore}
              group="dataShape"
              imageClass={module.image}
              shape={module.key}
              key={module.key}
              logoStore = {logoStore}
            />
          </Col>);
        })}
      </Row>
    }
    <Row>
      <Col sm={2}>Module Scale</Col>
      <Col sm={1}></Col>
      <Col sm={3}>
        <Slider defaultValue={EyesAndModulesStore.moduleScale}
                            minValue={75}
                            maxValue={150}
                            handleSliderChange={EyesAndModulesStore.handleModuleScale}
                            isDisabled={isDisabled}
        />
      </Col>

    </Row>
    <Row>
      <Col sm={2}>Module Rotation</Col>
      <Col sm={1}></Col>
      <Col sm={2}>
        <div className="move-switch-position">
          <CustomInput
              type="switch"
              id="moduleRotation"
              checked={EyesAndModulesStore.moduleRotation}
              onChange={() => EyesAndModulesStore.toogleModuleRotation()}
              />
        </div>
      </Col>
    </Row>
    <hr/>
    <Row>
      <Col sm={2}><FormattedMessage id="qrDesign.create.changeIndividualEyes.defaultMessage"/></Col>
      <Col sm={1}></Col>
      <Col sm={1}>
      <div className="move-switch-position">
          <CustomInput
            checked={EyesAndModulesStore.changeIndividualEyes}
            type="switch"
            id="openPrimaryEye"
            value="openPrimaryEye"
            onChange={EyesAndModulesStore.toggleChangeIndividualEyes}
          />
      </div>
    </Col>
    </Row>
    {/*</Col>*/}
    {!EyesAndModulesStore.changeIndividualEyes && (

      <Row sm={8}>
        <Col sm={2}>
        <Button id="eyes-btn" onClick={() => EyesAndModulesStore.toggle("openPrimaryEye")} style={{marginBottom: "2px"}}><img src={allThree} style={{width:"30px"}}/></Button>
        </Col>
      </Row>
    )}
    {EyesAndModulesStore.openPrimaryEye && !EyesAndModulesStore.changeIndividualEyes && (
      <>
        <Row>
          <Col sm={1}><h5>Eyelid</h5></Col>
        </Row>
        <Row>
          {eyeLids.map((lid, i) => {
            return (
              <>

              <Col sm={1}>
                <LidAndBallListItem
                  EyesAndModulesStore={EyesAndModulesStore}
                  group="eyePrimaryShape"
                  imageClass={lid.image}
                  shape={lid.key}
                  key={lid.key}
                  isPrimary={true}
                />
              </Col>
              </>);
          })}

        </Row>
        <Row>
          <Col sm={1}><h5>Eyeball</h5></Col>
        </Row>
        <Row>
          {eyeballs.map((ball, i) => {
            return (<Col sm={1}>
              <LidAndBallListItem
                EyesAndModulesStore={EyesAndModulesStore}
                group="ballPrimaryShape"
                imageClass={ball.image}
                shape={ball.key}
                key={ball.key}
                isPrimary={true}
              />
            </Col>);
          })}
          <Col></Col>
        </Row>
      </>
    )}
    {!EyesAndModulesStore.changeIndividualEyes && (
      <Row>
        <Col sm={2}>Rotation</Col>
        <Col sm={1}></Col>
        <Col sm={2}>
        <div className="move-switch-position">
            <CustomInput
              type="switch"
              id="openAllEyesRotation"
              value="openAllEyesRotation"
              onChange={EyesAndModulesStore.toogleOpenAllEyesRotation}
            />
        </div>
        </Col>
      </Row>
    )}
    {EyesAndModulesStore.openAllEyesRotation && (
    <>
      <EyesRotateItem eyeLidRotation={EyesAndModulesStore.allEyeLidsRotation}
                      eyeBallRotation={EyesAndModulesStore.allEyeBallsRotation}
                      handleEyeLidRotation={EyesAndModulesStore.handleAllEyeLidsRotation}
                      handleEyeBallRotation={EyesAndModulesStore.handleAllEyeBallsRotation}/>
    </>)}

    {EyesAndModulesStore.changeIndividualEyes && (
    <Row>
      <Col sm={2}>
        <Row>
        <Col sm={2}>
          <Button data-testid="eye-1" onClick={() => EyesAndModulesStore.toggle("openEye1")}
                  style={{marginBottom: "2px"}}>
            <img src={upperLeft} style={{width: "30px"}}/>
          </Button>
        </Col>
        </Row>
      </Col>
    </Row>
    )}
    {EyesAndModulesStore.openEye1 && EyesAndModulesStore.changeIndividualEyes && (
      <>
        <Row><Col sm={1}><h5>Eyelid</h5></Col></Row>
        <Row>
        {
          eyeLids.map((lid) => {
            return (<Col sm={1}>
              <LidAndBallListItem
                EyesAndModulesStore={EyesAndModulesStore}
                group="eye1Shape"
                imageClass={lid.image}
                shape={lid.key}
                key={lid.key}
              />
            </Col>);
          })
        }
        </Row>
        <Row><Col sm={1}><h5>Eyeball</h5></Col></Row>
        <Row>
        {eyeballs.map((ball) => {
          return (<Col sm={1}>
            <LidAndBallListItem
              EyesAndModulesStore={EyesAndModulesStore}
              group="ball1Shape"
              imageClass={ball.image}
              shape={ball.key}
              key={ball.key}
            />
          </Col>);
        })}
        </Row>
        <Row>
          <Col sm={2}>Rotation Eye 1</Col>
          <Col sm={1}></Col>
          <Col sm={2}>
            <div className="move-switch-position">
                <CustomInput
                  type="switch"
                  id="openEyes1Rotation"
                  value="openEyes1Rotation"
                  onChange={() => EyesAndModulesStore.toogleOpenIndividualEyesRotation(1)}
                />
            </div>
          </Col>
        </Row>
        {EyesAndModulesStore.openEyes1Rotation && (
          <>
            <EyesRotateItem eyeLidRotation={EyesAndModulesStore.eye1RotationAngle}
                          eyeBallRotation={EyesAndModulesStore.ball1RotationAngle}
                          handleEyeLidRotation={EyesAndModulesStore.handleIndividualEyeLidRotation}
                          handleEyeBallRotation={EyesAndModulesStore.handleIndividualEyeBallRotation}
                          position={1}/>
          </>
        )}
      </>
    )}
    {EyesAndModulesStore.changeIndividualEyes && (
    <Row sm={8}>
      <Col sm={2}>
        <Button onClick={() => EyesAndModulesStore.toggle("openEye2")} style={{marginBottom: "2px"}}><img src={upperRight} style={{width:"30px"}}/></Button>
      </Col>
    </Row>
    )}
    {EyesAndModulesStore.openEye2 && EyesAndModulesStore.changeIndividualEyes && (
      <>
        <Row><Col sm={1}><h5>Eyelid</h5></Col></Row>
        <Row>
        {eyeLids.map((lid, i) => {
          return (<Col sm={1}>
            <LidAndBallListItem
              EyesAndModulesStore={EyesAndModulesStore}
              group="eye2Shape"
              imageClass={lid.image}
              shape={lid.key}
              key={lid.key}
            />
          </Col>);
        })}
        </Row>

        <Row><Col sm={1}><h5>Eyeball</h5></Col></Row>
        <Row>
        {eyeballs.map((ball, i) => {
          return (<Col sm={1}>
            <LidAndBallListItem
              EyesAndModulesStore={EyesAndModulesStore}
              group="ball2Shape"
              imageClass={ball.image}
              shape={ball.key}
              key={ball.key}
            />
          </Col>);
        })}
        </Row>
        <Row>
          <Col sm={2}>Rotation Eye 2</Col>
          <Col sm={1}></Col>
          <Col sm={2}>
            <div className="move-switch-position">
                <CustomInput
                  type="switch"
                  id="openEyes2Rotation"
                  value="openEyes2Rotation"
                  onChange={() => EyesAndModulesStore.toogleOpenIndividualEyesRotation(2)}
                />
            </div>
          </Col>
        </Row>
        {EyesAndModulesStore.openEyes2Rotation && (
          <>
            <EyesRotateItem eyeLidRotation={EyesAndModulesStore.eye2RotationAngle}
                          eyeBallRotation={EyesAndModulesStore.ball2RotationAngle}
                          handleEyeLidRotation={EyesAndModulesStore.handleIndividualEyeLidRotation}
                          handleEyeBallRotation={EyesAndModulesStore.handleIndividualEyeBallRotation}
                          position={2}/>
          </>
        )}
      </>
    )}
    {EyesAndModulesStore.changeIndividualEyes && (
    <Row>
      <Col sm={2}>
        <Button onClick={() => EyesAndModulesStore.toggle("openEye3")}><img src={lowerLeft} style={{width:"30px"}}/></Button>
      </Col>
    </Row>
    )}
    {EyesAndModulesStore.openEye3 && EyesAndModulesStore.changeIndividualEyes &&(
      <>
        <Row><Col sm={1}><h5>Eyelid</h5></Col></Row>
        <Row>
        {eyeLids.map((lid, i) => {
          return (<Col sm={1}>
            <LidAndBallListItem
              EyesAndModulesStore={EyesAndModulesStore}
              group="eye3Shape"
              imageClass={lid.image}
              shape={lid.key}
              key={lid.key}
            />
          </Col>);
        })}
        </Row>

        <Row><Col sm={1}><h5>Eyeball</h5></Col></Row>
        <Row>
        {eyeballs.map((ball, i) => {
          return (<Col sm={1}>
            <LidAndBallListItem
              EyesAndModulesStore={EyesAndModulesStore}
              group="ball3Shape"
              imageClass={ball.image}
              shape={ball.key}
              key={ball.key}
            />
          </Col>);
        })}
        </Row>
        <Row>
          <Col sm={2}>Rotation Eye 3</Col>
          <Col sm={1}></Col>
          <Col sm={2}>
            <div className="move-switch-position">
                <CustomInput
                  type="switch"
                  id="openEyes3Rotation"
                  value="openEyes3Rotation"
                  onChange={() => EyesAndModulesStore.toogleOpenIndividualEyesRotation(3)}
                />
            </div>
          </Col>
        </Row>
        {EyesAndModulesStore.openEyes3Rotation && (
          <>
            <EyesRotateItem eyeLidRotation={EyesAndModulesStore.eye3RotationAngle}
                          eyeBallRotation={EyesAndModulesStore.ball3RotationAngle}
                          handleEyeLidRotation={EyesAndModulesStore.handleIndividualEyeLidRotation}
                          handleEyeBallRotation={EyesAndModulesStore.handleIndividualEyeBallRotation}
                          position={3}/>
          </>
        )}
      </>
      // )}
    )}
    {/*<Row>*/}
    {/*  <Col sm={2} className="text-sm-right">Rotated Eyes</Col>*/}
    {/*  <Col sm={1}>*/}
    {/*    <CustomInput*/}
    {/*      type="number"*/}
    {/*      min="0"*/}
    {/*      max="360"*/}
    {/*      value={EyesAndModulesStore.angle}*/}
    {/*      onChange={EyesAndModulesStore.handleAngleChange}*/}
    {/*      className="react-select-container"*/}
    {/*      classNamePrefix="react-select"*/}
    {/*      id="rotatedEyes">*/}
    {/*    </CustomInput>*/}
    {/*  </Col>*/}
    {/*</Row>*/}
  </>
    </DisableWrapper>
  )
})
export default EyesAndModulesView;
