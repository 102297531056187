import { types } from "mobx-state-tree";

export const StatusState = types.model('StatusState',{

  title: types.maybe(types.string),
  status: types.optional(types.string, "PUBLISHED"),
  isEditForm: types.optional(types.boolean,false),
  isCloneForm: types.optional(types.boolean, false),
  scheduleStatusChange: types.optional(types.boolean, false),
  statusScheduleData: types.optional(types.string, '{"status" : [{"date" : ""}]}'),
  isView: types.optional(types.boolean, false),
}).volatile((self)=>({

})).actions((self) => ({
  setStatus(status) {
    self.status = status
  },
  changeStatus(event) {
  self.status = event.target.value
  },
  setScheduleStatusChange(){
    self.scheduleStatusChange = !self.scheduleStatusChange
  },
  setCloneForm(bool) {
    self.isCloneForm = bool
  },
  getStatusScheduleData(){
    if(self.scheduleStatusChange) return JSON.parse(self.statusScheduleData)
    return undefined
  },
  setStatusScheduleData(data) {
    self.statusScheduleData = JSON.stringify(data)
  }
})).views((self) => ({
  isStatusNotDefault() {
    return (self.status !== "PUBLISHED" )
  },
  statusScheduleDataView() {
    return JSON.parse(self.statusScheduleData)
  },
}));

