import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);
const formatOptionLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/* <span style={groupBadgeStyles}>3</span> */}
  </div>
);


const BatchFilterSelect = observer(({ batchFilterSelectState }) => {


  // const options = [
  //   {
  //     label: "BatchType",
  //     options: [
  //       { label: "Create", value: "CREATE" },
  //       { label: "Publish", value: "PUBLISH" },
  //       { label: "Download", value: "DOWNLOAD" },
  //     ]
  //   },
  //   {
  //     label: "Status",
  //     options: [
  //       { label: "Pending", value: "PENDING" },
  //       { label: "Queued", value: "QUEUED" },
  //       { label: "Processing", value: "PROCESSING" },
  //       { label: "Complete", value: "COMPLETE" },
  //       { label: "Failed", value: "Failed" },
  //       { label: "DONE", value: "DONE" },
  //       { label: "CREATED", value: "CREATED" },
  //     ]
  //   },
  // ];
  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={batchFilterSelectState.optionValues()}
      onChange={batchFilterSelectState?.filterChange}
      value={batchFilterSelectState.selected}
      //value={groupSelectStore.groupSelected}
      //inputValue={createCodeInput.groupSelected}
      isSearchable
      //onInputChange={(data) => groupSelectStore.searchGroup(data)}
      //onChange={(data) => groupSelectStore.selectGroup(data)}
      // getOptionLabel={option => option.label}
      // getOptionValue={option => option.uuid}
      isMulti
      // formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
});

export default BatchFilterSelect;
