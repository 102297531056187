import HttpClient from "../../services/HttpClient";
import {FormattedMessage, useIntl} from "react-intl";
import { injectIntl } from "react-intl";
import moment from "moment-timezone";
import {codeTypes} from "../../utils/constants";

// import InternationizedString from "src/managers/codes/International.js"
/**
 *
 */
interface CodeInstance {
  name: String, //codename
  uuid: String, //qruuid

  //data,
  //attributes
  //

}




const InternationizedString = () => {
  const intl = useIntl();
  return intl;
};
class CodeManager {





  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/qr/codes`
    // this.intl = useIntl();


  }
    removeEmptyValuesFromUtm(utm) {
      return JSON.parse(JSON.stringify({ // removes empty values
        "utm": {
          "campaign": utm.campaign.length > 0 ? utm.campaign: undefined,
          "source": utm.source.length > 0 ? utm.source: undefined,
          "medium": utm.medium.length > 0 ? utm.medium: undefined,
          "term": utm.term.length > 0 ? utm.term: undefined,
          "content": utm.content.length > 0 ? utm.content: undefined,
        }
      }))
    }
  /**
   * Convert the frontend object into the API Resource for QRCode
   * @param instance
   * @returns {Promise<void>}
   */
  async buildCodeResource(instance){
    return {}
  }

  resetFieldIfNotEmpty(obj, field, defaultValue) {
    if (obj[field]?.length > 0) {
      obj[field] = defaultValue;
    }
  }

  async save(instance) {
    if(instance.experienceSelected.name === "vevent") {
      let parsedData = JSON.parse(instance.experienceData);
      parsedData.localTimeZone =  moment.tz.guess();
      instance.experienceData = JSON.stringify(parsedData);
    }
    let uri = ""
    if (instance?.vCardDownLoadLinkData?.length > 0) {
      uri = {"uri": instance?.vCardDownLoadLinkData}
    }
    if (instance?.keyword && instance?.keywordStatus) {
      uri = {"uri": instance?.keyword};
    }
    try {
      const data = {
        "name": instance.name,
        "type":instance.experienceSelected.name,
        "data": JSON.parse(instance.experienceData),
        "extendedData": instance?.getExtendedDataRequest(),
        "behavior": instance.behavior,
        "tags": instance.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        "rules":JSON.parse(instance.rules),
        ...this.removeEmptyValuesFromUtm(instance.utm),
        "parameters": {
          "codeParamType": instance.parameters.codeParamType,
          "scanParamType": instance.parameters.scanParamType,
          "countParamType": instance.parameters.countParamType,
          "premiumParamType": instance.parameters.premiumParamType,
          "customParamType": instance.parameters.customParamType,
        },
        "dataDetail": instance.pageTemplateSelectStore.selectedConfig?.configs,
        "owner": {
          "groupUuid":instance.groupSelectStore.selectedGroup.uuid,
          "dimensions":instance.getDimensions()
        },
        "shortUrl": {
          "domain":instance.publishDomainSelectStore.selectedPublishDomain?.domain,
          "prefix": instance.publishDomainSelectStore.selectedPublishDomain?.selectedPrefix?.prefix,
          ...uri
        },
        "images":{"configs":[{"uuid":instance.imageUuid}]},
        "attributes": {
          "static": {
          },
          "dynamic": {}
        },
        "statusDetail":{
          "publishOnUpdate":false,
          "schedule" : instance?.statusViewState?.getStatusScheduleData()
        },
        "status":instance.status
      }
      const res = await this.httpClient.post(this.url, data);
      console.log("res success",   res)
      return res
    } catch (e) {
      if (e.message.includes("data/url must match pattern")) {
        e.message = "Enter a  valid URl"
}
      instance.handleHasErrors(true);
      instance.handleErrorMessage(e.message);
      console.log("failed to save code: ", e.message);
      return e
    }
  }

  async isURLAvailable(data) {
    try {
      const res = await this.httpClient.post("qr/codes/available", data);
      return res?.data?.available;
    } catch (e) {
      console.error("Failed isURLAvailable: ", e.message)
    }
    return false;
  }

  async update(instance) {
    //TODO: Backend will throw 400 validation errors, need to show in form..
    if(instance?.experienceSelected?.name === "vevent") {
      let parsedData = JSON.parse(instance?.experienceData);
      parsedData.localTimeZone =  moment.tz.guess();
      instance.experienceData = JSON.stringify(parsedData);
    }
    if (instance?.experienceSelected?.name !== codeTypes.URL && instance?.experienceSelected?.name !== codeTypes.GS1) {
      instance.parameters = instance.parameters || {};
      this.resetFieldIfNotEmpty(instance.parameters, 'codeParamType', []);
      this.resetFieldIfNotEmpty(instance.parameters, 'scanParamType', []);
      this.resetFieldIfNotEmpty(instance.parameters, 'countParamType', []);
      this.resetFieldIfNotEmpty(instance.parameters, 'premiumParamType', []);
      this.resetFieldIfNotEmpty(instance.parameters, 'customParamType', []);
      if (instance.rules?.length > 0) {
        instance.rules = '[]';
      }
      instance.utm = instance.utm || {};
      this.resetFieldIfNotEmpty(instance.utm, 'campaign', '');
      this.resetFieldIfNotEmpty(instance.utm, 'source', '');
      this.resetFieldIfNotEmpty(instance.utm, 'medium', '');
      this.resetFieldIfNotEmpty(instance.utm, 'term', '');
      this.resetFieldIfNotEmpty(instance.utm, 'content', '');
    }
    try {
      const data = {
        "uuid": instance?.uuid,
        "name": instance?.name,
        "type":instance?.experienceSelected.name,
        "data": JSON.parse(instance?.experienceData),
        "extendedData": instance?.getExtendedDataRequest(),
        "dataDetail": instance?.pageTemplateSelectStore.selectedConfig?.configs,
        "tags": instance?.tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        "rules":JSON.parse(instance?.rules),
        ...this.removeEmptyValuesFromUtm(instance?.utm),
        "parameters": {
          "codeParamType": instance.parameters.codeParamType,
          "scanParamType": instance.parameters.scanParamType,
          "countParamType": instance.parameters.countParamType,
          "premiumParamType": instance.parameters.premiumParamType,
          "customParamType": instance.parameters.customParamType,
        },
        "owner": {
          "groupUuid":instance?.groupSelectStore.selectedGroup.uuid,
          "dimensions":instance?.getDimensions()
        },
        // "shortUrl": {
        //   "domain":instance?.publishDomainSelectStore.selectedPublishDomain?.domain,
        //   "uri":"...."
        // },
        "images":{"configs":[{"uuid":instance?.imageUuid}]},
        "statusDetail":{
          "publishOnUpdate": false,
          "schedule" : instance?.statusViewState?.getStatusScheduleData()
        },
        "status": instance?.codeStatus
      }
      const url = `${this.url}/${instance.uuid}`
      const res = await this.httpClient.put(url, data);

      if(res.data){
        console.log("Successfully updated", res)
        return res
      } else {
        throw new Error("Failed to update");
      }

    } catch (e) {
      if (e.message.includes("data/url must match pattern")) {
        e.message = "Enter a  valid URl"
      }
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.log("failed to save code: ", e.message);
    }
    console.log("Instance in codeManager 'put'", instance)
  }
  async removeDesign(instance) {
    const response = await this.fetchQrcodeWithId(instance.uuid)
    try {
      const data = {
        "id": response?.uuid,
        "name": response?.name,
        "type":response?.type,
        "data": response?.data,
        "images":{"configs":[{"uuid":""}]},
      }
      const url = `${this.url}/${instance.uuid}`
      const res = await this.httpClient.put(url, data);

      if(res.data){
        console.log("Successfully updated", res)
        return res
      } else {
        throw new Error("Failed to update");
      }
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
    }
    console.log("Instance in codeManager 'put'", instance)
  }

  /**
   * this will only disable/archive the qr, we never delete
   * @param instance
   * @returns {Promise<void>}
   */
  async delete(instance) {

    try {
      const url = `${this.url}/${instance.uuid}`;
      const res = await this.httpClient.destroy(url);
      console.log("delete response in the try block ", res)
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
        return response
    } catch (e) {
      instance.handleHasDeleteErrors(true)
      instance.handleDeleteErrorMessage(e.message)
      console.error("Failed to delete code", e.message)
    }
  }

  /***
   * restore a deleted/archived qr
   * @param instance
   * @returns {Promise<void>}
   */
  async recover(instance) {

  }
  async fetchAllCodes(queryParams) {
    if(!queryParams.hasOwnProperty("sort")) {
      queryParams = {
        ...queryParams,
        sort: "updatedAt",
        order: "desc"
      }
    }

    try {
      const res = await this.httpClient.get(this.url,queryParams );
      console.log("response in get request", res)
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get code", e);
    }
    return null;
  }

  async fetchAllFilters(queryParams) {
    const url = '/sites/filters/qr'
    try {
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request users", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get code", e);
    }
    return null;
  }

  async fetchQrcodeWithId(uuid) {
    const url = this.url + "/" + uuid
    try {
      const res = await this.httpClient.get(url);
      console.log("response in get request", res)
      if (res.data) {
        return res
      }
    } catch (e) {
      console.error("Failed to get code", e);
    }
    return null;
  }

  async publish(instance, code) {
    try {
      //TODO: Change this to  `/qr/codes/${code.uuid}/publish`
      let url = `qr/publish?uuid=${code.uuid}`
      const res = await this.httpClient.post(url);
      if (res?.error?.status === 403) return res
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("?")[1].split("=")[1]
      console.log("response in publish request", response);
      return response
    } catch (e) {
      instance.handleHasPublishErrors(true)
      instance.handlePublishErrorMessage(e.message)
      console.error("Failed to publish code", e.message)
    }
  }
}

export default CodeManager;
