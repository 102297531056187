import React, { createContext, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';

const defaultComponents = {
  // Add your default components here
  _tenantUuid: undefined,
};

/* The context itself */
const ComponentsContext = createContext(defaultComponents);

/* The provider lets you change the provided components */
const ScanbuyContextProvider = ({ children, components = defaultComponents, ...props }) => {
  const parsedHash = queryString.parse(window.location.hash);
  const {cacheLocation, defaultContext, storageKey, unAuthRedirect} = props;
  const [currentContext, setCurrentContext] = useState(undefined);

  useEffect(() => {
    //console.log('onLoad hash ', parsedHash);
    if(defaultContext) {
      //console.log('defaultContext ', defaultContext); for local only
      setCurrentContext(defaultContext)
    } else if (parsedHash && parsedHash[storageKey]) {
      setCurrentContext(parsedHash[storageKey])
    } else if (localStorage.getItem(storageKey)) {
      setCurrentContext(localStorage.getItem(storageKey))
    } else {
      handleUnAuthRedirect();
    }
  }, [children]);

  useEffect(() => {
    if(currentContext) {
      localStorage.setItem(storageKey, currentContext);
      components._tenantUuid = currentContext;
    }
  }, [currentContext]);

  const handleUnAuthRedirect = () => {
    localStorage.removeItem(storageKey);
    window.location.href = unAuthRedirect;
  }

  return (
    <ComponentsContext.Provider value={components}>
      {children}
    </ComponentsContext.Provider>
  );
};

/* A custom hook to get access to the provided components */
export const useScanbuyContext = () => useContext(ComponentsContext);

/* The components provider itself */
export default ScanbuyContextProvider;
