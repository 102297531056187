import validator from "@rjsf/validator-ajv8";
import React from "react";
import {withTheme} from "@rjsf/core";
import {Theme as Bootstrap4Theme} from "@rjsf/bootstrap-4";
const ThemedForm = withTheme(Bootstrap4Theme);

// This widget is used to provide a default time for the RJSF time widget

const widgetSchema = {
  "properties": {
    "time": {
      "type": "string",
      "format": "time",
    }
  }
}
const uiSchema = {
  "time": {
    "ui:label": false
  }
}

export const TimeWidget = ({value, onChange, schema = {}}) => {
    const handleOnChange = (e) => {
      const parsedTime = e.formData?.time;
      onChange(parsedTime)
    }

const data = value ? {time: value} : {time: schema.default}
  return (
    <ThemedForm
      schema={widgetSchema}
      formData={data}
      uiSchema={uiSchema}
      validator={validator}
      onChange={(data)=>{handleOnChange(data)}}
    >
      <div></div>
    </ThemedForm>
  );
};