import {getType, resolveIdentifier, types} from "mobx-state-tree";
import lodash from "lodash";
import Parameter from "../../../../models/Parameter";
import PremiumParamsStore from "../../../../stores/domain/parameters/PremiumParamsStore";

export const PremiumParamsTypeSelectState = types.model('PremiumParamsTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.reference(PremiumParamsStore)),
  selectedPremiumParameters: types.optional(types.array(types.safeReference(Parameter)), []),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  setConsumer(f){
    self.notifySelect = f
  },
  selectParameters(e) {
    if (lodash.isArray(e)) {
      self.selectedPremiumParameters = e?.map(g => {
        return resolveIdentifier(Parameter, self, g?.uuid || g)
      })
    } else if (e) {
      self.selectedPremiumParameters = e
    }
  },
})).views( (self) => ({
  options() {
    return self.store.getCodeParameters() || [];
  },
}))
