import {flow, getEnv, types} from 'mobx-state-tree'
import Parameter from "../../../models/Parameter";


const PremiumParamsStore = types.model('PremiumParamsStore', {
  storeUuid: types.identifier,
  list: types.optional(types.array(Parameter), []),
}).actions((self) => ({

  initWithData(parameters = []) {
    if (parameters) {
      self.setList(parameters)
    } else {
      self.setList("")
    }
  },
  setList(list) {
    if (list) {
      self.list = list.map(p => Parameter.create({
        uuid: p?.uuid,
        name: p?.name,
        label: p?.label,
      }))
    } else {
      self.list = []
    }
  },
  fetchParameters: flow(function* f() {
    //call parameter service
    const manager = getEnv(self).parametersManager;
    self.isLoading = true
    const response = yield manager.fetchOne("premiumParams");
    if (response != null) {
      self.setList(response.items)
      self.isLoading = false;
    }else{
      self.isLoading = false;
    }

  }),
})).views((self) => ({
  getCodeParameters() {
    return self.list
  }
}))


export default PremiumParamsStore;
