/**
 * Initialize the Mobx Router and Transitions.
 *
 */
import {
  browserHistory,
  createRouterState,
  HistoryAdapter,
  RouterStore,
} from "mobx-state-router";
import { menuroutes, routes } from "./index";


const notFound = createRouterState('notFound');

export function initRouter(rootStore) {

  const routerStore = new RouterStore(routes, notFound, {rootStore: rootStore, menuRoutes: menuroutes });

  // Observe history changes
  const historyAdapter = new HistoryAdapter(routerStore, browserHistory);
  historyAdapter.observeRouterStateChanges();

  return routerStore;
}

