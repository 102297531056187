import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Badge, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouterLink } from "mobx-state-router";
import lodash from "lodash";
import IosCreate from "react-ionicons/lib/IosCreate";
import IosPaper from "react-ionicons/lib/IosPaper"
import scanbuyLogo from "../assets/img/alt/scanbuy_logo.png"
import { StateContext } from "../App";
import config from "../configs";

const initOpenRoutes = (location, routes) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {[index]: isActive || isOpen || isHome})
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = path => {
      return (location.pathname === "/"+path) || location.pathname === path  ? "active" : "";
    };

    const getSidebarItemID = sidebarItem => {
      return "sidebar-item-" + sidebarItem.split("/").pop() 
    }

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <RouterLink id={getSidebarItemID(to)} routeName={to}  className="sidebar-link" activeClassName="active">
          {icon ? (

            <React.Fragment>
              { icon === IosPaper && <IosPaper color="#2977a8" fixedWidth className="svg-inline--fa fa-mail-bulk fa-w-18 fa-fw align-middle mr-2" />}
              { icon === IosCreate && <IosCreate color="#2977a8" fixedWidth className="svg-inline--fa fa-mail-bulk fa-w-18 fa-fw align-middle mr-2" />}
              {<FontAwesomeIcon icon={icon} fixedWidth className="align-middle mr-2" />}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </RouterLink>
      </li>
    );
  }
);
const filterRoutes = (currentRoutes, features) => {
  console.log(currentRoutes);
  return lodash.filter(currentRoutes, (obj)=>{
    if(obj.featureFlagRequired && features){
      return features[obj.featureFlagRequired]?.enabled;
    } else {
      return true;
    }
  })

}
const Sidebar = ({ location, sidebar }) => {

  const stateContext = useContext(StateContext);
  const liveRoutes = filterRoutes(stateContext.routeStore.options.menuRoutes, stateContext?.appConfigs?.features);

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location,stateContext.routeStore.options.menuRoutes));

  console.log(stateContext);

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[item]: false}))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  }

  return (
    <nav
      className={classNames(
        "sidebar",
        sidebar.isOpen || "toggled",
        !sidebar.isOnRight || "sidebar-right"
      )}
    >
      <div className="sidebar-content">
        <a
          className={classNames(
            "sidebar-brand",
            !sidebar.isOnRight || "text-right"
          )}
          href={config.PORTAL_URL}
        >
          <img src={scanbuyLogo} alt="scanbuy logo" height="30" />
        </a>

        {/*<div className="sidebar-user">*/}
        {/*  <img*/}
        {/*    src={avatar}*/}
        {/*    className="img-fluid rounded-circle mb-2"*/}
        {/*    alt="Linda Miller"*/}
        {/*  />*/}
        {/*  <div className="font-weight-bold">Linda Miller</div>*/}
        {/*  <small>Front-end Developer</small>*/}
        {/*</div>*/}

        <ul className="sidebar-nav">
          {liveRoutes.map((category, index) => {
            return (
              <React.Fragment key={index}>
                { category.header ? (
                  <br/>
                  // <li className="sidebar-header">{category.header}</li>
                ) : null }

                {category.children ? (
                  <SidebarCategory
                    name={category.name}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                    icon={category.icon}
                    to={category.path}
                    isOpen={openRoutes[index]}
                    onClick={() => toggle(index)}
                  >
                    {category.children.map((route, index) => (
                      <SidebarItem
                        key={index}
                        name={route.name}
                        to={route.path}
                        badgeColor={route.badgeColor}
                        badgeText={route.badgeText}
                      />
                    ))}
                  </SidebarCategory>
                ) : (
                  <SidebarItem
                    name={category.name}
                    to={category.path}
                    icon={category.icon}
                    badgeColor={category.badgeColor}
                    badgeText={category.badgeText}
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </nav>
  )
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar
  }))(Sidebar)
);
