import {flow, getEnv, getType, types} from "mobx-state-tree";
import {createExtendedDataSetModel, ExtendedDataSetModel} from "../../models/extendedData/ExtendedDataSet";

const ExtendedDataSetStore = types.model('ExtendedDataSetStore',{
  storeUuid: types.identifier,
  extendedDataSetList: types.optional(types.array(ExtendedDataSetModel), []),
}).volatile((self)=>({
  filterListOn: types.fn,
})).actions((self) => ({
  setFilterOn(funct) {
    self.filterListOn = funct;
  },
  setList(data) {
    self.extendedDataSetList = data?.map((item) => {return createExtendedDataSetModel(item, self)});
    self.sort();
  },
  initWithData(data = []) {
    try {
      if (data) {
        self.setList(data);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to initWithData`, e);
    }
  },
  init: flow(function* (queryParams = {}) {
    const response = yield getEnv(self).extendedDataManager.fetchAllExtendedDataSet(queryParams, self);
    self.initWithData(response?.results);
  }),
  sort() {
    try {
      self.getExtendedDataSetList()?.sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      console.warn(`${getType(self).name} Warn: Failed to sort`, e);
    }
  }
})).views((self) => ({
  getExtendedDataSetList() {
    if (self.filterListOn) {
      return self.extendedDataSetList.filter(self.filterListOn);
    } else {
      return self.extendedDataSetList;
    }
  }
}));

export default ExtendedDataSetStore;
