import { observer } from "mobx-react-lite";
import Select from "react-select";


const GroupSelect = observer(({ groupSelectStore, multi = false, disabled = false }) => {
  //TODO: leave comments until tested in dev
  // console.log("GroupSelect: groupSelectStore: ", groupSelectStore?.selectedGroup?.toJSON());
  // console.log("GroupSelect: groupOptionValues: ", groupSelectStore?.groupOptionValues());
  // console.log("GroupSelect: multi: ", multi);
  // if (multi) {
     return (
      <Select
        sm={10}
        className="react-select-container"
        classNamePrefix="react-select"
        options={groupSelectStore?.groupOptionValues()}
        id="selectedGroup"
        //inputValue={createCodeInput.selectedGroup}
        isSearchable
        onInputChange={(data) => groupSelectStore?.searchGroup(data)}
        onChange={(data) => groupSelectStore?.selectGroup(data)}
        getOptionLabel={option => option.label}
        getOptionValue={option => option.uuid}
        defaultValue={!multi ? groupSelectStore?.getFirstGroup() : groupSelectStore?.selectedGroups || []}
        //defaultValue={groupSelectStore?.selectedGroup ? groupSelectStore.selectedGroup : null}
        //defaultValue={ groupSelectStore?.getFirstGroup()}
        isMulti={multi}
        placeholder="Select"
        isDisabled={disabled}
      />
    );
  // } else {
  //   return (
  //     <Select
  //       sm={10}
  //       className="react-select-container"
  //       classNamePrefix="react-select"
  //       options={groupSelectStore?.groupOptionValues()}
  //       id="selectedGroup"
  //       defaultValue={groupSelectStore?.getFirstGroup()}
  //       //inputValue={createCodeInput.selectedGroup}
  //       isSearchable
  //       onInputChange={(data) => groupSelectStore?.searchGroup(data)}
  //       onChange={(data) => groupSelectStore?.selectGroup(data)}
  //       getOptionLabel={option => option.label}
  //       getOptionValue={option => option.uuid}
  //       //value={groupSelectStore?.getFirstGroup()}
  //     />
  //   );
  // }

});

export default GroupSelect;
