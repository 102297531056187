import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import config from "./configs";
import ScanbuyContextProvider from "./providers/ScanbuyContextProvider";


ReactDOM.render(
  <ScanbuyContextProvider
    cacheLocation="localstorage"
    storageKey="x-sb-context"
    unAuthRedirect={config.PORTAL_URL}
    defaultContext={config?.LOCAL_TENANT}
  >
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        audience={config.AUTH0_AUD}
        redirectUri={config.AUTH0_CALLBACK}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience:config.AUTH0_AUD
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </ScanbuyContextProvider>,
  document.getElementById("root")
);
