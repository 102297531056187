import {types} from "mobx-state-tree";

export const GenericToastState = types.model('GenericToastState', {
  showToast: types.optional(types.boolean, false),
  toastHeader: types.optional(types.string, "Great Job!!"),
  smallText: types.optional(types.string, ""),
  toastBody: types.optional(types.string, "You have created a toast message"),

}).volatile((self) => ({})).actions((self) => ({
  toggleShowToast() {
    self.showToast = !self.showToast
  },
  handleHeader(str) {
    self.toastHeader = str
  },
  handleSmallText(str) {
    self.smallText = str
  },
  handleBody(str) {
    self.toastBody = str
  },
  afterCreate() {

  }
}))

