import {types} from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";
import {TagModel} from "../tags/TagModel";

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string)
})

export const ParameterSet = types.model('ParameterSet', {
  uuid: types.identifier,
  name: types.maybe(types.string),
  label: types.maybe(types.string),
  owner: types.maybe(GroupData),
  _audit: types.maybeNull(AuditMetaData),
  isEditable: types.optional(types.boolean, false),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  tags: types.optional(types.array(types.safeReference(types.late(() => TagModel))), []),
  //TODO: complete object

})
