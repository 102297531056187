import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import Select from "react-select";
import {
	Row,
	Col,
	Collapse,
	Navbar,
	Nav,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	ListGroup,
	ListGroupItem,
	Form,
	Input
} from "reactstrap";

import RootAppStore from "../stores/RootAppStore";

const CountrySelector = observer(() =>{
	const [selectedCountry, setSelectedCountry] = useState('');

	const handleCountryChange = (event) => {
		setSelectedCountry(event.target.value);
		RootAppStore.currentLocale = event.target.value
	};

	return (
		<>
			<UncontrolledDropdown nav inNavbar className="ml-lg-1">
				<DropdownToggle nav caret>
					{selectedCountry ? (
						{
							'en-US': 'USA (en)',
							'fr-BE': 'Belgium (french)',
							'nl-BE': 'Belgium (dutch)',
							'en-UK': 'UK (en)',
							'fr-FR': 'France (fr)',
							'de-DE': 'Germany (de)',
							'it-IT': 'Italy (it)',
							'pt-PT': 'Portugal (pt)',
							'es-ES': 'Spain (es)',
							'jp-JP': 'Japan (jp)',
						}[selectedCountry]
					) : (
						'Country'
					)}
				</DropdownToggle>
				<DropdownMenu right>
					{/*<DropdownItem>en-US</DropdownItem>*/}
					{/*<DropdownItem>fr-BE</DropdownItem>*/}
					{/*<DropdownItem>nl-BE</DropdownItem>*/}
					{/*<DropdownItem>en-UK</DropdownItem>*/}
					{/*<DropdownItem>fr-FR</DropdownItem>*/}
					{/*<DropdownItem>de-DE</DropdownItem>*/}
					{/*<DropdownItem>it-IT</DropdownItem>*/}
					{/*<DropdownItem>de-DE</DropdownItem>*/}
					<DropdownItem value={"en-US"} onClick={handleCountryChange}>en-US</DropdownItem>
					<DropdownItem value={"fr-BE"} onClick={handleCountryChange}>Belguim (french)</DropdownItem>
					<DropdownItem value={"nl-BE"} onClick={handleCountryChange}>Belgium (dutch)</DropdownItem>
					<DropdownItem value={"en-UK"} onClick={handleCountryChange}>UK (en)</DropdownItem>
					<DropdownItem value={"fr-FR"} onClick={handleCountryChange}>France (fr)</DropdownItem>
					<DropdownItem value={"de-DE"} onClick={handleCountryChange}>Germany (de)</DropdownItem>
					<DropdownItem value={"it-IT"} onClick={handleCountryChange}>Italy (it)</DropdownItem>
					<DropdownItem value={"pt-PT"} onClick={handleCountryChange}>Portugal (pt)</DropdownItem>
					<DropdownItem value={"es-ES"} onClick={handleCountryChange}>Spain (es)</DropdownItem>
					<DropdownItem value={"jp-JP"} onClick={handleCountryChange}>Japan (jp)</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	)
});

export default CountrySelector;
