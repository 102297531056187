import {flow, getEnv, getType, types} from "mobx-state-tree";
import {create, DataExports} from "./DataExports";

export const DataExportListModel = types.model("DataExportListModel", {
  items: types.optional(types.array(DataExports), []),
  isLoading: types.optional(types.boolean, false),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField: types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  searchParams: {},
  filters: {},
})).actions((self) => ({
  fetchAllFilters: flow(function* (sortParams = {}) {
    self.isLoading = true;
    try {
      const queryParams = {
        ...(Object.keys(sortParams).length === 0 && {
          sort: 'updatedAt',
          order: self.sortOrder
        }),
        ...self.searchParams
      };
      self.filters = yield getEnv(self).dataExportManager.fetchAllFilters(queryParams, self);
    } catch (error) {
      console.error(`${getType(self).name} Error: Failed to fetch filters`, error);
    }
    self.isLoading = false;
  }),
  removeItem(uuid) {
    self.items = self.items.filter(i => i.uuid !== uuid);
  },
  fetchAll: flow(function* fetchAll(sortParams = {}) {
    self.isLoading = true;
    try {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...(Object.keys(sortParams).length === 0 && {
          sort: 'updatedAt',
          order: self.sortOrder
        }),
        ...self.searchParams
      };
      const response = yield getEnv(self).dataExportManager.fetchAllForMe(queryParams, self);

      if (response) {
        self.items = response?.results?.map((i) => create(i, self));
        self.totalRows = response._meta?.itemCount;
      }
    } catch (error) {
      console.error(`${getType(self).name} Error: Failed to fetch`, error);
    }
    self.isLoading = false;
  }),
  setSortOrder(sortDirection) {
    self.sortOrder = sortDirection;
  },
  setSortField(sortField) {
    self.sortField = sortField;
  },
  updatePage(page) {
    self.page = page;
  },
  updatePerPage(perPage) {
    self.perPage = perPage;
  },
  addSearchParams(param) {
    self.searchParams = { ...self.searchParams, ...param };
  },
  removeSearchParams(names, regex = '') {
    if (names) {
      names.map(name => delete self.searchParams[name]);
    }
    if (regex) {
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }
  },
  getItem(uuid) {
    return self.items.find(i => i.uuid === uuid);
  },
  delete: flow(function* (uuid) {
    return yield getEnv(self).dataExportManager.delete(uuid, self);
  }),
  getDownloadLink: flow(function* (uuid) {
    return yield getEnv(self).dataExportManager.getDownloadLink(uuid, self);
  }),
  performAction: flow(function* (uuid, action) {
    return yield getEnv(self).dataExportManager.performAction(uuid, action, self);
  }),
})).views((self) => ({
  getItems() {
    return self?.items || [];
  },
}));
