import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup, Input,
    InputGroup,
    Label,
    Row,
    CardImg, Collapse
} from "reactstrap";
import {observer} from "mobx-react-lite";

const EyesRotateItem =  observer(({eyeLidRotation, eyeBallRotation, handleEyeLidRotation, handleEyeBallRotation, position}) => {
    const eyeLidValue = eyeLidRotation
    return (
        <>
            <Row> 
            <Col sm={1}></Col> 
            <Col sm={1}>Eyelid</Col>
            <Col sm={1}></Col>
            <Col sm={1}>
                <CustomInput
                    type="select"
                    value={eyeLidValue}
                    id={position}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(e) => handleEyeLidRotation(e, position)}
                    >
                    <option value="0">0</option>
                    <option value="90">90</option>
                    <option value="180">180</option>
                    <option value="270">270</option>
                    </CustomInput>
            </Col>
            </Row>
            <Row>
            <Col sm={1}></Col>
            <Col sm={1}>Eyeball</Col>
            <Col sm={1}></Col>
            <Col sm={4}>
                <CustomInput
                            type="number"
                            id={eyeBallRotation}
                            value={eyeBallRotation}
                            onChange={(e) => handleEyeBallRotation(e, position)}
                            style={{width: "15%"}}
                            ></CustomInput>
            </Col>
            </Row>
        </>
    )
})

export default EyesRotateItem;

