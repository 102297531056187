import HttpClient from '../../services/HttpClient'

class QrTemplateManager{
    constructor(httpClient = null){
        this.httpClient = httpClient || new HttpClient();
        this.url = `/qr/templates`;
        // console.log("Http Client in TemplateManager",this.httpClient)
    }
   
    async fetchAll(queryParams){
        try {
          // console.log("entered into the templateManager")
          return await this.httpClient.get(this.url, queryParams);
        } catch (e) {
          // console.log("Error searching for a Templates");
        }
        return null;
      }

    async save(instance){
      try{

        const data= JSON.parse(instance.experienceData)
        const url = `${this.url}/${instance.uuid}/codes`
        const res = await this.httpClient.post(url,data)
  
      return res
      }catch(e){
        if (e.message.includes("data/url must match pattern")) {
          e.message = "Enter a  valid URl"
  }
        instance.handleHasErrors(true);
        instance.handleErrorMessage(e.message);
        return e
      }
    }
}

export default QrTemplateManager;