import { types } from "mobx-state-tree";
import { BatchDetails } from "./BatchDetails";

export const Batch = types.model("Batch", {
  uuid: types.string,
  batchType: types.string,
  fileName: types.string,
  status: types.string,
  totalItems: types.integer,
  // remainingCodes: types.integer,
  createdAt: types.string,
  updatedAt: types.string,
  details: types.maybe(BatchDetails),
});
