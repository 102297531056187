import {types} from "mobx-state-tree";
import {DashboardStore} from "../../stores/domain/DashboardStore";


export const DashboardViewState = types.model('DashboardViewState', {
  appsStore : types.maybe(types.late(() => types.reference(DashboardStore))),
  selectedExperience: types.optional(types.string, '')
}).actions((self) => ({
  setSelectedExperience(type) {
    self.selectedExperience = type
  },
}))

