import React from "react";

const applyDisabledProp = (child, isDisabled) => {
  if (React.isValidElement(child)) {

    const isSpecialElement = child.props.id === "advanced-configs" || child.props.id === "back-btn" || child.props.id === "preview-btn"|| child.props.id === "cancel-btn";
    if (isSpecialElement) return child;

    if (child.props && child.props.children) {
      const updatedChildren = React.Children.map(child.props.children, nestedChild =>
        applyDisabledProp(nestedChild, isDisabled)
      );

      return React.cloneElement(child, {
        children: updatedChildren,
        disabled: isDisabled || child.props.disabled,
      });
    }

    return React.cloneElement(child, {
      disabled: isDisabled || child.props.disabled,
    });
  }

  return child;
};

const DisableWrapper = ({ isDisabled, children }) => {
  if (!isDisabled) {
    return <>{children}</>;
  }

  return React.Children.map(children, child =>
    applyDisabledProp(child, isDisabled)
  );
};

export default DisableWrapper;
