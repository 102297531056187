import {getType, resolveIdentifier, types} from 'mobx-state-tree'
import {DimensionSelectState} from "./dimensionSelectState";
import DimensionStore from "../../../stores/domain/DimensionStore";
import {DimensionValues} from "../../../models/Dimension";

export const DimensionSelectViewState = types.model('DimensionSelectViewState',{
  selectStateUuid: types.identifier,
  dimensionSelectStates : types.array(DimensionSelectState),
  store: types.reference(DimensionStore),
  dimensionsEnabled: types.optional(types.boolean,false)
}).actions( (self)=>({
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
  },
  init() {
    const dimensions = self.store?.dimensions
    if(dimensions){
      self.dimensionSelectStates = dimensions.map(d => {
        const defaultDimensionValue = d?.values?.find(item => {
          if(item?.isDefault){
            return resolveIdentifier(DimensionValues, self,item?.uuid || item)
          }
          return false
        }) || undefined
        return DimensionSelectState.create({
          selectStateUuid: d?.uuid,
          dimension: d,
          selectedDimension: defaultDimensionValue
        })
      })
    }
    self.dimensionsEnabled = self.store?.dimensionsEnabled
  },
  setConsumer(fn){
    self.dimensionSelectStates.forEach(selectState => selectState?.setConsumer(fn))
  },
  setFilterConsumer(fn) {
    self.dimensionSelectStates.forEach(selectState => selectState?.setFilterConsumer(fn))
  },
  setFilteredDimensions(dimensions) {
    self.dimensionSelectStates.forEach(selectState => selectState?.setDimensionFilters(dimensions))
  },
  setDefaults(){
    self.dimensionSelectStates.forEach(selectState => selectState?.setDefaults())
  },
  reset() {
    self.dimensionSelectStates.forEach(selectState => selectState.reset())
  },
  setDimensions(obj){
    for (let key of Object.keys(obj)) {
      self.dimensionSelectStates.find(selectState => selectState?.selectStateUuid === key)?.setSelectedDimension(obj[key])
    }
  },
  clearSelections(){
    self.dimensionSelectStates.forEach(item => item?.clearSelectedDimensions())
  }
}))
