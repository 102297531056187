import {
  flow, getEnv,
  getType,
  resolveIdentifier,
  types,
} from 'mobx-state-tree'
import { QrConfigStore} from "../../../stores/domain/QrConfigStore";
import QrConfiguration from "../../../models/QrConfiguration";

export const QrConfigSelectState = types.model('QrConfigSelectState',{
  selectStateUuid: types.identifier,
  configStore: types.maybe(types.late(() => types.reference(QrConfigStore))),
  selectedConfig: types.maybe(types.safeReference(QrConfiguration)),
  selectedGroup:  types.maybe(types.string)
}).volatile(self => ({
  notifySelect: types.f,
  updateImageInPreview: types.f,
  hydrateModal: types.f,
  filterListOn:types.fn,
  previewImageNull: types.f,
})).actions((self)=>({
  searchConfig(txt){
    //search for config by typing into input
  },
  setConsumer(f){
    self.notifySelect = f
  },
  setUpdateImageInPreview(f){
    self.updateImageInPreview = f
  },
  setHydrateModal(f){
    self.hydrateModal = f
  },
  setPreviewImageNullFunction(f){
    self.previewImageNull = f
  },
  /**
   * When group is selected, filter the list of configs to only show configs in that group
   * also set the selected config to null (when group is selected, no config is selected by default)
   * @param groupUuid
   * @returns {Promise<void>}
   */
  async setSelectedGroup(groupUuid) {
    console.log("setSelectedGroup", groupUuid)
    if(self.selectedGroup !== groupUuid) { //this avoids unnecessary calls to the api
      self.selectedGroup = groupUuid;
      await self.configOptionLoadValues();
    }
    //self.setSelectedConfig(undefined);
  },
  selectConfig: flow(function* f(c) {
    if(c && c.uuid) {
      const config = yield self.configStore.fetchImageConfigWithIdAndUpdateStore(c.uuid)
      c.setQrConfig(config.qrConfig)
      self.selectedConfig = c
      self.notifySelect(c)
      // self.updateImageInPreview(c)
      self.hydrateModal&&self.hydrateModal(c)
    } else {
      self.setSelectedConfig(undefined)
    }
  }),
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  },
  setSelectedConfig(c) {
    console.log("setSelectedImageConfig", c)
    if(c) {
      self.selectedConfig = resolveIdentifier(QrConfiguration, self, c?.uuid || c)
      self.hydrateModal && self.hydrateModal(self.selectedConfig)
    } else {
      self.selectedConfig = undefined
      self.previewImageNull && self.previewImageNull()
    }
  },
  async configOptionLoadValues(inputValue) {
    console.log("configOptionLoadValues", inputValue)
    let { defaultConfiguration, designerConfig } = self.configStore || {};
    try {
      const queryParams = {
        perPage: 20,
        sort: "updatedAt",
        order: "desc",
        isExclusivelyGroupOwned: false
      };
      if (inputValue) {
        console.log(inputValue)
        queryParams.q = inputValue;
      }
      if (self.selectedGroup){
        queryParams.groupUuid = self.selectedGroup;
      }
      console.log("$$$$$ 1 imageConfigManager.fetchAll")
      const response = await getEnv(self).imageConfigManager.fetchAll(queryParams);
      if (response?.results) {
        self.configStore.initWithData(response.results);
      }
      const {defaultConfiguration}  = self.configStore || {};
      return defaultConfiguration || []
    } catch (error) {
      // ... including try/catch error handling
      console.error("Failed to fetch Configs", error);
    }
    return [] //[designerConfig,...defaultConfiguration]
  },
})).views( self => ({
  configOptionValues() {
    const {defaultConfiguration}  = self.configStore || [];
    //let newList = defaultConfiguration.filter(element => element?.level === "global")
    //console.log("$$$ defaultConfiguration", toJS(defaultConfiguration))
    return defaultConfiguration.slice().sort((a,b) => a?.label?.localeCompare(b?.label))
  },
  getDefault() {
    const { defaultConfiguration } = self.configStore || {};
    const defaultConfig = defaultConfiguration.find(element => element.is_default === true)
    defaultConfig && self.selectConfig(self.selectedConfig ? self.selectedConfig :defaultConfig)
    return self.selectedConfig
  }
}))
