import React from "react";
import { observer } from "mobx-react-lite";
import { Col, CustomInput, Input, Row } from "reactstrap";
import { getSnapshot } from "mobx-state-tree";
import {  useIntl,FormattedMessage} from "react-intl";

const ImageAndCharacteristicsView = observer(({ store, isDefaultDesigner }) => {
  console.log(getSnapshot(store))
  const intl = useIntl();
  return (
    <>
      <Row>
        <Col sm={3}>
          <FormattedMessage id="qrDesign.create.resolution.defaultMessage" />
        </Col>
        <Col sm={3}>
          <CustomInput
            type="number"
            min="0"
            max="1200"
            id="resolution"
            value={store.resolution}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={store.handleResolutionChange}
            style={{width: "100%"}}
          >
          </CustomInput>
        </Col>
        <Col sm={3}>
          {!store.isValidResolution && <p className="text-danger"><FormattedMessage id="qrDesign.create.validImageResolution.defaultMessage"/></p>}
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <FormattedMessage id="qrDesign.create.imageSize.defaultMessage" />
        </Col>
        <Col sm={3}>
          <CustomInput
            type="number"
            min="0"
            max="5"
            id="size"
            value={store.size}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={store.handleSizeChange}
            style={{width: "100%"}}
          >
          </CustomInput>
        </Col>
        <Col sm={3}>
          {!store.isValidSize && <p className="text-danger"><FormattedMessage id="qrDesign.create.validImageSize.defaultMessage"/></p>}
        </Col>
      </Row>
      
      {/*/////////////////////////// remove 'Select Format' till after deployment //////////////////////////////////*/}
      {/*<Row>*/}
      {/*  <Col sm={3}>*/}
      {/*    <FormattedMessage id="qrDesign.create.selectFormat.defaultMessage" />*/}
      {/*  </Col>*/}
      {/*  <Col sm={3}>*/}
      {/*    <CustomInput*/}
      {/*      type="select"*/}
      {/*      id="imageFormat"*/}
      {/*      value={store.imageFormat}*/}
      {/*      className="react-select-container"*/}
      {/*      classNamePrefix="react-select"*/}
      {/*      onChange={store.handleFormatSelect}*/}
      {/*    >*/}
      {/*      <option value="png">{intl.formatMessage({ id: 'qrDesign.create.selectFormatOptions.PNG.defaultMessage' })}</option>*/}
      {/*      <option value="jpg">{intl.formatMessage({ id: 'qrDesign.create.selectFormatOptions.JPG.defaultMessage' })}</option>*/}
      {/*    </CustomInput>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*//////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

      <Row>
        <Col sm={3}>
          <FormattedMessage id="qrDesign.create.unitOfMeasures.defaultMessage" />
        </Col>
        <Col sm={3}>
          <CustomInput
            type="select"
            value={store.unitsOfMeasure}
            id="unitsOfMeasure"
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={store.handleUnitOfMeasure}
          >
            <option value="inch">{intl.formatMessage({ id: 'qrDesign.create.unitOfMeasuresOptions.inch.defaultMessage' })}</option>
            <option value="cm">{intl.formatMessage({ id: 'qrDesign.create.unitOfMeasuresOptions.cm.defaultMessage' })}</option>
          </CustomInput>
        </Col>
      </Row>

      {/*<Row>*/}
      {/*  <Col sm={3}>*/}
      {/*    Advance Features*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        {/*We don't support BWR yet but here is the element for the future*/}
        {/*<Col sm={3}>*/}
        {/*  BWR*/}
        {/*</Col>*/}
        {/*<Col sm={3}>*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    id="bwr"*/}
        {/*    value={store.bwr}*/}
        {/*    onChange={store.handleBwr}*/}
        {/*  />*/}
        {/*</Col>*/}
      </Row>
      {isDefaultDesigner &&
      <Row>
        <Col sm={3}>
          <FormattedMessage id="qrDesign.create.errorCorrection.defaultMessage" />
        </Col>
        <Col sm={3}>
          <CustomInput
            type="select"
            id="errorCorrection"
            value={store.errorCorrection}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={store.handleErrorCorrection}
          >
            <option value="L">{intl.formatMessage({ id: 'qrDesign.create.errorCorrectionOptions.L.defaultMessage' })}</option>
            <option value="M">{intl.formatMessage({ id: 'qrDesign.create.errorCorrectionOptions.M.defaultMessage' })}</option>
            <option value="Q">{intl.formatMessage({ id: 'qrDesign.create.errorCorrectionOptions.Q.defaultMessage' })}</option>
            <option value="H">{intl.formatMessage({ id: 'qrDesign.create.errorCorrectionOptions.H.defaultMessage' })}</option>
          </CustomInput>
        </Col>
      </Row>
      } 
      <Row>
        <Col sm={3}> <FormattedMessage id="qrDesign.create.quietZone.defaultMessage" /></Col>
        <Col sm={3}>
          <CustomInput
            type="select"
            id="quietZone"
            value={store.quietZone}
            className="react-select-container"
            classNamePrefix="react-select"
            onChange={store.handleQuiteZone}
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </CustomInput>
        </Col>
        {/*<Col sm={2}>*/}
        {/*  <CustomInput*/}
        {/*    type="select"*/}
        {/*    className="react-select-container"*/}
        {/*    classNamePrefix="react-select">*/}
        {/*    <option value="">Modules</option>*/}
        {/*    <option>1</option>*/}
        {/*  </CustomInput>*/}
        {/*</Col>*/}
      </Row>
      {!isDefaultDesigner &&
        <Row>
          <Col sm={3}> <FormattedMessage id="qrDesign.create.legacyDesgin.BWR.defaultMessage" /></Col>
          <Col sm={3}>
            <CustomInput
              type="number"
              min="0"
              max="0.01"
              step="0.001"
              id="bwr"
              value={store.bwr}
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={store.handleBwrChange}
              style={{width: "100%"}}
            >
            </CustomInput>
          </Col>
        </Row>
      }
    </>
  )
})
export default ImageAndCharacteristicsView;
