import {getType, types} from "mobx-state-tree";
import DataExportTypeStore from "../../../stores/domain/DataExportTypeStore";
import {DataExportType} from "../../../models/data/exports/DataExportType";

export const DataExportTypeSelectState = types.model('DataExportTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(DataExportTypeStore))),
  selected: types.maybe(types.safeReference(DataExportType))
}).volatile(self => ({
  notifySelect: types.function,
})).actions((self)=>({
  setConsumer(func){
    self.notifySelect = func;
  },
  search(data) {},
  select(option) {
    self.notifySelect(option);
    self.selected = option;
  },
  afterCreate() {
    console.log(`Instantiated: ${getType(self).name}`);
    self.notifySelect = (func) => {
      console.log(getType(self).name, func);
    }
  }
})).views((self) => ({
  options() {
    return self?.store?.getDataExportTypes();
  },
}));
