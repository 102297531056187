import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup, Input,
    InputGroup,
    Label,
    Row,
    CardImg, Collapse
} from "reactstrap";
import Select from "react-select";
import { types, flow } from "mobx-state-tree"
import {observer} from "mobx-react-lite";
import * as Icon from "react-feather";
import ColorButton from "../../../ColorButton";
import Slider from '../../../Slider';
import DisableWrapper from "../../../../utils/disableWrapper";

const Caption = observer(({key, caption, imageCharsStore, onRemove,colorStore,isDisabled = false}) => {
    return (
      <DisableWrapper isDisabled={isDisabled}>
        <>
            <Row>


                <Col sm={2}>
                    <CustomInput
                        type="select"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={caption.handleTextPositionChange}
                        value={caption.captionTextPosition}
                    >
                        <option>Top</option>
                        <option>Bottom</option>
                        <option>Left</option>
                        <option>Right</option>
                    </CustomInput>
                </Col>

                <Col sm={3}>
                    <Slider defaultValue={caption.captionFontSize}
                        handleSliderChange={caption.handleFontSizeChange}
                        isDisabled={isDisabled}
                    />
                </Col>

                <Col sm={1}>
                    <ColorButton
                        defaultedColor={caption.captionColor}
                        colorPosition={caption.textPosition}
                        colorMode={colorStore.colorMode}
                        handleColorMode={colorStore.handleColorMode}
                        selectColor={caption.handleSelectColor}
                        isCMYK={colorStore.isCMYK}
                    />
                </Col>


                <Col sm={5}>
                    <Input
                        type="input"
                        onChange={(event) => caption.handleContentChange(event, imageCharsStore)}
                        value={caption.captionContent}
                    />
                </Col>
                <Col sm={1}>
                    <a id="delete" className="delete" onClick={onRemove}><Icon.Delete/></a>
                </Col>
            </Row>
        </>
      </DisableWrapper>
    )
})

export default Caption;
