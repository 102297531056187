import { flow, getEnv, types } from "mobx-state-tree";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import config from "../../../configs";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import Group from "../../../models/Group";

const SupersetState = types.model('SupersetState', {
  reportUuid: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string), // superset dashboard uuid
  guestToken: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
	}).volatile((self) => ({
  mountPoint: types.object,
  searchParams: {}
})).volatile(self => ({
  dashboard: types.object,
  sizingInterval: types.fn,
  items: [],
}) ).actions((self) => ({
    getScrollSize(){

      return self.dashboard?.getScrollSize();
    },
    getDashboard(){
      return self.dashboard;
    },
    setSizingInterval(ref){
      self.sizingInterval = ref;
    },
    cancelSizingInterval(ref){
      try {
        clearInterval(self.sizingInterval)
      } catch (e) {

      }
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
    initDashboard: flow(function* f(){
        yield self.fetchReportInfo();

        try {
          self.dashboard = yield embedDashboard({
            id: self.uuid,  // given by the Superset embedding UI
            supersetDomain: config.SUPERSET_API_DOMAIN,
            mountPoint: document.getElementById("superset-container"),
            fetchGuestToken: self.fetchGuestToken,
            dashboardUiConfig: {
              hideTitle: false,
              hideChartControls: false,
              hideTab: false
            },
            filters: {
              expanded: true,
            }
          })
        } catch (e) {
          console.warn("Failed to init dashboard")
        }

    }),

    fetchReportInfo: flow(function* f() {
      //api call
      const manager = getEnv(self).reportManager;
      const report = yield manager.fetchReport(self.reportUuid);
      self.uuid = report.superSetInfo.resources[0].id;

    }),

    fetchGuestToken: flow(function* f(sortParams = {}) {
      self.items = []
      self.isLoading = true
      try {
        const groupUuids = {groupUuid: self.groupSelectStore.selectedGroups.flatMap((row) => row.uuid).join(", ")}
        const queryParams = {
          ...sortParams,
          ...self.searchParams,
          // ...groupUuids
        }
        //api call
        const manager = getEnv(self).reportManager;
        const guestToken = yield manager.fetchGuestTokenForReport(self.reportUuid, queryParams);
        console.log("guestToken", guestToken.token)
        self.guestToken = guestToken.token;
        return Promise.resolve(guestToken.token);
      } catch (error) {
        console.error('Failed to fetchGuestToken', error)
      }
    })
}))

export default SupersetState
