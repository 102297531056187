import { RouterState, RouterStore } from "mobx-state-router";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import async from "../../components/Async";
import React from "react";

const AppListPage = async(() => import("../../pages/applinks/ApplinkList"));
const CreateEditApplinkPage = async(() => import("../../pages/applinks/Applink"));


export const viewMap = {
  applinkList: <AppListPage/>,
  createApplink: <CreateEditApplinkPage/>,
  editApplink: <CreateEditApplinkPage/>,
  deleteApplink: <CreateEditApplinkPage/>,

};


export const routeDefinitions = [
  {
    path: "applinkList",
    name: "Applinks",
    icon: faLink,
    component: AppListPage,
    children: null,
    featureFlagRequired: "cmp_ui_applinks"
  }
];


export const routeTransitions = [
  {
    name: 'applinkList',
    pattern: '/configs/applinks',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      // const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'createApplink',
    pattern: '/configs/applinks/create',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      console.log("ONENTER createApplink")
      console.log(routerStore)
      //itemStore.loadDepartmentItems(toState.params.id);

    },
  },
  {
    name: 'editApplink',
    pattern: '/configs/applinks/edit/:id',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;

    },
  },
  {
    name: 'deleteApplink',
    pattern: '/configs/applinks/delete/:id',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      // const {rootStore} = routerStore.options;

    },
  },

]
