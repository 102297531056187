import {resolveIdentifier, types} from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";
import {TagModel} from "../tags/TagModel";

export function createExtendedDataModel(item, target) {
  return ExtendedDataModel.create({
    uuid: item?.uuid,
    name: item?.name,
    propName: item?.propName,
    type: item?.type,
    label: item?.label,
    tags: item?.tags?.map(tag => {
      return resolveIdentifier(TagModel, target, tag?.uuid || tag);
    }),
    schemaDefinition: item?.schemaDefinition,
    data: item?.data,
    _audit: item?._audit,
    owner: item?.owner,
    isExclusivelyGroupOwned: item?.isExclusivelyGroupOwned,
    updatedAt: item?.updatedAt,
    createdAt: item?.createdAt,
  });
}

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string),
  dimensions: types.optional(types.frozen(),{}),
})

export const ExtendedDataModel = types.model("ExtendedDataModel", {
  uuid: types.identifier,
  propName: types.string,
  type: types.string,
  name: types.string,
  label: types.string,
  schemaDefinition: types.optional(types.frozen(), {}),
  data: types.optional(types.frozen(), {}),
  _audit: types.maybeNull(AuditMetaData),
  owner: types.maybe(GroupData),
  tags: types.optional(types.array(types.reference(TagModel)), []),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});
