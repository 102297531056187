import React from "react";
import {components} from "react-select";

export const IconOption = props => (
  <Option {...props}>
    {props.data.icon ? <img
      src={props.data.icon}
      style={{width: "20px", marginRight: "20px"}}
      alt={props.data.label}
    /> : props.data.label}
    {props.data.icon ? props.data.label : ""}
  </Option>
)

export const {Option} = components;
