import { flow, getEnv, resolveIdentifier, types } from 'mobx-state-tree'
import {RuleSet} from "../../models/RuleSet";
import { TagModel } from '../../models/tags/TagModel'
import lodash from "lodash";

export const RuleSetsStore = types.model('RuleSetsStore',{
  storeUuid: types.identifier,
  list: types.optional(types.array(RuleSet), []), //for select component
  flatList: types.optional(types.array(types.frozen()), []), //for list component, //because list can't access nested MST tags model
  sortOrder: types.optional(types.string, "desc"),
}).volatile(self => ({
  searchParams: {},
  filterListOn:types.fn,
  filters: {}
})).actions((self)=>({
  setFilterOn(groupUuid) {
    self.filterListOn = (item) => {   //function used to filter tag options by selected group
      return (item.owner.groupUuid === groupUuid || item?.isExclusivelyGroupOwned === false)
    }
  },
  initWithData (rulesets = []) {
    try {
      if (rulesets) {
        console.log('###$$$ start initWithData,ruleSets')
        self.list = rulesets.map(ruleSet => {
          let nestedTags = [];
          if(ruleSet.tags && ruleSet.tags.length > 0){
            nestedTags = ruleSet.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
          }
            return RuleSet.create({
              uuid: ruleSet?.uuid,
              name: ruleSet?.name,
              label: ruleSet?.name,
              _audit: ruleSet?._audit,
              createdAt: ruleSet?.createdAt,
              updatedAt: ruleSet?.updatedAt,
              isExclusivelyGroupOwned: ruleSet?.isExclusivelyGroupOwned,
              tags: nestedTags,
              owner: ruleSet.owner
            })
          },
        )
        self.list.sort((a, b) => a.label.localeCompare(b.label))
      }
    } catch (e) {
      console.error("Failed to init RuleSetStore with data",e);
    }
  },
  init: flow(function* f(params = {}) {
    console.log("###$$$ load ruleSetStore from API");
    yield self.fetchAll(params);
  }),
  fetchAll: flow(function* fetchAll(sortParams = {}) {
    console.log("###$$$ fetchAll load ruleSetStore from API");
    self.isLoading = true
    if (Object.keys(sortParams).length === 0) {
      sortParams = {
        sort: 'updatedAt',
        order: self.sortOrder
      };
    }
    try {
      let queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams,
        ...self.searchParams
      }

      //if its not a group query, add isExclusivelyGroupOwned
      queryParams = Object.assign({
          ...queryParams,
        },
        lodash.isEmpty(queryParams.groupUuid) && {isExclusivelyGroupOwned: false}
      )

      const ruleSetManager = getEnv(self).ruleSetManager
      const response = yield ruleSetManager.fetchAll(queryParams);

      if (response) {
        const flatRules = response.results.map(ruleSet=> {
          let flatTags = [];
          if(ruleSet.tags && ruleSet.tags.length > 0){
            flatTags = ruleSet.tags.map(n => {
              return {
                _tenantUuid: n?._tenantUuid,
                uuid: n?.uuid,
                name: n?.name,
                label: n?.name,
                tags: [],
                createdAt: n?.createdAt,
                updatedAt: n?.updatedAt,
              }
            })
          }
          return {
            uuid:ruleSet.uuid,
            name:ruleSet.name,
            label:ruleSet.name,
            _audit:ruleSet?._audit,
            createdAt: ruleSet?.createdAt,
            updatedAt: ruleSet?.updatedAt,
            isExclusivelyGroupOwned: ruleSet?.isExclusivelyGroupOwned,
            isEditable: ruleSet?.isEditable,
            tags: flatTags,
            owner: ruleSet?.owner
          }
        })
        console.log("###$$$ flatRules",flatRules)
        self.flatList = flatRules;
        self.totalRows = response._meta.itemCount;
      }
      self.isLoading = false;
    } catch (error) {
      console.error('Failed to fetch all', error)
    }
  }),
  fetchFilters: flow(function* fetchFilters(sortParams = {}) {
    self.isLoading = true;

    try {
      const queryParams = {
        ...self.searchParams
      }

      const ruleSetManager = getEnv(self).ruleSetManager
      const response = yield ruleSetManager.fetchFilters(queryParams);
      if (response != null) {
        self.filters = response
        self.isLoading = false;
      } else {
        self.isLoading = false;
      }

    } catch (error) {
      console.error('Failed to fetch all', error)
    }

  }),
  appendRules: function(rules) {
    let nestedTags = [];
    if(rules.tags && rules.tags.length > 0){
      nestedTags = rules.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
    }
    const newRuleSet = RuleSet.create({
      uuid: rules?.uuid,
      name: rules?.name,
      label: rules?.name,
      _audit: rules?._audit,
      createdAt: rules?.createdAt,
      updatedAt: rules?.updatedAt,
      tags: nestedTags,
      owner: rules.owner,
      isExclusivelyGroupOwned: rules?.isExclusivelyGroupOwned
    })
    self.list.push(newRuleSet);
  },
  removeRules(uuid) {
    self.list = self.list.filter(ruleSet => ruleSet.uuid !== uuid);
  },
  searchFor(txt){

  },
  reload(){

  },
  save(group){

  },
  update(group){

  },
  add(data){

  },
  delete(group){

  },
  afterCreate(){
    console.log("created")
  },
  clearSearchParams(names,regex = ''){
    if(names){
      names.map((name) => delete self.searchParams[name])
    }
    if(regex){
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }
  },
  addSearchParams(param){
    self.searchParams = {...self.searchParams, ...param}
  },

})).views((self) => ({
  filteredList() {
      return self.list.filter((item) => self.filterListOn(item));
  }
}));
