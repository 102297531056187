import {types} from "mobx-state-tree";
import { Col, Row } from "reactstrap";
import CaptionState from "./CaptionState";
import {v4 as uuidv4} from 'uuid';

const FrameAndCaptionsState = types.model('FrameAndCaptionsState', {
    frame: types.optional(types.boolean, false),
    isCMYK: types.optional(types.boolean, false),

    frameSides: types.optional(types.integer, 0),
    frameColor: types.optional(types.string, '#000000'),
    backgroundModulesColor: types.optional(types.string, '#000000'),
    useForegroundColor: types.optional(types.boolean, true),
    frameUseForegroundColor: types.optional(types.boolean, true),
    modulesUseForegroundColor: types.optional(types.boolean, true),
    frameRotation: types.optional(types.boolean, false),
    frameBackgroundImage: types.optional(types.boolean, false),
    captions: types.optional(types.boolean, false),
    backgroundModules: types.optional(types.boolean, false),
    circularText: types.optional(types.boolean, false),
    captionRows: types.array(CaptionState, []),
}).volatile(self => ({
    notifyDesigner: types.f,
    notifyDesignerDeleteKey: types.f,
    extendedDesignerConfig: types.string
})).actions((self) => ({
    setConsumer(f) {
        self.notifyDesigner = f
    },
    setDeleteKeyConsumer(f){
        self.notifyDesignerDeleteKey = f
    },
    toggleFrame(imageCharsStore) {
        self.frame = !self.frame
        if (!self.frame) {
            self.notifyDesignerDeleteKey("frameSides")
            self.frameSides = 0
            self.notifyDesignerDeleteKey("frameColor")
            self.notifyDesignerDeleteKey("frameOrientation")
            self.frameRotation = false
            self.notifyDesignerDeleteKey("frameBackgroundImage")
            self.frameBackgroundImage = false
            self.notifyDesignerDeleteKey("backgroundModules")
            self.backgroundModules = false
            self.frameUseForegroundColor = true
            self.modulesUseForegroundColor = true
            imageCharsStore.changeQuietZone(4)
        }
    },
    handleShapeChange(group, shape, imageCharsStore) {
        console.log("handleShapeChange", group, shape)
        if(shape === null) {
            self[group] = 0
            self.notifyDesignerDeleteKey(group)
            imageCharsStore.changeQuietZone(4)
        } else {
            self[group] = shape
            self.notifyDesigner(group,shape)
            if(imageCharsStore) {
                imageCharsStore.changeQuietZone(1)
            }
        }
    },
    applyToAll(){
        self.notifyDesigner("frameSides", self.frameSides)
    },
    handleSelectColor(selectColor, colorPosition, frameAndCaptionsStore,isCMYK = false) {
      const colorValue = isCMYK?`cmyk(${selectColor.cmyk.c}%, ${selectColor.cmyk.m}%, ${selectColor.cmyk.y}%, ${selectColor.cmyk.k}%)`: selectColor.hex

        self.notifyDesigner(colorPosition, colorValue)
        self[colorPosition] = colorValue
        // self[colorPosition+"UseForeground"] = false
    },
    toggleIsFrameRotation() {
        self.frameRotation = !self.frameRotation
        self.notifyDesigner("frameOrientation", self.frameRotation)
    },

    toggleFrameUseForegroundColor() {
        self.frameUseForegroundColor = !self.frameUseForegroundColor
        if(self.frameUseForegroundColor){
            self.notifyDesignerDeleteKey("frameColor")
            self.notifyDesignerDeleteKey("frameUseForegroundColor")
        }else{
            self.notifyDesigner("frameColor", self.frameColor)
            self.notifyDesigner("frameUseForegroundColor", self.frameUseForegroundColor)
        }
    },
  toggleFillFrameWithQrModules(colorStore) {
    self.backgroundModules = !self.backgroundModules
    if (self.backgroundModules) {
      self.notifyDesigner("backgroundModules", true)
      if(!self.modulesUseForegroundColor){
        self.notifyDesigner("backgroundModulesColor", self.backgroundModulesColor)
      }
      // if(!colorStore.addGradient && !self.useForegroundColor) {
      //     self.notifyDesigner("backgroundModulesColor", colorStore.foregroundColor)
      // }
    } else {
      self.notifyDesignerDeleteKey("backgroundModules")
      self.notifyDesignerDeleteKey("modulesUseForegroundColor")
      self.notifyDesignerDeleteKey("backgroundModulesColor")
    }
  },
    toggleModulesUseForegroundColor(colorStore) {
        self.modulesUseForegroundColor = !self.modulesUseForegroundColor
        if(self.modulesUseForegroundColor){
          if(!colorStore.addGradient){
            self.notifyDesigner("backgroundModulesColor", colorStore.foregroundColor)
          }else{
            self.notifyDesignerDeleteKey("backgroundModulesColor")
          }
            self.notifyDesignerDeleteKey("modulesUseForegroundColor")
        }else{
            self.notifyDesigner("backgroundModulesColor", self.backgroundModulesColor)
            self.notifyDesigner("modulesUseForegroundColor", self.modulesUseForegroundColor)
        }
    },
    toggleFrameBackgroundImage() {
        self.frameBackgroundImage = !self.frameBackgroundImage
        self.notifyDesigner("frameBackgroundImage", self.frameBackgroundImage)
    },
    toggleCaptions(imageCharsStore) {
        self.captions = !self.captions
        if (!self.captions) {
            if(self.captionRows.length > 0){
                self.captionRows.forEach((caption) => {
                    self.notifyDesignerDeleteKey(caption.id)
                }
            )}
        }
        imageCharsStore.changeQuietZone(4)
    },
    toggleCircularText() {
        self.circularText = !self.circularText
        self.notifyDesigner("circularText", self.circularText)
    },
    updateExtendConfig(key, value) {
        const parsedConfig = JSON.parse(self.extendConfig)
        const updatedConfig = {...parsedConfig, ...{[key]: value}}
        self.extendConfig = JSON.stringify(updatedConfig)
    },
    handleAddCaption(extendConfig) {
        let nextOptionValue = 'Top';
        if (self.captionRows.length != 0) {
            const currentOption = self.captionRows[self.captionRows.length-1]["captionTextPosition"]
            const options = ['Top', 'Bottom', 'Left', 'Right'];
            const currentIndex = options.indexOf(currentOption);
            const nextIndex = (currentIndex + 1) % options.length;
            nextOptionValue = options[nextIndex];
        }
        const newCaptionState = CaptionState.create({
            id: uuidv4(),
            captionTextPosition: nextOptionValue,
            captionFontSize: 50,
        });
        self.captionRows.push(newCaptionState)
        newCaptionState.setConsumer(extendConfig)
    },
    handleRemoveCaption(index, captionId, extendConfig) {
        self.captionRows.splice(index, 1)
        self.notifyDesignerDeleteKey(captionId)
    },
})).views((self) => ({
  hasFrame() {
      return self.frameSides > 0
  },
  hasCaptions() {
      return self.captions
  }
}))

export default FrameAndCaptionsState;

