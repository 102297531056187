import React, {useCallback, useEffect, useState} from "react";
import { CardBody, Col, CustomInput, Modal, ModalHeader, Row} from "reactstrap";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";

const timeZones = Intl.supportedValuesOf('timeZone')
console.log("timezone length",timeZones.length)

const options = timeZones.map(timeZone => ({
  value: timeZone,
  label: timeZone
}));
export const TimeZonePopUp = ({value, onChange}) => {
  const [useLocalTimeZone, setUseLocalTimeZone] = useState(true)
  const [showPopUp, setShowPopUp] = useState(false)

  const handleShowPopUp = () => {
    if (value === undefined) {
      setUseLocalTimeZone(true)
    }else{
      setUseLocalTimeZone(false)
        }
    setShowPopUp(!showPopUp)
  }
  const handleChange = useCallback(selectedOption => {
    onChange(selectedOption.value);
  }, [onChange]);
  const selectedOption = options.find(option => option.value === value);

  const handleUseLocalTimeZone = () => {
    onChange(undefined)
    setUseLocalTimeZone(!useLocalTimeZone)
  }
  useEffect(() => {
    if (value === undefined) {
      setUseLocalTimeZone(true)
    }else{
      setUseLocalTimeZone(false)
        }
  }, [value, onChange]);


    return (
        <>
            <br/>

          <div >
            {value?  value:"Scanner time"} <FontAwesomeIcon icon={faEdit} style={{cursor:"pointer",marginLeft:"3px"}} onClick={() => {handleShowPopUp()}}/>
          </div>
          {showPopUp &&
              <Modal
                  isOpen={showPopUp}
                  toggle={()=>{setShowPopUp(!showPopUp)}}
                  centered
                  size="sm"
              >
                <CardBody>
                  <ModalHeader toggle={()=>{setShowPopUp(!showPopUp)}}>
                    <Row >
                      {/*<Col sm={4}>*/}
                      <div style={{marginRight:"17px"}}>
                        Use Local Time
                      </div>
                      {/*</Col>*/}
                      <Col >
                        <CustomInput
                            type="switch"
                            name="useLocalTimeZone"
                            id="useLocalTimeZoneSelectButton"
                            defaultChecked={useLocalTimeZone}
                            value={useLocalTimeZone}
                            checked={useLocalTimeZone}
                            onClick={() => {
                              handleUseLocalTimeZone()
                            }}
                        />
                      </Col>
                    </Row>
                  </ModalHeader>
                  <Row style={{height:"15px"}}></Row>
                  {!useLocalTimeZone &&
                      <Select
                          className="react-select-container"
                          isDisabled={useLocalTimeZone}
                          options={options}
                          onChange={handleChange}
                          value={selectedOption}
                          placeholder="Select"
                      />
                  }
                </CardBody>
                {/*</Card>*/}
              </Modal>
          }
        </>
    )
};

