import {types} from "mobx-state-tree";


const  Parameter = types.model('Parameter',{
  uuid: types.identifier,
  name: types.string,
  label: types.string,
})


export default Parameter;

