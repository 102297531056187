import {Button, Col, CustomInput, FormGroup, Input, Label, NavItem, Row, TabContent, Nav, NavLink, TabPane,
Card, CardTitle, CardText} from "reactstrap";
import React, {useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {getSnapshot} from "mobx-state-tree";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import { Tab } from "react-bootstrap";
import Slider from '../../../Slider';
import "../../../../assets/scss/qr-designer.scss"
import {StateContext} from "../../../../App";
import {useContext} from "react";
import DisableWrapper from "../../../../utils/disableWrapper";

const Logo = observer(({store, eyesAndModulesStore, isDisabled = false}) => {
  const hiddenFileInput = React.useRef(null);
  const handleImageUploadClick = event => {
    hiddenFileInput.current.click();
  };

  const hiddenLogoFileInput = React.useRef(null);
  const handleLogoUploadClick = event => {
    hiddenLogoFileInput.current.click();
  };


  return (
    <>
      <DisableWrapper isDisabled={isDisabled}>
        <div>
      <Nav tabs>
        <NavItem>
          <NavLink id="insert-logo" className={store.activeTab === '1' ? 'active' : ''}
                    onClick={() => store.toggleTab('1')}
          >
            Insert Logo
          </NavLink>
        </NavItem>
        {/*<NavItem>*/}
        {/*  <NavLink className={activeTab === '2' ? 'active' : ''}*/}
        {/*            onClick={() => toggleTab('2')}*/}
        {/*  >*/}
        {/*    Background Image*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        <NavItem>
          <NavLink id="empty-space" className={store.activeTab === '2' ? 'active' : ''}
                    onClick={() => store.toggleTab('2')}
          >
            Empty Space
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={store.activeTab}>
        <TabPane tabId="1">
          <br/>
          <Col >
            <Row>
              <Col sm={3}><FormattedMessage id="qrDesign.create.upload.defaultMessage"/></Col>
              <Col sm={2}>
                <Button id="upload-btn" onClick={handleLogoUploadClick}>
                  <FormattedMessage id="qrDesign.create.upload.defaultMessage"/>
                </Button>
                <input
                  type="file"
                  name="myLogoImage"
                  style={{display: "none"}}
                  ref={hiddenLogoFileInput}
                  onChange={ (event) => {
                    if (event.target) {
                      const selectedFile = event.target.files[0];
                      if(selectedFile) {
                         store.setSelectedImage(event.target.files[0], true)
                         event.target.value = ''
                      }
                    }
                  }}
                ></input>
              </Col>
              <Col>
                <FormattedMessage id="qrDesign.create.acceptedFormats.defaultMessage"/>
                <br/> <FormattedMessage id="qrDesign.create.acceptedSize.defaultMessage"/>
              </Col>
              <Col sm={3}>
                {!store.logoFileIsCorrectSize && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoFileIsCorrectSize.defaultMessage"/></p>}
                {!store.logoFileIsCorrectType && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoFileIsCorrectType.defaultMessage"/></p>}
              </Col>
            </Row>

            {store.doesLogoExist()&&
            <Row>

              <Col sm={3}>
                Insert Size
              </Col>
              <Col sm={3}>

                  <Slider defaultValue={store.logoPercentageWidth}
                          minValue={10}
                          maxValue={50}
                          handleSliderChange={(event)=> store.handleLogoSize(event)}/>

              </Col>
              <Col>
                {store.logoSizeWarn &&
                  <div className="text-center">
                    <p className="text-danger"><FormattedMessage id="preview.logoSizeWarn.defaultMessage" /></p>
                    </div>
                }
              </Col>
            </Row>
            }
            {store.doesLogoExist()&&
            <Row>
              <Col sm={3} ><FormattedMessage id="qrDesign.create.imageMargin.defaultMessage"/></Col>
              <Col sm={9}>
                <Row>
                  <Col sm={2}>
                    <CustomInput
                      type="number"
                      min="-1"
                      max="3"
                      value={store.logoMargin}
                      onChange={store.handleLogoMargin}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      id="logoMargin"
                      style={{width: "50%"}}
                      >
                    </CustomInput>
                </Col>
                <Col sm={4}>
                {store.logoMarginWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoMarginWarn.defaultMessage"/></p>}
                </Col>
                </Row>
              </Col>
              {/* <Col sm={4}>
                {store.logoMarginWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoMarginWarn.defaultMessage"/></p>}
              </Col> */}
            </Row>
            }

          </Col>
          {/*<Col sm={3}>*/}
          {/*  {store.pdfLogoPng == "" && store.logo ? (*/}
          {/*    <div>*/}
          {/*      <img style={{ width: "100px" }} src={`data:image/eps;base64,${store.logo}`} />*/}
          {/*      <div>*/}
          {/*        <FontAwesomeIcon onClick={store.removeLogo} style={{ cursor: "pointer" }} icon={faTrash} className="align-middle" />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ) : store.pdfLogoPng !== "" && store.logo ? (*/}
          {/*    <div>*/}
          {/*      <img style={{ width: "100px" }} src={`${store.pdfLogoPng}`} />*/}
          {/*      <div>*/}
          {/*        <FontAwesomeIcon onClick={store.removeLogo} style={{ cursor: "pointer" }} icon={faTrash} className="align-middle" />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  ) : null}*/}

          {/*</Col>*/}
          <Col>
            {store.doesLogoExist()&&
            <Row>
              <Col sm={3}><FormattedMessage id="qrDesign.create.imageMove.defaultMessage"/></Col>
              <Col sm={4}>
                <Row>
                  <Col sm={2}>
                    {/*Left*/}
                  </Col>
                  Left
                  <Col sm={7} color="secondary">
                    <CustomInput
                      name="range"
                      type="range"
                      id="imageMoveRight"
                      max="15"
                      min="-15"
                      color="secondary"
                      value={store.logoOffsetX}
                      onChange={store.handleLogoOffsetX}
                      style={{ color: "secondary"}}
                      ></CustomInput>
                  </Col>
                  Right
                  {/*<Col sm={2}>*/}
                  {/*  Right*/}

                  {/*</Col>*/}
                  <Col sm={6}>
                    {store.logoOffsetXWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoOffsetXWarn.defaultMessage"/></p>}
                  </Col>
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  {/*<div style={{transform: "rotate(90deg)"}}>*/}
                  {/*  <Col sm={3}>*/}
                  {/*    Down*/}
                  {/*  </Col>*/}
                    <Col sm={5}>
                      <CustomInput
                        name="range"
                        type="range"
                        max="15"
                        min="-15"
                        id="imageMoveDown"
                        value={store.logoOffsetY}
                        onChange={store.handleLogoOffsetY}
                        backgroundColor="secondary"
                        style={{ transform: "rotate(90deg)", color: "secondary"}}
                      ></CustomInput>
                    </Col>
                  <div style={{position: "relative", height: "100px",marginLeft:"-40px",marginTop:"-40px"}}>
                    <div style={{position: "absolute",top:"0px"}}>
                      Up
                    </div>
                    <div style={{position: "absolute",bottom:"0px"}}>
                      Down
                    </div>
                  </div>
                  {/*</div>*/}
                  <Col sm={6}>
                    {store.logoOffsetYWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoOffsetYWarn.defaultMessage"/></p>}
                  </Col>
                </Row>
              </Col>
            </Row>


            }
            {/* comment crop around image */}
            {/* <Row>
              <Col sm={3}><FormattedMessage id="qrDesign.create.CropAroundImage.defaultMessage"/></Col>
              <Col sm={2}>
                <div className="move-switch-position">
                  <CustomInput
                      type="switch"
                      id="isCropAroundImage"
                      name="isCropAroundImage"
                      checked={store.cropAroundImage}
                      onChange={() => store.toggleCropAroundImage()}
                      />
                </div>
              </Col>
            </Row> */}
          </Col>

          <Row>
            <Col sm={3}>
            {store.pdfLogoPng == "" && store.logo && store.logo !== "empty" ? (
              <div>
                <img style={{ width: "100px" }} src={`data:${store.logoFormat};base64,${store.logo}`} />
                <div>
                  <FontAwesomeIcon onClick={store.removeLogo} style={{ cursor: "pointer" }} icon={faTrash} className="align-middle" />
                </div>
              </div>
            ) : store.pdfLogoPng !== "" && store.logo ? (
              <div>
                <img style={{ width: "100px" }} src={`${store.pdfLogoPng}`} />
                <div>
                  <FontAwesomeIcon onClick={store.removeLogo} style={{ cursor: "pointer" }} icon={faTrash} className="align-middle" />
                </div>
              </div>
            ) : null}
            </Col>
          </Row>
        </TabPane>

        <TabPane tabId="2">
          <br/>
          <Col>
              <Row>
                <Col sm={3}>
                    Insert size
                  </Col>
                <Col sm={3}>
                  <Slider defaultValue={store.logoPercentageWidth}
                          minValue={10}
                          maxValue={33}
                          handleSliderChange={store.handleLogoSize}
                          isDisabled={isDisabled}
                  />
                </Col>

              </Row>
            <Row>
              <Col sm={3} >Margin</Col>
              <Col sm={9}>
                <Row>
                  <Col sm={2}>
                    <CustomInput
                      type="number"
                      min="-1"
                      max="3"
                      value={store.logoMargin}
                      onChange={store.handleLogoMargin}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      id="logoMargin"
                      style={{width: "50%"}}
                    >
                    </CustomInput>
                  </Col>
                  <Col sm={4}>
                    {store.logoMarginWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoMarginWarn.defaultMessage"/></p>}
                  </Col>
                </Row>
              </Col>
              {/* <Col sm={4}>
                {store.logoMarginWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoMarginWarn.defaultMessage"/></p>}
              </Col> */}
            </Row>
              <Row>
                <Col sm={2}>Move space</Col>
                <Col sm={4}>
                  <Row>
                    <Col sm={2}>
                      {/*Left*/}
                    </Col>
                    Left
                    <Col sm={7} color="secondary">
                      <CustomInput
                        name="range"
                        type="range"
                        id="imageMoveRight"
                        max="15"
                        min="-15"
                        color="secondary"
                        value={store.logoOffsetX}
                        onChange={store.handleLogoOffsetX}
                        style={{ color: "secondary"}}
                      ></CustomInput>
                    </Col>
                    Right
                    {/*<Col sm={2}>*/}
                    {/*  Right*/}

                    {/*</Col>*/}
                    <Col sm={6}>
                      {store.logoOffsetXWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoOffsetXWarn.defaultMessage"/></p>}
                    </Col>
                  </Row>
                </Col>
                <Col sm={4}>
                  <Row>
                    {/*<div style={{transform: "rotate(90deg)"}}>*/}
                    {/*  <Col sm={3}>*/}
                    {/*    Down*/}
                    {/*  </Col>*/}
                    <Col sm={5}>
                      <CustomInput
                        name="range"
                        type="range"
                        max="15"
                        min="-15"
                        id="imageMoveDown"
                        value={store.logoOffsetY}
                        onChange={store.handleLogoOffsetY}
                        backgroundColor="secondary"
                        style={{ transform: "rotate(90deg)", color: "secondary"}}
                      ></CustomInput>
                    </Col>
                    <div style={{position: "relative", height: "100px",marginLeft:"-40px",marginTop:"-40px"}}>
                      <div style={{position: "absolute",top:"0px"}}>
                        Up
                      </div>
                      <div style={{position: "absolute",bottom:"0px"}}>
                        Down
                      </div>
                    </div>
                    {/*</div>*/}
                    <Col sm={6}>
                      {store.logoOffsetYWarn && <p className="text-danger"><FormattedMessage id="qrDesign.create.logoOffsetYWarn.defaultMessage"/></p>}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
        </TabPane>
      </TabContent>
        <br/>
      <Button id="bgimage-btn" onClick={store.setOpenBackgroundImage}>Background Image</Button>
        <br/>
        <br/>
      {store.openBackgroundImage && <>
        <Col>
          <Row>
            <Col sm={3}><FormattedMessage id="qrDesign.create.upload.defaultMessage"/></Col>
            <Col sm={2}>
              <Button id="uploadbg-btn" onClick={handleImageUploadClick}>
                <FormattedMessage id="qrDesign.create.upload.defaultMessage"/>
              </Button>
              <input
                type="file"
                name="backgroundImage"
                style={{display: "none"}}
                ref={hiddenFileInput}
                onChange={(event) => {
                  const selectedFile = event.target.files[0];
                  if (selectedFile) {
                    store.setSelectedImage(selectedFile, false, eyesAndModulesStore);
                    event.target.value = ''
                  }
                }}
              ></input>
            </Col>
            <Col>
              <FormattedMessage id="qrDesign.create.acceptedFormats.defaultMessage"/>
              <br/> <FormattedMessage id="qrDesign.create.acceptedSize.defaultMessage"/>
            </Col>
          </Row>
          <Row>
            <Col sm={3}><FormattedMessage id="qrDesign.create.oversizedBackground.defaultMessage"/></Col>
            <Col sm={2}>
              <CustomInput
                type="switch"
                id="isFullBackground"
                name="isFullBackground"
                checked={store.fullBackground}
                onChange={() => store.toggleFullBackground()}
              />
            </Col>
          </Row>
        </Col>
        <Row>
          <Col sm={3}>
            {store.backgroundImage &&
              <div>
                <img style={{width: "100px"}}
                     src={`data:${store.backgroundImageFormat};base64,${store.backgroundImage}`}/>
                <div>
                  <FontAwesomeIcon onClick={store.removeBackgroundImage} style={{cursor: "pointer"}} icon={faTrash}
                                   className="align-middle"/>
                </div>
              </div>
            }
          </Col>
        </Row>
      </>
      }
      </div>
      </DisableWrapper>
    </>
  )

})
export default Logo;
