import React, {useContext, useEffect} from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CreateRuleForm from "../../components/configs/rulesets/create/CreateRuleForm";
import { StateContext } from "../../App";
import Header from "../../components/Header";
import {RouterLink} from "mobx-state-router";

const EditRuleSetHome = observer(() => {

  const stateContext = useContext(StateContext);
  const createRuleSetState = stateContext.rootUiStore.createRuleSetState
  // useEffect(() => {
  //   createRuleSetState.fetchRuleSetWithID(createRuleSetState.selectedRuleSetUUID)
  // })
  return (
    <Container fluid>
      <Header>
        <Breadcrumb>
          <BreadcrumbItem>
            <RouterLink routeName="dashboard"><FormattedMessage id="routes.home.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <RouterLink routeName="ruleSets"><FormattedMessage id="routes.rulesets.defaultMessage"/></RouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="routes.editCode.defaultMessage"/></BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Row>
        <Col >
          <Card>
            <CardBody>
              <CreateRuleForm createRuleSetState ={createRuleSetState} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
})


export default EditRuleSetHome;
