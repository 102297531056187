import async from "../../../components/Async";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import DataExportListViewState from "../../../components/data/exports/list/DataExportListViewState";
import {DataExportListModel} from "../../../models/data/exports/DataExportListModel";
import {buildColumns} from "../../../components/data/exports/list/DataExportListView";
import DataExportFormDomain from "../../../components/data/exports/form/DataExportFormState";
import {ModalState} from "../../../components/elements/modal/GenericModal";
import lodash from "lodash";
import {getJSONFromBase64} from "../../../utils/common";
import {checkUserPermissions} from "../../../utils/requiredRolesCheck";

const DataExportList = async(() => import("../../../pages/data/exports"));
const DataExportsForm = async(() => import("../../../pages/data/exports/form/FormView"));

export const viewMap = {
  "/data/exports/create": <DataExportsForm />,
  "/data/exports": <DataExportList />,
  "/data/exports/edit/:id": <DataExportsForm />,
}

export const routeDefinitions = [
  {
    path: "/data/exports",
    name: "Data Exports",
    icon: faFileArrowDown,
    component: DataExportList,
    children: null,
    featureFlagRequired: "cmp_ui_data_exports"
  }
];

const createDataExportModel = (rootUiStore) => {
  const {
    groupSelectStore,
    tagSelectStore,
    dataExportTypeSelectStore,
    dimensionSelectViewState,
    dimensionsMultiSelectState
  } = rootUiStore;

  dimensionsMultiSelectState.init();
  dimensionsMultiSelectState.setDefaults();

  return DataExportFormDomain.create({
    groupSelectStore: groupSelectStore,
    tagSelectStore: tagSelectStore,
    dataExportTypeSelectStore: dataExportTypeSelectStore,
    dimensionSelectViewState: dimensionSelectViewState,
    dimensionsMultiSelectStore: dimensionsMultiSelectState,
  });
};

const createDataExportFromQuery = (queryParams) => {
  try {
    const dimensions = getJSONFromBase64(queryParams?.dimensions, null);
    const tags = getJSONFromBase64(queryParams?.tags, null);
    const definition = getJSONFromBase64(queryParams?.definition, null);
    const hideActions = getJSONFromBase64(queryParams?.hideActions, null);
    const groups = getJSONFromBase64(queryParams?.groups, null);
    const item = {
      type: queryParams?.type || "default",
      name: queryParams?.name || "",
      owner: {
        ...(groups?.length && {groupUuid: groups[0]}),
        groups,
        dimensions
      },
      tags,
      format: queryParams?.format || "CSV",
      definition,
      hideActions,
    };
    return item;
  } catch (err) {
    console.error(`Error: Failed to populate fields from query`, err);
  }
}

const sanitizeItemForClone = (item={}) => {
  item.name = "";
  item.hideActions = [];
  return item;
};

export const routeTransitions = [
  {
    name: '/data/exports/create',
    pattern: '/data/exports/create',
    beforeEnter: checkUserPermissions,
    onEnter: async (fromState, toState, routerStore) => {
      let {queryParams} = toState;
      const {rootStore} = routerStore.options;
      const dataExportFormState = createDataExportModel(rootStore.rootUiStore);

      console.log(`Attaching Page State: 'dataExportFormState'`);
      await rootStore.rootUiStore.attachPageState('dataExportFormState', dataExportFormState);

      if (!lodash.isEmpty(queryParams)) {
        await dataExportFormState.setOnSelectChanges();

        let item;
        if (queryParams?.clone) {
          const snapShot = rootStore.rootUiStore?.globalCache[`clone-data-export:${queryParams?.clone}`];
          if (snapShot) {
            item = JSON.parse(JSON.stringify(snapShot)); // copy to update snapShot
          } else {
            // Case: If user clears cache after cloning
            item = await dataExportFormState?.fetchItemByUUID(queryParams.clone);
          }
          item = sanitizeItemForClone(item);
        } else {
          item = createDataExportFromQuery(queryParams);
        }

        await dataExportFormState?.hydrateForm(item);

        const action = queryParams?.action;
        if (action && item.name) {
          const {results: dataExports} = await dataExportFormState?.fetchItemByName(item.name);
          let response;
          if (!lodash.isEmpty(dataExports)) {
            response = await dataExportFormState?.update(dataExports[0].uuid);
          } else {
            response = await dataExportFormState?.saveNew();
          }
          if (!lodash.isEmpty(dataExports) || response?.status === 201) {
            switch (action) {
              case 'create':
                routerStore.goTo('/data/exports');
                break;
              case 'edit':
                routerStore.goTo('/data/exports/edit/:id', {
                  params: {id: response.data.uuid || dataExports[0].uuid}
                });
                // TODO: what if user then clicks back on edit page? it will try to create and give 409
                break;
              default:
                break;
            }
          } else {
            console.error(response);
            routerStore.goTo('/data/exports');
          }
        }
      } else {
        dataExportFormState?.setDefaults();
      }
    }
  },
  {
    name: '/data/exports/edit/:id',
    pattern: '/data/exports/edit/:id',
    beforeEnter: checkUserPermissions,
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;
      const dataExportFormState = createDataExportModel(rootStore.rootUiStore);

      console.log(`Attaching Page State: 'dataExportFormState'`);
      await rootStore.rootUiStore.attachPageState('dataExportFormState', dataExportFormState);
      const {params} = toState;
      await dataExportFormState?.fetchModelAndHydrate(params.id);
    }
  },
  {
    name: '/data/exports/delete/:id',
    pattern: '/data/exports/delete/:id',
    beforeEnter: checkUserPermissions,
    onEnter: async (fromState, toState, routerStore) => {}
  },
  {
    name: '/data/exports',
    pattern: '/data/exports',
    beforeEnter: checkUserPermissions,
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const {
        groupSelectStore,
        tagSelectStore,
        tagFilterSelectState,
        dimensionSelectViewState,
        dataExportFilterSelectState
      } = rootStore.rootUiStore;

      const dataExportListViewState = DataExportListViewState.create({
        store: DataExportListModel.create({}),
        modalStore: ModalState.create({}),
        dimensionSelectViewState: dimensionSelectViewState,
        groupSelectStore: groupSelectStore,
        tagSelectStore: tagSelectStore,
        dataExportFilterSelectState: dataExportFilterSelectState,
        tagFilterSelectState: tagFilterSelectState
      });

      console.log("Attaching Page State : dataExportListViewState")
      await rootStore.rootUiStore.attachPageState('dataExportListViewState', dataExportListViewState);
      await dataExportListViewState?.fetchAndInitialize();
      dataExportListViewState?.setColumns(buildColumns(routerStore, dataExportListViewState));
    }
  }
];
