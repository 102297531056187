import React, {createContext, useEffect, useState} from "react";
import {Provider} from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import {IntlProvider} from "react-intl";
import {RouterContext} from 'mobx-state-router';
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import {initRouter} from "./routes/initRouter";
import {useAuth0} from "@auth0/auth0-react";
import config from "./configs";
import {useScanbuyContext} from './providers/ScanbuyContextProvider';
import { initRootAppStore } from "./stores/initRootAppStore";
import "./App.css"
import {DocumentationProvider} from "./providers/DocumentationProvider";

LogRocket.init(config.LOGROCKET_APP_ID);
setupLogRocketReact(LogRocket);

export const StateContext = createContext();
const rootStore = initRootAppStore({})

window.__DEV__ = rootStore; //TODO: Remove in prod

console.log(rootStore);
console.log("window",window.__DEV__);

function App() {
  const [routerStore, setRouterStore] = useState(null);
  const {user, isLoading,isAuthenticated, getAccessTokenSilently, loginWithRedirect} = useAuth0();
  console.log("user", user);
  const {_tenantUuid} = useScanbuyContext();
  const [bootStrapped, setBootstrapped] = useState(false);
  const [errorOnBootStrap, setErrorOnBootstrap] = useState(false);
  const [notAuthorized , setNotAuthorized] = useState(false);

  const clearLocalCache = () => {
    localStorage.clear();
    window.location = config.PORTAL_URL;
  };

  useEffect(() => {
    !document.querySelector(".splash") ||
    document.querySelector(".splash").classList.remove("active");

    if (isAuthenticated && !isLoading) { //if isAuthenticated, init the RootStore
      getAccessTokenSilently({
        authorizationParams: {
          audience: config.AUTH0_AUD
        },
      }).then((token) => {
        console.log("access token ", token);
        rootStore.init({jwt: token, tenantUuid: _tenantUuid})
        if (!bootStrapped) {
          rootStore.bootStrap().then((obj) => {
            if(obj == null){
              console.log("NO LOAD");
              setNotAuthorized(true);
              setErrorOnBootstrap(true)
            } else {
              let rs = initRouter(rootStore);
              rootStore.setRouteStore(rs);
              setRouterStore(rs)
              setBootstrapped(true)

              LogRocket.identify(user.sub, {
                name: user.name,
                email: user.email,
                tenantUuid: _tenantUuid,
              });
            }
          }).catch(reason => {
            console.log("error bootstrapping ", reason)
            setErrorOnBootstrap(true)
          })
        }
      }).catch(reason => {
          loginWithRedirect()
      })

    } else if(!isAuthenticated && !isLoading){
      loginWithRedirect()
    }

  }, [ isAuthenticated, isLoading, getAccessTokenSilently, user, _tenantUuid , bootStrapped, loginWithRedirect ]);

  if(errorOnBootStrap){
    //TODO: Add logrocket event?
    if(notAuthorized){
      return <><center>Access Denied.  If you think this is an error, please click <a href="#" onClick={clearLocalCache}>here </a> to clear the session and login again. Please contact Scanbuy if the error persists.</center></>

    }
    return <><center>Error Loading ... </center></>
  }

  if(isLoading || !bootStrapped){
    return <>
      <div className="splash active">
        <div className="splash-icon"></div>
      </div>
    </>
  }


  return (
    <Provider store={store}>
      <StateContext.Provider value={rootStore}>
        <IntlProvider defaultLocale="en" locale={rootStore.currentLocale} messages={rootStore.messages}>
          <DocumentationProvider links={rootStore.messages}>
            {bootStrapped &&
              <RouterContext.Provider value={routerStore}>
                <Routes/>
              </RouterContext.Provider>}
            <ReduxToastr
              timeOut={5000}
              newestOnTop={true}
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />

          </DocumentationProvider>
        </IntlProvider>
      </StateContext.Provider>
    </Provider>
  );

}

export default App;
