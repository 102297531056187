import {types} from "mobx-state-tree";
import DashboardModel from "../../models/dashboard";
import lodash from 'lodash';

export const DashboardStore = types.model('DashboardStore',{
  storeUuid: types.identifier,
  apps:types.optional(DashboardModel, {}),
}).actions(self => ({

  initWithData(apps = {}) {
    try {
      if (!lodash.isEmpty(apps) && lodash.isObject(apps)) {
        self.apps = DashboardModel.create({
          bulletins: apps?.bulletins,
          experiences: apps?.experiences,
          bulletinText: apps?.bulletinText,
          defaultImageConfig: apps?.defaultImageConfig,
          bulletinsV2: apps?.bulletinsV2,
        })
      }
    } catch (e) {
      console.error("Failed to init DashboardStore with data",e);
    }
  }
}));
