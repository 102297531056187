import {flow, getEnv, getType, types} from "mobx-state-tree";
import {GroupSelectState} from "../elements/groups/GroupSelectState";
import {TagSelectState} from "../elements/tags/TagSelectState";
import Group from "../../models/Group";
import {DimensionsMultiSelectState} from "../elements/dimensions/multi-select/dimensionsMultiSelectState";
import lodash from "lodash";
import Notification from "../../utils/Notification";
import {ExtendedDataTypeSelectStore} from "./ExtendedDataTypeSelectStore";
import {formConstants} from "../../utils/constants";
import {GroupFilterSelectState} from "../elements/filters/GroupFilterSelectState";
import {TagFilterSelectState} from "../elements/filters/TagFilterSelectState";
import {CodeFilterSelectState} from "../elements/filters/CodeFilterSelectState";
import Ajv from "ajv";
import addFormats from "ajv-formats";

const defaultSchema = {
  "type": "object",
  "required": ["pattern"],
  "properties": {
    "pattern": {
      "type": "string",
      "title": "Regular Expression Pattern"
    }
  }
};

const ExtendedDataFormState = types.model("ExtendedDataFormState", {
  name: types.optional(types.string, ""),
  isLoading: types.optional(types.boolean, false),
  isNotUsed: types.optional(types.boolean, false),
  liveValidateSchema: types.optional(types.boolean, false),
  isNameInvalid: types.optional(types.boolean, false),
  extendedDataTypeSelectStore: types.maybe(types.late(() => types.reference(ExtendedDataTypeSelectStore))),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedTags: types.optional(types.array(types.string), []),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  codeFilterSelectState: types.maybeNull(types.reference(CodeFilterSelectState)),
  selectedGroup: types.maybe(types.safeReference(Group)),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionsFilterMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  data: types.optional(types.string, '{}'),
}).volatile((self)=>({
  groupFilterSelectOnChange: types.function,
  tagFilterSelectOnChange: types.function,
  dimensionFilterSelectOnChange: types.function,
  advFilterSelectOnChange: types.function,
})).actions(self => ({
  setDefaults() {
    self.isLoading = true;
    self?.setOnSelectChanges();
    self?.groupSelectStore?.selectGroup(self?.groupSelectStore?.groupStore?.groups[0] || undefined);
    self?.tagSelectStore?.setSelectedTags([]);
    self?.selectExtendedDataType(self?.extendedDataTypeSelectStore?.store?.extendedDataType[0] || undefined);
    self.isLoading = false;
  },
  handleNameChange(e) {
    self.name = e?.target?.value;
    if (self.name) {
      self.isNameInvalid = false;
    }
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean;
  },
  handleErrorMessage(message) {
    self.errorMessage = message;
  },
  setOnSelectChanges() {
    self.groupSelectStore?.setConsumer(self.selectGroup);
    self.tagSelectStore?.setConsumer(self.selectTag);
    self.extendedDataTypeSelectStore?.setConsumer(self.selectExtendedDataType)
    self.groupFilterSelectState?.setConsumer(self.notifyGroupFilterSelectConsumer);
    self.tagFilterSelectState?.setConsumer(self.notifyTagFilterSelectConsumer);
    self.dimensionsFilterMultiSelectStore?.setConsumer(self.notifyDimensionFilterSelectConsumer);
    self.codeFilterSelectState.setConsumer(self.notifyAdvFilterSelectConsumer);
  },
  hydrateVisibilityFilter(data) {
    if (data?.visibilityConditions?.filters) {
      Object.keys(data.visibilityConditions.filters)?.forEach((filterName) => {
        const {
          filterSelectState,
          methodName
        } = {
          "groups" : {
            filterSelectState: "groupFilterSelectState",
            methodName: "filterChange"
          },
          "dimensions" : {
            filterSelectState: "dimensionsFilterMultiSelectStore",
            methodName: "setSelectedDimension"
          }
        }[filterName];

        if (
          filterSelectState &&
          methodName &&
          !data.visibilityConditions.filters[filterName].items?.every(item => lodash.isEmpty(item))
        ) {
          self?.[filterSelectState]?.[methodName](data.visibilityConditions.filters[filterName].items);
        }
      });
    }
  },
  notifyGroupFilterSelectConsumer(obj, covered) {
    self.groupFilterSelectOnChange && self.groupFilterSelectOnChange(self.groupFilterSelectState?.selected);
  },
  notifyTagFilterSelectConsumer(obj, covered) {
    self.tagFilterSelectOnChange && self.tagFilterSelectOnChange(self.tagFilterSelectState?.selected);
  },
  notifyDimensionFilterSelectConsumer(obj, covered) {
    self.dimensionFilterSelectOnChange && self.dimensionFilterSelectOnChange(self.dimensionsFilterMultiSelectStore?.selected);
  },
  notifyAdvFilterSelectConsumer(obj, covered) {
    self.advFilterSelectOnChange && self.advFilterSelectOnChange(self.codeFilterSelectState?.selected);
  },
  setGroupFilterSelectConsumer(onChange) {
    self.groupFilterSelectOnChange = onChange;
  },
  setTagFilterSelectConsumer(onChange) {
    self.tagFilterSelectOnChange = onChange
  },
  setDimentionFilterSelectConsumer(onChange) {
    self.dimensionFilterSelectOnChange = onChange
  },
  setAdvFilterSelectConsumer(onChange) {
    self.advFilterSelectOnChange = onChange
  },
  selectExtendedDataType(one) {
    self.extendedDataTypeSelectStore?.setSelectedExtendedDataType(one);
  },
  selectGroup(one) {
    self.selectedGroup = one;
    self.tagSelectStore?.tagStore?.setFilterOn(self?.selectedGroup?.uuid);
    self.refreshJSONGroupFilter();
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag) => tag.uuid);
  },
  refreshJSONGroupFilter() {
    let groupsList = [];
    if (self?.isExclusivelyGroupOwned) {
      groupsList = [{
        uuid: self?.selectedGroup?.uuid,
        label: self?.selectedGroup?.label
      }];
    } else {
      groupsList = self?.groupSelectStore?.groupStore?.groups;
    }
    self?.groupFilterSelectState.setGroups(groupsList.map((g) => {
      return {uuid: g.uuid, name: g.label};
    }));

    try {
      let data = JSON.parse(self.data);
      data.visibilityConditions = {
        ...data?.visibilityConditions,
        filters: {
          ...data?.visibilityConditions?.filters,
          groups: {
            ...data?.visibilityConditions?.filters?.groups,
            items: self?.isExclusivelyGroupOwned ? [{
              value: `groupUuid.${self?.selectedGroup?.uuid}`,
              label: self?.selectedGroup?.label
            }] : (data?.visibilityConditions?.filters?.groups?.items || [])
          }
        }
      };
      self.updateExtendedDataType(data);
    } catch (e) {
      console.error(e);
    }
  },
  toggleIsExclusivelyGroupOwned(){
    self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned;
    self.refreshJSONGroupFilter();
  },
  toggleDimensionAll(){
    self.dimensionAll = !self.dimensionAll;
    self.toggleIsDimensionSelectVisible(!self.dimensionAll);
    self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset();
  },
  toggleIsDimensionSelectVisible(visible){
    self.isDimensionSelectVisible = visible
  },
  transformErrors(errors) {
    return errors.map(error => {
      return error;
    });
  },
  update: flow(function* (uuid) {
    self.isLoading = true;
    self.handleHasErrors(false);
    let response;
    try {
      if (self.validateForm()) {
        response = yield getEnv(self).extendedDataManager.updateExtendedData(uuid, self);
        if (self.formHasErrors) {
          new Notification()
            .setType("error")
            .setMessage(`${self.name} Extended Data failed updating`)
            .send();
        } else {
          new Notification()
            .setType("success")
            .setMessage(`${self.name} Extended Data updated`)
            .send();
          self.handleHasErrors(false);
        }
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to updateExtendedData`, e);
    }
    self.isLoading = false;
    return response;
  }),
  getSelectedDimensions() {
    const dimensions = self.dimensionsMultiSelectStore.getTranspiledDimensions();
    return Object.values(dimensions).every(l => !l.length) ? undefined : dimensions;
  },
  updateExtendedDataType(data) {
    self.data = JSON.stringify(data);
  },
  enableEditIfUnused: flow(function* (id) {
    self.isLoading = true;
    if (!id) {
      console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
    }

    let response;
    try {
      // fetch all codes that has extendedData item with id
      response = yield getEnv(self).codeManager.fetchAllCodes({
        extendedDataIds: id
      }, self);

      if (response) {
        self.isNotUsed = !Boolean(response.results.length);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to assign enableEditIfUnused`, e);
    }
    self.handleHasErrors(false);
    self.isLoading = false;
    return response;
  }),
  validateSchema(schema, formData) {
    const ajv = new Ajv({allErrors: true, validateFormats: false});
    addFormats(ajv);
    const validate = ajv.compile(schema);
    let valid = validate(formData);
    if (!valid) {
      self.setLiveValidateSchema(true);
    }
    return valid;
  },
  validateForm() {
    if (lodash.isEmpty(self.name)) {
      self.isNameInvalid = true;
    }
    const isValid = self.validateSchema(self?.getSelectedTypeSchema(), self?.getFormData());
    return !self.isNameInvalid && isValid;
  },
  saveNew: flow(function* () {
    self.isLoading = true;
    self.handleHasErrors(false);
    let response;
    try {
      if (self.validateForm()) {
        response = yield getEnv(self).extendedDataManager.saveExtendedData(self);
        if (self.formHasErrors) {
          new Notification()
            .setType("error")
            .setMessage(`${self.name} Extended Data failed creating`)
            .send();
        } else {
          new Notification()
            .setType("success")
            .setMessage(`${self.name} Extended Data created`)
            .send();
          self.handleHasErrors(false);
        }
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to saveExtendedData:`, e);
    }
    self.isLoading = false;
    return response;
  }),
  fetchModelAndHydrate: flow(function* (id, queryParams={}){
    if (!id) {
      console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
    }
    let response;
    self.isLoading = true;
    self?.setOnSelectChanges();
    try {
      response = yield getEnv(self).extendedDataManager.fetchExtendedDataByUUID(id, queryParams, self);
      if (response) {
        self.hydrateForm(response);
        self.handleHasErrors(false);
      } else {
        self.handleHasErrors(true);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to Fetch and Hydrate data for '${id}'`, e);
    }
    self.isLoading = false;
    return response;
  }),
  hydrateDimension(dimensions = {}){
    const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
    self.dimensionAll = !isDimensionAll
    self.toggleDimensionAll()
    if (!self.dimensionAll) {
      self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
    }
  },
  hydrateForm(item){
    self.isLoading = true;
    try {
      self.name = item?.name;
      self.groupSelectStore?.selectGroup(item?.owner?.groupUuid);
      self.data = JSON.stringify(item?.data);
      self.hydrateVisibilityFilter(item?.data);
      self.tagSelectStore?.setSelectedTags(item?.tags?.flatMap(t => t?.uuid || t) || []);
      self.isExclusivelyGroupOwned = item?.isExclusivelyGroupOwned;
      self?.selectExtendedDataType(item?.type);
      self.hydrateDimension(item?.owner?.dimensions || {});
    } catch (err) {
      console.error(`${getType(self).name} Error: Failed to populate fields to view`, err);
    }
    self.isLoading = false;
  },
  setLiveValidateSchema(b) {
    self.liveValidateSchema = b;
  },
})).views((self) => ({
  getDefaultOptions() {
    const data = self.getFormData();
    const values = data?.values?.filter(i => i) || [];
    const additionalValues = data?.additionalValues?.filter(i => i) || [];
    if (values.length || additionalValues.length) {
      return [
        ...values,
        ...additionalValues
      ];
    }
    return [];
  },
  getSelectedTypeSchema() {
    let selectedType = self?.extendedDataTypeSelectStore?.getSelectedExtendedDataType() || JSON.stringify(defaultSchema);
    const result = (selectedType && selectedType.schema) ?
      JSON.parse(selectedType.schema) :
      {};
    if (!self?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled) {
      const filtersJSONSchema = result?.properties?.visibilityConditions?.dependencies?.toggle?.oneOf?.find(i => i.hasOwnProperty("required"));
      if (filtersJSONSchema) {
        delete filtersJSONSchema?.properties?.filters?.properties?.dimensions;
      }
    }
    return result;
  },
  getSelectedTypeUISchema() {
    const selectedType = self?.extendedDataTypeSelectStore?.getSelectedExtendedDataType() || JSON.stringify({});
    return (selectedType && selectedType.uiSchema) ?
      JSON.parse(selectedType.uiSchema) :
      {};
  },
  getFormData() {
    return JSON.parse(self.data);
  },
  allowFormUpdates(currentMode) {
    return currentMode === formConstants.Mode.EDIT ? self.isNotUsed : true;
  }
}));

export default ExtendedDataFormState;
