import React from "react";
import { observer } from "mobx-react-lite";
import AppleFooter from "../components/AppleFooter";
import GenericPass from "./GenericPass";

const AppleGenericPass = observer(({store}) => {
  if (typeof store === "string"){
    store = JSON.parse(store);
  }
  const data = store?.generic;

  return (
    <GenericPass
      store={{
        data,
        minHeight: "400px",
        footer: <AppleFooter barcodeType={data?.barcodeType} mobileWalletCode={data?.mobileWalletCode} />
      }}
    />
  );
});

export default AppleGenericPass;
