import { TableColumn } from './ScanbuyTable'
import dayjs from 'dayjs';
import {Badge, UncontrolledTooltip} from "reactstrap";
import React from "react";

export const createdColumn = TableColumn.create({
  name: 'Create Date', property: 'created', width: '15%', hide: 'sm',sortable: true, sortField: 'createdAt',
});
createdColumn.setSelector((row) => {
  const {createdAt} = row || {};
  const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
  return formattedCreateDate;
});

export const updatedColumn = TableColumn.create({
  name: 'Last Modified', property: 'updated', width: '15%', hide: 'sm', sortable: true, sortField: 'updatedAt',
});
updatedColumn.setSelector((row) => {
  const {updatedAt} = row || {};
  const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
  return formattedUpdateDate;
});

export const createTabColumn = (
  {
    name="Tag(s)",
    property="tags",
    hide="sm",
    uiStyle="info"
  }={}) => {
  const tabs = TableColumn.create({
    name: name,
    property: property,
    hide: hide
  });
  tabs.setSelector((row) => {
    return row[property];
  });
  tabs.setCell((row) => {
    return (
      <div>
        {row?.[property]?.map((tag) => {
          return (
            <Badge
              key={tag?.uuid}
              color={tag?.uiStyleConfigs?.color || uiStyle}
              className="badge-pill mr-1 mb-1"
            >
              {tag?.name}
            </Badge>
          );
        })}
      </div>
    );
  });
  return tabs;
};

export const createToolTipColumn = (
  {
    name="Name",
    property="name",
    width="25%",
    placement="top",
    sortable=true,
    sortField="name"
  }={}) => {
  const col = TableColumn.create({
    name: name,
    property: property,
    width: width,
    sortable: sortable,
    sortField: sortField,
  });
  col.setSelector((row)=> {
    const id = `tooltip-${property}-${row.uuid}`;
    return (
      <>
        <span id={id}>{row[property]}</span>
        <UncontrolledTooltip target={id} placement={placement}>
          {row[property]}
        </UncontrolledTooltip>
      </>
    );
  });
  return col;
};

export const createActionColumn = (
  {
    name="Action",
    property="action",
    width="5%",
    button=true,
    allowOverflow=true,
  }={}) => {
  const action = TableColumn.create({
    name: name,
    property: property,
    allowOverflow: allowOverflow,
    button: button,
    width: width,
  });
  return action;
};


export const createdByColumn = () => {
  const column = TableColumn.create({
    name: 'Created By',
    property: 'createdBy',
    width: '15%',
    hide: 'sm',
  });

  column.setSelector((row) => {
    const {_audit} = row;
    //console.log("_audit", _audit);
    const email = _audit?.email;
    return email;
  });

  return column;
};
