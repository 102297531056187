import HttpClient from "../../services/HttpClient";
import Notification from "../../utils/Notification";
import configs from "src/configs";

class PreviewManager {

  constructor(httpClient = null, defaultImageConfig = {}, v1ImageConfig = {}) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/qr`;
    this.defaultImageConfig = defaultImageConfig
    this.v1ImageConfig = v1ImageConfig
  }

  async hexToCMYK(hex) {
    // Ensure the hex string is in the correct format
    if (hex.charAt(0) === '#') {
      hex = hex.substring(1);
    }

    // Parse the hex color to get RGB values
    let r = parseInt(hex.substring(0, 2), 16) / 255;
    let g = parseInt(hex.substring(2, 4), 16) / 255;
    let b = parseInt(hex.substring(4, 6), 16) / 255;

    // Convert RGB to CMY
    let c = 1 - r;
    let m = 1 - g;
    let y = 1 - b;

    // Find the minimum value of C, M, and Y
    let k = Math.min(c, m, y);

    // Convert CMY to CMYK
    if (k === 1) {
      c = 0;
      m = 0;
      y = 0;
    } else {
      c = (c - k) / (1 - k);
      m = (m - k) / (1 - k);
      y = (y - k) / (1 - k);
    }

    // Convert to percentages and format as CMYK string
    c = Math.round(c * 100);
    m = Math.round(m * 100);
    y = Math.round(y * 100);
    k = Math.round(k * 100);
    return `cmyk(${c}%, ${m}%, ${y}%, ${k}%)`;
  }
  async convertCMYKStringsToArray(obj) {
    for (const key in obj) {

      if (typeof obj[key] === 'string' && obj[key].startsWith('cmyk(')) {
        // Extract the CMYK values from the string
        const cmykString = obj[key].slice(5, -1);
        const cmykArray = cmykString.split(',').map(value => parseInt(value));

        // Update the object with the array of integers
        obj[key] = cmykArray;
      }
    }
    return obj;
  }
  async convertHexInPayloadToCMYK(obj) {
    // Regular expression to check if a string is a valid hex color
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;

    for (let key in obj) {
      if (typeof obj[key] === 'string' && hexColorRegex.test(obj[key])) {
        obj[key] = await this.hexToCMYK(obj[key]);
      }
    }

    return obj;
  }

  async create(config, isSavingConfig = false) {
    const QRImageFormData = config?.qrImageFormData? JSON.parse( config.qrImageFormData): {}
    let isDefaultDesignerConfig = true
    if(QRImageFormData.isDefaultDesigner === false) {
      isDefaultDesignerConfig = QRImageFormData.isDefaultDesigner
    }
    if(QRImageFormData.colorMode === "HEXA"){  // hack because of the image service doesn't allow hexa as a colorMode.
      QRImageFormData.colorMode = "rgb"
    }
    if(!isDefaultDesignerConfig){
      let shorturl = config?.shortUrl? config.shortUrl: "Th1s 1s @ s@mpl3 QR v@lu3"
      return await this.createV1QR(QRImageFormData, isSavingConfig, shorturl, this.v1ImageConfig)
    } else {
      const baseConfig = this.defaultImageConfig
      let captionsInfo = {}
      try {
        if(config.qrImageFormData){
          captionsInfo = this.processCaptionData(QRImageFormData, baseConfig, isSavingConfig)
        }
      } catch (e) {
        new Notification()
          .setType("success")
          .setMessage("Failed to preview QR Image")
          .send();
        console.log("error", e)
        return null
      }
      this.processLogoData(QRImageFormData)
      if (QRImageFormData?.isDefaultDesigner) {
        // temp for color mode, needto be removed after api fixed cmyk issue
        QRImageFormData.colorMode = "rgb"
      }
      if (QRImageFormData?.transparentBackground){QRImageFormData.backgroundColor="transparent"}
      const updatedConfigWithBase = {...baseConfig, ...QRImageFormData, ...captionsInfo}
      // if color mode is cmyk, change image format to jpg
      if(QRImageFormData.colorMode === "cmyk" && (QRImageFormData.imageFormat === "png" || QRImageFormData.imageFormat == null)){
        updatedConfigWithBase.imageFormat = "jpg"

      }
      updatedConfigWithBase.colorMode = QRImageFormData.colorMode
      // updatedConfigWithBase.imageFormat = QRImageFormData.imageFormat

      if(QRImageFormData.imageFormat === "postscript"){
        updatedConfigWithBase.imageFormat = "eps"
      }
      const dataPayload = {
        "data": `${config?.shortUrl}`,
      }
      const payload = {...updatedConfigWithBase, ...dataPayload}
      console.log("payload", payload)
      const payloadCMYKConversion = await this.convertCMYKStringsToArray(payload.isCMYK? await this.convertHexInPayloadToCMYK(payload): payload)
      try {
        const res = await this.httpClient.post(this.url, payloadCMYKConversion);
        console.log('PreviewManager result', res)
        if (res.data) {
          return res
        }
      } catch (e) {
        new Notification()
          .setType("error")
          .setMessage("Failed to preview QR Image")
          .send();
          console.log("error", e)
        return null;
      }
    }
  }

  async createV1QR(qrImageFormData, isSavingConfig = false, shorturl, v1ImageConfig) {
    console.log("createV1QR", v1ImageConfig)
    const baseConfig = v1ImageConfig
    if(qrImageFormData.imageFormat) {
      if(qrImageFormData.imageFormat === "svg+xml"){
        baseConfig.setImageFormat("SVG")
      } else {
        baseConfig.setImageFormat(qrImageFormData.imageFormat.toUpperCase())
      }

    }
    if(qrImageFormData.resolution){
      baseConfig.setResolution(qrImageFormData.resolution)
    }
    if(qrImageFormData.size){
      baseConfig.setBarcodeSize(qrImageFormData.size)
    }
    if(qrImageFormData.barcodeSizeUnit){
      baseConfig.setBarcodeSizeUnit(qrImageFormData.unitOfMeasure)
      baseConfig.setReductionUnit(qrImageFormData.unitOfMeasure)
    }
    if(qrImageFormData.barcodeSizeUnit === "cm") {
      baseConfig.setBarcodeSize(qrImageFormData.size * 10)
    }
    if(qrImageFormData.quietZone){
      baseConfig.setQuietSpace(qrImageFormData.quietZone)
    }
    if(qrImageFormData.colorMode){
      baseConfig.setColorMode(qrImageFormData.colorMode)
    }
    if(qrImageFormData.foregroundColor) {
      let color = ''
      if(qrImageFormData.colorMode === "cmyk"){
        color = this.hexToCmyk(qrImageFormData.foregroundColor)
      }
      if(qrImageFormData.colorMode === "rgb"){
        color = this.hexToRgb(qrImageFormData.foregroundColor)
      }
      baseConfig.setBarcodeColor(color)
    }
    if(qrImageFormData.bwr) {
      baseConfig.setReduction(qrImageFormData.bwr)
    }
    baseConfig.setData(shorturl)
    const v1HttpClient = new HttpClient(configs.V1_COMPACT_QR_API_URL);
    const res = await v1HttpClient.post("/images", baseConfig);
    if (res.data.codeData) {
      const qrImageBase64 = "b'" + res.data.codeData
      let qrImage = {
        data: qrImageBase64,
        status: 201
      }
      //qrImage.data = qrImage.data.slice(1, -1);
      return qrImage
    }
  }

  hexToRgb(hex) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    return `${r},${g},${b}`;
  }

  rgbToCmyk(rgb) {
    var parts = rgb.split(',').map(Number);
    var r = parts[0];
    var g = parts[1];
    var b = parts[2];

    if (r == 0 && g == 0 && b == 0) {
      return "0,0,0,100"; // Pure black
    }

    var c = 1 - (r / 255);
    var m = 1 - (g / 255);
    var y = 1 - (b / 255);
    var k = Math.min(c, m, y);

    c = Math.round(((c - k) / (1 - k)) * 100);
    m = Math.round(((m - k) / (1 - k)) * 100);
    y = Math.round(((y - k) / (1 - k)) * 100);
    k = Math.round(k * 100);

    return `${c},${m},${y},${k}`;
  }

  hexToCmyk(hex) {
    var rgb = this.hexToRgb(hex);
    return this.rgbToCmyk(rgb);
  }

  // processCaptionData(data, isSavingConfig){
  //   const newCaptionData = {};
  //   for (const key in data) {
  //     //if (typeof data[key] === 'string') {
  //         const captionData = data[key];
  //         if (captionData.hasOwnProperty('captionTextPosition')) {
  //           if (captionData.captionTextPosition === 'Top' && !newCaptionData.hasOwnProperty('topText')) {
  //             let fontSize = captionData.captionFontSize
  //             if(isSavingConfig){
  //               fontSize = Math.floor(fontSize * 0.1)
  //             }
  //             newCaptionData.topText = captionData.captionContent;
  //             newCaptionData.topTextSize = fontSize;
  //             newCaptionData.topTextColor = captionData.captionColor;
  //           } else if (captionData.captionTextPosition === 'Bottom' && !newCaptionData.hasOwnProperty('bottomText')) {
  //             let fontSize = captionData.captionFontSize
  //             if(isSavingConfig){
  //               fontSize = Math.floor(fontSize * 0.1)
  //             }
  //             newCaptionData.bottomText = captionData.captionContent;
  //             newCaptionData.bottomTextSize = fontSize;
  //             newCaptionData.bottomTextColor = captionData.captionColor;
  //           } else if(captionData.captionTextPosition === 'Left' && !newCaptionData.hasOwnProperty('leftText')) {
  //             let fontSize = captionData.captionFontSize
  //             if(isSavingConfig){
  //               fontSize = Math.floor(fontSize * 0.1)
  //             }
  //             newCaptionData.leftText = captionData.captionContent;
  //             newCaptionData.leftTextSize = fontSize;
  //             newCaptionData.leftTextColor = captionData.captionColor;
  //           } else if(captionData.captionTextPosition === 'Right' && !newCaptionData.hasOwnProperty('rightText')) {
  //             let fontSize = captionData.captionFontSize
  //             if(isSavingConfig){
  //               fontSize = Math.floor(fontSize * 0.1)
  //             }
  //             newCaptionData.rightText = captionData.captionContent;
  //             newCaptionData.rightTextSize = fontSize;
  //             newCaptionData.rightTextColor = captionData.captionColor;
  //           } else if(captionData.captionTextPosition === 'Top' && newCaptionData.hasOwnProperty('topText')) {
  //             throw new Error('Top caption already exists')
  //           } else if(captionData.captionTextPosition === 'Bottom' && newCaptionData.hasOwnProperty('bottomText')) {
  //             throw new Error('Bottom caption already exists')
  //           } else if(captionData.captionTextPosition === 'Left' && newCaptionData.hasOwnProperty('leftText')) {
  //             throw new Error('Left caption already exists')
  //           } else if(captionData.captionTextPosition === 'Right' && newCaptionData.hasOwnProperty('rightText')) {
  //             throw new Error('Right caption already exists')
  //           }
  //         }

  //    // }
  //   }
  //   return newCaptionData
  // }

  processCaptionData(data, defaultImageConfig, isSavingConfig) {
    const newCaptionData = {};

    const positionKeys = defaultImageConfig.positionKeys;

    for (const key in data) {
      const captionData = data[key];
      const captionTextPosition = captionData.captionTextPosition;

      if (captionData.hasOwnProperty('captionTextPosition') && positionKeys.hasOwnProperty(captionTextPosition)) {
        const positionKey = positionKeys[captionTextPosition];

        if (newCaptionData.hasOwnProperty(positionKey)) {
          throw new Error(`${captionTextPosition} caption already exists`);
        }

        let fontSize = captionData.captionFontSize;
        if (isSavingConfig) {
          fontSize = Math.floor(fontSize * 0.1);
        }

        newCaptionData[positionKey] = captionData.captionContent;
        newCaptionData[`${positionKey}Size`] = fontSize;
        newCaptionData[`${positionKey}Color`] = captionData.captionColor;
      }
    }

    return newCaptionData;
  }

  // filter logo data,
  // if logoType is emptyImage, delete backgroundImage
  // if logoType is logoImage, delete backgroundImage
  // if logoType is backgroundImage, delete logo
  processLogoData(data){
    if(data.hasOwnProperty("logoType")){
      if(data["logoType"] === "emptyImage"){
        data["logo"] = "empty"
      }
    }

  }


  // async fetch(data){
  //
  //   try {
  //     const res = await this.httpClient.get(this.url, data);
  //     if (res.data) {
  //       return res.data
  //     }
  //   }catch (e){
  //     if(e.message.includes("timeout of 1000ms exceeded")){
  //       console.log("try again")
  //       await this.create()
  //     }
  //     console.log("e", e)
  //   }
  // }
}


export default PreviewManager
