import Notification from "./Notification";
import {MergedRoutes, RoleSet} from "./constants";

export const requiredRolesCheck = (roles = [], userRoles = []) => {
  const hasRequiredRole = roles.some(role => userRoles.includes(role));
  if (hasRequiredRole) return true;

  // if the user does not have the required role, show err notification and return false
  new Notification("warning", "Your permission set do not allow you to access that feature and/or functionality. Please contact an administrator to modify if needed your permission set")
    .setTitle("Missing permission")
    .send();
  return false;
}


export const checkUserPermissions = (fromState, toState, routerStore) => {
  const {rootStore: {userRoles}} = routerStore.options;
  let {queryParams, routeName} = toState;

  if (MergedRoutes.has(routeName) && queryParams?.isEditable === 'false') routeName = MergedRoutes.get(routeName)

  const verifiedUser = requiredRolesCheck(RoleSet[routeName], userRoles)

  if (!verifiedUser) {
    return Promise.resolve(fromState);  // Prevent the route transition
  }
};
