import React from "react";
import { observer } from "mobx-react-lite";
import { Col, Collapse, CustomInput, FormGroup, Input, Label, Row } from "reactstrap";
import ColorButton from "../../../ColorButton";
import { getSnapshot } from "mobx-state-tree";
import { FormattedMessage } from "react-intl";
import { useIntl } from 'react-intl';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DisableWrapper from "../../../../utils/disableWrapper";

const ColorView = observer(({ store, frameAndCaptionsStore, isDefaultDesigner, isDisabled = false }) => {
  const intl = useIntl();
  return (
    <DisableWrapper isDisabled={isDisabled}>
       <>
    {/*<div className="colorMode">*/}
    {/*  <Row>*/}
    {/*    <Col sm={3}><FormattedMessage id="qrDesign.create.colorMode.defaultMessage" /></Col>*/}
    {/*    <Col>*/}
    {/*      <Row>*/}
    {/*        <Col sm={2}>*/}
    {/*          <FormGroup check>*/}
    {/*            <Input*/}
    {/*              name="colorMode"*/}
    {/*              value='1'*/}
    {/*              type="radio"*/}
    {/*              id='radio1'*/}
    {/*              checked={store.colorMode === 1}*/}
    {/*              onChange={store.handleColorMode}*/}
    {/*            />*/}
    {/*            {" "}*/}
    {/*            <Label check><FormattedMessage id="qrDesign.create.colorModeOptions.RGB.defaultMessage" /></Label>*/}
    {/*          </FormGroup>*/}
    {/*        </Col>*/}
    {/*        <Col sm={2}>*/}
    {/*          <FormGroup check>*/}
    {/*            <Input*/}
    {/*              name="colorMode"*/}
    {/*              value='2'*/}
    {/*              type="radio"*/}
    {/*              id='radio2'*/}
    {/*              checked={store.colorMode === 2}*/}
    {/*              onChange={store.handleColorMode}*/}
    {/*            />*/}
    {/*            {" "}*/}
    {/*            <Label check><FormattedMessage id="qrDesign.create.colorModeOptions.CMYK.defaultMessage" /></Label>*/}
    {/*          </FormGroup>*/}
    {/*        </Col>*/}
    {/*        <Col sm={2}>*/}
    {/*          <FormGroup check>*/}
    {/*            <Input*/}
    {/*              name="colorMode"*/}
    {/*              value='3'*/}
    {/*              type="radio"*/}
    {/*              id='radio3'*/}
    {/*              checked={store.colorMode === 3}*/}
    {/*              onChange={store.handleColorMode}*/}
    {/*            />*/}
    {/*            {" "}*/}
    {/*            <Label check><FormattedMessage id="qrDesign.create.colorModeOptions.grayScale.defaultMessage" /></Label>*/}
    {/*          </FormGroup>*/}
    {/*        </Col>*/}
    {/*      </Row>*/}
    {/*    </Col>*/}
    {/*  </Row>*/}
    {/*</div>*/}
    <Row>
    <Col sm={2} >Is CMYK </Col>
    <Col sm={1}></Col>
    <Col sm={2}>
      <div className="move-switch-position">
        <CustomInput
          checked={store.isCMYK}
          type="switch"
          id="isCMYK"
          onChange={()=>{store.handleIsCMYK(!store.isCMYK)}}
        />
      </div>
    </Col>
    </Row>

    {isDefaultDesigner &&
      <>
        <Row>
          <Col >
            Background
          </Col>
        </Row>
        <div style={{textAlign: "left", marginLeft: 15}}>
      <Row>
        <Col sm={2} >
          <FormattedMessage id="qrDesign.create.transparentBackground.defaultMessage" />
        </Col>
        <Col sm={1}></Col>
        <Col sm={1}>
            <CustomInput
              checked={store.transparentBackground}
              type="switch"
              id="transparent"
              onChange={store.handleTransparentBackground}
            />
        </Col>
      </Row>
    </div>

      <div style= {{textAlign: "left", marginLeft: 15}}>
        <Collapse isOpen={!store.transparentBackground}>
          <Row>
            <Col sm={3} >
              <FormattedMessage id="qrDesign.create.colorBackground.defaultMessage" />
            </Col>
            <Col sm={1}>
                <ColorButton
                  defaultedColor={store.backgroundColor}
                  colorPosition="backgroundColor"
                  selectColor={store.handleSelectColor}
                  colorMode={store.colorMode}
                  handleColorMode={store.handleColorMode}
                  isCMYK={store.isCMYK}
                />
            </Col>
          </Row>
        </Collapse>
      </div>
      </>
    }
    <div className="foreground">
    <Row>
      <Col >

        <FormattedMessage id="qrDesign.create.foreground.defaultMessage" />
      </Col>
    </Row>
    <div style={{ textAlign: "left", marginLeft: 15 }}>
      {isDefaultDesigner &&
      <Row>
        <Col sm={3}><FormattedMessage id="qrDesign.create.addGradient.defaultMessage" /></Col>
        <Col sm={1}>
          <CustomInput
            type="switch"
            id="addGradient"
            checked={store.addGradient}
            onChange={()=>{store.toggleAddGradient(frameAndCaptionsStore)}}
          />
        </Col>
      </Row>
      }
      <Collapse isOpen={!store.addGradient}>
        <Row>
          <Col sm={3}>
            {isDefaultDesigner &&
            <FormattedMessage id="qrDesign.create.oneColor.defaultMessage" />
            }
            {!isDefaultDesigner &&
            <FormattedMessage id="qrDesign.create.legacyDesgin.color.defaultMessage" />
            }
          </Col>
          <Col sm={1}>
            <ColorButton
              defaultedColor={store.foregroundColor}
              colorPosition="foregroundColor"
              selectColor={store.handleSelectColor}
              frameAndCaptionsStore={frameAndCaptionsStore}
              colorMode={store.colorMode}
              isCMYK={store.isCMYK}
              handleColorMode={store.handleColorMode}
              isDefaultDesigner={isDefaultDesigner}
            />
          </Col>
        </Row>
      </Collapse>
      {isDefaultDesigner &&
      <Collapse isOpen={store.addGradient}>
        <Row>
          <Col sm={2}>
            <FormattedMessage id="qrDesign.create.twoColors.defaultMessage" />
          </Col>
          {/*  <Col sm={3}>*/}
          {/*</Col>*/}
          <Col sm={1} style={!store.gradientType ? ({opacity: 0.3,pointerEvents: "none"}) : {}}>
              <ColorButton
                defaultedColor={store.gradientColor1}
                colorPosition="gradientColor1"
                selectColor={store.handleSelectColor}
                colorMode={store.colorMode}
                isCMYK={store.isCMYK}
                handleColorMode={store.handleColorMode}
              /></Col>
            <Col sm={2} style={!store.gradientType ? ({opacity: 0.3,pointerEvents: "none"}) : {}}>
              <ColorButton
                defaultedColor={store.gradientColor2}
                colorPosition="gradientColor2"
                selectColor={store.handleSelectColor}
                colorMode={store.colorMode}
                isCMYK={store.isCMYK}
                handleColorMode={store.handleColorMode}
              />
            </Col>
          <Col sm={2}>
            <CustomInput
              className="w-auto"
              type="select"
              value={store.gradientType}
              name="gradientType"
              id={store.gradientType}
              onChange={store.handleGradientType}
              defaultValue={store.gradientType}
            >
              <option value={'vertical'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.vertical.defaultMessage' })}</option>
              <option value={'horizontal'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.horizontal.defaultMessage' })}</option>
              <option value={'diagonal'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.diagonal.defaultMessage' })}</option>
              <option value={'inverse diagonal'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.inverseDiagonal.defaultMessage' })}</option>
              <option value={'radial'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.radial.defaultMessage' })}</option>
              <option value={'square'}>{intl.formatMessage({ id: 'qrDesign.create.gradientTypeOptions.square.defaultMessage' })}</option>
            </CustomInput>
          </Col>
        </Row>
      </Collapse>
      }
      {isDefaultDesigner && <>
      <div className="colorQrElements">
        <Row>
          <Col sm={3} > <FormattedMessage id="qrDesign.create.colorQRElements.defaultMessage" /></Col>
          <Col sm={1}>
            <CustomInput
              checked={store.colorEyesAndModules}
              type="switch"
              id="qrElements"
              onChange={store.handleColorEyesAndModules}
            />
          </Col>
        </Row>
        <Collapse isOpen={store.colorEyesAndModules}>
          <div style={{textAlign: "left", marginLeft: 15}}>
            <Row>
              <Col sm={2}>
                <FormattedMessage id="qrDesign.create.eyelid.defaultMessage" />
              </Col>
              <Col sm={1}
                   style={
                     store.addThreeEyelidColors ? ({
                       opacity: 0.3,
                       pointerEvents: "none"
                     }) : {}
                   }
              >
                <ColorButton

                defaultedColor={store.eyelidColor}
                colorPosition="eyelidColor"
                selectColor={store.handleSelectColor}
                colorMode={store.colorMode}
                isCMYK={store.isCMYK}
                handleColorMode={store.handleColorMode}
              />
            </Col>

              <Col sm={3}><FormattedMessage id="qrDesign.create.selectThree.defaultMessage" /></Col>
              <Col sm={1} style={{marginLeft: -50}}>
                <CustomInput
                  checked={store.addThreeEyelidColors}
                  type="switch"
                  id="toggleEyelidThree"
                  value="toggleEyelidThree"
                  onChange={store.toggleAddThreeEyelidColors}
                />
              </Col>
              <Collapse isOpen={store.addThreeEyelidColors}>
                <Row sm={1} style={
                  store.eye1ColorUseForeground ? ({
                    opacity: 0.3,
                    marginLeft: 3
                  }) : {marginLeft: 3}
                }>
                  <Col sm={5}>
                  <ColorButton
                    defaultedColor={store.eye1Color}
                    colorPosition="eye1Color"
                    selectColor={store.handleSelectColor}
                    colorMode={store.colorMode}
                    isCMYK={store.isCMYK}
                    handleColorMode={store.handleColorMode}
                  />
                  </Col>
                  <Col sm={1}/>
                  <Col sm={2}><FontAwesomeIcon onClick={() => {
                    store.deleteColor("eye1Color")
                  }} style={{cursor: "pointer"}} icon={faTrash} className="align-middle"/>
                  </Col>
                </Row>
                <Row sm={1} style={
                  store.eye2ColorUseForeground ? ({
                    opacity: 0.3,
                    marginLeft: 3
                  }) : {marginLeft: 3}
                }>
                  <Col sm={5}>
                  <ColorButton
                    defaultedColor={store.eye2Color}
                    colorPosition="eye2Color"
                    selectColor={store.handleSelectColor}
                    colorMode={store.colorMode}
                    isCMYK={store.isCMYK}
                    handleColorMode={store.handleColorMode}
                  />
                  </Col>
                  <Col sm={1}/>
                  <Col sm={2}><FontAwesomeIcon onClick={() => {
                    store.deleteColor("eye2Color")
                  }} style={{cursor: "pointer"}} icon={faTrash} className="align-middle"/>
                  </Col>
                </Row>
                <Row sm={1} style={
                  store.eye3ColorUseForeground ? ({
                    opacity: 0.3,
                    marginLeft: 3
                  }) : {marginLeft: 3}
                }>
                  <Col sm={5}>
                    <ColorButton
                      defaultedColor={store.eye3Color}
                      colorPosition="eye3Color"
                      selectColor={store.handleSelectColor}
                      colorMode={store.colorMode}
                      isCMYK={store.isCMYK}
                      handleColorMode={store.handleColorMode}
                    />
                  </Col>
                  <Col sm={1}/>
                  <Col sm={2}><FontAwesomeIcon onClick={() => {
                    store.deleteColor("eye3Color")
                  }} style={{cursor: "pointer"}} icon={faTrash} className="align-middle"/>
                  </Col>
                </Row>
              </ Collapse>
            </Row>
            <Row>
              <Col sm={2}>
                <FormattedMessage id="qrDesign.create.eyeball.defaultMessage" />
              </Col>
              <Col sm={1}
                   style={
                     store.addThreeEyeballColors ? ({
                       opacity: 0.3,
                       pointerEvents: "none"
                     }) : {}
                   }
              >
                <ColorButton
                  defaultedColor={store.eyeballColor}
                  colorPosition="eyeballColor"
                  selectColor={store.handleSelectColor}
                  colorMode={store.colorMode}
                  isCMYK={store.isCMYK}
                  handleColorMode={store.handleColorMode}
                />
              </Col>
              <Col sm={3}><FormattedMessage id="qrDesign.create.selectThree.defaultMessage" /></Col>
              <Col sm={1} style={{marginLeft: -50}}>
                <CustomInput
                  type="switch"
                  checked={store.addThreeEyeballColors}
                  id="addThreeEyeballColors"
                  value="addThreeEyeballColors"
                  onChange={store.toggleAddThreeEyeballColors}
                />
              </Col>
              <Collapse isOpen={store.addThreeEyeballColors}>
                <Row sm={1} style={
                  store.ball1ColorUseForeground ? ({
                    opacity: 0.3,marginLeft: 3,marginTop: 2,
                  }) : {marginTop: 2,marginLeft: 3}
                }>
                  <Col sm={5}>
                  <ColorButton
                    defaultedColor={store.ball1Color}
                    colorPosition="ball1Color"
                    selectColor={store.handleSelectColor}
                    colorMode={store.colorMode}
                    isCMYK={store.isCMYK}
                    handleColorMode={store.handleColorMode}
                  />
                  </Col>
                  <Col sm={1} />
                  <Col sm={2}><FontAwesomeIcon onClick={()=>{store.deleteColor("ball1Color")}} style={{cursor:"pointer"}} icon={faTrash} className="align-middle"/></Col>
                </Row>
                <Row sm={1} style={
                  store.ball2ColorUseForeground ? ({
                    opacity: 0.3,
                    marginLeft: 3
                  }) : {marginLeft: 3}
                }>
                  <Col sm={5}>
                    <ColorButton
                      defaultedColor={store.ball2Color}
                      colorPosition="ball2Color"
                      selectColor={store.handleSelectColor}
                      colorMode={store.colorMode}
                      isCMYK={store.isCMYK}
                      handleColorMode={store.handleColorMode}
                    />
                  </Col>
                  <Col sm={1} />
                  <Col sm={1}><FontAwesomeIcon onClick={()=>{store.deleteColor("ball2Color")}} style={{cursor:"pointer"}} icon={faTrash} className="align-middle"/></Col>
                </Row>
                <Row sm={1}  style={
                  store.ball3ColorUseForeground? ({
                    opacity: 0.3,
                    marginLeft: 3
                  }) : {marginLeft: 3}
                }>
                  <Col sm={5}>
                  <ColorButton
                    defaultedColor={store.ball3Color}
                    colorPosition="ball3Color"
                    selectColor={store.handleSelectColor}
                    colorMode={store.colorMode}
                    isCMYK={store.isCMYK}
                    handleColorMode={store.handleColorMode}
                  />
                </Col>
                <Col sm={1} />
                <Col sm={1} onClick={()=>{store.deleteColor("ball3Color")}} ><FontAwesomeIcon
                  style={{cursor: "pointer"}} icon={faTrash} className="align-middle"/></Col>
                </Row>
              </ Collapse>
            </Row>
            {/*<Row>*/}
            {/*  <Col sm={2}>*/}
            {/*    Module*/}
            {/*  </Col>*/}
            {/*  <Col sm={2}>*/}
            {/*    <ColorButton*/}
            {/*      defaultedColor={store.moduleColor}*/}
            {/*      colorPosition="moduleColor"*/}
            {/*      selectColor={store.handleSelectColor}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </div>
        </Collapse>
      </div>
      </>}
    </div>

    </div>
    </>
    </DisableWrapper>
      )
})
export default ColorView;
