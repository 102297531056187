import { getType, resolveIdentifier, types } from 'mobx-state-tree'
import QrTemplateStore from "../../../stores/domain/QrTemplateStore";
import QrTemplate from "../../../models/QrTemplates";
import {ModalState} from "../modal/GenericModal";

export const QrTemplateSelectState = types.model('TemplateSelectState',{
selectStateUuid : types.maybe(types.identifier),
qrtemplateStore: types.maybe(types.late(() => types.reference(QrTemplateStore))),
selectedTemplate: types.maybe(types.safeReference(QrTemplate)),
}).actions((self)=>({
    selectTemplate(e){
        self.selectedTemplate=e;
    },clearSelected(){
        self.selectedTemplate = undefined;
    }
})).views( (self) => ({
    options() {
      const { qrtemplates } = self.qrtemplateStore || [];
      return qrtemplates.slice().sort((a,b) => a?.label?.localeCompare(b?.label))
    },
}))

export default QrTemplateSelectState;