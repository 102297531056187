import { types } from "mobx-state-tree";
import {BatchItemQrModel} from "./batchItemQrModel";

export const BatchItem = types.model("BatchItem", {
  uuid: types.optional(types.string, ""),
  batchUuid: types.optional(types.string, ""),
  status: types.optional(types.string, ""),
  qr: types.maybe( BatchItemQrModel)
  //raw
});
