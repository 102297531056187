import {flow, getEnv, getType, types} from "mobx-state-tree";
import {GroupFilterSelectState} from "../../elements/filters/GroupFilterSelectState";
import {AdvancedConfFilterSelectState} from "../../elements/filters/AdvancedConfFilterSelectState";
import lodash from "lodash";
import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import {TagFilterSelectState} from "../../elements/filters/TagFilterSelectState";
import {formConstants} from "../../../utils/constants";
import {ModalState} from "../../elements/modal/GenericModal";
import {ExtendedDataListModel} from "../../../models/extendedData/ExtendedDataListModel";
import {ExtendedDataSetListModel} from "../../../models/extendedData/ExtendedDataSetListModel";

const ExtendedDataListViewState = types.model("ExtendedDataListViewState", {
  modalStore: types.maybe(ModalState),
  setStore: types.maybe(types.late(() => ExtendedDataSetListModel)),
  store: types.maybe(types.late(() => ExtendedDataListModel)),
  activeTab: types.optional(types.string, '1'),
  isFilterVisible: types.optional(types.boolean, true),
  isFilterTypeOR: types.optional(types.boolean, true),
  advFilterSelectState: types.maybeNull(types.reference(AdvancedConfFilterSelectState)),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  groupFilterSelectState: types.maybeNull(types.reference(GroupFilterSelectState)),
  filterGroup: types.optional(types.boolean, true),
})
  .volatile((self) => ({
    modalComponent: types.object,
    modalComponentStore: types.object,
  }))
  .actions((self) => ({
    getSetStore() {
      return self.setStore;
    },
    handleRowClick(row, componentStore, component) {
      self.modalComponent = component;
      self.modalComponentStore = componentStore;
      self.modalStore.handleTitle(componentStore.title);
      self.modalStore.toggle();
    },
    getStore() {
      return self.store;
    },
    setActiveTab(tab) {
      self.activeTab = tab;
      self.refresh();
      self.getFilters();
    },
    getCurrentStore() {
      return self.activeTab === "1" ? self.getSetStore() : self.getStore();
    },
    setOnSelectChanges() {
      self.advFilterSelectState?.setConsumer(self.selectFilter)
      self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter);
      self.tagFilterSelectState?.setConsumer(self.selectFilter)
      self.groupFilterSelectState?.setConsumer(self.selectFilter)
    },
    toggleFilterVisible() {
      self.isFilterVisible = !self.isFilterVisible
    },
    selectFilter(obj, covered){
      self.getCurrentStore().removeSearchParams(covered,/^dimension/);
      if(obj){
        const transpiledDimensions = {};
        if (!lodash.isEmpty(obj.dimensions)) {
          obj.dimensions.forEach(dimension => {
            const [uuid, value] = dimension.split('_');
            if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
              transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
            } else {
              transpiledDimensions[`dimension[${uuid}]`] = value;
            }
          });
          delete obj.dimensions
        }

        const params = lodash.mapValues(obj,(value, key) => {
          return {}[key] = value.join(',');
        });
        self.getCurrentStore().addSearchParams({...params,...transpiledDimensions})
        self.refresh()
        const prevGroupUuid = self.prevGroupUuid || [];
        const currentGroupUuid = obj.groupUuid || [];
        if (!lodash.isEqual(prevGroupUuid, currentGroupUuid)) {
          self.getFilters();
          self.filterGroup = false
          self.prevGroupUuid = currentGroupUuid;
        }
      }
    },
    onSearchChange(txt){
      console.log(txt?.currentTarget?.value);
      const searchText = txt?.currentTarget?.value;
      if (searchText) {
        if (searchText.length > 2) {
          self.getCurrentStore().removeSearchParams(["q"]);
          self.getCurrentStore().addSearchParams({ q: searchText })
          self.refresh()
        } else {
          self.getCurrentStore().removeSearchParams(["q"])
        }
      } else {
        self.getCurrentStore().removeSearchParams(["q"]);
        self.refresh()
      }
    },
    isItemUnused: flow(function* (id) {
      self.isLoading = true;
      if (!id) {
        console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
      }

      let response;
      try {
        // fetch all codes that has extendedData item with id
        response = yield getEnv(self).codeManager.fetchAllCodes({
          extendedDataIds: id
        }, self);
      } catch (e) {
        console.error(`${getType(self).name} Error: Failed to assign isItemUnused`, e);
      }

      self.isLoading = false;

      return response && response.results?.length === 0;
    }),
    selectFilterMethod() {
      if (!self.isFilterTypeOR) {
        self.isFilterTypeOR = !self.isFilterTypeOR
        self.getCurrentStore().addSearchParams({tagFilterType: formConstants.Mode.OR})
      } else {
        self.isFilterTypeOR = !self.isFilterTypeOR
        self.getCurrentStore().addSearchParams({tagFilterType: formConstants.Mode.AND})
      }
      self.refresh()
    },
    getFilters() {
      self.getCurrentStore().fetchAllFilters();
    },
    refresh() {
      self.getCurrentStore().fetchAll();
    },
  }));

export default ExtendedDataListViewState;
