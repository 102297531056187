import React, {useEffect} from "react";
import {
  Button,
  Card,
  CardBody, Col, DropdownItem, InputGroup, InputGroupAddon, Row, UncontrolledTooltip,
} from "reactstrap";
import {observer} from "mobx-react-lite";
import ScanbuyTable, {TableColumn} from "../../../tables/ScanbuyTable";
import GroupSelect from "../../../elements/groups/GroupSelect";
import {useIntl} from "react-intl";
import TableActionsDropdown from "../../../tables/TableActionsDropdown";
import {Edit as EditIcon, Search as ViewIcon} from "react-feather";

export const buildDomainColums = (routerStore) => {


  const c1 = TableColumn.create({ name: 'Domain', property: 'domain' });
  c1.setSelector((row)=> {
    return(
      <>
      <span id={`tooltip-${row.uuid}`}>{row.label}</span>
      <UncontrolledTooltip target={`tooltip-${row.uuid}`} placement="top">{row.label}</UncontrolledTooltip>
      </>
    )});
  const c2 = TableColumn.create({ name: 'Default', property: 'isDefault' });
  c2.setSelector((row)=> row.isDefault.toString());

  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });
  actionColl.setCell((row) => (
    <TableActionsDropdown actionsList={[
      <DropdownItem id="action-btn-view" key={'view/' + row.uuid} onClick={() => {
        routerStore.goTo('/domains/view/:id', {
          params: {id: row.uuid}
        });
      }}>
        <ViewIcon/>&nbsp;View
      </DropdownItem>,
      <DropdownItem id="action-btn-edit" key={'edit/' + row.uuid} onClick={() => {
        routerStore.goTo('/domains/edit/:id', {
          params: {id: row.uuid}
        });
      }}>
        <EditIcon/>&nbsp;Edit
      </DropdownItem>,
    ]}/>
  ))

  return [

    c1,
    c2,
    actionColl,
  ];
}

const DomainsListView = observer(({domainsListViewState}) => {
  const intl = useIntl();
  console.log("domainStore",domainsListViewState.columns);
  console.log("domainStore",domainsListViewState.store);
  domainsListViewState.setOnSelectChanges()


  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        // borderRadius: '5px',
        outline: '1px solid #FFFFFF',
      },
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row sm={12}>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                   {intl.formatMessage({ id: "filterByGroups.defaultMessage"})}
                </InputGroupAddon>
                <GroupSelect groupSelectStore={domainsListViewState?.groupSelectStore}/>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ScanbuyTable
                columns={domainsListViewState.columns}
                customStyles={customStyles}
                store={domainsListViewState.store}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
})
export default DomainsListView
