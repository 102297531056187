import {getType, types} from "mobx-state-tree";
// import ParameterSet from "../../../models/parameterSets/ParameterSetModel";
import ParameterSetStore from "../../../stores/domain/ParameterSetStore";
import {ParameterSet} from "../../../models/parameterSets/ParameterSetModel";

export const ParameterSetSelectState = types.model('ParameterSetSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(ParameterSetStore))),
  selected: types.maybe(types.safeReference(ParameterSet)),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  search(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
    //self.groupStore.groups
  },
  setConsumer(f){
    self.notifySelect = f
  },
  select(parameterSet) {
    console.log("*****")
    console.log(parameterSet)
    self.notifySelect(parameterSet.uuid)
    self.selected = parameterSet

  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }

    self.selectedSchema = {}
  }
})).views( (self) => ({
  options() {
    const list = self.store.list || [];
    return list.slice().sort((a,b) => a?.label?.localeCompare(b?.label))
  },
}))


