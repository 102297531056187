/**
 * Builds and exports our "<Route>" component
 */
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { RouterView } from "mobx-state-router";
import { withAuthenticationRequired } from '@auth0/auth0-react';

import DashboardLayout from "../layouts/Dashboard";
import ScrollToTop from "../components/ScrollToTop";
import { viewMap } from "./index";


const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <DashboardLayout><RouterView viewMap={viewMap} /></DashboardLayout>
      </Switch>
    </ScrollToTop>
  </Router>
);

export default withAuthenticationRequired(Routes, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div className="text-center">
    <h1 className="display-1 font-weight-bold">Scanbuy</h1>
    <p className="h1">Signing into Portal</p>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>

})
