export function updateInfoAfterSelectGroup(self, obj) {
    self.selectedGroup = obj;
    self.publishDomainSelectStore?.refresh(self.selectedGroup)
    self.tagSelectStore?.tagStore?.setFilterOn(self?.selectedGroup?.uuid);
    const dimensionUuids = self.dimensionSelectViewState?.dimensionSelectStates
        .filter(selectState => selectState?.dimension?.type === "CONDITIONAL")
        .map(selectState => selectState?.selectStateUuid);
    dimensionUuids.forEach(uuid => {
        self.dimensionSelectViewState?.dimensionSelectStates?.find(selectState => selectState?.selectStateUuid == uuid).getListOnGroup(self?.selectedGroup?.uuid)
    })
}