import {types} from "mobx-state-tree";
import PageTemplateModel from "../../models/pageTemplate";
import lodash from 'lodash';

export const PageTemplateStore = types.model('PageTemplateStore',{
  storeUuid: types.identifier,
  pageTemplates:types.optional(types.array(PageTemplateModel),[]),
}).actions(self => ({

  initWithData(pageTemplates = []) {
    try {
      if (!lodash.isEmpty(pageTemplates) && lodash.isArray(pageTemplates)) {
        self.pageTemplates = pageTemplates.map(template => PageTemplateModel.create({
          uuid: template?.uuid,
          name: template?.name,
          label: template?.name,
          pageType: template?.pageType,
          configs: template?.configs
        }))
        self.pageTemplates.sort((a,b) => a.label.localeCompare(b.label))
      }
    } catch (e) {
      console.error("Failed to init pageTemplateStore with data",e);
    }
  }
}));
