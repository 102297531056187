import { getType, resolveIdentifier, types } from 'mobx-state-tree'
import Dimension, {DimensionValues} from "../../../models/Dimension";
import _ from 'lodash'
import lodash from "lodash";
export const DimensionSelectState = types.model('DimensionSelectState',{
  selectStateUuid: types.identifier,
  dimension: types.maybe(types.late(() => types.reference(Dimension))),
  selectedDimension: types.maybe(types.safeReference(DimensionValues)),
}).volatile(self => ({
  notifySelect: types.function,
  notifyFilterSelect: types.funtion,
  selected: [],
  filteredDimensions: [],
  filteredDimensionValuesOnGroup: [],
})).actions( (self)=>({
  setConsumer(f){
    self.notifySelect = f
  },
  setFilterConsumer(f){
    self.notifyFilterSelect = f
  },
  setDimensionFilters(dimensions){
    if(dimensions) {
      self.filteredDimensions = dimensions
    }
  },
  reset() {
    self.selected = []
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  },
  setSelectedDimension(selectedDimension) {
    // self.selectedDimension = selectedDimension?.map( (t) => {
    //   return resolveIdentifier(DimensionValues, self,t?.uuid || t)
    // });
    self.selectedDimension = selectedDimension
    console.log(self.selectedDimension)
    self.notifySelect(self.selectedDimension,self.selectStateUuid);
  },
  setDefaults(){
    let defaultDimensions = self.dimension.values.find(item => item?.isDefault)
    if(_.isEmpty(defaultDimensions) && self.dimension.values.length === 1){
      defaultDimensions = self.dimension.values[0] // if user has access to only one dimension, select it by default
    }
    self.setSelectedDimension(defaultDimensions)
  },
  clearSelectedDimensions(){
    self.selectedDimension = undefined
  },
  filterChange(data) {
    self.selected = data;
    self.notifyFilterSelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["dimensionUuid"]);
  },
  getListOnGroup(groupUuid) {
    self.filteredDimensionValuesOnGroup = self.dimension?.values?.find(item => {
      const groupUuids = item?.owner?.groupUuids;
      return groupUuids?.includes(groupUuid) || !groupUuids || groupUuids?.length === 0
    }) || [];

  },
})).views( (self) => ({
  dimensionOptionValues() {
    let { values } = self.dimension || {};
    if(self.filteredDimensionValuesOnGroup?.length > 0 ){
      values = self.filteredDimensionValuesOnGroup
    } else {
      if(self.dimension?.type === "CONDITIONAL" && self.filteredDimensionValuesOnGroup?.length === 0){
        values = self.dimension.values.filter(item => item?.isDefault)
      }
    }
    return values.slice().sort((a,b) => a?.label?.localeCompare(b?.label)) || []
  },
  dimensionFilterOptions() {
    const {values} = self.dimension || {};
    let list = [];

    values.flatMap(value => {
      return self.filteredDimensions.flatMap(fd => {
        return fd.values.filter(f => {
          if (f.uuid === value.uuid) {
            list.push(value);
          }
        });
      });
    });

    list.sort((a, b) => a.label.localeCompare(b.label))

    return list?.map(dimension => ({
      label: dimension?.label,
      value: `dimensions.${self.dimension?.uuid}_${dimension?.uuid}`,
    })) || [];
  }
}))
