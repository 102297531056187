import HttpClient from "../../services/HttpClient";

class ExtendedDataManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    this.url = `/extended-data`;
    this.setURL = `/extended-data-set`;
  }

  handleError(instance, error) {
    instance?.handleHasErrors(true);
    instance?.handleErrorMessage(error?.message);
    console.error(`Error: ${error?.message}`, error);
    return error;
  }

  async get(url, queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(url, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async fetchAllExtendedData(queryParams, instance) {
    return this.get(this.url, queryParams, instance);
  }

  async fetchAllExtendedDataSet(queryParams, instance) {
    return this.get(this.setURL, queryParams, instance);
  }

  async fetchAllExtendedDataFilters(queryParams, instance) {
    return this.get("sites/filters/extended-data", queryParams, instance);
  }

  async fetchAllExtendedDataSetFilters(queryParams, instance) {
    return this.get("sites/filters/extended-data-set", queryParams, instance);
  }

  async getOne(url, uuid, queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(`${url}/${uuid}`, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async fetchExtendedDataByUUID(uuid, queryParams, instance) {
    return this.getOne(this.url, uuid, queryParams, instance);
  }

  async fetchExtendedDataSetByUUID(uuid, queryParams, instance) {
    return this.getOne(this.setURL, uuid, queryParams, instance);
  }

  async destroy(url, uuid, instance) {
    let response;
    try {
      response = await this.httpClient.destroy(`${url}/${uuid}`);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async deleteExtendedData(uuid, instance) {
    return this.destroy(this.url, uuid, instance);
  }

  async deleteExtendedDataSet(uuid, instance) {
    return this.destroy(this.setURL, uuid, instance);
  }

  async post(url, body, instance) {
    let response;
    try {
      response = await this.httpClient.post(url, body);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  transformData(data={}) {
    if (
      data.hasOwnProperty("values") &&
      data.hasOwnProperty("additionalValues")
    ) {
      // For 'Set' extended-data
      if (data["additionalValues"].length) {
        // Move the values from additionalValues to values
        data.values = data.values.concat(data.additionalValues);
        data.additionalValues = [];
      }
    }
    return data;
  }

  async saveExtendedData(instance) {
    return this.post(this.url, {
      "name": instance?.name,
      "label": instance?.name,
      "owner": {
        "groupUuid": instance?.groupSelectStore?.selectedGroup?.uuid,
        ...(instance?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled &&
          { "dimensions": instance?.getSelectedDimensions() })
      },
      "isExclusivelyGroupOwned": instance.isExclusivelyGroupOwned,
      "tags": instance?.tagSelectStore?.selectedTags?.flatMap((tag) => tag?.uuid),
      "data": this.transformData(instance?.getFormData()),
      "type": instance?.extendedDataTypeSelectStore?.getSelectedExtendedDataType()?.label,
    }, instance);
  }

  async saveExtendedDataSet(instance) {
    return this.post(this.setURL, {
      "name": instance.name,
      "owner": {
        "groupUuid": instance?.groupSelectStore?.selectedGroup?.uuid,
        ...(instance?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled &&
          { "dimensions": instance?.getSelectedDimensions() })
      },
      "isExclusivelyGroupOwned": instance.isExclusivelyGroupOwned,
      "tags": instance?.tagSelectStore?.selectedTags?.flatMap((tag) => tag?.uuid),
      "extendedData": instance?.extendedDataSelectStore?.getSelectedExtendedData()?.flatMap((i) => i?.uuid)
    }, instance);
  }

  async update(url, uuid, body, instance) {
    let response;
    try {
      response = await this.httpClient.put(`${url}/${uuid}`, body);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

  async updateExtendedData(uuid, instance) {
    return this.update(this.url, uuid, {
      "name": instance?.name,
      "label": instance?.name,
      "owner": {
        "groupUuid": instance?.groupSelectStore?.selectedGroup?.uuid,
        ...(instance?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled &&
          { "dimensions": instance?.getSelectedDimensions() })
      },
      "isExclusivelyGroupOwned": instance.isExclusivelyGroupOwned,
      "tags": instance?.tagSelectStore?.selectedTags?.flatMap((tag) => tag?.uuid),
      "data": this.transformData(instance?.getFormData()),
      "type": instance?.extendedDataTypeSelectStore?.getSelectedExtendedDataType()?.label,
    }, instance);
  }

  async updateExtendedDataSet(uuid, instance) {
    return this.update(this.setURL, uuid, {
      "name": instance.name,
      "owner": {
        "groupUuid": instance?.groupSelectStore?.selectedGroup?.uuid,
        ...(instance?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled &&
          { "dimensions": instance?.getSelectedDimensions() })
      },
      "isExclusivelyGroupOwned": instance.isExclusivelyGroupOwned,
      "tags": instance?.tagSelectStore?.selectedTags?.flatMap((tag) => tag?.uuid),
      "extendedData": instance?.extendedDataSelectStore?.getSelectedExtendedData()?.flatMap((i) => i?.uuid)
    }, instance);
  }

}

export default ExtendedDataManager;
