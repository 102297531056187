import { RouterState, RouterStore } from "mobx-state-router";
import { GenericToastState } from "../../components/elements/toast/GenericToastState";
import { ModalState } from "../../components/elements/modal/GenericModal";
import { ReportModelList } from "../../models/reports/Report";
import ReportsState from "../../components/reports/ReportsState";
import { buildReportsColumns } from "../../components/reports/ReportsView";
import SupersetState from "../../components/reports/superset/SupersetState";
import { faChartLine, faChartPie, faFileExport } from "@fortawesome/free-solid-svg-icons";
import Blank from "../../pages/misc/Blank";
import async from "../../components/Async";
import React from "react";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";

const ReportsPage = async(() => import("../../pages/reports/Reports"));
const SupersetPage = async(() => import("../../pages/reports/superset/Superset"))

export const viewMap = {
  reports: <ReportsPage/>,
  superset: <SupersetPage/>,

};

export const routeDefinitions = [
  {
    path: "reports",
    name: "Analytics",
    icon: faChartPie,
    header: "Reports",
    component: ReportsPage,
    children: null
  },
  {
    path: "realtime",
    name: "Realtime",
    icon: faChartLine,
    component: Blank,
    children: null,
    featureFlagRequired: "cmp_ui_realtime"
  },
  {
    path: "exports",
    name: "Exports",
    icon: faFileExport,
    component: Blank,
    children: null,
    featureFlagRequired: "cmp_ui_reportexports"
  }
];

export const routeTransitions = [
  {
    name: "reports",
    pattern: "/reports",
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const { rootStore } = routerStore.options;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const toastState = GenericToastState.create({});
      const modalState = ModalState.create({});
      const reports = ReportModelList.create({});
      const reportsState = ReportsState.create({
        store: reports,
        modalStore: modalState,
        toastStore: toastState,
        tagSelectStore: tagSelectStore,
        groupSelectStore: groupSelectState
      });

      reportsState.setColumns(buildReportsColumns(routerStore, reportsState));
      console.log("Attaching Page State : reportsState ");
      await rootStore.rootUiStore.attachPageState("reportsViewState", reportsState);
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const { rootStore } = routerStore.options;
      console.log("Detaching Page State : reportViewState ");

    }
  },
  {
    name: "superset",
    pattern: "/reports/view/:reportUuid",
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const { rootStore } = routerStore.options;
      const { params } = toState;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const supersetState = SupersetState.create({
        reportUuid: params.reportUuid,
        name: params.name,
        groupSelectStore: groupSelectState,
        // supersetInfo: superset
      });
      await rootStore.rootUiStore.attachPageState("supersetViewState", supersetState);
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const { rootStore } = routerStore.options;
      console.log("Detaching Page State : supersetViewState ");
      rootStore.rootUiStore?.supersetViewState?.cancelSizingInterval();

    }
  }
];
