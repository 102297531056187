import React from "react";
import { observer } from "mobx-react-lite";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import TextField from "../components/TextField";
import LayoutBox from "../components/LayoutBox";

const EventPass = observer(({store}) => {
  let {
    data,
    minHeight,
    maxWidth,
    backgroundColor,
    foregroundColor,
    labelColor,
    footer
  } = store;

  backgroundColor = backgroundColor || data?.backgroundColor || "#FFFFFF";
  foregroundColor = foregroundColor || data?.foregroundColor || "#000000";
  labelColor = labelColor || data?.labelColor || "#000000";

  const AuxiliaryRow = () => {
    return (
      <>
        <Col
          className="pl-0"
        >
          <TextField
            style={{
              textAlign: "left"
            }}
            label="Gate"
            labelStyle={{
              color: labelColor
            }}
            value={data?.gate || "-"}
            valueStyle={{
              color: foregroundColor
            }}
          />
        </Col>
        <Col
          className="pl-0 pr-0"
        >
          <TextField
            style={{
              textAlign: "center"
            }}
            label="Section"
            labelStyle={{
              color: labelColor
            }}
            value={data?.section || "-"}
            valueStyle={{
              color: foregroundColor
            }}
          />
        </Col>
        <Col
          className="pr-0"
        >
          <TextField
            style={{
              textAlign: "right"
            }}
            label="Row / Seat"
            labelStyle={{
              color: labelColor
            }}
            value={`${data?.row || "-"} / ${data?.number || "-"}`}
            valueStyle={{
              color: foregroundColor
            }}
          />
        </Col>
      </>
    );
  };

  return (
    <Card
      style={{
        minHeight: minHeight || "400px",
        maxWidth: maxWidth || "336px",
        borderRadius: "14px",
        background: backgroundColor
      }}
    >
      <CardBody style={{
        display: "flex",
        flexDirection: "column"
      }}>
        <Row>
          <Col xs="3">
            {data?.headerLogo ?
              <img
                className="rounded-circle"
                src={data?.headerLogo}
                alt="mobilePassLogo"
                style={{ width: "35px",height: "35px"}}
                id="mobilePassLogo"
              /> :
              <LayoutBox alt="Logo"/>
            }
          </Col>
          <Col
            xs="9"
            className="pl-0"
          >
            {data?.headerTitle ?
              <h5
                className="mt-2 mb-1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  color: foregroundColor
                }}
              >
                <strong>
                  {data?.headerTitle}
                </strong>
              </h5> :
              <LayoutBox alt="Title"/>
            }
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            {data?.title ?
              <TextField
                style={{
                  textAlign: "left"
                }}
                label={data?.locationName}
                labelStyle={{
                  color: labelColor
                }}
                value={data?.title}
                valueStyle={{
                  fontSize: "22px",
                  color: foregroundColor
                }}
              /> :
              <LayoutBox height="90px" alt="Primary Field"/>
            }
          </Col>
        </Row>
        <br/>
        <div style={{
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Container>
            <Row
              className="mt-2"
            >
              {data?.startDate ?
                <>
                  <Col
                    className="pl-0"
                  >
                    <TextField
                      style={{
                        textAlign: "left"
                      }}
                      label="Date"
                      labelStyle={{
                        color: labelColor
                      }}
                      value={!data?.startDate ? "-" : new Date(data?.startDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })}
                      valueStyle={{
                        color: foregroundColor
                      }}
                    />
                  </Col>
                  <Col
                    className="pr-0"
                  >
                    <TextField
                      style={{
                        textAlign: "right"
                      }}
                      label="Time"
                      labelStyle={{
                        color: labelColor
                      }}
                      value={!data?.startDate ? "-" : new Date(data?.startDate).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                      })}
                      valueStyle={{
                        color: foregroundColor
                      }}
                    />
                  </Col>
                </> :
                <LayoutBox alt="Secondary Fields"/>
              }
            </Row>
            <Row
              className="mt-2"
            >
              {data?.gate || data?.section || data?.row || data?.mobileWalletCode ?
                (data?.showMobileWalletCode || data?.barcodeType === "None") ?
                  <Container>
                    <Row>
                      <AuxiliaryRow />
                    </Row>
                    <Row
                      className="mt-2"
                    >
                      <Col
                        className="pl-0 pr-0"
                      >
                        <TextField
                          style={{
                            textAlign: "left"
                          }}
                          label="CODE"
                          labelStyle={{
                            color: labelColor
                          }}
                          value={data?.mobileWalletCode || "-"}
                          valueStyle={{
                            color: foregroundColor
                          }}
                        />
                      </Col>
                    </Row>
                  </Container> :
                  <AuxiliaryRow />
                :
                <LayoutBox alt="Auxiliary Fields"/>
              }
            </Row>
          </Container>
          {footer}
        </div>
      </CardBody>
    </Card>
  );
});

export default EventPass;
