import React, {useContext, useState} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, Input,
  InputGroup,
  InputGroupAddon,
  Row,
  TabPane,
  TabContent,
  Nav,
  NavItem, NavLink, FormGroup, Label
} from "reactstrap";
import {observer} from "mobx-react-lite";
import QrConfigSelect from "../../elements/qrconfig/QrConfigSelect";
import GenericModal from "../../elements/modal/GenericModal";
import {StateContext} from "../../../App";
import {FormattedMessage} from "react-intl";
import VCard from "@scanbuy-inc/cmp_v2_pages/vcard/src/components/VCard"
import {VCardViewState} from "@scanbuy-inc/cmp_v2_pages/vcard/src/components/VCardViewState"
import DesignerView from "../../qr/designer/DesignerView";
import QrPreviewImage from "../../qr/qrpreview/qrPreviewImage/QrPreviewImageView";
import QrDownload from "../../qr/qrpreview/qrDownload/QrDownloadView";
import PageTemplateManager from "../../../managers/codes/PageTemplateManager";
import PageTemplateSelect from "../../elements/pageTemplate/PageTemplateSelect";
import "./PreviewCodeAndEditDesign.css"
import {NoteViewState} from "@scanbuy-inc/cmp_v2_pages/note/src/components/NoteViewState";
import Note from "@scanbuy-inc/cmp_v2_pages/note/src/components/Note";
import moment from "moment-timezone";
import {EventCalViewState} from "@scanbuy-inc/cmp_v2_pages/vevent/src/components/EventCalViewState";
import EventCal from "@scanbuy-inc/cmp_v2_pages/vevent/src/components/EventCal";
import {Html5ViewState} from "@scanbuy-inc/cmp_v2_pages/html5/src/components/Html5ViewState";
import HTML5 from "@scanbuy-inc/cmp_v2_pages/html5/src/components/Html5Component";
import {MenuLiteViewState} from "@scanbuy-inc/cmp_v2_pages/menulite/src/components/MenuLiteViewState";
import MenuLite from "@scanbuy-inc/cmp_v2_pages/menulite/src/components/MenuLite";
import {getPassPreviewComponent} from "../../elements/walletPass/utils";
import PassPage from "@scanbuy-inc/cmp_v2_pages/passpage/src/components/PassPage";
import {PassPageViewState} from "@scanbuy-inc/cmp_v2_pages/passpage/src/components/PassPageViewState";
import Notification from "../../../utils/Notification";
import PreviewMobileWalletPass from "../../elements/previewMobileWalletPass/PreviewMobileWalletPass";



const PreviewCodeAndEditDesign = observer(({store}) => {
  const stateContext = useContext(StateContext);
  const qrPreviewStore = stateContext.rootUiStore.qrPreviewStore
  const designerStore = stateContext.rootUiStore.designerViewState
  const qrConfigSelectStore = stateContext.rootUiStore.qrConfigSelectStore
  const qrConfigStore = stateContext.domainStore.qrConfigStore
  const codeListViewState = stateContext.rootUiStore.codeListViewState

  const pageTemplateManager = new PageTemplateManager()
  let {codeState, experienceData, styles} = pageTemplateManager.getCodeState(stateContext.rootUiStore)
  if (!experienceData) experienceData = qrPreviewStore?.experienceData
  const vCardSelectStore = codeState?.vCardSelectStore
  styles = vCardSelectStore?.selectedConfig?.configs?.styles || styles
  const vCardViewState = VCardViewState.create(experienceData);
  vCardViewState.setStyles(styles || {})
  const qrType = codeState?.experienceSelected?.name || qrPreviewStore.qrType
  qrPreviewStore.setOnSelectChanges()
  qrConfigSelectStore?.setHydrateModal(designerStore?.hydrateEditComponent)
  const [activeTab, setActiveTab] = useState('1');
  designerStore?.setGenerateImageListener(qrPreviewStore?.setQRImageFormData)
  qrConfigSelectStore?.setPreviewImageNullFunction(qrPreviewStore?.setPreviewImageNull)

  function qrPreview() {
    return <div >
      <CardHeader>
        <CardTitle tag="h5"><FormattedMessage id="preview.qrPreview.defaultMessage"/></CardTitle>
      </CardHeader>
      <CardBody>
        <QrPreviewImage store={qrPreviewStore}/>
        <Row className="justify-content-center mb-3">
          <Col className="">
            {store.editMode &&
              <Button id="preview-btn" className="align-content-end" onClick={designerStore.handleUpdateImage}>
                <FormattedMessage id="qrDesign.create.previewImage.defaultMessage"/>
              </Button>}
          </Col>
        </Row>
        <Row>
        </Row>
        {codeListViewState?.shouldRenderCopyUrl() && <Row className="justify-content-center">
          <Col lg="6">
            <InputGroup className="mb-3">
              <Input placeholder={qrPreviewStore?.shortUrl} disabled/>
              <InputGroupAddon addonType="append" color="primary">
                <Button id="copy-btn" disabled={qrPreviewStore?.testMode} onClick={qrPreviewStore?.copyUrl}><FormattedMessage
                  id="preview.copy.defaultMessage"/></Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>}
        <Row className="justify-content-center">
          <Col lg="6">
            <InputGroup style={{zIndex: 2}} className="mb-3">
              <QrConfigSelect store={qrPreviewStore.qrConfigSelectStore}/>
              {qrConfigSelectStore?.selectedConfig?.level === "qr" &&
                <InputGroupAddon addonType="append" color="primary">
                  <Button
                    id="edit-btn"
                    onClick={store.handleEditMode}
                  ><FormattedMessage id="preview.edit.defaultMessage"/></Button>
                </InputGroupAddon>
              }
            </InputGroup>
          </Col>
        </Row>
        {qrPreviewStore.modalComponent &&
          <GenericModal
            modalStore={qrPreviewStore.modalStore}
            componentStore={qrPreviewStore.modalComponentStore}
            EmbedComponent={qrPreviewStore.modalComponent}
            size="xl"
          />}
        <QrDownload store={qrPreviewStore}/>
        <div style={{marginBottom: "3rem"}}/>
      </CardBody>
    </div>
  }



  const NotePreview = (s) => {
    const noteViewState = NoteViewState.create(experienceData);
    noteViewState.setStyles(styles || {});
    return <Note viewState={noteViewState} />;
  };

  const PassPagePreview = (s) => {

    const mobilePassSelectStore = codeState?.pageTemplateSelectStore;
    styles = mobilePassSelectStore?.selectedConfig?.configs?.styles || styles;
    const passPageViewState = PassPageViewState.create(experienceData);
    passPageViewState.setStyles(styles || {});
    const selectPassTypeMessage = new Notification()
      .setType("info")
      .setMessage(`Please select Mobile Wallet Pass Type`);

    return (
      <>
        <PassPage viewState={passPageViewState} />
        <PageTemplateSelect store={mobilePassSelectStore}></PageTemplateSelect>
        <div
          className="d-flex mt-4 mb-4"
        >
          <Button
            className="mr-1 flex-fill"
            color="primary"
            onClick={() => {
              if (!experienceData?.passType) {
                selectPassTypeMessage.send();
                return;
              }
              const applePreviewPass = getPassPreviewComponent(experienceData?.passType, "apple");
              if (applePreviewPass) {
                codeListViewState?.handleModal2PopUp(applePreviewPass, `Apple Pass Preview`, "experienceData", "sm", "");
              }
            }}
          >
            <FormattedMessage id="preview.passpage.provider.apple.defaultMessage" />
          </Button>
          <Button
            className="ml-1 flex-fill"
            color="primary"
            onClick={() => {
              if (!experienceData?.passType) {
                selectPassTypeMessage.send();
                return;
              }
              const googlePreviewPass = getPassPreviewComponent(experienceData?.passType, "google");
              if (googlePreviewPass) {
                codeListViewState?.handleModal2PopUp(googlePreviewPass, `Google Pass Preview`, "experienceData", "sm", "");
              }
            }}
          >
            <FormattedMessage id="preview.passpage.provider.google.defaultMessage" />
          </Button>
        </div>
      </>
    );
  };

  const VCardPreview = (s) => {
    const vCardSelectStore = codeState?.pageTemplateSelectStore;
    styles = vCardSelectStore?.selectedConfig?.configs?.styles || styles;
    const vCardViewState = VCardViewState.create(experienceData);
    vCardViewState.setStyles(styles || {});

    return (
      <>
        <VCard viewState={vCardViewState}></VCard>
        <PageTemplateSelect store={vCardSelectStore}></PageTemplateSelect>
      </>
    );
  };

  const VEventPreview =  (s) => {
    const vEventSelectStore = codeState?.pageTemplateSelectStore;
    styles = vEventSelectStore?.selectedConfig?.configs?.styles || styles;
    let newExpDataObj = {...experienceData}  //doesn't allow change to the original object that is view only
    newExpDataObj.localTimeZone = moment.tz.guess();
    const vEventViewState = EventCalViewState.create({...newExpDataObj});
    vEventViewState.setStyles(styles || {});
    return (
      <>
        <EventCal viewState={vEventViewState}></EventCal>
        <PageTemplateSelect store={vEventSelectStore}></PageTemplateSelect>
      </>
    );
  };

  const Html5Preview = () => {
    const html5ViewState = Html5ViewState.create(experienceData);
    return <HTML5 viewState={html5ViewState}/>

  }

  // const MenuLitePreview = () => {
  //   const menuLiteStore = codeState?.pageTemplateSelectStore
  //   styles = menuLiteStore?.selectedConfig?.configs?.styles || styles;
  //   const menuLiteViewState = MenuLiteViewState.create(experienceData);
  //   menuLiteViewState.setStyles(styles || {});
  //   menuLiteViewState.setBlocks(experienceData.blocks)
  //   menuLiteViewState.setFooter(experienceData.footer);
  //   menuLiteViewState?.getBackgroundImage() && menuLiteViewState.updateStyles();
  //
  //   return (
  //     <>
  //       <MenuLite viewState={menuLiteViewState}></MenuLite>
  //       <PageTemplateSelect store={menuLiteStore}></PageTemplateSelect>
  //     </>
  //   );
  // };



  const validQrTypes = {
    "vcard": {
      title: "VCard Preview",
      component: <VCardPreview viewState={store} />
    },
    "vevent": {
      title: "Event Preview",
      component: <VEventPreview store={store} />
    },
    "note": {
      title: "Note Preview",
      component: <NotePreview store={store} />
    },
    "passpage": {
      title: "Landing Page Preview",
      component: <PassPagePreview store={store} />
    },
    "html5": {
      title: "Mini Landing Page Preview",
      component: <Html5Preview store = {store}/>
    }
    // "menulite":{
    //   title: "Menu Lite List Preview",
    //   component: <MenuLitePreview store = {store}/>
    // }
  };
  const qrTypeWithPreview = Object.keys(validQrTypes).includes(qrType);
  const secondPreviewTab = validQrTypes[qrType];
  return (
    <>
      {store.editMode &&<Row>
        <Col sm={2}>
          <FormGroup check>
            <Input name="noDesign"
                   type="radio"
                   id="noDesign"
                   value={store.noDesignOpen}
                   checked={store.noDesignOpen}
                   onClick={() => {
                     store.setNoneOpen()
                   }}
            />
            <Label check>
              None
            </Label>
          </FormGroup>
        </Col>
        <Col sm={3}>
          <FormGroup check>
            <Input name="customOpen"
                   type="radio"
                   id="customOpen"
                   value={store.customOpen}
                   checked={store.customOpen}
                   onClick={() => {
                     store.setCustomOpen()
                   }}
            />
            <Label check><FormattedMessage
              id="createQr.advancedConfigsOptions.modalButtons.addCustom.defaultMessage"/></Label>
          </FormGroup>
        </Col>
        {store.customOpen &&
          <>
            <Col sm={4}>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend"
                  // label={intl.formatMessage({id: 'createQr.advancedConfigsOptions.modalButtons.autofillForm.defaultMessage'})}
                >
                  Autofill Form
                  {/*<FormattedMessage id="createQr.advancedConfigsOptions.modalButtons.autofillForm.defaultMessage"/>*/}
                </InputGroupAddon>
                <QrConfigSelect store={qrConfigSelectStore}/>
              </InputGroup>
            </Col>
          </>
        }
      </Row>}
      <Row>
        {store.editMode &&
          <Col md={8}>
            {store.customOpen &&
              <DesignerView
                store={designerStore}
                isNotCodeModal={false}
              />
            }
            <Row className="p-1">
              <Col sm={2}>
                <Button color="secondary"
                        onClick={async () => {
                          codeListViewState?.modalStore.toggle()
                        }}
                >Cancel</Button>
              </Col>
              <Col sm={10} className="text-right">
                <Button outline color="primary"
                        onClick={async () => {
                          const design = qrConfigStore?.defaultConfiguration.find(config => config.level === "qr")
                          //call image manager and update image
                          const res =  await store?.updateDesign(designerStore,design.uuid,qrConfigStore,qrConfigSelectStore,codeListViewState)
                          if (res) {
                            codeListViewState?.modalStore.toggle()
                            qrPreviewStore?.setQRImageFormData(designerStore.extendConfig)
                          }
                        }
                        }
                > Apply</Button>
              </Col>
            </Row>
          </Col>}
        <div className="div-behind-preview-experience"></div>

        <Col md={store.editMode?4:12}>
          {/*<Col className="" > </Col>*/}

          <Card style={{ backgroundColor: "#E0EFFF" }}>
            { qrTypeWithPreview ? (
              secondPreviewTab &&
              <>
                <Nav tabs style={{cursor: "pointer"}}>
                  <NavItem >
                    <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                      <FormattedMessage id="preview.qrPreview.defaultMessage" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                      {secondPreviewTab.title}
                    </NavLink>
                  </NavItem>
                  {experienceData?.enableWalletPass &&
                    <NavItem>
                      <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                        <FormattedMessage id="preview.passpage.defaultMessage" />
                      </NavLink>
                    </NavItem>
                  }
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {qrPreview()}
                  </TabPane>
                  <TabPane tabId="2">
                    <CardHeader>
                      <CardTitle tag="h5">{secondPreviewTab.title}</CardTitle>
                    </CardHeader>
                    <CardBody className="ck-content" style={{height: "60vh", overflowY: "auto"}} >
                      {secondPreviewTab.component}
                    </CardBody>
                  </TabPane>
                  {experienceData?.enableWalletPass &&
                    <TabPane tabId="3">
                      <CardHeader>
                        <CardTitle tag="h5"><FormattedMessage id="preview.passpage.defaultMessage" /></CardTitle>
                      </CardHeader>
                      <CardBody className="ck-content" style={{height: "60vh", overflowY: "auto"}} >
                        <PreviewMobileWalletPass passType={experienceData?.passType} showModalPopUp={codeListViewState?.handleModal2PopUp} />
                      </CardBody>
                    </TabPane>
                  }
                </TabContent>
              </>
            ) : qrPreview()}
          </Card>
        </Col>
      </Row>
    </>
  )
})


export default PreviewCodeAndEditDesign;
