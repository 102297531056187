import { faServer, faSitemap } from "@fortawesome/free-solid-svg-icons";
import Blank from "../../pages/misc/Blank";


export const viewMap = {}


export const routeDefinitions = [
  {
    path: "/configs/rules",
    name: "Rules",
    icon: faServer,
    component: Blank,
    children: null,
    featureFlagRequired: "cmp_ui_ruless"
  }
];


export const routeTransitions = [];
