import {types} from "mobx-state-tree";


const Parameters = types.model('Parameters', {
  codeParamType: types.optional(types.array(types.string), []),
  scanParamType: types.optional(types.array(types.string), []),
  countParamType: types.optional(types.array(types.string), []),
  premiumParamType: types.optional(types.array(types.string), []),
  customParamType: types.optional(types.array(types.string), []),
}).actions(self => ({
  setParameters(paramType, parameters) {
    self[paramType] = parameters
  },
  hydrateParameters(parameters) {
    parameters?.codeParamType && (self.codeParamType = parameters?.codeParamType)
    parameters?.scanParamType && (self.scanParamType = parameters?.scanParamType)
    parameters?.countParamType && (self.countParamType = parameters?.countParamType)
    parameters?.premiumParamType && (self.premiumParamType = parameters?.premiumParamType)
    parameters?.customParamType && (self.customParamType = parameters?.customParamType)
  },
  clearParams() {
    self.codeParamType = []
    self.scanParamType = []
    self.countParamType = []
    self.premiumParamType = []
    self.customParamType = []
  },
}))


export default Parameters;
