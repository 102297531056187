import {flow, getEnv, types} from "mobx-state-tree";
import Notification from "../../../../utils/Notification";

const DeleteDataExportState = types.model('DeleteDataExportState', {
  name: types.optional(types.string, ""),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
.volatile(self => ({
  toggleModal: types.fn,
  refreshList: types.fn,
}))
.actions((self) => ({
  handleHasDeleteErrors(boolean) {
    self.formHasErrors = boolean;
  },
  handleDeleteErrorMessage(message) {
    self.errorMessage = message;
  },
  setToggleListener(listener) {
    self.toggleModal = listener;
  },
  setRefreshListener(listener) {
    self.refreshList = listener;
  },
  delete: flow(function* () {
    const resp = yield getEnv(self).dataExportManager.delete(self.uuid, self);
    if (!self.formHasErrors) {
      self.refreshList();
      self.toggleModal();
      new Notification()
        .setType("success")
        .setMessage(`${self.name} Data Export deleted`)
        .send();
    }
  }),
}));

export default DeleteDataExportState;
