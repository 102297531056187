import React from "react";
import { observer } from "mobx-react-lite";
import AsyncSelect from "react-select/async";
import Select from "react-select";

const TagSelect = observer(({ tagSelectStore, disabled = false }) => {
  //console.log(tagSelectStore?.selectedTags.toJSON())
  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      defaultValue={tagSelectStore?.selectedTags ? tagSelectStore.selectedTags : []}
      options={tagSelectStore?.tagOptionValues()}
      // options={tagSelectStore?.tags.map(value => ({
      //   value: {
      //     ...value,
      //     /**
      //      * The method above is to avoid React Select from spamming
      //      * the console with key errors since it is using this object
      //      * as a value for every select option
      //      **/
      //     toString: () => `${value.name}`
      //   },
      //   label: `${value.name}`
      // }))}
      isSearchable
      isMulti
      onChange={(data)=>tagSelectStore?.setSelectedTags(data)}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.uuid}
      placeholder="Select"
      isDisabled={disabled}
    />

  );
});

export default TagSelect;
