import {flow, getEnv, getType, types} from "mobx-state-tree";
import lodash from "lodash";
import {DataExportType, create} from "../../models/data/exports/DataExportType";

const DataExportTypeStore = types.model('DataExportTypeStore',{
  storeUuid: types.identifier,
  dataExportTypes: types.optional(types.array(DataExportType), []),
}).actions((self) => ({
  setDataExportTypes(data) {
    self.dataExportTypes = data?.map(create);
    self.sortDataExportTypes();
  },
  initWithData(data = [], enabledTypes = ["default"]) {
    try {
      if (data) {
        data = lodash.filter(data, t => enabledTypes.includes(t.type));
        self.setDataExportTypes(data);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to initWithData`, e);
    }
  },
  init: flow(function* (queryParams = {}) {
    const response = yield getEnv(self).dataExportManager.fetchAllTypes(queryParams, self);
    self.initWithData(response?.results);
  }),
  sortDataExportTypes() {
    try {
      self.dataExportTypes?.sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      console.warn(`${getType(self).name} Warn: Failed to sort`, e);
    }
  }
})).views((self) => ({
  getDataExportTypes() {
    return self.dataExportTypes;
  }
}));

export default DataExportTypeStore;
