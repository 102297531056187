import axios from "axios";
import Notification from "../utils/Notification";


class HttpClient {
  constructor(url, tenantUuid = undefined) { //testing marketing tenant
    this.jwt = ""
    this.webApiUrl = url
    this.tenantUuid = tenantUuid

    this.instance = axios.create({
      baseURL: this.webApiUrl,
      timeout: 18000,
      headers: {"x-sb-context": this.tenantUuid }
    });
  }

  setJwt(jwt) {
    this.jwt = jwt
    return this
  }

  setHeaders({jwt, tenantUuid}) {
    this.instance.defaults.headers = {
      Authorization: "Bearer " + jwt,
      "x-sb-context": tenantUuid,
    }
    return this
  }

  setTenantUuid(tenantUuid) {
    this.tenantUuid = tenantUuid
  }

  async get(url, queryParams = {}) {
    try {
      const response = await this.instance.get(url, {
        params: queryParams
      });

      if(response && response.status === 401){
        return null;
      }

      if(response && response.status !==401) {
        return response.data;
      }
      return null;
    } catch (e) {
      console.log(e);
      if(e?.response?.status === 403){
        new Notification("warning", "Your permission set do not allow you to access that feature and/or functionality. Please contact an administrator to modify if needed your permission set")
          .setTitle("Missing permission")
          .send();
      }
      return null;
    }
  }

  async post(url, data = {}, config = {}) {
    console.log("data", data)

    try {
      return await this.instance.post(url, data, {
        // headers: {
        //   Authorization: "Bearer " + this.jwt
        // }
      });
    } catch (e) {

      if (e.response?.status === 403) {
        new Notification("warning", "Your permission set do not allow you to access that feature and/or functionality. Please contact an administrator to modify if needed your permission set")
          .setTitle("Missing permission")
          .send();
        return {
          error : {
            status : 403,
            message : "Your permission set do not allow you to access that feature and/or functionality. Please contact an administrator to modify if needed your permission set"
          }
        }

      }

      if (e.response.data) {

        throw new Error(`${e.response.data.error}`);
      } else {
        // console.log("e",e);
        // console.log("e.response.data",e.response.data);
        // console.log("e.response.status",e.response.status);
        // console.log("e.response.headers",e.response.headers);
        throw new Error(`${e}`);
      }
    }
  }

  async put(url, data = {}, config = {}) {
    try {
      const response = await this.instance.put(url, data, {
        // headers: {
        //   Authorization: "Bearer " + this.jwt
        // }
      });
      return response.data;
    } catch (e) {

      // console.log("e",e);
      // console.log("e.response.data",e.response.data);
      // console.log("e.response.status",e.response.status);
      // console.log("e.response.headers",e.response.headers);
      throw new Error(`${e.response.data.error} status. Current error message comes back as a html doctype`);
    }
  }

  async destroy(url, config) {
    try {
      const response = await this.instance.delete(url, {
        // headers: {
        //   Authorization: "Bearer " + this.jwt
        // }
      });
      console.log("response in http client ", response)
      return response;
    } catch (e) {
      // console.log("e",e);
      // console.log("e.response.data",e.response.data);
      // console.log("e.response.status",e.response.status);
      // console.log("e.response.headers",e.response.headers);

      throw new Error(`${e.response.status} status.  Current error message comes back as a html doctype`);

    }
  }
}

export default HttpClient;
