import {CardImg, Container, Row} from "reactstrap";
import scnqr from "../../../../assets/img/alt/scnqr.png";
import LayoutBox from "./LayoutBox";
import React from "react";

const GoogleFooter = ({barcodeType, mobileWalletCode}) => {
  const showBarcode = barcodeType && barcodeType !== "None";

  return (
    <Container
      style={!showBarcode ? {}: {
        display: "flex",
        justifyContent: "center"
      }}
    >
      {showBarcode ?
        <CardImg
          style={{
            width: "160px",
            height: "160px"
          }}
          className="rounded"
          src={scnqr}
          alt="QR Image"
          id="mobilePassQrCode"
        />
        :
        !mobileWalletCode &&
        <Row
          className="mt-2"
        >
          <LayoutBox height="90px" alt="Barcode"/>
        </Row>
      }
    </Container>
  );
};

export default GoogleFooter;
