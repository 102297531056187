import React, {useContext, useEffect} from 'react'
import {
	Card,
	CardBody,
	Col,
	InputGroup, InputGroupAddon,
	Row,
} from "reactstrap";
import {observer} from "mobx-react-lite";
import GroupSelect from "../elements/groups/GroupSelect";
import ScanbuyTable, {TableColumn} from "../tables/ScanbuyTable";
import GenericModal from "../elements/modal/GenericModal";
import GenericToastView from "../elements/toast/GenericToastView";
import { useIntl } from "react-intl";
import dayjs from 'dayjs';
import DocLink from "../DocLink";
import {StateContext} from "../../App";

export const buildReportsColumns = (routerStore, reportsViewState) => {
	const nameCol = TableColumn.create({
		name: 'Report Name', property: 'name', button: true, width: '25%',
	})
	nameCol.setSelector((row) => {
		return <a key={row.uuid} onClick={() => {
			// const componentStore = SupersetState.create();
			// reportsViewState.handleRowClick(row, componentStore, SupersetView);
			routerStore.goTo('superset', {
				params: {name: row.name, reportUuid: row.uuid},
			});
		}}>{row.name}</a>

	});

	const createdAtCol = TableColumn.create({
		name: 'Create Date',
		property: 'createdAt',
		sortable: true,
		sortField: 'createdAt',
		selector: 'createdAt',
		width: '200px',
	})
	createdAtCol.setSelector((row) => {
		const {createdAt} = row || {};
		const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
		return formattedCreateDate;
	})

	const updatedAtCol = TableColumn.create({
		name: 'Last Modified', property: 'updatedAt', width: '200px',
	});
	updatedAtCol.setSelector((row) => {
		const {updatedAt} = row || {};
		const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
		return formattedUpdateDate;
	});

	return [nameCol, createdAtCol, updatedAtCol];
}

const ReportsView = observer(({reportsViewState}) => {
  const stateContext = useContext(StateContext);

  //console.log('reportsViewState', reportsViewState)
  reportsViewState.setOnSelectChanges()
  //const intl = useIntl();


	return (<>
			<Card>
				<CardBody>
          {stateContext?.appConfigs?.features?.cmp_ui_docs?.enabled &&
            <Row className="p-1 text-sm-right">
              <Col sm={12}>
                <DocLink id="docs.analytics"/>
              </Col>
            </Row>
          }
					<Row>
						<Col sm={4}>
							{/*<InputGroup className="mb-3">*/}
							{/*	<InputGroupAddon addonType="prepend">*/}
							{/*	{intl.formatMessage({ id: "filterByGroups.defaultMessage"})}*/}
							{/*	</InputGroupAddon>*/}
							{/*	<GroupSelect groupSelectStore={reportsViewState.groupSelectStore} multi={true}/>*/}
							{/*</InputGroup>*/}
						</Col>
						{/*<Col sm={4}>*/}
						{/*	<InputGroup className="mb-3">*/}
						{/*		<InputGroupAddon addonType="prepend">*/}
						{/*			Filter*/}
						{/*		</InputGroupAddon>*/}
						{/*		<CodeFilterSelect/>*/}
						{/*	</InputGroup>*/}
						{/*</Col>*/}
					</Row>

					<ScanbuyTable
						columns={reportsViewState.columns}
						customStyles={reportsViewState.customStyles}
						store={reportsViewState.store}
						queryParams={{}}
					/>
				</CardBody>
			</Card>
			<GenericModal
				modalStore={reportsViewState.modalStore}
				componentStore={reportsViewState.modalComponentStore}
				EmbedComponent={reportsViewState.modalComponent}
			/>
			<GenericToastView genericToastState={reportsViewState.toastStore}/>
		</>
	);
})

export default ReportsView;
