import HttpClient from "../../services/HttpClient";


class RuleTypeDataManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/rules`
  }

  async getRuleData(id) {
    // const {uuid} = ruleType
    // console.log("get rule")
    // const id = uuid.split("/")[0]
    try {
      const url = `${this.url}/${id}/data`;
      const res = await this.httpClient.get(url);
      console.log("res success", res)

      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to get rule: ", e.message);
      return null
    }
  }


}


export default RuleTypeDataManager;
