import HttpClient from "../../services/HttpClient";


class PublishDomainManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    console.log(this.httpClient);
    this.url = `/domains`;
  }

  async fetchAll(queryParams) {
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get publish domains", e);
    }
    return null;
  }

  async fetchPrefixes(domainUuid, queryParams) {
    try {
      // const prefixUrl = `/domains/${domainUuid}/prefixes`;
      const res = await this.httpClient.get(`${this.url}/${domainUuid}/prefixes`, queryParams);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get prefixes", e);
    }
    return null;
  }

  async fetchByUUID(uuid, queryParams, instance) {
    let response;
    try {
      response = await this.httpClient.get(`${this.url}/${uuid}`, queryParams);
    } catch (e) {
      this.handleError(instance, e);
    }
    return response;
  }

}

export default PublishDomainManager;
