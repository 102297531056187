import HttpClient from "../../services/HttpClient";


class GroupManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/groups`
  }

  /**
   * Search , used for autocomplete
   * @param name
   * @returns {Promise<void>}
   */
  async searchFor(name) {

    try {
      const queryParams = {
        name:name
      };

      const res = await this.httpClient.get(this.url, queryParams);

      if (res.data) {
        console.log("Search Results", res);
        return res;
      }
     } catch (e) {
      console.log("Error searching for group");
    }

    return null;
  }

  async fetchAll(queryParams){
    try {
      return await this.httpClient.get(this.url, queryParams);
    } catch (e) {
      console.log("Error searching for group");
    }
    return null;
  }
}

export default GroupManager;
