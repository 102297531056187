import React, {useContext} from "react";
import {
  Button,
  Card,
  CardBody,
  Col, CustomInput,
  FormGroup, Input, InputGroup, InputGroupAddon,
  Label,
  Row
} from "reactstrap";
import {types, hasParent, getParentOfType, clone, detach} from "mobx-state-tree";
import {observer} from "mobx-react-lite";
import "bootstrap-daterangepicker/daterangepicker.css";
import TreeRole from "./ruleComponents/TreeRole";
import GroupSelect from "../../../elements/groups/GroupSelect";
import {FormattedMessage, useIntl} from "react-intl";
import {RuleTypeSelectState} from "../../../elements/ruleType/RuleTypeSelectState";
import {RuleType} from "../../../../models/RuleType";
import {CreateRuleSetState} from "./CreateRuleSetState";
import RuleTypeStore from "../../../../stores/domain/RuleTypeStore";
import {browserHistory, useRouterStore} from "mobx-state-router";
import {v4 as uuidv4} from 'uuid';
import {TagSelectState} from "../../../elements/tags/TagSelectState";
import TagSelect from "../../../elements/tags/TagSelect";
import DimensionsMultiSelect from "../../../elements/dimensions/multi-select/dimensionsMultiSelect";
import CodeTypeSelect from "../../../elements/codeTypes/CodeTypeSelect";
import DocLink from "../../../DocLink";
import {StateContext} from "../../../../App";
import DisableWrapper from "../../../../utils/disableWrapper";
export const Country = types.model({
  value: types.string,
  label: types.string,
});
export const TreeNode = types
  .model("TreeNode", {
    nodeUuid: types.identifier,
    parent: types.maybeNull(types.reference(types.late(() => TreeNode))),
    children: types.optional(types.array(types.late(() => TreeNode)), []),
    order: types.optional(types.integer, 0),
    showIndent: types.optional(types.boolean, false),
    showUnindent: types.optional(types.boolean, false),
    createRuleSetState: types.maybe(types.late(() => CreateRuleSetState)),
    ruleTypeSelectState: types.maybe(RuleTypeSelectState),
    ruleTypeSelected: types.maybe(types.reference(RuleType)),
    ruleInputData: types.optional(types.string, "{}"),
    position: types.string,
    liveValidateSchema: types.optional(types.boolean, false),
    tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
    selectedCountries: types.optional(types.array(Country),[]),
    selectedRegions: types.optional(types.array(Country),[]),
    //type
    //ruleData
    //ruleInputData: types.optional(types.string, '{}'),

  }).volatile((self) => ({
    volatileRuleTypeStore: types.object // this store is a hack for accessing the store from the nested children
  }))
  .actions(self => {
    function transformErrors(errors) {
      return errors.map(error => {
        if (error.name === "pattern") {
          error.message = "must be a valid url"
        }
        return error;
      });
    }
    function liveValidation() {
      self.liveValidateSchema = true
    }
    function clearChildren() {
      self.children = [];
    }
    function setParent(treeNode) {
      self.parent = treeNode;
    }
    function setVolatileRuleTypeStore(store) {
      self.volatileRuleTypeStore = store;
    }

    function setPosition(newPosition) {
      self.position = newPosition;
    }

    function setShowIndent(status) {
      self.showIndent = status;
    }

    function addChild(treeNode) {
      const tempOrder = self.children.length
      treeNode.order = tempOrder
      treeNode.parent = null
      treeNode.position = self.position + '_' + tempOrder
      // let tempNode = clone(treeNode)
      treeNode.setParent(self)
      treeNode.parent = self.nodeUuid
      console.log('addChild', treeNode)
      self.children.push(treeNode)
    }

    function addNewChild(ruleTypeStore, hydrateNode = null, readonly=false) {
      const tempOrder = self.children.length
      const nodeUuid = uuidv4()
      let showUnindent = false
      let showIndent = true
      if (tempOrder == 0) {
        showIndent = false
      }
      let position = self.position + '_' + tempOrder
      if (hydrateNode !== null) {
        position = hydrateNode["action"]["rid"]
      }

      const ruleTypes = ruleTypeStore.ruleTypes;
      const ruleTypeStoreNode = RuleTypeStore.create({
        storeUuid: "store/" + nodeUuid,
        ruleTypes: ruleTypes.map(el => RuleType.create({
          uuid: el.uuid + "/" + nodeUuid,
          label: el.label,
          labelGroup: el.labelGroup,
          name: el.name,
          type: el.type,
          ruleUiSchema: el.ruleUiSchema,
          ruleSchema: el.ruleSchema
        }))
      });

      const ruleTypeSelectStateNode = RuleTypeSelectState.create({
        selectStateUuid: "rtss/" + nodeUuid,
        nodeStore: ruleTypeStoreNode,
        isDisabled: readonly
      });
      ruleTypeSelectStateNode.setConsumer(ruleTypeStore.addRuleTypeData) // add ruleTypeData to the store
      hydrateNode?.type
      && ruleTypeSelectStateNode.select(hydrateNode?.type)
      && ruleTypeStore.addRuleTypeData(hydrateNode?.type)



      const tempNode = TreeNode.create({
        nodeUuid: nodeUuid,
        parent: self,
        children: [],
        order: tempOrder,
        showUnindent: (hydrateNode && position !== "0_0") ? self.checkEditRuleUnIndentIcon() : self.checkEditRuleUnIndentIcon(),
        showIndent: (hydrateNode && position !== "0_0") ? self.checkEditRuleIndentIcon() : self.checkEditRuleIndentIcon(),
        position: position,
        ruleTypeSelectState: ruleTypeSelectStateNode,
      })
      tempNode.setVolatileRuleTypeStore(ruleTypeStore)


      hydrateNode && (tempNode.updateRuleInputData(hydrateNode))
      tempNode.indent()
      self.children.push(tempNode)

    }

    function removeChild(changeNodeOrder) {
      console.log('changeNodeOrder', changeNodeOrder)
      self.changeChildrenOrder(changeNodeOrder)
      let child = self.children[changeNodeOrder]
      detach(self.children[changeNodeOrder])
      return child
    }

    function removeParentChild(changeNodeOrder) {
      let parent = getParentOfType(self, TreeNode);
      return parent.removeChild(changeNodeOrder);
    }

    function changeChildrenOrder(changeNodeOrder) {
      for (let i = changeNodeOrder; i < self.children.length; i++) {
        self.children[i].order -= 1
        console.log('changeChildrenOrder', changeNodeOrder)
        console.log('changeChildrenOrder', self.children[i])
        self.children[i].position = self.position + '_' + self.children[i].order
        if (self.children[i].position.split("_").length === 2) {
          self.children[i].showUnindent = false;
        }
        if (self.children[i].position === '0_0') {
          self.children[i].showIndent = false;
        }
        // if tree is changed, check first children, removing its indent icon
        if (i === 0 && self.children.length > 1) {
          self.children[1].setShowIndent(false);
        }
        // change sibling all children position
        changePositionsRecursively(self.children[i])
      }
    }

    function indent() {
      if (hasParent(self)) {
        let parentNode = getParentOfType(self, TreeNode); // root
        const changeNodeOrder = self.order  //[0,1,2]
        let needAddNode = parentNode.children[changeNodeOrder - 1] // nod1
        //parentNode.changeChildrenOrder(changeNodeOrder - 1)
        let currentNode = self.removeParentChild(changeNodeOrder)
        if (needAddNode) {
          needAddNode.addChild(currentNode);
        }
        // change current node children position
        changePositionsRecursively(self)
        self.newShowIndentIcon()
        self.newShowUnIndentIcon()
      }
    }

    function changePositionsRecursively(node) {
      if (node.children.length > 0) {
        for (const childNode of node.children) {
          childNode.setPosition(`${node.position}_${childNode.order}`);
          changePositionsRecursively(childNode);
        }
      }
    }

    function addChildByOrder(order, treeNode) {
      // update existing children
      for (let i = order + 1; i < self.children.length; i++) {
        self.children[i].order += 1
        let newOrder = i + 1
        self.children[i].position = self.position + '_' + newOrder
        changePositionsRecursively(self.children[i])
      }
      treeNode.order = order + 1
      treeNode.parent = self.nodeUuid
      treeNode.position = self.position + '_' + treeNode.order
      if (treeNode.order >= self.children.length) {
        // self.children.push(clone(treeNode))
        self.children.push(treeNode)
      } else {
        // self.children.splice(order+1, 0, clone(treeNode))
        self.children.splice(order + 1, 0, treeNode)
      }
    }

    function unindent() {
      let parentNode = getParentOfType(self, TreeNode);
      if (hasParent(parentNode)) {
        let ancientNode = getParentOfType(getParentOfType(self, TreeNode), TreeNode);
        console.log(ancientNode)
        // self.showIndentIcon(false)

        let changeNodeOrder = self.order
        self.order = parentNode.order + 1

        let currentNode = self.removeParentChild(changeNodeOrder)
        ancientNode.addChildByOrder(parentNode.order, currentNode)
        // change current node children position
        changePositionsRecursively(self)
        self.newShowIndentIcon()
        self.newShowUnIndentIcon()
      }
    }

    function newShowIndentIcon() {
      if (self.order !== 0) {
        self.showIndent = true
      } else {
        self.showIndent = false
      }
      return self.showIndent
    }

    function newShowUnIndentIcon() {
      if (self.parent == null || self.parent.parent == null) {
        self.showUnindent = false
      } else {
        self.showUnindent = true
      }
      return self.showUnindent
    }

    function showIndentIcon(stepIn) {
      if (stepIn) {
        if (self.parent.children[0].children.length === 0) {
          self.showIndent = false;
        } else {
          self.showIndent = true;
        }
      } else {
        self.showIndent = true;
      }

    }

    function showUnIndentIcon(stepIn) {
      if (stepIn) {
        self.showUnindent = true;
      } else {
        if (self.parent.parent.parent == null) {
          console.log("hide");
          self.showUnindent = false;
        }
      }

    }

    function checkEditRuleIndentIcon() {
      if (self.children.length === 0) {
        return false;
      } else {
        return true;
      }
    }

    function checkEditRuleUnIndentIcon() {
      const array = self.position.split("_")
      if (array.length === 1) {
        return false;
      } else {
        return true;
      }
    }

    function remove() {
      let parentNode = getParentOfType(self, TreeNode);
      parentNode.removeChild(self.order);
    }

    function selectRuleType(obj) {
      self.ruleTypeSelected = obj;
    }
    function selectTag(arr) {
      self.selectedTags = arr.flatMap((tag) => tag.uuid)
    }
    function updateRuleInputData(data) {
      // if (data?.factValue && typeof data?.factValue[0] !== 'string' && typeof data?.factValue !== 'number' && typeof data?.factValue !== "object"){
      //   data.factValue = data.factValue.map((fact) => { //here we are transforming the factValue to an array of strings
      //     if (fact?.value) {
      //       return fact.value
      //     }
      //   })
      // }
      self.ruleInputData = JSON.stringify(data);
      console.log(self.toJSON());
    }
    function ruleInputDataView() {
      return JSON.parse(self.ruleInputData);
    }

    function ruleTypeSchemaJson() {
      let {ruleSchema} = self.ruleTypeSelectState.selected || "{}"; //self.ruleTypeSelectState.selected
      return JSON.parse(ruleSchema);
    }

    function uiSchemaJson() {
      let {ruleUiSchema} = self.ruleTypeSelectState.selected || "{}";
      return JSON.parse(ruleUiSchema);
    }

    function setConsumer(){
      self.ruleTypeSelectState.setClearInputData(self.updateRuleInputData)
    }

    const positionDisplayed = (string) => {
      const arr = string.split("_")
      arr.shift()
      const plusOne = arr.map(el => {
        return parseInt(el) + 1
      })
      return plusOne.join(": ")
    }
    const subrulePositionDisplayed = (string) => {
      const arr = string.split("_")
      arr.shift()
      const plusOne = arr.map(el => {
        return parseInt(el) + 1
      })
      //add an X as a last position
        plusOne.push("x")
      return plusOne.join(": ")

    }
    const nextLevelRightPositionDisplayed = (string) => {
      const arr = string.split("_")
      arr.shift()
      const plusOne = arr.map(el => {
        return parseInt(el) + 1
      })
        plusOne[plusOne.length - 1] = plusOne[plusOne.length - 1] - 1
          plusOne.push("x")

      return plusOne.join(": ")

    }
    const levelLeftPositionDisplayed = (string) => {
      const arr = string.split("_")
      arr.shift()
      const plusOne = arr.map(el => {
        return parseInt(el) + 1
      })
      //remove last position
        plusOne.pop()
       //add 1 to the last position
        plusOne[plusOne.length - 1] = plusOne[plusOne.length - 1] + 1

      return plusOne.join(": ")

    }
    function setSelectedCountries(data){
      self.selectedCountries = data
    }
    function setSelectedRegions(data){
      self.selectedRegions = data
    }

    return {
      transformErrors,
      setConsumer,
      liveValidation,
      addChild,
      unindent,
      indent,
      removeParentChild,
      removeChild,
      addNewChild,
      // addNewChildNode,
      setParent,
      changeChildrenOrder,
      addChildByOrder,
      showIndentIcon,
      remove,
      selectRuleType,
      selectTag,
      updateRuleInputData,
      ruleInputDataView,
      ruleTypeSchemaJson,
      uiSchemaJson,
      newShowIndentIcon,
      newShowUnIndentIcon,
      setPosition,
      checkEditRuleIndentIcon,
      checkEditRuleUnIndentIcon,
      positionDisplayed,
      subrulePositionDisplayed,
      nextLevelRightPositionDisplayed,
      levelLeftPositionDisplayed,
      setVolatileRuleTypeStore,
      setShowIndent,
      changePositionsRecursively,
      clearChildren,
      setSelectedCountries,
      setSelectedRegions,
    };
  });


const CreateRuleForm = observer(({createRuleSetState}) => {
  const intl = useIntl();
  const routerStore = useRouterStore();
  const stateContext = useContext(StateContext);

  createRuleSetState.setOnSelectChanges();
  {!createRuleSetState.isModalUi && createRuleSetState.tagSelectStore.tagStore.setFilterOn(createRuleSetState?.groupSelectStore?.selectedGroup?.uuid)}
  // {!createRuleSetState.isModalUi && createRuleSetState.tagSelectStore.tagStore?.setHydrateFuntion(createRuleSetState?.tagSelectStore.configOptionLoadValues)}
  const isDisabled = createRuleSetState.isView;

  const {rootNode} = createRuleSetState;
  // createRuleSetState.setConsumer()
  createRuleSetState.addRoot(rootNode);
  return (
    <Card>
      <CardBody>
        <DisableWrapper isDisabled={isDisabled}>

          {!createRuleSetState.isModalUi &&
            <>
            {stateContext?.appConfigs?.features?.cmp_ui_docs?.enabled &&
              <Row className="p-1 text-sm-right">
                <Col sm={12}>
                  <DocLink id="docs.rules"/>
                </Col>
              </Row>
            }
            <Row className="p-1">
            {/*<Row>*/}
            {/*<Col sm={1}></Col>*/}
            <Label className="text-sm-left"><FormattedMessage id="filterByGroups.defaultMessage"/></Label>
            <Col sm={3}>
                <GroupSelect groupSelectStore={createRuleSetState.groupSelectStore}/>
            </Col>
            <Col sm={2} style={{alignContent:"center", marginTop:"5px"}}>
              <CustomInput
                type="switch"
                id="isGroupGlobal"
                name="isGroupGlobal"
                label={intl.formatMessage({id: 'qrDesign.create.groupOnly.defaultMessage',defaultMessage:"Only"})}
                checked={createRuleSetState?.isExclusivelyGroupOwned}
                onChange={() => createRuleSetState.toggleIsExclusivelyGroupOwned()}
              />
            </Col>
            {createRuleSetState?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled && <Col sm={2} style={{alignContent:"center", marginTop:"5px"}}>
              <CustomInput
                type="switch"
                id="isDimensionAll"
                name="isDimensionAll"
                label={createRuleSetState?.dimensionsMultiSelectStore?.getLabelsWithPrefix(intl.formatMessage({id: 'qrDesign.create.dimensionAll.defaultMessage'}))}
                checked={createRuleSetState?.dimensionAll}
                onChange={() => createRuleSetState.toggleDimensionAll()}
              />
            </Col>}
            {createRuleSetState?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled && createRuleSetState.isDimensionSelectVisible && <>
              <Col>
                <DimensionsMultiSelect dimensionMultiSelectState={createRuleSetState.dimensionsMultiSelectStore}/>
              </Col>
            </>}
          </Row>
          <Row className="p-1 mt-2">
            <Label className="text-sm-left"><FormattedMessage id="createQr.tags.defaultMessage"/></Label>
            <Col>
              <TagSelect tagSelectStore={createRuleSetState?.tagSelectStore}/>
            </Col>
          </Row>
          </>
          }
          <Row className="p-1">
            <Col sm={12}>
              <div><TreeRole rootNode={rootNode} ruleTypeStore={createRuleSetState.ruleTypeSelectState?.store} disabled={isDisabled}/></div>
            </Col>
          </Row>
          <br/>
          <Row>
              <Button id="add-rule-btn" onClick={e => rootNode.addNewChild(createRuleSetState.ruleTypeSelectState.store)}>
                Add Rule
              </Button>
          </Row>
          {!createRuleSetState.isModalUi &&
            <>
              <Row className="p-1 ">
                {/*<hr />*/}
              </Row>
              <Row className="p-1 ">
                <Label sm={2}>Rules Set Name</Label>
                <Col sm={10}>
                    {/*<div className="custom-controls-stacked">*/}
                    <Input
                      invalid={!createRuleSetState.nameIsValid}
                      required={true}
                      type="input"
                      id="ruleSetName"
                      name="ruleSetName"
                      onChange={createRuleSetState.handleRuleSetNameChange}
                      value={createRuleSetState.ruleSetName}
                    />
                    {/*</div>*/}

                </Col>
              </Row>
              {/*<Row className="p-1 text-sm-right">*/}
              {/*  <Col sm={4}>*/}
              {/*    <InputGroup className="mb-3">*/}
              {/*      <InputGroupAddon addonType="prepend">*/}
              {/*        Make Primary URL*/}
              {/*      </InputGroupAddon>*/}
              {/*      /!*<div className="custom-controls-stacked">*!/*/}
              {/*      <Input*/}
              {/*        type="input"*/}
              {/*        id="primaryUrl"*/}
              {/*        name="primaryUrl"*/}
              {/*        onChange={createRuleSetState.handlePrimaryUrlChange}*/}
              {/*        value={createRuleSetState.primaryUrl}*/}
              {/*      />*/}
              {/*      /!*</div>*!/*/}
              {/*    </InputGroup>*/}

              {/*  </Col>*/}
              {/*</Row>*/}

              {/*<Row className="p-1 text-sm-right">*/}
              {/*  <Col sm={1}> Make Default</Col>*/}
              {/*  <Col sm={1}>*/}
              {/*    <FormGroup check>*/}
              {/*      <Input  name="makePrimaryURL"*/}
              {/*              id="radio1"*/}
              {/*              value={createRuleSetState.isPrimaryUrlDefault}*/}
              {/*              type="radio"*/}
              {/*              checked={createRuleSetState.isPrimaryUrlDefault}*/}
              {/*              onChange={createRuleSetState.handleIsPrimary}*/}
              {/*      />*/}
              {/*      <Label check>Yes</Label>*/}
              {/*    </FormGroup>*/}
              {/*  </Col>*/}
              {/*  <Col sm={1}>*/}
              {/*    <FormGroup check>*/}
              {/*      <Input name="makePrimaryURL"*/}
              {/*             id="radio2"*/}
              {/*             value={!createRuleSetState.isPrimaryUrlDefault}*/}
              {/*             type="radio"*/}
              {/*             checked={!createRuleSetState.isPrimaryUrlDefault}*/}
              {/*             onChange={createRuleSetState.handleIsPrimary}*/}
              {/*      />*/}
              {/*      <Label check>No</Label>*/}
              {/*    </FormGroup>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <hr/>
              <Row className="p-1">
                <Col sm={2}>
                  <Button id="back-btn" color="secondary"
                          onClick={browserHistory.goBack}
                  >
                    <FormattedMessage id="qrDesign.create.back.defaultMessage"/>
                  </Button>
                </Col>
                <Col sm={10} className="text-right">
                  <Button id="save-btn" outline color="primary" disabled={createRuleSetState.isLoading} onClick={async () => {
                    const res = await createRuleSetState.addRuleSet()
                    if (res) {
                      if(!createRuleSetState.selectedRuleSetUUID) {
                        routerStore.goTo('ruleSets')
                      }
                    }
                  }
                  }>
                    {!createRuleSetState.selectedRuleSetUUID
                      ? <FormattedMessage id="qrDesign.create.save.defaultMessage"/>
                      : "Update"

                    }
                  </Button>
                </Col>
              </Row>
            </>}
        </DisableWrapper>
      </CardBody>
    </Card>
  );
});


export default CreateRuleForm;
