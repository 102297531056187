import { flow, getEnv, types } from "mobx-state-tree";
import {PublishDomain} from "../../models/PublishDomain";
import lodash from "lodash";

const publishDomains = [
  PublishDomain.create({ uuid: '1', name: 'http://scn.by', label: 'http://scn.by' }),
  PublishDomain.create({ uuid: '2', name: 'http://scn.my', label: 'http://scn.my' }),
  PublishDomain.create({ uuid: '3', name: 'http://scb.ai', label: 'http://scb.ai' })
]
/**
 * Base Layer of Persistence
 *
 * Shared Values across the session.
 *
 * CRUD operations go through Domain store.
 *
 * Search operations go through domain store.
 *
 *
 */
const PublishDomainStore = types.model('PublishDomainStore',{
  storeUuid: types.identifier,
  publishDomains: types.optional(types.array(PublishDomain), publishDomains),
  //isLoading..
}).actions((self)=>({
  initWithData(items = [], enableSharedDomains = false) {
    try {
      if(!lodash.isEmpty(items)) {
        if(!enableSharedDomains){
          items = items.filter(item => !item.isGlobal);
        }
        self.publishDomains = items.map(item =>
          PublishDomain.create({
            uuid: item.uuid,
            name: item.domain,
            domain: item.domain,
            label: item.domain,
            isDefault: item.isDefault,
            isGlobal: item.isGlobal,
            owner: item?.owner?.groupUuid,
            shared: item?.shared,
          })
        );
        self.publishDomains.sort((a,b) => a.label.localeCompare(b.label))
      }
    } catch (e) {
      console.error("Failed to init published domains with data", e);
    }
  },
  init: flow(function* f(params = {}) {
    console.log("load tagStore from API");
    const resp = yield self.getPublishDomains(params);
    console.log(resp);
    self.publishDomains = resp.results.map(pd => PublishDomain.create({ ...pd}))
  }),
  getPublishDomains: flow(function* getDomains(params = {}) {
    try {
      return yield getEnv(self).publishDomainManager.fetchAll(params);
    } catch (error) {
      console.error("Failed to fetch publish domains", error);
    }
  }),
  setPublishDomains(domains){ //TODO: we should not use this method, get domains set by initSites method

    self.publishDomains = domains.map(item =>
      PublishDomain.create({
        uuid: item.uuid, name: item.domain,
        domain: item.domain, label: item.domain,
        isDefault: item.isDefault, isGlobal: item.isGlobal
      })
    )
  },
  searchFor(txt){
    //todo : search for groups with name like 'txt'
  },
  reload(){

  },
  save(group){

  },
  update(group){

  },
  add(data){

  },
  delete(group){

  }

}))



export default PublishDomainStore;
