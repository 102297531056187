import React from "react";
import {FormGroup} from "reactstrap";
import {observer} from "mobx-react-lite";
import "./ModulesSprite.css"
import "./EyeSprite.css"
import "./BallSprite.css"
const ModulesListItem = observer(({EyesAndModulesStore, imageClass, shape, group, logoStore, key, itemID}) => {
  return (

    <div
      id={itemID}
      onClick={() => {
        EyesAndModulesStore.handleShapeChange(group, shape, logoStore)
      }}
      style={{
        height: "95px",
        width: "100px",
        marginRight: "20px",
        cursor: "pointer",
        border: (shape === EyesAndModulesStore[group])
          ? "solid 2px red"
          : ""
      }}
    >
      <div  className={`${imageClass}`}></div>
    </div>
  )
})

export default ModulesListItem;
