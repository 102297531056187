import {getType, types} from "mobx-state-tree";
import {RuleSetsStore} from "../../../stores/domain/RuleSetsStore";
import {RuleSet} from "../../../models/RuleSet";

export const RuleSetSelectState = types.model('RuleSetSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(RuleSetsStore))),
  selected: types.maybe(types.safeReference(RuleSet)),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  search(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
    //self.groupStore.groups
  },
  setConsumer(f){
    self.notifySelect = f
  },
  select(ruleSet) {
    console.log("*****")
    console.log(ruleSet)
    self.notifySelect(ruleSet.uuid)
    self.selected = ruleSet

  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => { //TODO
      console.log("placeholder notifySelect")
    }

    self.selectedSchema = {}
  }
})).views( (self) => ({
  options() {
    const list  = self.store.filteredList() || [];
    list.sort((a,b) => a?.label?.localeCompare(b?.label))
    return list || []
  },
  // selectedSchemaView(){
  //   console.log(self.selectedSchema)
  //   return JSON.parse(self.selectedSchema)
  // }
}))


