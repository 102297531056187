import {types,getEnv,flow} from "mobx-state-tree";
import Notification from "../../utils/Notification";

const CreateQrFromTemplateState = types.model('CreateQrFromTemplateState', {
  title: types.maybe(types.string),
  uuid: types.maybe(types.string),
  formSchema: types.maybe(types.string),
  experienceData: types.optional(types.string, '{}'),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  //formSchema: types.model({})
  //formHasErrors: types.optional(types.boolean, false),
  //errorMessage: types.optional(types.string, ""),
})
.volatile(self => ({

}))
.actions((self) => ({
  updateExperienceData(data) {
    self.experienceData = JSON.stringify(data)
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean
  },
  handleErrorMessage(message) {
    self.errorMessage = message
  },

  saveNew: flow(function* f() {

    const manager = getEnv(self).qrtemplateManager;
    const resp = yield manager.save(self);
    if(self.formHasErrors){
      new Notification()
        .setType("error")
        .setMessage(`${self.title} Code failed creating`)
        .send();
    }else{
      new Notification()
        .setType("success")
        .setMessage(`${self.title} Code created`)
        .send();
      self.formHasErrors = false;
    }
    return resp
  })
})).views((self)=>({
  experienceDataView() {
    return JSON.parse(self.experienceData)
  },
}))


export default CreateQrFromTemplateState
