import HttpClient from "../../services/HttpClient";


class UserManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    console.log(this.httpClient);
    this.url = `/users`;
  }

  async fetchAll(queryParams) {
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in user get request", res);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get users", e);
    }
    return null;
  }


}

export default UserManager;
