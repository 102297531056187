import HttpClient from "../services/HttpClient";
import config from "../configs";
import RootAppStore from "./RootAppStore";
import InitSitesManager from "../managers/initSites/initSitesManager";
import CodeManager from "../managers/codes/codeManager";
import ImageConfigManager from "../managers/ImageConfigs/ImageConfigManager";
import ReportManager from "../managers/reports/reportManager";
import TagManager from "../managers/tags/tagManager";
import GroupManager from "../managers/groups/groupManager";
import PublishDomainManager from "../managers/publishdomain/publishDomainManager";
import CodeTypeManager from "../managers/codetypes/codeTypeManager";
import AppConfigsManager from "../managers/appConfigs/appConfigsManager";
import BulkManager from "../managers/codes/BulkManager";
import BatchManager from "../managers/codes/BatchManager"
import RuleTypeManager from "../managers/rules/ruleManager";
import RuleTypeDataManager from "../managers/ruleTypeData/ruleTypeDataManager";
import RuleSetManager from "../managers/ruleSets/ruleSetManager";
import UtmManager from "../managers/utms/utmManager";
import QrTemplateManager from "../managers/qrtemplates/qrTemplatesManager";
import ParameterSetManager from "../managers/parameterSets/parameterSetManager";
import ParametersManager from "../managers/parameters/ParametersManager";
import UserManager from "../managers/users/userManager";
import DataExportManager from "../managers/dataExports/DataExportManager";
import ExtendedDataManager from "../managers/extendedData/ExtendedDataManager";
/*--YEOMAN--MANAGERIMPORT--*/

const locale = localStorage['locale'] ? localStorage['locale'] : 'en'; // English is default locale if none is set
export function initRootAppStore(cfgs = {}) {
  const httpClient = new HttpClient(config.API_DOMAIN_NAME);

  const {
    appVersion = "1.0.0",
    currentLocale = locale
  } = cfgs;

  return RootAppStore.create({
    appVersion: appVersion,
    currentLocale: currentLocale
  }, {
    httpClient: httpClient,
    initSitesManager: new InitSitesManager(httpClient),
    codeManager: new CodeManager(httpClient),
    extendedDataManager: new ExtendedDataManager(httpClient),
    imageConfigManager: new ImageConfigManager(httpClient),
    reportManager: new ReportManager(httpClient),
    tagManager: new TagManager(httpClient),
    groupManager: new GroupManager(httpClient),
    dataExportManager: new DataExportManager(httpClient),
    publishDomainManager: new PublishDomainManager(httpClient),
    codeTypeManager: new CodeTypeManager(httpClient),
    appConfigsManager: new AppConfigsManager(httpClient),
    bulkManager: new BulkManager(httpClient),
    batchManager: new BatchManager(httpClient),
    ruleTypeManager: new RuleTypeManager(httpClient),
    ruleTypeDataManager: new RuleTypeDataManager(httpClient),
    ruleSetManager: new RuleSetManager(httpClient),
    utmManager: new UtmManager(httpClient),
    qrtemplateManager: new QrTemplateManager(httpClient),
    parameterSetManager: new ParameterSetManager(httpClient),
    parametersManager: new ParametersManager(httpClient),
    userManager: new UserManager(httpClient),
    /*--YEOMAN--MANAGERINIT--*/
  });
}




