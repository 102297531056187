import React from "react";
import { observer } from "mobx-react-lite";
import GoogleFooter from "../components/GoogleFooter";
import {getLabelColor} from "../utils";
import StorePass from "./StorePass";

const GoogleStorePass = observer(({store}) => {
  if (typeof store === "string"){
    store = JSON.parse(store);
  }
  const data = store?.storeCard;

  const backgroundColor = data?.backgroundColor || "#FFFFFF";
  const foregroundColor = getLabelColor(backgroundColor);
  const labelColor = getLabelColor(backgroundColor);

  return (
    <StorePass
      store={{
        data,
        minHeight: "430px",
        backgroundColor,
        foregroundColor,
        labelColor,
        footer: <GoogleFooter barcodeType={data?.barcodeType} mobileWalletCode={data?.mobileWalletCode} />
      }}
    />
  );
});

export default GoogleStorePass;
