import {types} from "mobx-state-tree";
import {ResourcesModal} from "./Resources";
import {RlsModel} from "./Rls";
import {SupersetUserModal} from "./SupersetUser";

export const SuperSetInfoModel = types
	.model({
		user: types.maybeNull(SupersetUserModal),
		resources: types.array(ResourcesModal),
		rls: types.array(RlsModel)
	})
	.actions((self) => ({
		updateStatus(newStatus) {
			self.status = newStatus
		},
		addTag(newTag) {
			self.tags.push(newTag)
		},
		removeTag(oldTag) {
			//remove from self.tags
		},
		update(data) {

		},
		refresh(){

		},
		save() {
			//? PUT / ...
		}
	}))
