import {types} from "mobx-state-tree";

const CaptionState = types.model('CaptionState', {
    id: types.identifier,
    captionTextPosition: types.optional(types.string, 'Top'),
    captionFontSize: types.optional(types.integer, 50),
    captionContent: types.optional(types.string, ''),
    captionColor: types.optional(types.string, '#000000'),
    circularText: types.optional(types.boolean, false),
}).volatile(self => ({
    notifyDesigner: types.f,
    extendedDesignerConfig: types.string
})).actions((self) => ({
    setConsumer(f) {
        self.notifyDesigner = f
    },
    handleFontSizeChange(e) {
        self.captionFontSize = parseInt(e.target.value)
        self.notifyDesigner(self.id, self)


    },
    handleTextPositionChange(e) {
        self.captionTextPosition = e.target.value
        self.notifyDesigner(self.id, self)
    },
    handleContentChange(e, imageCharsStore) {
        self.captionContent = e.target.value
        self.notifyDesigner(self.id, self)
        imageCharsStore.changeQuietZone(1)

    },
    handleSelectColor(selectColor, colorPosition, frameAndCaptionsStore,isCMYK = false) {
      const colorValue = isCMYK?`cmyk(${selectColor.cmyk.c}%, ${selectColor.cmyk.m}%, ${selectColor.cmyk.y}%, ${selectColor.cmyk.k}%)`: selectColor.hex
        self.captionColor = colorValue
        self.notifyDesigner(self.id, self)
    }
}));

export default CaptionState;

