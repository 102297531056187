import {detach, flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {QrConfiguration} from "../../models/QrConfiguration";
import HttpClient from "../../services/HttpClient";
import PreviewManager from "../../managers/preview/PreviewManager";
import Notification from "../../utils/Notification";
import {setDefaultBackgroundColor, setEyesAndModules} from "../../utils/common";
import {scannabilityCheck} from "../../utils/scannability";
import {TagModel} from "../../models/tags/TagModel";
import {DashboardStore} from "./DashboardStore";

export const QrConfigStore = types.model('QrConfigStore',{
  storeUuid: types.identifier,
  defaultConfiguration:types.optional(types.array(QrConfiguration),[]), //dropdown
  alternateConfiguration: types.optional(types.array(types.frozen()),[]),
  configSelected: types.maybe(types.safeReference(QrConfiguration)),
  designerConfig: types.maybe(types.safeReference(QrConfiguration)),
  dashboardStore: types.maybe(types.reference(DashboardStore))
}).volatile((self)=>({
  filterListOn:types.fn,
})).actions((self)=>({
  // setFilterOn(groupUuid) {
  //   console.log("Inside Config Store", groupUuid)
  //   self.filterListOn = (item) => {   //function used to filter tag options by selected group
  //     return item?.owner?.groupUuid === groupUuid
  //   }
  // },
  initWithData(qrConfigs = []) {
    try {
      if (qrConfigs) {
        console.log("*** start initWithData,QrConfigStore");
        self.clearImageConfig();
        self.defaultConfiguration = [...self.defaultConfiguration, ...qrConfigs.map(img => {
          //we dont need tags in dropdown store
          let nestedTags = [];
          // if(img.tags && img.tags.length > 0){
          //   nestedTags = img.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
          // }

          return QrConfiguration.create({
          uuid: img?.uuid,
          name: img?.name,
          label: img?.name,
          level: img?.level,
          image: img?.qrPreview || "",
          qrConfig: img?.data || "",
          is_default: img?.is_default,
          isExclusivelyGroupOwned: img?.isExclusivelyGroupOwned,
          createdAt: img?.createdAt,
          updatedAt: img?.updatedAt,
          //tags: nestedTags,
            owner: img?.owner
        })}).sort((a, b) => a.label.localeCompare(b.label))]
      }

    } catch (e) {
      console.error("Failed to init QRConfigStore with data",e);
    }
  },
  addImageToList(imageConfig) {
    self.removeImageConfig();
    self.defaultConfiguration = [imageConfig, ...self.defaultConfiguration]
  },
  updateImageConfig(imageConfig) {
    console.log("Image Config",imageConfig)
    const index = self.defaultConfiguration.findIndex(img => img.uuid === imageConfig?.uuid);
    if(index > -1) {
      let nestedTags = [];
      if(imageConfig.tags && imageConfig.tags.length > 0){
        nestedTags = imageConfig.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
      }
      imageConfig.tags=nestedTags;
      self.defaultConfiguration[index].updateConfig(imageConfig);
    }
  },
  removeImageConfig() {
    self.defaultConfiguration = self.defaultConfiguration.filter(img => img.level !== "qr");
  },
  clearImageConfig() {
    self.defaultConfiguration.forEach(item => {
      if(item.level !== "qr") {
        detach(item);
        self.defaultConfiguration.remove(item);
      }
    });
    //self.defaultConfiguration.clear();
  },
  // replaceImageConfig(qrConfigs) {
  //   self.clearImageConfig();
  //   const qrConfigurationObjects = qrConfigs.map(img =>{
  //     let nestedTags = [];
  //     if(img.tags && img.tags.length > 0){
  //       nestedTags = img.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
  //     }
  //     return ({
  //     uuid: img?.uuid || "",
  //     name: img?.name || "",
  //     label: img?.name || "",
  //     level: img?.level || "",
  //     image: img?.qrPreview || "",
  //     qrConfig: img?.data || "",
  //     is_default: img?.is_default || false,
  //     isExclusivelyGroupOwned: img?.isExclusivelyGroupOwned || false,
  //     createdAt: img?.createdAt || "",
  //     updatedAt: img?.updatedAt || "",
  //     tags: nestedTags,
  //     owner: img?.owner
  //   })});
  //
  //   qrConfigurationObjects.forEach(qrConfigObj => {
  //     const qrConfigInstance = QrConfiguration.create(qrConfigObj);
  //     self.defaultConfiguration.push(qrConfigInstance);
  //   });
  //   //self.defaultConfiguration.push(self.designerConfig)
  //   self.defaultConfiguration.sort((a,b) => a.label.localeCompare(b.label))
  // },

  fetchImageConfigWithIdAndUpdateStore: flow(function* f(uuid) {
    const res = yield getEnv(self).imageConfigManager.fetchWithId(uuid);
    self.updateImageConfig(res);
    return res;
  }),
  fetchImageConfigWithId: flow(function* f(uuid) {
      const res = yield getEnv(self).imageConfigManager.fetchWithId(uuid);
      if(res) {
        let nestedTags = [];
        if(res.tags && res.tags.length > 0){
          nestedTags = res.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
        }
        const imageConfig = QrConfiguration.create({
          uuid: res?.uuid,
          level: res?.level,
          name: res?.name,
          label: res?.name,
          image: res?.image,
          qrConfig: JSON.stringify(JSON.parse(res?.qrConfig)),
          is_default: res?.is_default,
          isExclusivelyGroupOwned: res?.isExclusivelyGroupOwned,
          createdAt: res?.createdAt,
          updatedAt: res?.updatedAt,
          tags: nestedTags,
          owner: res?.owner
        })
        //self.designerConfig = imageConfig
        self.addImageToList(imageConfig);
        return res;
      }
  }),

  // init: flow(function* f(params = {}) {
  //   console.log("load configStore from API");
  //   yield self.getQRConfigs(params);
  // }),
  // getQRConfigs: flow(function* getDomains(params = {}) {
  //   try {
  //     const imageConfigResponse = yield getEnv(self).imageConfigManager.fetchAll(params);
  //     if(imageConfigResponse && imageConfigResponse.results && imageConfigResponse.results.length > 0) {
  //       self.defaultConfiguration = imageConfigResponse.results.map(img =>
  //       {
  //         let nestedTags = [];
  //         if(img.tags && img.tags.length > 0){
  //           nestedTags = img.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
  //         }
  //         return QrConfiguration.create({
  //         uuid: img?.uuid,
  //         name: img?.name,
  //         label: img?.name,
  //         level: img?.level,
  //         image: img?.qrPreview,
  //         qrConfig:img?.data,
  //         is_default: img?.is_default,
  //         isExclusivelyGroupOwned: img?.isExclusivelyGroupOwned,
  //         createdAt: img?.createdAt,
  //         updatedAt: img?.updatedAt,
  //         tags: nestedTags,
  //         owner: img?.owner
  //       })}).sort((a, b) => a.label.localeCompare(b.label))
  //     }
  //
  //     return imageConfigResponse;
  //   } catch (error) {
  //     console.error("Failed to fetch image configs", error);
  //     return null;
  //   }
  // }),
  selectGroup(obj) {
    self.configSelected = obj.uuid;
  },
  setConfigStore(configs){
    self.alternateConfiguration = configs
  },
  setDesignerConfig(c) {
      self.designerConfig =  c
  },
  addConfig: flow(function* f(state) {


    let extendConfig = JSON.parse(state.extendConfig)
    setDefaultBackgroundColor(extendConfig)
    setEyesAndModules(extendConfig)
    const formDataForIcon = JSON.stringify({...extendConfig ,size: 0.1, resolution: 300 })
    const configForIcon = {
      name:state.configurationName,
      qrImageFormData:formDataForIcon,
      shortUrl:""
    }

    const config = {
      name:state.configurationName,
      qrImageFormData:JSON.stringify(extendConfig),
      isExclusivelyGroupOwned: state.isExclusivelyGroupOwned,
      groupUuid:state.groupSelectStore.selectedGroup.uuid,
      tags: state.selectedTags,
      ...(state.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled) && {dimensions: state.dimensionsMultiSelectStore.getTranspiledDimensions()}
    }
    let isDefaultDesignerConfig = true
    if(extendConfig.isDefaultDesigner === false) {
      isDefaultDesignerConfig = extendConfig.isDefaultDesigner
    }
    try {
      const designerHttpClient = new HttpClient("https://nigiu8dgm4.execute-api.us-east-2.amazonaws.com/dev")
      const previewManager = new PreviewManager(designerHttpClient, state.dashboardStore.apps.defaultImageConfig, state.dashboardStore.apps.v1ImageConfig)
      console.log('addConfig extendConfig:', configForIcon)
      const isSavingConfig = true
      const resp = yield previewManager.create(configForIcon, isSavingConfig)
      console.log('addConfig result', resp)
      if (resp.status === 201) {
        //add call to the design manager
        let qrPreview
        // if the config is v1, we need to create a v1 qrPreview
        if(!isDefaultDesignerConfig) {
          qrPreview = `data:image/png;base64,` + resp.data.split("'")[1].split("=")[0]
        } else {
          if(JSON.parse(resp.config.data).imageFormat === "pdf" || JSON.parse(resp.config.data).imageFormat === "eps"){
            const pdfEpsConfig = configForIcon
            const pdfEpsQr = JSON.parse(pdfEpsConfig.qrImageFormData)
            pdfEpsQr.imageFormat = "png"
            pdfEpsConfig.qrImageFormData = JSON.stringify(pdfEpsQr)
            const pdfEpsPreview = yield previewManager.create(pdfEpsConfig, isSavingConfig)
            qrPreview = `data:image/png;base64,` + pdfEpsPreview.data.split("'")[1].split("=")[0]
          } else {
            qrPreview = `data:image/${JSON.parse(resp.config.data).imageFormat};base64,` + resp.data.split("'")[1].split("=")[0]
          }
        }
        // need to check if the image is scannable
        const previewConfig = yield previewManager.create(config)
        const scanabilityCheck = yield scannabilityCheck(previewConfig.data.split("'")[1])
        const configImagePayload = { ...config,
          "qrPreview":qrPreview}
        const imageConfigManager = getEnv(self).imageConfigManager
        const res = yield imageConfigManager.create(configImagePayload)
        const newConfig = QrConfiguration.create({
          uuid: res.data.uuid,
          label: res.data.name,
          level: res.data?.level,
          name: res.data.name,
          qrConfig: res.data.data,
          image: res.data.qrPreview,
          isExclusivelyGroupOwned: res.data?.isExclusivelyGroupOwned,
          tags: res.tags,
          owner: res.data?.owner
        })
        self.defaultConfiguration.push(newConfig)
        return resp.status
      }else{
        return resp.status
      }
    } catch (error) {
      console.error("Failed to add image config", error);
      return null;
    }
  }),
  deleteConfig(uuid){
    const config = self.defaultConfiguration.find(el => el.uuid === uuid)
    for (let el of self.defaultConfiguration) {
        if (el.uuid === uuid) {
          const indexOf = self.defaultConfiguration.indexOf(el)
          self.defaultConfiguration.splice(indexOf, 1)
        }
      }
    new Notification()
      .setType("success")
      .setMessage(`${config?.name} Designer Template deleted`)
      .send();

  }
})).views((self) => ({
  filteredList() {
      return self.defaultConfiguration || []
  }
}));
