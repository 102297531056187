import HttpClient from "../../services/HttpClient";


class ReportManager {
  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    console.log(this.httpClient);
    this.url = `/reports`;
  }
  async fetchReport(reportUuid){
    try {
      const res = await this.httpClient.get(`${this.url}/${reportUuid}`);
      console.log("response in get request", res);
      if (res) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get reports", e);
    }
    return null;
  }

  async fetchAllReports(queryParams) {

    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get reports", e);
    }
    return null;
  }

  async fetchGuestTokenForReport(reportUuid, queryParams){
    try {
      const res = await this.httpClient.get(`${this.url}/${reportUuid}/embeddedToken`, queryParams);
      console.log("response in post request", res);
      if (res) {
        return res; //<-- res.token
      }
    } catch (e) {
      console.error("Failed to get reports", e);
    }
    return null;
  }
}

export default ReportManager;
