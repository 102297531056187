//-> appState
//-> UI State
//-> Domain state

import { flow, getEnv, types } from "mobx-state-tree";
import { flattenMessages } from '../utils/flattenMessages';
import {getLanguageFromLocale} from '../utils/getLanguageFromLocale'
import { RootUiState } from "./RootUiState";

import RootDomainStore from "./RootDomainStore";
import GroupStore from "./domain/GroupStore";
import CodeTypeStore from "./domain/CodeTypeStore";
import { QrConfigStore } from "./domain/QrConfigStore";
import PublishDomainStore from "./domain/PublishDomainStore";
import DataExportTypeStore from "./domain/DataExportTypeStore";
import TagStore from './domain/TagStore';
import TenantStore from './domain/TenantStore';
import RuleTypeStore from "./domain/RuleTypeStore";
import {PageTemplateStore} from "./domain/PageTemplateStore";
import UtmStore from "./domain/UtmStore";
import {RuleSetsStore} from "./domain/RuleSetsStore";
import {DashboardStore} from "./domain/DashboardStore";
import DimensionStore from "./domain/DimensionStore";
import QrTemplateStore from "./domain/QrTemplateStore";
import ParameterSetStore from "./domain/ParameterSetStore";
import UserStore from "./domain/UserStore";
import ExtendedDataStore from "./domain/ExtendedDataStore";
import ExtendedDataTypeStore from "./domain/ExtendedDataTypeStore";
import ExtendedDataSetStore from "./domain/ExtendedDataSetStore";
import BatchStore from "./domain/BatchStore";
/*--YEOMAN-STORE-IMPORT--*/



const locale = localStorage['locale'] ? localStorage['locale'] : 'en'; // English is default locale if none is set

const RootAppStore = types.model({
  appVersion: types.optional(types.string,"0.0.1"),
  domainStore: types.maybe(RootDomainStore),
  rootUiStore: types.maybe(RootUiState),
  currentLocale: types.optional(types.string,locale),
  messages: types.optional(types.frozen()),
  language:types.optional(types.frozen())
  //sbContext: types.string < tenantUuid
  //sessionToken: types.string
}).volatile((self) => ({
  httpClient: types.undefined,
  appConfigs: types.undefined,
  routeStore: types.undefined,
  userRoles: types.undefined
})).actions((self) => ({
  updateToken(newToken) {
  },
  init(options = {}) {
    self.httpClient = getEnv(self).httpClient.setHeaders(options)
  },
  setRouteStore(rt){
    self.routeStore = rt;
  },
  bootStrap: flow(function* f() {
    yield self.changeLocale(self.currentLocale);

    //Init All Stores from API.

    const groupStore = GroupStore.create({
      storeUuid: 'g'
    })
    const dimensionStore = DimensionStore.create({
      storeUuid: 'd'
    })

    const qrConfigStore = QrConfigStore.create({
      storeUuid: 'qrcs',
    })
    const codeTypeStore = CodeTypeStore.create({
      storeUuid: 'cts'
    })

    const publishDomainStore = PublishDomainStore.create({
      storeUuid: "pds"
    })

    const dataExportTypeStore = DataExportTypeStore.create({
      storeUuid: "dets"
    });

    const tagStore = TagStore.create({
      storeUuid: 'ts'
    })

    const extendedDataStore = ExtendedDataStore.create({
      storeUuid: "eds"
    })

    const extendedDataSetStore = ExtendedDataSetStore.create({
      storeUuid: "edsets"
    })

    const extendedDataTypeStore = ExtendedDataTypeStore.create({
      storeUuid: "edts"
    })

    const tenantStore = TenantStore.create({
      storeUuid: 'tns'
    })

    const ruleTypeStore = RuleTypeStore.create({
      storeUuid: 'rts'
    })

    const ruleSetStore = RuleSetsStore.create({
      storeUuid: 'rss'
    })

    const pageTemplateStore = PageTemplateStore.create({
      storeUuid: 'pts'
    })

    const utmStore = UtmStore.create({
      storeUuid: "utms"
    });

    const dashboardStore = DashboardStore.create({
      storeUuid: "ds"
    });

    const qrtemplateStore = QrTemplateStore.create({
      storeUuid: "tms"
    })

    const parameterSetStore = ParameterSetStore.create({
      storeUuid: "pss"
    });

    const userStore = UserStore.create({
      storeUuid: "us"
    })

    const batchStore = BatchStore.create({
      storeUuid: "bts"
    })

    /*--YEOMAN-STORE-INSTANCE--*/

    self.domainStore = RootDomainStore.create({
      groupStore: groupStore,
      codeTypeStore: codeTypeStore,
      dataExportTypeStore: dataExportTypeStore,
      qrConfigStore: qrConfigStore,
      publishDomainStore: publishDomainStore,
      tagStore: tagStore,
      extendedDataStore: extendedDataStore,
      extendedDataSetStore: extendedDataSetStore,
      extendedDataTypeStore: extendedDataTypeStore,
      tenantStore: tenantStore,
      pageTemplateStore: pageTemplateStore,
      ruleTypeStore: ruleTypeStore,
      utmStore: utmStore,
      ruleSetStore: ruleSetStore,
      dashboardStore: dashboardStore,
      dimensionStore: dimensionStore,
      qrtemplateStore: qrtemplateStore,
      parameterSetStore: parameterSetStore,
      userStore: userStore,
      batchStore: batchStore,
      /*--YEOMAN-STORE-SET--*/
    })

    self.rootUiStore = RootUiState.create({});
    console.log("init ui store");

    try {
      const initSitesManager = getEnv(self).initSitesManager;
      const [initResponse] = yield Promise.all([initSitesManager.fetchAll(), self.rootUiStore.init()]);
      console.log("initResponse",initResponse);
      if (initResponse === null){
        return null;
      }
      self.appConfigs = {
        configs : initResponse?.appConfigs?.configs, // configs.qr_dynamic?
        features: initResponse.appConfigs?.featureFlags || {
          //"cmp_ui_domains":{}
          //"cmp_ui_rules":{},
        }
      }

      self.userRoles = initResponse?.userRoles;

      const configs = self.appConfigs.configs;
      configs.dynamic_codes = configs.dynamic_codes || {};
      configs.qr_auto_generation = configs.qr_auto_generation || false;
      configs.default_language = configs.default_language || "en";
      configs.use_shared_domains = configs.use_shared_domains || false;
      configs.enabled_code_types = configs.enabled_code_types || ['url','vcard'];
      configs.enabled_data_export_types = configs.enabled_data_export_types || ['default'];
      configs.dimensions_enabled = configs.dimensions_enabled || false;
      configs.enabled_rule_types = configs.enabled_rule_types || ['country', 'lang', 'device', 'countryRegion', 'city', 'postalCode', 'metroCode',
        'userScanCount', 'carousel'];

      groupStore.initWithData(initResponse?.groups);
      console.log("groupStore.initWithData")

      tagStore.initWithData(initResponse?.tags);
      console.log("tagStore.initWithData")

      extendedDataTypeStore.initWithData(initResponse?.extendedDataTypes);
      console.log("extendedDataTypeStore.initWithData");

      try {
        qrConfigStore.initWithData(initResponse?.imageConfigs);
        console.log("qrConfigStore.initWithData")
      } catch (e) {
        console.warn("failed image configs init ",e)
      }

      try {
        codeTypeStore.initWithData(initResponse?.codeType || [],configs.enabled_code_types);
        console.log("codeTypeStore.initWithData")
      } catch (e) {
        console.warn("failed to init",e);
      }

      try {
        dataExportTypeStore.initWithData(initResponse?.dataExportTypes || [], configs.enabled_data_export_types);
        console.log("dataExportTypeStore.initWithData")
      } catch (e) {
        console.warn("failed to init",e);
      }

      try {
        publishDomainStore.initWithData(initResponse?.publishDomains || [],configs.use_shared_domains);
        console.log("publishDomainStore.initWithData")
      } catch (e) {
        console.warn("publishDomainStore.initWithData error",e)
      }

      try {
        ruleTypeStore.initWithData(initResponse?.ruleTypes || [], configs?.enabled_rule_types);
        console.log("RuleTypeStore.initWithData")
      } catch (e) {
        console.warn("RuleTypeStore.initWithData error",e)
      }
      try {
        ruleSetStore.initWithData(initResponse?.ruleSets || []);
        console.log("RuleSetStore.initWithData")
      } catch (e) {
        console.warn("RuleTypeStore.initWithData error",e)
      }
      try {
        tenantStore.initWithData(initResponse?.tenantInfo || {});
        console.log("tenantStore.initWithData")
      } catch (e) {
        console.warn("tenantStore.initWithData error",e)
      }

      try {
        pageTemplateStore.initWithData(initResponse?.pageTemplates || {});
        console.log("pageTemplateStore.initWithData")
      } catch (e) {
        console.warn("pageTemplateStore.initWithData error",e)
      }

      try {
        utmStore.initWithData(initResponse?.utms || {});
        console.log("utmStore.initWithData")
      } catch (e) {
        console.warn("utm.initWithData error",e)
      }

      try {
        dashboardStore.initWithData(initResponse?.dashboardApps || {});
        console.log("dashboardStore.initWithData")
      } catch (e) {
        console.warn("dashboardStore.initWithData error",e)
      }

      try {
        dimensionStore.initWithData(initResponse?.dimensions || [],configs.dimensions_enabled);
        console.log("dimensionStore.initWithData")
      } catch (e) {
        console.warn("dimensionStore.initWithData error",e)
      }

      try {
        qrtemplateStore.initWithData(initResponse?.qrTemplate);
        console.log("templateStore.initWithData")
      } catch (e)  {
        console.warn("template.init error",e)
      }
      try {
        parameterSetStore.initWithData(initResponse?.parameterSets || {});
        console.log("parameterSetStore.initWithData")
      } catch (e) {
        console.warn("parameterSet.initWithData error",e)
      }

      try {
        userStore.initWithData(initResponse?.users);
        console.log("userStore.initWithData")
      } catch (e)  {
        console.warn("userStore.init error",e)
      }

      /*--YEOMAN-STORE-INIT--*/


      self.rootUiStore.setAnyDefaultListSelects();
      console.log("INIT Done");
      return "ok";
    } catch (e) {
      console.warn("Faild init",e);
      return null;
    }
  }),
  changeLocale: flow(function* f(locale)  {
    self.currentLocale = locale;
    self.language = yield getLanguageFromLocale(self.currentLocale);
    self.messages = flattenMessages(self.language);
  }),
  afterCreate() {
    console.log("RootAppStore AfterCreate")
    //inject volatiles
    self.httpClient = getEnv(self).httpClient
  }
})
)


export default RootAppStore









