import HttpClient from "../../services/HttpClient";


class RuleTypeManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/rules`
  }

  async createRule(data) {
    console.log("save rule")
    try {
      const payload = {
        ...data
      }
      const res = await this.httpClient.post(this.url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to save rule: ", e.message);
      return e
    }
  }

  async getRule(uuid) {
    console.log("get rule")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.get(url);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to get rule: ", e.message);
      return e
    }
  }

  async fetchAll(queryParams) {
    console.log("Rule manager fetchAllRules")
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res)
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get rule", e);
    }
    return null;
  }

  async updateRule(uuid, data) {
    console.log("update rule")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.put(url, data);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to update rule: ", e.message);
      return e
    }
  }

  async deleteRule(uuid) {
    try {
      console.log("delete rule")
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
      return response
    } catch (e) {
      console.error("Failed to delete rule", e.message)
    }
  }
}


export default RuleTypeManager;
