import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../../elements/groups/GroupSelectState";
import RuleSetSelectView from "../../../elements/ruleSets/RuleSetSelectView";
import {RuleSetSelectState} from "../../../elements/ruleSets/RuleSetSelectState";
import {CreateRuleSetState} from "../create/CreateRuleSetState";


export const RuleSetModalState = types.model("RuleSetModalState", {
  isLoading: types.optional(types.boolean, false),
  ruleSetSelectState: types.maybe(types.reference(RuleSetSelectState)),
  createRuleSetState:types.maybe(types.late(() => CreateRuleSetState)),

  isMadeDefault:types.optional(types.boolean, false),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),


  noRuleOpen:types.optional(types.boolean, false),
  customOpen:types.optional(types.boolean, true),
  isView: types.optional(types.boolean, false),
})
  .actions(self => ({
    handleKeepRuleSet(){

    },
    handleIsDefault(){
      self.isMadeDefault = !self.isMadeDefault
      console.log(self)
    },
    setNoneOpen(){
      self.noRuleOpen = true
      self.customOpen = false
    },
    setCustomOpen(){
      self.noRuleOpen = false
      self.customOpen = true
    },

    addToCode(){

    },

  })).views((self) => ({}))
// toastr.error(`Failure`, `${self.errorMessage}`)

