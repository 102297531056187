import { flow, getEnv, types } from "mobx-state-tree";
import { get } from "../../services/httpService";
import { BatchItem } from "./BatchItem";

const url = `/batches/`;


//TODO: Items --> Stores
export const BatchItemList = types
.model("BatchItemList", {
  batchUuid: types.optional(types.string, ""),
  items: types.optional(types.array(BatchItem), []), //types.optional(types.array(Tag), []),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
})
.actions((self) => ({
  getItems() {
    return self.items
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection
  },
  setSortField(sortField){
    self.sortField = sortField
  },
  fetchAll: flow(function* fetchAll(sortParams = {}) {
    self.items = [];
    self.isLoading = true;
    //const batchUuid = "d6a745cc1f634e2e9d66e8aa149fcfa8";
    if (Object.keys(sortParams).length === 0) {
      sortParams = {
        sort: 'updatedAt',
        order: self.sortOrder
      };
    }
    try {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams
      };
      // ... yield can be used in async/await style
      //const response = yield get(url, queryParams)
      const manager = getEnv(self).batchManager
      const response = yield manager.fetchBatchItems(self.batchUuid, queryParams);
      if (response) {
        self.items = response.results;
        self.totalRows = response._meta.itemCount;
      }

      self.isLoading = false;
    } catch (error) {
      // ... including try/catch error handling
      console.error("Failed to fetch batch items", error);
    }
  }),
  updatePage: function(page) {
    self.page = page;
  },
  updatePerPage: function(perPage) {
    self.perPage = perPage;
  },
  updateBatchUuid: function(uuid) {
    self.batchUuid = uuid;
  },
  // add: async function(data) {
  //   try {
  //     await getEnv(self).httpClient.post(url, data);
  //   } catch (e) {
  //     console.error("Failed to create tag", e);
  //   }
  // },
  // get: async function(uuid) {
  //   try {
  //     return await getEnv(self).httpClient.get(url + "/" + uuid);
  //   } catch (e) {
  //     console.error("Failed to get tag", e);
  //   }
  // },
  // put: async function(uuid, data) {
  //   try {
  //     return await getEnv(self).httpClient.put(url + "/" + uuid, data);
  //   } catch (e) {
  //     console.error("Failed to edit tag", e);
  //   }
  // },
  // remove: async function(uuid) {
  //   return await getEnv(self).httpClient.destroy(url + "/" + uuid);
  // }
}));
