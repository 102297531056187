import {CardImg, Col, Container, Row} from "reactstrap";
import * as Icon from "react-feather";
import scnqr from "../../../../assets/img/alt/scnqr.png";
import LayoutBox from "./LayoutBox";
import React from "react";

const AppleFooter = ({barcodeType, mobileWalletCode}) => {
  const showBarcode = barcodeType && barcodeType !== "None";

  return (
    <Container>
      <Row
        className="mt-2"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: "nowrap"
        }}
      >
        <Col
          style={{
            textAlign: "left"
          }}
        >
          <Icon.Share size={14}/>
        </Col>
        {showBarcode ?
          <CardImg
            style={{
              width: '160px',
              height: '160px'
            }}
            className="rounded"
            src={scnqr}
            alt="QR Image"
            id="mobilePassQrCode"
          /> :
          (!mobileWalletCode) && <LayoutBox height="90px" alt="Barcode"/>
        }
        <Col
          style={{
            textAlign: "right"
          }}
        >
          <Icon.Info size={14}/>
        </Col>
      </Row>
    </Container>
  );
};

export default AppleFooter;
