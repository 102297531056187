import { toastr } from "react-redux-toastr";

export default class Notification {
  constructor(type, message) {
    this.type = type || "info";
    this.message = message || "";
    this.title = "";
    this.options = {};
  }
  setType(type) {
    this.type = type;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  setMessage(message) {
    this.message = message;
    return this;
  }

  setOptions(options) {
    this.options = options;
    return this;
  }

  send() {
    toastr.clean();
    if (this.type === "success") {
      this.title = "Success";
    } else if (this.type === "error" && !this.title) {
      this.title = "Failure";
    }
    if (!["info", "warning", "error", "success"].includes(this.type) || (!this.title && !this.message)) {
      // Invalid toastr
      return;
    }
    toastr[this.type](this.title, this.message, this.options);
  }
}
