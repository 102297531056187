
const configurationFile = {
  production: {
    AUTH0_DOMAIN: "auth.scanbuy.com",
    AUTH0_CLIENT_ID: "jVTIciJATIp0xBTOiMfMRm8W3GJFcfyX",
    AUTH0_AUD: "https://scanbuyportal.auth0.com/api/v2/",
    AUTH0_CALLBACK: "https://cmp.scanbuy.com",
    DOMAIN_NAME: "https://cmp.scanbuy.com",
    API_DOMAIN_NAME: "https://api.scanbuy.com/v2",
    SUPERSET_API_DOMAIN: "https://analytics.scanbuy.com",
    IMAGE_API_DOMAIN_NAME: "https://api.scanbuy.com/media",
    X_API_KEY: "hboKwoEiLY3Ib00T18uUk610hHizenDH5bxpCFmf",
    X_API_KEY_IMAGE: "1AU7oLt0DObOvQ07NAcP4UbeXOa9V0x3EL0jbgR4",
    META_FILE: "./meta.json",
    LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
    PORTAL_URL: "https://portal.scanbuy.com/",
    LOGOUT_URL: "https://auth.scanbuy.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=jVTIciJATIp0xBTOiMfMRm8W3GJFcfyX",
    V1_COMPACT_QR_API_URL: "https://compact.scanlife.com"
  },
  staging: {
    // AUTH0_DOMAIN: "scanbuyportal-stg.auth0.com",
    // AUTH0_CLIENT_ID: "j6X1n2FCHGIKOHy55PC0FOcdiqYSyyWA",
    // AUTH0_AUD: "https://scanbuyportal-stg.auth0.com/api/v2/",
    // AUTH0_CALLBACK: "https://stgportal.scanbuy.com/pages",
    // DOMAIN_NAME: "https://stgportal.scanbuy.com/",
    // API_DOMAIN_NAME: "https://stgportal.scanbuy.com/api/menu",
    // IMAGE_API_DOMAIN_NAME: "https://stgapi.scanbuy.com/media",
    // X_API_KEY: "9DU9BNIu0y7ejuSnL4mML2M5551sNqkZ1qLuRq6d",
    // X_API_KEY_IMAGE: "PNXptlNFnN8nttEzpsRyR3IJ63wuhHbr9eCy8ltH",
    // META_FILE: "/pages/meta.json",
    // LOGROCKET_APP_ID: 'oj3ojm/scanbuy'
  },
  test: {
    // AUTH0_DOMAIN: "scanbuyportal-test.auth0.com",
    // AUTH0_CLIENT_ID: "XqpSwuCi7ktQzQgVv1Es2xliIDrweRx3",
    // AUTH0_AUD: "https://scanbuyportal-test.auth0.com/api/v2/",
    // AUTH0_CALLBACK: "https://testportal.scanbuy.com/pages",
    // DOMAIN_NAME: "https://testportal.scanbuy.com/menus",
    // API_DOMAIN_NAME: "https://testapi.scanbuy.com/pages",
    // IMAGE_API_DOMAIN_NAME: "http://10.0.0.245:3100",
    // X_API_KEY: "",
    // META_FILE: "/pages/meta.json",
    //LOGROCKET_APP_ID: 'oj3ojm/scanbuy'
  },
  development: {
    AUTH0_DOMAIN: "scanbuyportal-dev.auth0.com",
    AUTH0_CLIENT_ID: "rxUVC1iB5FULP4ihy6cbhOkqiLIlEv2Y",
    AUTH0_AUD: "https://scanbuyportal-dev.auth0.com/api/v2/",
    AUTH0_CALLBACK: "https://devcmp.scanbuy.com",
    DOMAIN_NAME: "https://devcmp.scanbuy.com",
    API_DOMAIN_NAME: "https://devapi2.scanbuy.com/v2",
    SUPERSET_API_DOMAIN: "https://analytics.scanbuy.com",
    IMAGE_API_DOMAIN_NAME: "https://devapi.scanbuy.com/media",
    X_API_KEY: "tWAh433UMB6gFVSfKthEc6B9K66vMKNQ1a4MeMkN",
    X_API_KEY_IMAGE: "wYgPJ31v9x3wvYxRj4MeR12CkGm8nvZh9bvggLrL",
    META_FILE: "./meta.json",
    LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
    PORTAL_URL: "https://devportal.scanbuy.com/",
    LOGOUT_URL: "https://scanbuyportal-dev.auth0.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=rxUVC1iB5FULP4ihy6cbhOkqiLIlEv2Y",
    V1_COMPACT_QR_API_URL: "https://compact.scanlife.com"
  },
  local: {
    AUTH0_DOMAIN: "scanbuyportal-local.auth0.com",
    AUTH0_CLIENT_ID: "HvWEtZk7vK4je07CswbgMsaB8kdUbVqT",
    AUTH0_AUD: "https://scanbuyportal-local.auth0.com/api/v2/",
    AUTH0_CALLBACK: "http://localhost:3000",
    DOMAIN_NAME: "http://127.0.0.1:3000",
    API_DOMAIN_NAME: "http://localhost:8881",
    SUPERSET_API_DOMAIN: "http://localhost:8088",
    IMAGE_API_DOMAIN_NAME: "http://localhost:4003/local",
    X_API_KEY: "tWAh433UMB6gFVSfKthEc6B9K66vMKNQ1a4MeMkN",
    X_API_KEY_IMAGE: "123",
    META_FILE: "./meta.json",
    LOGROCKET_APP_ID: 'oj3ojm/scanbuy',
    PORTAL_URL: "https://scanbuy.com/",
    LOCAL_TENANT: "qxYsuUjc45uoiy2EMJp8Bq", //DO NOT SET THIS FOR DEV/STG/PRODUCTION // Comment this line for testing x-sb-context unauth redirect
    LOGOUT_URL: "https://scanbuyportal-local.auth0.com/v2/logout?federated&returnTo=https%3A%2F%2Fscanbuy.com&client_id=HvWEtZk7vK4je07CswbgMsaB8kdUbVqT",
    V1_COMPACT_QR_API_URL: "https://compact.scanlife.com"
  }
};
const CURRENT_ENV = process.env["REACT_APP_ENV"] || "local";

export default configurationFile[CURRENT_ENV];

