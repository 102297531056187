import {getType, types} from "mobx-state-tree";
import {PageTemplateStore} from "../../../stores/domain/PageTemplateStore";
import PageTemplateModel from "../../../models/pageTemplate";


export const PageTemplateSelectState = types.model('PageTemplateSelectState',{
  selectStateUuid: types.identifier,
  configStore: types.maybe(types.late(() => types.reference(PageTemplateStore))),
  selectedStyles: types.optional(types.string,'{}'),
  selectedConfig: types.maybeNull(types.safeReference(PageTemplateModel)),
}).volatile(self => ({
  notifySelect: types.f,
})).actions((self)=>({
  searchConfig(txt){
    //search for config by typing into input
  },
  setConsumer(f){
    self.notifySelect = f
  },
  selectConfig(c) {
    self.selectedConfig = c
    self.notifySelect(c)
  },
  setStyles(styles){
    self.selectedStyles = JSON.stringify(styles)
  },
  getStyles(){
    return JSON.parse(self.selectedStyles)
  },
  clearSelectedConfig(){
    self.selectedConfig = null
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }
})).views( self => ({
  configOptionValues() {
    //based on code experience selected filter out type
    const { pageTemplates } = self.configStore || {};
    return pageTemplates || []
  }
}))
