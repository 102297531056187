import { flow, getEnv, types } from "mobx-state-tree";
import {UserModel} from "../../models/users/userModel";
import lodash from "lodash";


const UserStore = types.model('UserStore',{
  storeUuid: types.identifier,
  users: types.optional(types.array(UserModel), []),
  //isLoading..
}).actions((self)=>({
  initWithData(items = []) {
    try {
      if(!lodash.isEmpty(items)) {
        self.users = items.reduce((acc, item) => {
          if (!lodash.isEmpty(item?.email) && !lodash.isEmpty(item?.auth0Id)) {
            acc.push(UserModel.create({
              uuid: item.uuid,
              email: item.email,
              auth0Id: item.auth0Id,
              _tenantUuid: item._tenantUuid,
            }));
          }
          // Return the accumulator for the next iteration
          return acc;
        }, []);
        self.users.sort((a, b) => a?.email?.localeCompare(b?.email))
      }
    } catch (e) {
      console.error("Failed to init users with data", e);
    }
  },
  init: flow(function* f(params = {}) {
    console.log("load userStore from API");
    const resp = yield self.getUsers(params);
    console.log(resp);
    self.users = resp.results.map(user => UserModel.create({ ...user}))
  }),
  getUsers: flow(function* getUsers(params = {}) {
    try {
      return yield getEnv(self).userManager.fetchAll(params);
    } catch (error) {
      console.error("Failed to fetch publish domains", error);
    }
  })

}))



export default UserStore;
