import HttpClient from "../../services/HttpClient";

class InitSitesManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    this.url = `/sites/initialize`
  }

  async fetchAll(queryParams = {}) {
    try {
      return await this.httpClient.get(this.url, queryParams);
    } catch (e) {
      console.log("Error fetching init data");
    }
    return null;
  }

}


export default InitSitesManager;
