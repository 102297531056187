import {types} from "mobx-state-tree";
import Group from "../models/Group";

const Prefix = types.model('PrefixModel',{
  // groupOptions: types.array(Group),
  uuid: types.identifier,
  prefix: types.string,
  label: types.maybe(types.string),
  domainAttachedTo: types.string,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string)
  // groupSelected: types.maybe(types.safeReference(Group)),
}).actions(self => ({
  selectGroup(obj) {
    self.groupSelected = obj.uuid;
  },
}))

export default Prefix;
