import React from "react";
import { observer } from "mobx-react-lite";
import {Card, CardBody, CardImg, CardTitle, Col, Container, Row} from "reactstrap";
import * as Icon from "react-feather";
import scnqr from "../../../../assets/img/alt/scnqr.png";
import TextField from "../components/TextField";
import LayoutBox from "../components/LayoutBox";

const CouponPass = observer(({store}) => {
  let {
    data,
    minHeight,
    maxWidth,
    backgroundColor,
    foregroundColor,
    labelColor,
    footer
  } = store;

  backgroundColor = backgroundColor || data?.backgroundColor || "#FFFFFF";
  foregroundColor = foregroundColor || data?.foregroundColor || "#000000";
  labelColor = labelColor || data?.labelColor || "#000000";

  const AuxiliaryRow = () => {
    return (
      <>
        <Col
          className="pl-0"
        >
          <TextField
            style={{
              textAlign: "left"
            }}
            label={data?.shortDescriptionOne}
            labelStyle={{
              color: labelColor
            }}
            value={data?.shortDescriptionTwo || ""}
            valueStyle={{
              color: foregroundColor
            }}
          />
        </Col>
        <Col
          className="pr-0"
        >
          <TextField
            style={{
              textAlign: "left"
            }}
            label="EXPIRES"
            labelStyle={{
              color: labelColor
            }}
            value={!data?.expirationDate ? "-" : new Date(data?.expirationDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric"
            })}
            valueStyle={{
              color: foregroundColor
            }}
          />
        </Col>
      </>
    );
  };

  return (
    <Card
      style={{
        minHeight: minHeight || "400px",
        maxWidth: maxWidth || "336px",
        borderRadius: '14px',
        background: backgroundColor
      }}
    >
      <CardBody style={{
        display: "flex",
        flexDirection: "column"
      }}>
        <Row>
          <Col xs="3">
            {data?.headerLogo ?
              <img
                className="rounded-circle"
                src={data?.headerLogo}
                alt="mobilePassLogo"
                style={{ width: "35px",height: "35px"}}
                id="mobilePassLogo"
              /> :
              <LayoutBox alt="Logo"/>
            }
          </Col>
          <Col
            xs="9"
            className="pl-0"
          >
            {data?.headerTitle ?
              <h5
                className="mt-2 mb-1"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  color: foregroundColor
                }}
              >
                <strong>
                  {data?.headerTitle}
                </strong>
              </h5> :
              <LayoutBox alt="Title"/>
            }
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            {data?.title ?
              <TextField
                style={{
                  fontSize: "56px",
                  textAlign: "left",
                  width: "95%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
                value={data?.title}
                valueStyle={{
                  color: foregroundColor
                }}
              />
              :
              <LayoutBox height="90px" alt="Primary Field"/>
            }
          </Col>
        </Row>
        <br/>
        <div style={{
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <Container>
            <Row
              className="mt-2"
            >
              {(data?.shortDescriptionOne && data?.shortDescriptionTwo) || data?.expirationDate || data?.mobileWalletCode ?
                (data?.showMobileWalletCode || data?.barcodeType === "None") ?
                  <>
                    <AuxiliaryRow />
                    <Col
                      className="pr-0"
                    >
                      <TextField
                        style={{
                          textAlign: "left"
                        }}
                        label="CODE"
                        labelStyle={{
                          color: labelColor
                        }}
                        value={data?.mobileWalletCode || "-"}
                        valueStyle={{
                          color: foregroundColor
                        }}
                      />
                    </Col>
                  </>
                  :
                  <AuxiliaryRow />
                :
                <LayoutBox alt="Secondary & Auxiliary Fields"/>
              }
            </Row>
          </Container>
          {footer}
        </div>
      </CardBody>
    </Card>
  );
});

export default CouponPass;
