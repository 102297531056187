import {flow, getType, types} from "mobx-state-tree";
import PublishDomainStore from "../../../stores/domain/PublishDomainStore";
import {PublishDomain} from "../../../models/PublishDomain";
import PublishDomainManager from "src/managers/publishdomain/publishDomainManager";

export const PublishDomainSelectState = types.model('PublishDomainSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  publishDomainStore: types.maybe(types.late(() => types.reference(PublishDomainStore))),
  selectedPublishDomain: types.maybe(types.safeReference(PublishDomain)),
  selectedGroupUuid: types.maybe(types.string),
}).volatile((self)=>({
  notifySelect: types.function,
})).actions((self) => ({

  searchPublishDomain(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
  },
  setConsumer(f){
    self.notifySelect = f
  },
  selectPublishDomain: flow(function* f(e, params = {}) {
    self.selectedPublishDomain = e
    self.notifySelect(e)
    yield self.selectedPublishDomain.setPrefixes(params)
  }),
  refresh: flow(function* f(groupProxy, params = {}){
    if(groupProxy && groupProxy?.uuid){
      self.selectedGroupUuid = groupProxy?.uuid;
      const { publishDomains } = self.publishDomainStore || {};
      let publishDomainsResponse = publishDomains.filter(item => item?.owner === self.selectedGroupUuid || item?.shared?.groupUuids?.includes(self.selectedGroupUuid))

      publishDomainsResponse = publishDomainsResponse?.length > 0 ? publishDomainsResponse : publishDomains.filter(item => item?.isGlobal === true)

      self.selectedPublishDomain = publishDomainsResponse?.length > 0 ? publishDomainsResponse.filter(pd => pd?.isDefault === true).length > 0 ? publishDomainsResponse.filter(pd => pd?.isDefault === true)[0] : publishDomainsResponse[0] : undefined;
      //console.log("$$$$ selected publish domain", self.selectedPublishDomain)
      //self.publishDomainStore.reload({groupUuid: groupProxy?.uuid})
      if (self.selectedPublishDomain) {
        yield self.selectedPublishDomain.setPrefixes(params)
      }
    }
  }),
  initDefaults(){
    //select tenants default domain if it exists
    //const { publishDomains } = self.publishDomainStore || {};
    //self.selectedPublishDomain = publishDomains?.length > 0 ? publishDomains.filter(pd => pd?.isDefault === true).length > 0 ? publishDomains.filter(pd => pd?.isDefault === true)[0] : publishDomains[0] : undefined;
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  publishDomainOptionValues() {
    const { publishDomains } = self.publishDomainStore || {};
    let publishDomainsResponse = [];
    if(self.selectedGroupUuid) { //if selected group has domains or shared domains, show those first
      publishDomainsResponse = publishDomains.filter(item => item?.owner === self.selectedGroupUuid || item?.shared?.groupUuids?.includes(self.selectedGroupUuid))
    }

    //if no group/tenant shared domains then show global domains
    publishDomainsResponse = publishDomainsResponse?.length > 0 ? publishDomainsResponse : publishDomains.filter(item => item?.isGlobal === true)
    return publishDomainsResponse.slice().sort((a,b) => a?.label?.localeCompare(b?.label))
  },
  getFirstPublishedDomain(){
    return self.selectedPublishDomain;
  },
  prefixOptionValues() {
    const { prefixes } = self.selectedPublishDomain || [];
    return prefixes.length > 0 ? prefixes : [];
  },
  getDomainFirstPrefix() {
    return self.selectedPublishDomain?.selectedPrefix || null;
  }
}))

