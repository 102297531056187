import {types, flow, getEnv, onSnapshot, getType} from "mobx-state-tree";
import Group from "../../../models/Group";
import CodeType from "../../../models/CodeType";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {CodeTypeSelectState} from "../../elements/codeTypes/CodeTypeSelectState";
import {PublishDomainSelectState} from "../../elements/publishDomain/PublishDomainSelectState";
import {ModalState} from "../../elements/modal/GenericModal";
import {StatusState} from "../../configs/status/StatusState";
import {PageTemplateSelectState} from "../../elements/pageTemplate/PageTemplateSelectState";
import {UtmModalState} from "../../utms/modal/UtmModalState";
import {RuleSetModalState} from "../../configs/rulesets/modal/RuleSetModalState";
import Ajv from 'ajv';
import addFormats from "ajv-formats"
import {Utm} from "../../../models/utms/UtmModel";
import {DesignerModalState} from "../../qr/modal/DesignerModalState";

import {DimensionSelectViewState} from "../../elements/dimensions/dimensionSelectViewState";
import lodash from 'lodash'
import {codeBehavior, codeTypes, Roles} from "../../../utils/constants";
import Notification from "../../../utils/Notification";
import {addHttpsIfNeeded, urlValidator} from "../../../utils/common";
import {ParameterSetModalState} from "../../configs/parametersets/modal/ParameterSetModalState";
import Parameters from "../../../models/Parameters";
import {walletExperienceDataBuilder} from "../../elements/walletPass/utils";
import {ExtendedDataSettingModalState} from "../../extendedData/modal/ExtendedDataSettingModalState";
import {updateInfoAfterSelectGroup} from "../../../utils/group";

const defaultSchemaJson = {
  "type": "object",
  "properties": {
    "url": {
      "title": "Landing Url:",
      "type": "string",
      "validationMessage": {
        "default": "Url must be of proper format: http(s)://...",
        "302": "Url is required"
      }
    }
  },
  "required": ["url"]
};

const CreateCode = types.model('CreateCode', {
  isLoading: types.optional(types.boolean, false),
  name: types.optional(types.string, ""),
  keyword: types.optional(types.string, ""),

  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  dimensionSelectViewState: types.maybe(types.late(() => types.reference(DimensionSelectViewState))),
  codeTypeSelectStore: types.maybe(types.late(() => types.reference(CodeTypeSelectState))),
  publishDomainSelectStore: types.maybe(types.late(() => types.reference(PublishDomainSelectState))),
  pageTemplateSelectStore: types.maybe(types.late(() => types.reference(PageTemplateSelectState))),
  // vEventSelectStore: types.maybe(types.late(() => types.reference(VEventSelectState))),
  selectedGroup: types.maybe(types.reference(Group)),
  experienceSelected: types.maybe(types.reference(CodeType)),
  isExperienceDynamic: types.optional(types.boolean, true),
  behavior: types.optional(types.string, ''),

  //qrUrl: types.optional(types.safeReference(QrUrl))    // domain | prefix | short  [placeholder for the final QRUrl, or the actual one]
  isAutoGeneratedUrl: types.optional(types.boolean, true),
  keywordStatus: types.maybe(types.boolean), // true - success, false - failure, undefined - warning
  selectedDimensions: types.optional(types.string, '{}'),
  selectedTags: types.optional(types.array(types.string), []),
  //types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  experienceData: types.optional(types.string, '{}'),
  extendedDataSettingModalState: types.maybe(types.late(() => ExtendedDataSettingModalState)),
  vCardDownLoadLinkData: types.optional(types.string, ''),
  experienceUISchema: types.optional(types.string, '{}'),

  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  selectedDefaultUrl: types.optional(types.string, ''),
  inSubmission: types.optional(types.boolean, false),
  liveValidateSchema: types.optional(types.boolean, false),

  experienceReadonly: types.optional(types.boolean, false),
  qrUrlReadonly: types.optional(types.boolean, false),

  modalStore: types.maybe(ModalState),
  statusViewState: types.maybe(types.late(() => StatusState)),
  utmModalState: types.maybe(types.late(() => UtmModalState)),
  utm: types.maybe(types.late(() => Utm)),
  ruleSetModalState: types.maybe(types.late(() => RuleSetModalState)),
  rules: types.optional(types.string, "[]"),
  designerModalState: types.maybe(types.late(() => DesignerModalState)),
  parameterSetModalState: types.maybe(types.late(() => ParameterSetModalState)),
  parameters: types.maybe(Parameters),
  imageUuid: types.optional(types.string, ""),
  previousPlatform: types.optional(types.string, ""),
  status: types.optional(types.string, "PUBLISHED"),

}).volatile((self) => ({
  modalComponent: types.object,
  modalComponentStore: types.object,
  modalSize: types.str,
  modalClassName: types.str,
  urlListener: types.fn,
  vCardDownloadLink: types.str,
  extendedDataUpdateDisposers: [],
})).actions(self => ({
  handleNameChange(e) {
    self.name = e.target.value
  },
  handleKeywordChange(e) {
    self.keyword = e.target.value;
    self.debounceSetKeywordStatus();
  },
  setKeyword(keyword) {
    self.keyword = keyword;
    self.setKeywordStatus(self.keyword);
  },
  setStatus(status) {
    self.status = status
  },
  handleImageUuidChange(uuid) {
    self.imageUuid = uuid
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean
  },
  handleErrorMessage(message) {
    self.errorMessage = message
  },
  setShortUrlListener(listener) {
    self.urlListener = listener
  },
  notifyShortUrl(short) {
    self.urlListener(short)
  },
  handleConfigModalPopUp(component, title, componentStore, size = "", className = "") {
    self.modalComponentStore = self[componentStore];
    self.modalComponent = component;
    self.modalSize = size;
    self.modalClassName = className;
    self.modalStore.toggle()
    self.modalStore.handleTitle(title)
  },
  setOnSelectChanges() {
    self.groupSelectStore?.setConsumer(self.selectGroup)
    self.tagSelectStore?.setConsumer(self.selectTag)
    self.codeTypeSelectStore?.setConsumer(self.selectExperience)
    self.publishDomainSelectStore?.setConsumer(self.selectPublishDomain)
    self.designerModalState?.setConsumer(self.handleImageUuidChange)
    self.dimensionSelectViewState?.setConsumer(self.selectDimension)
    self.publishDomainSelectStore?.selectedPublishDomain?.setConsumer(self.selectPrefix);
    self?.extendedDataSettingModalState?.setConsumer(self.selectExtendedDataSelection);
  },
  setFilterOnExtendedDataStore(groupUUID) {
    self.extendedDataSettingModalState?.extendedDataSetSelectStore?.store?.setFilterOn((item) => {
      return (item.owner.groupUuid === groupUUID || item?.isExclusivelyGroupOwned === false);
    });

    self.extendedDataSettingModalState?.extendedDataSelectStore?.store?.setFilterOn((item) => {
      return (item.owner.groupUuid === groupUUID || item?.isExclusivelyGroupOwned === false);
    });
  },
  selectExtendedDataSelection(d) {
    const selectedExtendedData = self.extendedDataSettingModalState?.getSelectedExtendedDataMap() || {};
    self.extendedDataList = Object.values(selectedExtendedData);
  },
  setDefaultStatusBasedOnRoles(userRoles) {
    if (userRoles.includes(Roles.ALL) || userRoles.includes(Roles.PUBLISH)|| userRoles.includes(Roles.BATCH)) {
      self.status = "PUBLISHED";
    } else {
      self.status = "DRAFT";
    }
  },
  selectDimension(obj, parentUuid) {
    let existingDimension = JSON.parse(self?.selectedDimensions)
    existingDimension[parentUuid] = obj?.uuid
    existingDimension = lodash.pickBy(existingDimension, value => !(lodash.isEmpty(value))); // to delete empty  object
    self.selectedDimensions = JSON.stringify(existingDimension)
  },
  selectPrefix(prefix) {
    if (self.keyword) {
      self.setKeywordStatus(self.keyword);
    }
  },
  debounceSetKeywordStatus: lodash.debounce(() => {
    self.setKeywordStatus(self.keyword);
  }, 600),
  setKeywordStatus: flow(function* (keyword) {
    self.isLoading = true;
    if (keyword && keyword.length >= 4) {
      const prefix = self?.publishDomainSelectStore?.selectedPublishDomain?.selectedPrefix?.prefix || "";
      if (!self.keywordIsValid(prefix, keyword)) {
        self.keywordStatus = false;
      } else {
        self.keywordStatus = yield self.isCodeURLUnique();
      }
    } else {
      self.keywordStatus = undefined;
    }
    self.handleHasErrors(!self.keywordStatus);
    self.isLoading = false;
  }),
  keywordIsValid(prefix, keyword) {
    // any lowercase/uppercase letter, digit, or hyphen
    const keywordIsURL = keyword && /^[a-zA-Z0-9\-]+$/i.test(keyword);
    return keywordIsURL;
  },
  isCodeURLUnique: flow(function* () {
    if (!self?.publishDomainSelectStore?.selectedPublishDomain?.domain || !self.keyword) {
      return false;
    }
    self.isLoading = true;
    const uri = self.keyword;

    const available = yield getEnv(self).codeManager.isURLAvailable({
      "domain": self?.publishDomainSelectStore?.selectedPublishDomain?.domain,
      "prefix": self?.publishDomainSelectStore?.selectedPublishDomain?.selectedPrefix?.prefix || "",
      uri
    });
    self.handleHasErrors(!available);
    self.isLoading = false;
    return available;
  }),
  registerUpdatesForExtendedData() {
    // IMP NOTE: don't register any state that will re-trigger onSnapshot
    const storesToWatch = [
      "codeTypeSelectStore",
      "publishDomainSelectStore",
      "tagSelectStore",
      "groupSelectStore",
      "dimensionSelectViewState",
    ];

    storesToWatch.forEach(storeName => {
      if (self[storeName]) {
        self.extendedDataUpdateDisposers.push(onSnapshot(self[storeName], async snapshot => {
          await self?.hydrateExtendedDataBasedOnState();
        }));
      }
    });

  },
  unRegisterUpdatesForExtendedData() {
    if (self.extendedDataUpdateDisposers.length) {
      self.extendedDataUpdateDisposers.forEach(disposer => disposer());
      self.extendedDataUpdateDisposers = [];
    }
  },
  hydrateExtendedDataBasedOnState: flow(function* () {
    try {
      let dimensions = ["*"];
      if (self?.dimensionSelectViewState?.dimensionsEnabled) {
        self?.dimensionSelectViewState?.dimensionSelectStates?.forEach(dimensionSelectStore => {
          if (dimensionSelectStore.dimension?.uuid && dimensionSelectStore.selectedDimension?.uuid) {
            dimensions.push(`dimensions.${dimensionSelectStore.dimension.uuid}_${dimensionSelectStore.selectedDimension.uuid}`);
          }
        });
      }

      const tagUuids = self?.tagSelectStore?.selectedTags.map(t => `tags.${t.uuid}`);

      let filters = [];
      self?.experienceSelected?.name && filters.push(`type.${self?.experienceSelected?.name}`);
      self.status && filters.push(`status.${self.status}`);
      self?.publishDomainSelectStore?.selectedPublishDomain && filters.push(`domain.${self?.publishDomainSelectStore?.selectedPublishDomain?.uuid}`);
      self.behavior && filters.push(`behavior.${self.behavior}`);
      // todo: should we add filter for 'createdBy'?

      const visibilityConditions = {
        groups: `*,groupUuid.${self?.groupSelectStore?.selectedGroup.uuid}`,
        dimensions: dimensions.join(",")
      };

      self?.extendedDataSettingModalState?.setConsumer(self.selectExtendedDataSelection);
      yield self?.extendedDataSettingModalState?.getDefaultExtendedDataAndHydrate(visibilityConditions);
      self?.setFilterOnExtendedDataStore(self?.groupSelectStore?.selectedGroup.uuid);
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to hydrateExtendedDataBasedOnState`, e);
    }
  }),
  getDimensions() {
    if (lodash.isEmpty(JSON.parse(self.selectedDimensions))) return undefined
    return JSON.parse(self.selectedDimensions)
  },
  validateDimension() {
    if (!self.dimensionSelectViewState?.dimensionsEnabled) return true
    let dimensions = self.getSelectedDimensions()
    const dimensionUuids = self.dimensionSelectViewState?.dimensionSelectStates.map(selectState => selectState?.selectStateUuid)
    dimensionUuids.forEach(uuid => {
      if (!dimensions.hasOwnProperty(uuid)) {
        self.dimensionSelectViewState?.dimensionSelectStates?.find(selectState => selectState?.selectStateUuid == uuid).setDefaults()
      }
    })
    dimensions = self.getSelectedDimensions()
    return dimensionUuids.every(uuid => dimensions.hasOwnProperty(uuid));
  },
  getSelectedDimensions() {
    return JSON.parse(self.selectedDimensions)
  },
  selectGroup(obj) {
    updateInfoAfterSelectGroup(self, obj)
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag) => tag.uuid)
    // const root = getRoot(self)
    // console.log(obj.flatMap( (row)=> [resolveIdentifier(TagModel, root, row.value.uuid)]))
    // self.selectedTags = obj
    // .flatMap( (row)=> [resolveIdentifier(TagModel, root, row.value.uuid)])
  },
  selectExperience(obj,behavior=null) {
    //TODO: check if its changed, and form-data exists, to alert the User if they want to change
    self.experienceSelected = obj;
    if (obj.behavior?.length === 1 && obj.behavior.includes(codeBehavior.STATIC)){
        self.behavior = codeBehavior.STATIC
        self.isExperienceDynamic = false
      }else {
      self.behavior = codeBehavior.DYNAMIC
      self.isExperienceDynamic = true
    }
    if (behavior) { // if behavior is passed. An example is when cloning code
        self.behavior = behavior
        self.isExperienceDynamic = behavior === codeBehavior.DYNAMIC
      }
  },
  setVCardFetchDownloadLink(fn) {
    self.vCardDownloadLink = fn
  },
  selectPublishDomain(obj) {
    self.selectedDefaultUrl = obj.name
    if (self.keyword) {
      self.setKeywordStatus(self.keyword);
    }
  },
  selectPageTemplate(obj) {
    self.selectedPage = obj
  },
  searchGroup(txt) {
    console.log(txt)
  },
  updateExperienceData(data) {
    if (self.isCodeTypeSocialMedia()) {
      self.updateFormData(data);
    }
    if (data.hasOwnProperty("enableWalletPass") && data?.enableWalletPass) {
      data = {
        ...data,
        ...walletExperienceDataBuilder(self.experienceSelected?.uuid, data),
      };
    }
    if (data.hasOwnProperty("passType")) {
      // For Mobile Wallet Pass experience
      ["eventTicket", "coupon", "storeCard", "generic"].forEach((t) => {
        if (data.hasOwnProperty(t) && data[t]) {
          data[t] = {
            ...data[t],
            "showMobileWalletCode": data[t].barcodeType === "None" || data[t].showMobileWalletCode
          };
        }
      });
    }
    self.experienceData = JSON.stringify(data)
    console.log(self.toJSON())
  },
  addVCardDownLoadLinkData(data) {
    self.vCardDownLoadLinkData = JSON.stringify(data)
  },
  updateExperienceUISchema(uiSchema="{}") {
    self.experienceUISchema = uiSchema;
  },
  updateExperienceUISchemaOnData(data) {
    let uiSchema = JSON.parse(self.experienceUISchema);
    if (data.hasOwnProperty("passType")) {
      // For Mobile Wallet Pass experience
      ["eventTicket", "coupon", "storeCard", "generic"].forEach((t) => {
        if (uiSchema.hasOwnProperty(t) && data[t]) {
          uiSchema[t] = {
            ...uiSchema[t],
            "showMobileWalletCode": {
              "ui:readonly": data[t].barcodeType === "None"
            }
          };
        }
      });
    }
    self.updateExperienceUISchema(JSON.stringify(uiSchema));
  },
  updatePreviousPlatform(platform) {
    self.previousPlatform = platform
  },
  updateFormData(data) {
    const platformDefaults = {
      "Facebook": ["Follow", "Share"],
      "Instagram": "Follow",
      "YouTube": ["Watch", "Subscribe"],
      "LinkedIn": "Connect",
      "Pinterest": "Follow",
      "Reddit": ["Follow", "Join"],
      "Snapchat": "Follow",
      "Telegram": "Follow",
      "TikTok": ["Follow", "Share"],
      "Tumblr": "Follow",
      "X (Twitter)": ["Follow", "Tweet"],
      "Line": "Add"
    };

    if (data.platform !== self.previousPlatform) {
      self.updatePreviousPlatform(data.platform);
      if (platformDefaults.hasOwnProperty(data.platform)) {
        data.username = "";
      }
    }

    if (Array.isArray(platformDefaults[data.platform])) {
      if (platformDefaults[data.platform].includes(data.action)) {
        data.action = data.action;
      } else {
        data.action = platformDefaults[data.platform][0];
      }
    } else {
      if (data.action === platformDefaults[data.platform]) {
        data.action = data.action;
      } else {
        data.action = platformDefaults[data.platform];
      }
    }
  },
  toggleTrue(str) {
    self[str] = true
  },
  toggleIsExperienceDynamicBoolean(){
    self.isExperienceDynamic = !self.isExperienceDynamic
  },
  toggleIsExperienceDynamic() {
    self.toggleIsExperienceDynamicBoolean()
    if (self.experienceSelected?.behavior?.length > 1) {
      if (self.isExperienceDynamic) {
        self.behavior = codeBehavior.DYNAMIC
      } else {
        self.behavior = codeBehavior.STATIC
      }
    } else {
      if (self.experienceSelected?.behavior[0] === codeBehavior.DYNAMIC) {
        new Notification()
            .setType("info")
            .setTitle("Dynamic Code")
            .setMessage("Only DYNAMIC code is available for this experience.")
            .send();
      }
      if (self.experienceSelected?.behavior[0] === codeBehavior.STATIC) {
        new Notification()
            .setType("info")
            .setTitle("Static Code")
            .setMessage("Only STATIC code is available for this experience.")
            .send();
      }
      setTimeout(() => {
        self.toggleIsExperienceDynamicBoolean()
      }, "500")
    }
  },



    // } else {
    //   self.isExperienceDynamic = !self.isExperienceDynamic
    //   new Notification()
    //     .setType("info")
    //     .setTitle("Authorization")
    //     .setMessage("Only dynamic code is available to you")
    //     .send();
    //   setTimeout(() => {
    //     self.toggleTrue("isExperienceDynamic")
    //   }, "500")
    // }

  toggleIsAutoGeneratedUrl(configs) {
    if (configs.qr_auto_generation) {
      self.isAutoGeneratedUrl = !self.isAutoGeneratedUrl
    } else {
      self.isAutoGeneratedUrl = !self.isAutoGeneratedUrl
      new Notification()
        .setType("info")
        .setTitle("Authorization")
        .setMessage("Only auto generation is available to you")
        .send();
      setTimeout(() => {
        self.toggleTrue("isAutoGeneratedUrl")
      }, "500")
    }
    self.setKeyword("");
    self.handleHasErrors(false);
  },

  transformErrors(errors) {
    return errors.map(error => {
      if (error.name === "pattern") {
        switch (error.property) {
          case ".text": error.message = "Invalid SMS message body"
            break;
          case ".url": error.message = "Must be a valid url"
            break;
        }
      }
      return error;
    });
  },
  validateSchema(schema, formData) {
    const ajv = new Ajv({allErrors: true, validateFormats: false})
    addFormats(ajv);
    const validate = ajv.compile(schema)
    let valid = validate(formData)
    if (!valid) {
      self.liveValidateSchema = true
    }
    return valid
  },
  validateForm() {
    const isExperienceFormValid = self.validateSchema(JSON.parse(self.experienceSelected.schema), JSON.parse(self.experienceData));
    if (self?.getExtendedDataList()?.length) {
      const isExtenedFormValid = self.extendedDataSettingModalState?.validateForm();
      return isExperienceFormValid && isExtenedFormValid;
    }
    return isExperienceFormValid;
  },
  saveNew: flow(function* f() {
    // TODO: validate Input
    if (!self.validateDimension()) {
      // TODO: Make this generic for all validations
      new Notification()
        .setType("error")
        .setMessage("select atleast one dimensions each")
        .send(); // TODO: make this localised String
      self.isLoading = false
      return
    }
    if(self.experienceSelected.name==="appLink"){
      const experienceDataObj = JSON.parse(self.experienceData);
      experienceDataObj.fallback = addHttpsIfNeeded(experienceDataObj.fallback )
      experienceDataObj.playStore =addHttpsIfNeeded(experienceDataObj.playStore)
      experienceDataObj.appStore = addHttpsIfNeeded(experienceDataObj.appStore)
      try {
        urlValidator(experienceDataObj.fallback);
        urlValidator(experienceDataObj.playStore);
        urlValidator(experienceDataObj.appStore);
        self.experienceData = JSON.stringify(experienceDataObj);
      } catch (error) {
        new Notification()
          .setType("error")
          .setMessage(error.message)
          .send();
        self.isLoading = false;
        return;
      }
    }
    if(self.experienceSelected.name==="url" || self.experienceSelected.name==="socialMedia") {
      const experienceDataObj = JSON.parse(self.experienceData);
      const url = experienceDataObj.url;
      if (url) {
        experienceDataObj.url = addHttpsIfNeeded(url);
        self.experienceData = JSON.stringify(experienceDataObj);
        try {
          urlValidator(experienceDataObj.url);
        } catch (error) {
          new Notification()
            .setType("error")
            .setMessage(error.message)
            .send();
          self.isLoading = false;
          return;
        }
      }
    }
    const validSchema = self.validateForm()
    if (validSchema) {
      self.isLoading = true
      const manager = getEnv(self).codeManager
      const resp = yield manager.save(self);
      self.formHasErrors && new Notification()
        .setType("error")
        .setMessage(`${self.name} Code failed creating`)
        .send();
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.name} Code created`)
        .send()
      && self.notifyShortUrl(resp.data.shortUrl.domain + "/" + resp.data.shortUrl.uri)
      self.handleHasErrors(false)
      self.isLoading = false
      return resp
    } else {
      return null
    }
    self.isLoading = true
    const manager = getEnv(self).codeManager
    const resp = yield manager.save(self);
    self.formHasErrors && new Notification()
      .setType("error")
      .setMessage(`${self.name} Code failed creating`)
      .send();
    !self.formHasErrors && new Notification()
      .setType("success")
      .setMessage(`${self.name} Code created`)
      .send()
    && self.notifyShortUrl(resp.data.shortUrl.domain + "/" + resp.data.shortUrl.uri)
    self.handleHasErrors(false)
    self.isLoading = false
    return resp
  }),
  addRulesToCode() {
    const rules = self.ruleSetModalState.createRuleSetState.grabRuleSetForCode()
    if (rules?.length > 0) {
      self.rules = self.ruleSetModalState.noRuleOpen ? "[]" : rules
      return self.rules
    } else {
      return null
    }
  }
  ,
  addUtmToCode() {
    if (self.utmModalState.noUtmOpen || !self.utmModalState.utm.doesUtmHaveValue()) {
      self.utm = Utm.create({uuid: "noUtm"})
    } else {
      self.utm = self.utmModalState.utm.grabUtmForCode()
    }
  },
  addParameterSetToCode() {
    if (!self.parameterSetModalState.noParameterSetOpen) {
      self.parameters.setParameters("codeParamType",self.parameterSetModalState.codeParamsTypeSelectState.selectedCodeParameters.map((param) => param.uuid))
      self.parameters.setParameters("scanParamType",self.parameterSetModalState.scanParamsTypeSelectState.selectedScanParameters.map((param) => param.uuid))
      self.parameters.setParameters("countParamType",self.parameterSetModalState.countParamsTypeSelectState.selectedCountParameters.map((param) => param.uuid))
      self.parameters.setParameters("premiumParamType",self.parameterSetModalState.premiumParamsTypeSelectState.selectedPremiumParameters.map((param) => param.uuid))
      self.parameters.setParameters("customParamType",self.parameterSetModalState.customParamsTypeSelectState.selectedCustomParameters.map((param) => param.uuid))

    } else if (self.parameterSetModalState.noParameterSetOpen || !self.parameterSetModalState.doesParamsHaveValues()) {
      self.parameters.clearParams()
    }
  },
  afterCreate() {
    //self.selectExperience(self.codeTypeSelectStore?.selected)
  }
})).views((self) => ({
  configButtonColor(bool) {
    return bool ? "secondary" : "primary"
  },
  configClassName(bool) {
    return bool ? "btn-outline-secondary mr-1 mb-1" : "btn-outline-primary mr-1 mb-1"
  },
  isCodeTypeSocialMedia() {
    return self.experienceSelected?.name === codeTypes.SocialMedia
  },
  shouldRenderAutoGenerateToggle() {
    return self.experienceSelected?.behavior?.includes(codeBehavior.DYNAMIC) && self.isExperienceDynamic
  },
  shouldRenderDomainSection() {
    return self.experienceSelected?.behavior?.includes(codeBehavior.DYNAMIC) && self.isExperienceDynamic
  },
  // advanced configs
  shouldRenderExtendedData() {
    return true; // todo: default for all?
  },
  shouldRenderUTMConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
        && self.isExperienceDynamic
  },
  shouldRenderParametersConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
        && self.isExperienceDynamic
  },
  shouldRenderRulesConfig() {
    return (self.experienceSelected.name === codeTypes.URL || self.experienceSelected.name === codeTypes.GS1)
        && self.isExperienceDynamic
  },
  shouldRenderStatusConfig() {
    return self.experienceSelected?.behavior?.includes(codeBehavior.DYNAMIC) && self.isExperienceDynamic
  },
  shouldRenderSecondTabInPreview(){
    return  self.isExperienceDynamic
  },
  experienceDataView() {
    return JSON.parse(self.experienceData)
  },
  experienceExtendedDataView() {
    return self.extendedDataSettingModalState?.formData;
  },
  experienceSchemaJson() {
    //TODO: this method is being called everytime code name or url is changed, needs refactoring
    let {schema} = self.experienceSelected || {}
    console.log("Schema is :" + schema);
    schema = schema || JSON.stringify(defaultSchemaJson);
    return JSON.parse(schema);
  },
  experienceUISchemaView() {
    let {uiSchema} = self.experienceSelected;
    self.updateExperienceUISchema(JSON.stringify({
      ...JSON.parse(uiSchema || '{}'),
      ...JSON.parse(self.experienceUISchema)
    }));
    return JSON.parse(self.experienceUISchema);
  },
  parametersHaveValue() {
    return self.parameters.codeParamType.length > 0
        || self.parameters.scanParamType.length > 0
        || self.parameters.countParamType.length > 0
        || self.parameters.premiumParamType.length > 0
        || self.parameters.customParamType.length > 0
  },
  hasExtendedData() {
    return self.getExtendedDataList()?.length && self.extendedDataSettingModalState?.showDropdown;
  },
  getExtendedDataList() {
    return self.extendedDataList?.filter(n => n);
  },
  getExtendedDataRequest() {
    return self.hasExtendedData() ? {
      "items": self?.getExtendedDataList()?.map((item) => item.uuid),
      "data": self?.experienceExtendedDataView()
    } : {};
  },
  isStatusNotDefault() {
    return (self.status != "PUBLISHED" || self.statusViewState.scheduleStatusChange);
  },
  isSaveDisabled() {
    return self.isAutoGeneratedUrl ? (self.isLoading) : (self.isLoading || !self.keywordStatus);
  }
}))

export default CreateCode;
