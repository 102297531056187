import React from "react";
import { observer } from "mobx-react-lite";
import BasicRule from "./BasicRule";


const TreeRole = observer(({ rootNode,ruleTypeStore,parentColor,readonly, disabled = false }) => {

  // Exception: Error: [mobx-state-tree] Failed to resolve reference 'rtss' to type 'ruleTypeSelectState'
  // (from node: /children/0/ruleTypeSelectState) at new InvalidReferenceError


    const color ={
      r:  255,
      g: 255,
      b:  255,
      a: 0.1
    }
    const darkenColor = () => {
        const darkerColor = {
                r: color.r - 100,
                g: color.g - 100,
                b: color.b - 100,
                a: color.a
            }
       return  rgba2hex(colorString(darkerColor))
    }
    function rgba2hex(orig) {
        let a,
            rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
            alpha = (rgb && rgb[4] || "").trim(),
            hex = rgb ?
                (rgb[1] | 1 << 8).toString(16).slice(1) +
                (rgb[2] | 1 << 8).toString(16).slice(1) +
                (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

        if (alpha !== "") {
            a = alpha;
        } else {
            a = 1;
        }
        a = ((a * 255) | 1 << 8).toString(16).slice(1)
        hex = hex + a;

        return "#"+hex;
    }
    const colorString = (color) => {
        return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    };

    return (
    <>
      {(rootNode.children).map((child,index) => {
          return (
              <div>
            <div style={{borderBottomLeftRadius: "5px",paddingTop:"11px",backgroundColor:(child.order===0 || index !==0)?darkenColor() :rgba2hex(colorString(color))}} >
              <BasicRule
                child={child}
                order={child.order}
                indent={child.indent}
                unindent={child.unindent}
                showIndent={child.showIndent}
                showUnindent={child.showUnindent}
                remove={child.remove}
                position={child.position}
                ruleTypeStore={ ruleTypeStore}
                volatileRuleTypeStore={child.volatileRuleTypeStore}
                rootNode = {child}
                readonly={readonly}
                disabled={disabled}
              />
             <TreeRole rootNode={child} parentColor={color} />

            </div>
                  {child.position.length === 3 &&
                      <>
                          <div style={{margin:"10px"} }> </div>
                          {/*<div style={{border: `1px solid #f9fafc`,marginLeft:"20px",marginRight:"20px"}}></div>*/}
                          {/*<div style={{margin:"10px"} }> </div>*/}
                      </>
                  }
              </div>
          );
        }
      )}
    </>
  );
});


export default TreeRole;
