import React, {useEffect, useState} from 'react'
import {Button, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/classic.min.css';
import {observer} from "mobx-react-lite";
import CMYKModal from "./CMYKModal";

export function cmykToHex(cmykString) {
  // Extract CMYK values from the input string
  let match = cmykString.match(/cmyk\((\d+)%?%?,\s*(\d+)%?%?,\s*(\d+)%?%?,\s*(\d+)%?%?\)/i);
  // Convert percentages to decimal values
  let c = parseInt(match[1]) / 100;
  let m = parseInt(match[2]) / 100;
  let y = parseInt(match[3]) / 100;
  let k = parseInt(match[4]) / 100;
  // Convert CMYK to RGB
  let r = 255 * (1 - c) * (1 - k);
  let g = 255 * (1 - m) * (1 - k);
  let b = 255 * (1 - y) * (1 - k);
  // Convert RGB to Hex
  let rgbToHex = function (value) {
    let hex = Math.round(value).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return '#' + rgbToHex(r) + rgbToHex(g) + rgbToHex(b);
}

const ColorButton = observer((({selectColor, colorPosition, defaultedColor, frameAndCaptionsStore, colorMode, handleColorMode, isDefaultDesigner = true,isCMYK=false}) => {


  let lastRepresentation = 'HEXA';


  if(colorMode === "cmyk" || isCMYK){
    lastRepresentation = 'CMYK';
  } else if(colorMode === "rgb"){
    lastRepresentation = 'RGBA';
  }
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState(defaultedColor);
  const [modalCMYKOpen, setModalCMYKOpen] = useState(false);
  const [cmyk, setCmyk] = useState({
    c: "0",
    m: "0",
    y: "0",
    k:  (colorPosition==="backgroundColor")?"0" :"100"
  })
  console.log("top cmyk",cmyk)
  const pickrContainerRef = React.useRef(null);
  const pickrInstanceRef = React.useRef(null);
  useEffect(() => {
    if(defaultedColor){
      if(defaultedColor.toLowerCase().includes("cmyk")){
        const cmykColor = defaultedColor.split("(")[1].split(")")[0].split(",")
        setCmyk({
          c: parseInt(cmykColor[0]),
          m: parseInt(cmykColor[1]),
          y: parseInt(cmykColor[2]),
          k: parseInt(cmykColor[3])
        })
      }
    }
    if (display) {
      if (!pickrInstanceRef.current ) {
        pickrInstanceRef.current = createPicker();
      }
    } else {
      destroyPicker();
    }
    return () => {
      destroyPicker();
    };
  }, [display]);

  const destroyPicker = () => {
    if (pickrInstanceRef.current) {
      pickrInstanceRef.current.destroyAndRemove();
      pickrInstanceRef.current = null;
    }
  };

  const createPicker = (modalCMYKColor = "") => {
    if (!pickrInstanceRef.current && pickrContainerRef.current) {
      console.log("color picker isDefaultDesigner:", isDefaultDesigner)
      if (typeof defaultedColor === "object" ){
        setCmyk({ c: defaultedColor[0], m: defaultedColor[1], y: defaultedColor[2], k: defaultedColor[3] })
      }
      let DefaultForPicker = ""
      if(isCMYK || defaultedColor){
        DefaultForPicker = (isCMYK && `cmyk(${defaultedColor[0]}%, ${defaultedColor[1]}%, ${defaultedColor[2]}%, ${defaultedColor[3]}%)` ) || defaultedColor
      }else{
        DefaultForPicker = 'rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + color.a + ')'
      }

      const pickrInstance = Pickr.create({
        el: pickrContainerRef.current,
        theme: 'classic',
        default: DefaultForPicker,
        position: "right",
        useAsButton: true,
        swatches: [
          'rgba(244, 67, 54, 1)',
          'rgba(233, 30, 99, 1)',
          'rgba(156, 39, 176, 1)',
          'rgba(103, 58, 183, 1)',
          'rgba(63, 81, 181, 1)',
          'rgba(33, 150, 243, 1)',
          'rgba(3, 169, 244, 1)',
          'rgba(0, 188, 212, 1)',
          'rgba(0, 150, 136, 1)',
          'rgba(76, 175, 80, 1)',
          'rgba(139, 195, 74, 1)',
          'rgba(205, 220, 57, 1)',
          'rgba(255, 235, 59, 1)',
          'rgba(255, 193, 7, 1)',
        ],
        components: {
          opacity: true,
          hue: true,
          interaction: {
            input: true,
            hex: isDefaultDesigner,
            rgba: true,
            cmyk: isCMYK,
            // save: true,
          },
        },
      });
      pickrContainerRef.current.pickr = pickrInstance;


      pickrInstance.on('init', instance => {
        console.log('init:', lastRepresentation);
        instance.show();


      });

      pickrInstance.on('show', (color, instance) => {
        const lastSelectedButton = document.querySelector(`.pcr-type[data-type="${lastRepresentation}"]`);
        //if (lastSelectedButton && !lastSelectedButton.classList.contains('active')) {
            lastSelectedButton.click();
        if (isCMYK){
          instance.hide();
        }
        //}
      });

      pickrInstance.on('hide', instance => {
        if (isCMYK) {
          instance.setColor(`cmyk(${cmyk.c}%, ${cmyk.m}%, ${cmyk.y}%, ${cmyk.k}%)`, true)
        }
        instance.hide();
        setDisplay(!display);
        let selectedColorMode = ""
        if(lastRepresentation === "HEXA"){
          selectedColorMode = "HEXA"
        } else if(lastRepresentation === "RGBA"){
          selectedColorMode = "rgb"
        } else if(lastRepresentation === "CMYK"){
          selectedColorMode = "cmyk"
        }
        handleColorMode(selectedColorMode)
      });

      pickrInstance.on('change', (color, source, instance) => {
        onChangeMethod(color, source, instance);
        document.querySelectorAll('.pcr-type').forEach(button => {
          button.addEventListener('click', () => {
              lastRepresentation = button.getAttribute('data-type');
          });
        });
        // currentRepresentation = instance._representation;
      });

      pickrInstance.on('save', (color, instance) => {
        instance.hide();
        setDisplay(!display);
      });

      return pickrInstance;
    }
  }


  const onClickMethod = () => {
    if(isCMYK){
      setModalCMYKOpen(true)
    }else{
    setDisplay(!display);
    }

  };
  const toggleCMYKModal   = async () => {
    if(modalCMYKOpen){
      setDisplay(!display)
    }
    setModalCMYKOpen(!modalCMYKOpen)
  }

  const onCloseMethod = () => {
    setDisplay(false);
  };

  const onChangeMethod = (color, source, instance) => {
    const rgba = color.toRGBA();
    const hex = color.toHEXA().toString();
    const cmykFromPicker = color.toCMYK()
    const colorMode = pickrInstanceRef.current?.getColorRepresentation();
    handleColorMode(colorMode?.toLowerCase())
    let newColor = {};
    newColor.rgb = {
      r: rgba[0],
      g: rgba[1],
      b: rgba[2],
      a: rgba[3]
    }
    if(isCMYK){
      newColor.cmyk = {
        ...cmyk
      }
    }else{
    newColor.cmyk = {
      c: Math.round(cmykFromPicker[0]),
      m: Math.round(cmykFromPicker[1]),
      y: Math.round(cmykFromPicker[2]),
      k: Math.round(cmykFromPicker[3]),
    }

    }
    newColor.hex = hex.toLowerCase();
    setColor({...newColor.rgb});
    setCmyk({...newColor.cmyk});
    selectColor(newColor, colorPosition, frameAndCaptionsStore,isCMYK)
  };

  const popover = {
    position: "absolute",
    zIndex: "3"
  };

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const defaultColor = {

    width: '36px',
    height: '14px',
    borderRadius: '2px',
    background: defaultedColor ?
      (defaultedColor.toLowerCase().includes("cmyk")?cmykToHex(defaultedColor):defaultedColor) :
      'rgba(' + color.r + ', ' + color.g + ', ' + color.b + ', ' + color.a + ')',
    marginLeft: '-10px',
  }

  return (
    <>
      <Button style={defaultColor} className="colorSelector" onClick={()=>{onClickMethod()}}>

      </Button>
      <div className="pickr" ref={pickrContainerRef} />

      <CMYKModal
        modalCMYKOpen={modalCMYKOpen}
        toggleCMYKModal={toggleCMYKModal}
        cmyk={cmyk}
        setCmyk={setCmyk}
        selectColor={selectColor}
        colorPosition={colorPosition}
        frameAndCaptionsStore={frameAndCaptionsStore}

      />
    </>
  );


}))

export default ColorButton
