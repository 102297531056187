import React, {useContext} from 'react';
import {DocumentationContext} from "../providers/DocumentationProvider";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DocLink = ({ id }) => {
  const documentationLinks = useContext(DocumentationContext);

  const href = documentationLinks[`${id}.href`];
  const docMessage = documentationLinks[`${id}.message`];

  return <a {...(!documentationLinks || !href) ? {
    href: "#"
  } : {
    href,
    target: "_blank",
    rel: "noopener noreferrer"
  }}>
    {docMessage || <FontAwesomeIcon icon={faQuestionCircle} />}
  </a>;
};

export default DocLink;
