import {getType, resolveIdentifier, types} from "mobx-state-tree";
import lodash from "lodash";
import CodeParamsStore from "../../../../stores/domain/parameters/CodeParamsStore";
import Parameter from "../../../../models/Parameter";

export const CodeParamsTypeSelectState = types.model('CodeParamsTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.reference(CodeParamsStore)),
  selectedCodeParameters: types.optional(types.array(types.safeReference(Parameter)), []),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  setConsumer(f){
    self.notifySelect = f
  },
  selectParameters(e) {
    if (lodash.isArray(e)) {
      self.selectedCodeParameters = e?.map(g => {
        return resolveIdentifier(Parameter, self, g?.uuid || g)
      })
    } else if (e) {
      self.selectedCodeParameters = e
    }
  },
})).views( (self) => ({
  options() {
    return self.store.getCodeParameters() || [];
  },

}))


