import {resolveIdentifier, types} from 'mobx-state-tree'
import {ExtendedDataSetModel} from "../../models/extendedData/ExtendedDataSet";
import ExtendedDataSetStore from "../../stores/domain/ExtendedDataSetStore";

export const ExtendedDataSetSelectState = types.model("ExtendedDataSetSelectState",{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(ExtendedDataSetStore))),
  selectedExtendedDataSets: types.optional(types.array(types.safeReference(ExtendedDataSetModel)), []),
}).volatile(self => ({
  notifySelect: types.function
})).actions( (self)=>({
  selectExtendedDataSet(arr){
    self.notifySelect(arr);
  },
  setConsumer(f){
    self.notifySelect = f;
  },
  setSelectedExtendedData(arr) {
    const result = [];
    arr?.forEach((set) => {
      result.push(resolveIdentifier(ExtendedDataSetModel, self, set?.uuid || set));
    });
    self.selectedExtendedDataSets = result;
  },
})).views( (self) => ({
  getSelectedExtendedDataSets() {
    return self.selectedExtendedDataSets?.filter(n => n);
  },
  getSelectedExtendedData() {
    return self.selectedExtendedDataSets?.flatMap(s => s.extendedData);
  },
  optionValues() {
    return self?.store?.getExtendedDataSetList() || [];
  },
}));
