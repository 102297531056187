import async from "../../components/Async";
import {faAsterisk} from "@fortawesome/free-solid-svg-icons";
import ExtendedDataListViewState from "../../components/extendedData/list/ExtendedDataListViewState";
import {ModalState} from "../../components/elements/modal/GenericModal";
import ExtendedDataSetFormState from "../../components/extendedData/ExtendedDataSetFormState";
import ExtendedDataFormState from "../../components/extendedData/ExtendedDataFormState";
import {ExtendedDataListModel} from "../../models/extendedData/ExtendedDataListModel";
import {ExtendedDataSetListModel} from "../../models/extendedData/ExtendedDataSetListModel";

const ExtendedDataListPage = async(()=> import("../../pages/extendedData/ExtendedDataListPage"));
const ExtendedDataPage = async(()=> import("../../pages/extendedData/ExtendedDataPage"));
const ExtendedDataSetPage = async(()=> import("../../pages/extendedData/ExtendedDataSetPage"));

export const viewMap = {
  "extendedDataNavPage": <ExtendedDataListPage />,
  "createExtendedData": <ExtendedDataPage />,
  "editExtendedData": <ExtendedDataPage />,
  "createExtendedDataSet": <ExtendedDataSetPage />,
  "editExtendedDataSet": <ExtendedDataSetPage />,
}
export const routeDefinitions = [
  {
    path: "extendedDataNavPage",
    name: "Extended Data",
    icon: faAsterisk,
    component: ExtendedDataListPage,
    children: null,
    featureFlagRequired: "cmp_ui_extended_data"
  }
];

export const routeTransitions = [
  {
    name: 'extendedDataNavPage',
    pattern: '/extended-data',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      let viewState = rootStore.rootUiStore.extendedDataListViewState;
      const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
      const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
      advFilterSelectState.reset();
      dimensionSelectViewState.reset();
      tagFilterSelectState.reset();
      groupFilterSelectState.reset();

      if (!viewState) {

        const extendedDataStore = rootStore.domainStore.extendedDataStore;
        await extendedDataStore.init();
        const extendedDataSetStore = rootStore.domainStore.extendedDataSetStore;

        viewState = ExtendedDataListViewState.create({
          setStore: ExtendedDataSetListModel.create({
            store: extendedDataSetStore
          }),
          store: ExtendedDataListModel.create({
            store: extendedDataStore
          }),
          advFilterSelectState: advFilterSelectState,
          tagFilterSelectState: tagFilterSelectState,
          dimensionSelectViewState: dimensionSelectViewState,
          groupFilterSelectState: groupFilterSelectState,
          modalStore: ModalState.create({}),
        });
      }

      console.log("Attaching Page State : extendedDataListViewState")
      await rootStore.rootUiStore.attachPageState('extendedDataListViewState', viewState);
      await viewState?.refresh();
      await viewState?.getFilters();
    }
  },
  {
    name: 'createExtendedData',
    pattern: '/extended-data/create',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const {
        groupSelectStore,
        tagSelectStore,
        dimensionsMultiSelectState,
        extendedDataTypeSelectStore,
        groupFilterSelectState,
        tagFilterSelectState,
        codeFilterSelectState,
        dimensionsFilterMultiSelectStore,
      } = rootStore.rootUiStore;

      const tenantStore = rootStore.domainStore.tenantStore;
      groupSelectStore?.selectDefaultGroup(tenantStore.defaultGroupUuid);

      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();

      dimensionsFilterMultiSelectStore.init();
      dimensionsFilterMultiSelectStore.setDefaults();

      groupFilterSelectState.reset();
      tagFilterSelectState.reset();
      codeFilterSelectState.reset();
      await codeFilterSelectState.populateAllFilters();

      const viewState = ExtendedDataFormState.create({
        groupSelectStore: groupSelectStore,
        tagSelectStore: tagSelectStore,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        dimensionsFilterMultiSelectStore: dimensionsFilterMultiSelectStore,
        extendedDataTypeSelectStore: extendedDataTypeSelectStore,
        groupFilterSelectState: groupFilterSelectState,
        tagFilterSelectState: tagFilterSelectState,
        codeFilterSelectState: codeFilterSelectState,
      });

      console.log("Attaching Page State : extendedDataFormState");
      await rootStore.rootUiStore.attachPageState('extendedDataFormState', viewState);

      await viewState?.setDefaults();
      const {params, queryParams} = toState;
      const snapShot = queryParams.id ? rootStore.rootUiStore?.globalCache[`clone:${queryParams.id}`] : null;
      if (snapShot) {
        await viewState?.hydrateForm({...snapShot, name: ""});
      } else {
        tagSelectStore?.tagStore?.init();
      }
      tagSelectStore?.tagStore?.setFilterOn(groupSelectStore?.selectedGroup?.uuid);
    }
  },
  {
    name: 'editExtendedData',
    pattern: '/extended-data/edit/:id',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const {
        groupSelectStore,
        tagSelectStore,
        dimensionsMultiSelectState,
        extendedDataTypeSelectStore,
        groupFilterSelectState,
        tagFilterSelectState,
        codeFilterSelectState,
        dimensionsFilterMultiSelectStore
      } = rootStore.rootUiStore;

      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();
      dimensionsFilterMultiSelectStore.init();
      dimensionsFilterMultiSelectStore.setDefaults();
      groupFilterSelectState.reset();
      tagFilterSelectState.reset();
      codeFilterSelectState.reset();
      await codeFilterSelectState.populateAllFilters();

      const viewState = ExtendedDataFormState.create({
        groupSelectStore: groupSelectStore,
        tagSelectStore: tagSelectStore,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        dimensionsFilterMultiSelectStore: dimensionsFilterMultiSelectStore,
        extendedDataTypeSelectStore: extendedDataTypeSelectStore,
        groupFilterSelectState: groupFilterSelectState,
        tagFilterSelectState: tagFilterSelectState,
        codeFilterSelectState: codeFilterSelectState,
      });

      console.log("Attaching Page State : extendedDataFormState");
      await rootStore.rootUiStore.attachPageState('extendedDataFormState', viewState);

      const {params} = toState;
      await viewState?.fetchModelAndHydrate(params.id);
      await viewState?.enableEditIfUnused(params.id);
    }
  },
  {
    name: 'deleteExtendedData',
    pattern: '/extended-data/delete/:id',
    onEnter: async (fromState, toState, routerStore) => {}
  },
  {
    name: 'createExtendedDataSet',
    pattern: '/extended-data-set/create',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const {
        groupSelectStore,
        tagSelectStore,
        dimensionSelectViewState,
        dimensionsMultiSelectState,
        extendedDataSelectStore
      } = rootStore.rootUiStore;

      const tenantStore = rootStore.domainStore.tenantStore;
      groupSelectStore?.selectDefaultGroup(tenantStore.defaultGroupUuid);


      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();
      await extendedDataSelectStore.store.init();

      const viewState = ExtendedDataSetFormState.create({
        groupSelectStore: groupSelectStore,
        tagSelectStore: tagSelectStore,
        dimensionSelectViewState: dimensionSelectViewState,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        extendedDataSelectStore: extendedDataSelectStore
      });

      console.log("Attaching Page State : extendedDataSetFormState");
      await rootStore.rootUiStore.attachPageState('extendedDataSetFormState', viewState);

      await viewState?.setDefaults();
      const {params, queryParams} = toState;
      const snapShot = queryParams.id ? rootStore.rootUiStore?.globalCache[`clone:${queryParams.id}`] : null;
      if (snapShot) {
        await viewState?.hydrateForm({...snapShot, name: ""});
      } else {
        tagSelectStore?.tagStore?.init();
      }
      tagSelectStore?.tagStore?.setFilterOn(groupSelectStore?.selectedGroup?.uuid);
    }
  },
  {
    name: 'editExtendedDataSet',
    pattern: '/extended-data-set/edit/:id',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const {
        groupSelectStore,
        tagSelectStore,
        dimensionSelectViewState,
        dimensionsMultiSelectState,
        extendedDataSelectStore
      } = rootStore.rootUiStore;

      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();
      extendedDataSelectStore.store.init();

      const viewState = ExtendedDataSetFormState.create({
        groupSelectStore: groupSelectStore,
        tagSelectStore: tagSelectStore,
        dimensionSelectViewState: dimensionSelectViewState,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        extendedDataSelectStore: extendedDataSelectStore
      });

      console.log("Attaching Page State : extendedDataSetFormState");
      await rootStore.rootUiStore.attachPageState('extendedDataSetFormState', viewState);

      const {params} = toState;
      await viewState?.fetchModelAndHydrate(params.id);
    }
  },
  {
    name: 'deleteExtendedDataSet',
    pattern: '/extended-data-set/delete/:id',
    onEnter: async (fromState, toState, routerStore) => {}
  },
];
