import {getRoot, resolveIdentifier, types} from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";
import {TagModel} from "../tags/TagModel";
import {ExtendedDataModel} from "./ExtendedData";

export function createExtendedDataSetModel(item, target) {
  const root = getRoot(target);
  return ExtendedDataSetModel.create({
    uuid: item?.uuid,
    name: item?.name,
    label: item?.name,
    isExclusivelyGroupOwned: item?.isExclusivelyGroupOwned,
    tags: item?.tags?.map(tag => {
      return resolveIdentifier(TagModel, target, tag?.uuid || tag);
    }),
    extendedData: item?.extendedData?.map(i => {
      return resolveIdentifier(ExtendedDataModel, root, i?.uuid || i);
    }),
    _audit: item?._audit,
    owner: item?.owner,
    updatedAt: item?.updatedAt,
    createdAt: item?.createdAt,
  });
}

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string),
  dimensions: types.optional(types.frozen(),{}),
})

export const ExtendedDataSetModel = types.model("ExtendedDataSetModel", {
  uuid: types.identifier,
  name: types.string,
  label: types.string,
  _audit: types.maybeNull(AuditMetaData),
  owner: types.maybe(GroupData),
  tags: types.optional(types.array(types.reference(TagModel)), []),
  extendedData: types.optional(types.array(types.reference(ExtendedDataModel)), []),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});
