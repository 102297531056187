import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import {
  Card,
  CardBody,
  Button,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  Input,
  CustomInput, UncontrolledTooltip, Badge, DropdownItem,
} from "reactstrap";

import { RouterLink } from "mobx-state-router";
import { StateContext } from "../../../../App";
import { useIntl, FormattedMessage } from "react-intl";
import ScanbuyTable, {TableColumn} from "../../../tables/ScanbuyTable";
import { formConstants } from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import GroupSelect from "../../../elements/groups/GroupSelect";
import TagFilterSelect from "../../../elements/filters/TagFilterSelect";
import dayjs from "dayjs";
import TableActionsDropdown from "../../../tables/TableActionsDropdown";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  RefreshCw as RetryIcon,
  Copy as CopyIcon
} from "react-feather";
import {buildDimensionFilters} from "../../../../utils/buildDimensionFilters";
import DataExportFilterSelect from "../../../elements/filters/data/exports/DataExportFilterSelect";
import lodash from "lodash";
import DeleteDataExportState from "../modal/DeleteDataExportState";
import DeleteDataExportView from "../modal/DeleteDataExportView";
import GenericModal from "../../../elements/modal/GenericModal";
import {getSnapshot} from "mobx-state-tree";

export const buildColumns = (routerStore, dataExportListViewState) => {
  const {rootStore} = routerStore.options;

  const dataExportTypeStore = rootStore.domainStore.dataExportTypeStore;
  const typeMap = lodash.keyBy(dataExportTypeStore.getDataExportTypes(), "type");

  const name = TableColumn.create({
    name: 'Data Export Name',
    property: 'name',
    width: '25%',
    sortable: true,
    sortField: 'name',
  });
  name.setSelector((row)=> {
    const id = `tooltip-export-name-${row.uuid}`;
    return(
      <>
        <span id={id}>{row.name}</span>
        <UncontrolledTooltip target={id} placement="top">
          {row.name}
        </UncontrolledTooltip>
      </>
    );
  });

  const status = TableColumn.create({
    name: 'Status',
    property: 'status',
    sortable: true,
    sortField: 'status',
    selector: 'status',
    width: '20%',
  });
  status.setSelector((row) => {
    const id = `tooltip-status-${row.uuid}`;
    return(
      <>
        <span id={id}>{row.status}</span>
        <UncontrolledTooltip target={id} placement="top">
          {row.status}
        </UncontrolledTooltip>
      </>
    );
  });

  const exportType = TableColumn.create({
    name: 'Data Export Type',
    property: 'type',
    width: '20%',
  });
  exportType.setSelector((row) => {
    return typeMap[row.type]?.label || row.type;
  });

  const lastModified = TableColumn.create({
    name: 'Last Modified',
    property: 'updated',
    width: '15%',
    hide: 'sm',
    sortable: true,
    sortField: 'updatedAt',
  });
  lastModified.setSelector((row) => {
    const {updatedAt} = row || '';
    const formattedDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
    const id = `tooltip-updatedAt-${row.uuid}`;
    return(
      <>
        <span id={id}>{formattedDate}</span>
        <UncontrolledTooltip target={id} placement="top">
          {formattedDate}
        </UncontrolledTooltip>
      </>
    );
  });

  const tags = TableColumn.create({
    name: 'Tag(s)',
    property: 'tags',
    hide: 'sm'
  });
  tags.setSelector((row) => {
    return row.tags;
  });
  tags.setCell((row) => {
    const tags = row?.tags?.flatMap((t) => {
      return {
        uuid: t.uuid,
        name: t.name,
        color: t.uiStyleConfigs?.color || 'info'
      };
    });
    return (
      <div>
        {tags?.map((tag, index) => (
          <Badge
            key={index}
            color="info"
            className="badge-pill mr-1 mb-1"
            >
              {tag?.name}
            </Badge>))}
        </div>
    );
  });

  const action = TableColumn.create({
    name: 'Action',
    property: 'action',
    allowOverflow: true,
    button: true,
    width: '5%',
  });
  action.setCell((row) => (
    <TableActionsDropdown actionsList={[
      ...(!row?.hideActions?.includes('retry') ? [
        <DropdownItem id="action-btn-retry" key={'retry/' + row.uuid} onClick={() => {
          dataExportListViewState.handleAction(row.uuid, 'retry');
        }}>
          <RetryIcon/>&nbsp;Retry
        </DropdownItem>
      ] : []),
      ...(!row?.hideActions?.includes('download') ? [
        <DropdownItem id="action-btn-download" key={'download/' + row.uuid} onClick={() => {
          dataExportListViewState.downloadRow(row.uuid);
        }}>
          <DownloadIcon/>&nbsp;Download
        </DropdownItem>
      ] : []),
      ...(!row?.hideActions?.includes('edit') ? [
        <DropdownItem id="action-btn-edit" key={'edit/' + row.uuid} onClick={() => {
          routerStore.goTo('/data/exports/edit/:id', {
            params: {id: row.uuid}
          });
        }}>
          <EditIcon/>&nbsp;Edit
        </DropdownItem>
      ] : []),
      ...(!row?.hideActions?.includes('clone') ? [
        <DropdownItem id="action-btn-clone" key={'clone/' + row.uuid} onClick={() => {
          const snapshot = getSnapshot(row);
          rootStore.rootUiStore.addCloneToCache(`clone-data-export:${row.uuid}`, snapshot);
          routerStore.goTo('/data/exports/create',{
            queryParams: {clone: row.uuid}
          })
        }}>
          <CopyIcon/>&nbsp;Clone
        </DropdownItem>
      ] : []),
      ...(!row?.hideActions?.includes('delete') ? [
        <DropdownItem key="divider" divider />,
        <DropdownItem id="action-btn-delete" key={'delete/' + row.uuid} onClick={() => {
          const componentStore = DeleteDataExportState.create({
            title: `Delete ${row.name || ""}`,
            uuid: row.uuid,
            name: row.name || ""
          });
          rootStore.rootUiStore.attachPageState('deleteDataExportState', componentStore);

          componentStore.setToggleListener(dataExportListViewState.modalStore.toggle);
          componentStore.setRefreshListener(dataExportListViewState.refresh);
          dataExportListViewState.handleRowClick(row, componentStore, DeleteDataExportView);
        }}>
          <DeleteIcon/>&nbsp;Delete
        </DropdownItem>
      ] : []),
    ]}/>
  ));

  return [name, exportType, status, tags, lastModified, action];
};


const DataExportListView = observer(({ dataExportListViewState }) => {
  const intl = useIntl();
  const stateContext = useContext(StateContext);
  dataExportListViewState?.tagFilterSelectState.setTags(dataExportListViewState?.store?.filters?.tags);
  dataExportListViewState?.dimensionSelectViewState?.setFilteredDimensions(dataExportListViewState?.store?.filters?.dimensions);
  dataExportListViewState?.dataExportFilterSelectState?.setStatusTypes(dataExportListViewState.store?.filters?.status);
  dataExportListViewState?.setOnSelectChanges();

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="text-right">
              <Button
                id="data-export-list-dropdown-slider"
                color="primary"
                onClick={() => {
                  dataExportListViewState?.toggleFilterVisible();
                }}
              >
                <FontAwesomeIcon
                  icon={dataExportListViewState.isFilterVisible ?
                    faAngleDoubleUp :
                    faAngleDoubleDown} />
              </Button>
            </Col>
          </Row>
          {dataExportListViewState?.isFilterVisible && (
            <>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "filterByGroups.defaultMessage"})}
                    </InputGroupAddon>
                    <GroupSelect
                      groupSelectStore={dataExportListViewState?.groupSelectStore}
                      multi={true}
                    />
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                    </InputGroupAddon>
                    <DataExportFilterSelect state={dataExportListViewState?.dataExportFilterSelectState}/>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                {dataExportListViewState?.dimensionSelectViewState?.dimensionsEnabled &&
                  dataExportListViewState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore))}
              </Row>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="searchInput"
                      placeholder="Search"
                      onChange={dataExportListViewState?.onSearchChange}
                    />
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({
                        id: "listCodes.filterBytags.defaultMessage",
                      })}
                    </InputGroupAddon>
                    <TagFilterSelect
                      tagFilterSelectState={dataExportListViewState?.tagFilterSelectState}
                    />
                  </InputGroup>
                </Col>
                <Col sm={1}>
                  <CustomInput
                    type="switch"
                    id="isFilterTypeOR"
                    name="isFilterTypeOR"
                    label={formConstants.Mode.OR}
                    checked={dataExportListViewState?.isFilterTypeOR}
                    onChange={() => dataExportListViewState?.selectFilterMethod()}
                  />
                </Col>
                <Col sm={0.5}>
                  <InputGroup className="justify-content-end">
                    <RouterLink routeName="/data/exports/create">
                      <Button id="create-data-export-btn" color="primary">
                        <FormattedMessage id="listCodes.create.defaultMessage" />
                      </Button>
                    </RouterLink>
                  </InputGroup>
                </Col>
              </Row>
            </>
          )}
          <ScanbuyTable
            columns={dataExportListViewState?.columns}
            store={dataExportListViewState?.store}
            queryParams={{}}
            highlightOnHover={true}
          />
        </CardBody>
      </Card>
      <GenericModal
        modalStore={dataExportListViewState.modalStore}
        componentStore={dataExportListViewState.modalComponentStore}
        EmbedComponent={dataExportListViewState.modalComponent}
      />
    </>
  );
});

export default DataExportListView;
