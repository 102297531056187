import { observer } from "mobx-react-lite";
import Select from "react-select";
import { useState,useEffect } from "react";
import { DropdownItem ,DropdownMenu,DropdownToggle,UncontrolledButtonDropdown } from "reactstrap";

const QrTemplateSelect = observer(({ qrtemplateSelectStore,onItemClick }) => {

    const length =(qrtemplateSelectStore?.options()).length
     return (<>
            { length>0 &&(<UncontrolledButtonDropdown > 
                <DropdownToggle color="primary" split/>
                <DropdownMenu color="primary" placement="left">
                  {qrtemplateSelectStore?.options().map((item,index)=>(
                     <DropdownItem key={index} value= {item.uuid } onClick={() => onItemClick(item)}>{item.name}</DropdownItem>
                    ))
                  }
                </DropdownMenu>
                </UncontrolledButtonDropdown>
                )}
                </>
            );
                  
});

export default QrTemplateSelect;

 