import validator from "@rjsf/validator-ajv8";
import React from "react";
import {withTheme} from "@rjsf/core";
import {Theme as Bootstrap4Theme} from "@rjsf/bootstrap-4";
const ThemedForm = withTheme(Bootstrap4Theme);


// This widget is used because we need the RJSF library for time, but we want to manually control timeZone elsewhere



const schema = {
  "properties": {
    "dateTime": {
      "type": "string",
      "format": "date-time"
    }
  }
}
const uiSchema = {
  "dateTime": {
    "ui:label": false
  }
}
export const DateTimeWidget = ({value, onChange}) => {

    const handleOnChange = (e) => { // this logic removes the time zone.
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      const parsedDate = new Date(e.formData.dateTime);
      let localDateTimeString = "";
      if (!isNaN(parsedDate.getTime())) {
        localDateTimeString = new Intl.DateTimeFormat('en-US', options).format(parsedDate);
      }

      // Expected Format  YYYY-MM-DDTHH:mm:ss (2023-12-09T11:07:00)
      const formattedDateTime = localDateTimeString.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+) (AM|PM)/, function(match, p1, p2, p3, p4, p5, p6, p7) {
        // Pad single digits with leading zeros
        let hour24;
        if (p7 === 'AM' && p4 === '12') {
          hour24 = '00';
        } else if (p7 === 'PM' && p4 !== '12') {
          hour24 = String(Number(p4) + 12).padStart(2, '0');
        } else {
          hour24 = p4.padStart(2, '0');
        }
        return `${p3}-${p1.padStart(2, '0')}-${p2.padStart(2, '0')}T${hour24}:${p5.padStart(2, '0')}:${p6.padStart(2, '0')}`;
      });
      onChange(formattedDateTime)
    }

const data = {dateTime: value}
  return (
    <ThemedForm
      schema={schema}
      formData={data}
      uiSchema={uiSchema}
      validator={validator}
      onChange={(data)=>{handleOnChange(data)}}
    >
      <div></div>
    </ThemedForm>
  );
};



