import {flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {Utm} from "../../models/utms/UtmModel";
import {TagModel} from "../../models/tags/TagModel";
import lodash from "lodash";

const UtmStore = types.model('UtmStore', {
  storeUuid: types.identifier,
  list: types.optional(types.array(Utm), []),
  flatList: types.optional(types.array(types.frozen()), []),
  //isLoading..
}).volatile((self)=>({
  filterListOn:types.fn,
})).actions((self) => ({
  setFilterOn(groupUuid) {
    self.filterListOn = (item) => {   //function used to filter utm options by selected group
      return (item.owner.groupUuid === groupUuid || item?.isExclusivelyGroupOwned === false)
    }
  },
  initWithData(items = []) {
    try {
      if (items) {
        let nestedTags = [];
        self.list = items.map(utm => {
            if(utm.tags && utm.tags.length > 0){
              nestedTags = utm.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
            }
            return Utm.create({
              uuid: utm?.uuid,
              name: utm?.name,
              label: utm?.name,
              _audit: utm?._audit,
              createdAt: utm?.createdAt,
              updatedAt: utm?.updatedAt,
              isExclusivelyGroupOwned: utm?.isExclusivelyGroupOwned,
              tags: nestedTags,
              owner: utm?.owner,
              isEditable: utm?.isEditable
            })
          },
        )
        self.list.sort((a, b) => a.label.localeCompare(b.label))
      }
    } catch (e) {
      console.error("Failed to init utms with data", e);
    }
  },
  init: flow(function* f(params = {}) {
    const resp = yield self.getUtms(params);
    if(!resp) {
      return;
    }
    self.setUtms(resp.results)
  }),
  getUtms: flow(function* getUtms(params = {}) {
    try {

      //only if its not a group query add isExclusivelyGroupOwned
      params = Object.assign({
          ...params,
        },
        lodash.isEmpty(params.groupUuid) && {isExclusivelyGroupOwned: false}
      )

      const response = yield getEnv(self).utmManager.fetchAll(params);
      if(response && response.results && response.results.length > 0) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch publish Utms", error);
      return null;
    }
  }),
  setUtms(Utms) {
    if (Utms) {
      let nestedTags = [];
      const flatList = Utms.map(utm => {
          if(utm.tags && utm.tags.length > 0){
            nestedTags = utm.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
          }
          return {
            uuid: utm?.uuid,
            name: utm?.name,
            label: utm?.name,
            _audit: utm?._audit,
            createdAt: utm?.createdAt,
            updatedAt: utm?.updatedAt,
            isExclusivelyGroupOwned: utm?.isExclusivelyGroupOwned,
            tags: nestedTags,
            owner: utm?.owner,
            isEditable: utm?.isEditable
          }
        },
      )
      self.flatList= flatList
    }
  },
  getUtm: flow(function* getUtm(uuid) {
    try {
      const response = yield getEnv(self).utmManager.getUtm(uuid);
      if(response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch Utm", error);
      return null;
    }

  }),
  searchFor(txt) {
    //TODO: search for groups with name like 'txt'
  }


})).views((self) => ({

  filteredList() {
     // return self.list.filter((item) => self.filterListOn(item)) //filter utms by whatever filterListOn is set to
     return self.flatList.filter((item) => self.filterListOn(item))
  }
}));


export default UtmStore;
