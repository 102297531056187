import Select from "react-select";
import {useEffect, useState} from "react";


const CustomCheckboxWidget = (props) => {
  const { value, onChange,schema,country, region ,onCountryChange, onRegionChange, readonly
  } = props
  let optionMap=[];
  const validRuleTypes = ["MetroCode/DMA", "City", "State/Province"];
  if(validRuleTypes.includes(props.label)) {
    if(props.label==="MetroCode/DMA") {
      if (country?.some((countryItem) => countryItem.value === "US")) {
        optionMap = schema?.items?.map((item) => {
          return {value: item.code, label: item.name}
        })
      }
    }
    else {
      country?.forEach(countryItem => {
        let filteredItems = schema?.items.filter(item => item.countryCode === countryItem.value);
        if (region && region.length>0) {
          filteredItems = filteredItems?.filter(item => region.some(regionItem => regionItem.value === item.stateCode));
        }

        const optionMapForCountry = filteredItems.map(item => {
          const { cityName, stateName, stateCode, countryCode } = item;
          if(cityName && stateCode && countryCode) { //when there is city with state and country
            return {
              value: cityName,
              label: `${cityName} (${stateCode}, ${countryCode})`
            }
          } else if (cityName && countryCode) { // city has to state/region
            return {
              value: cityName,
              label: `${cityName} (${countryCode})`
            }
          } else if (stateName && stateCode && countryCode) { //country region with country code
            return {
              value: stateCode,
              label: `${stateName} (${countryCode})`
            }
          }
          return null
        })

        optionMap = optionMap.concat(optionMapForCountry);
      });

    }
  }
  else if(schema.items && schema.items.length > 0) {
    optionMap = schema?.items?.map((item) => {
      return {value: item?.code, label: item?.name}
    })
  } else if(schema.items && typeof schema.items === "object" && schema.items.enum && schema.items.enum.length > 0) {
    for(let i=0; i<schema.items.enum.length; i++) {
      let label = schema.items.enumNames[i] ? schema.items.enumNames[i] : schema.items.enum[i]
      optionMap.push({value: schema.items.enum[i], label: label})
    }
  }
  const optionHash = {}
  optionMap.forEach((item) => {
    optionHash[item.value] = item.label
  })




  const setCountry = (data) => {
    if (props?.label === "Country") {
      onCountryChange(data);
      //check if node has children  then reselect and retain the value
    }
  }
  const setRegion = (data) => {
    if (props?.label === "State/Province") {
      onRegionChange(data);
    }
  }

  let selectedValues = []
  if(value) {
    if (typeof value[0] === "string") {
      selectedValues = value?.map((item, index) => {
        return {value: item, label: optionHash[item]}
      })
      setCountry(selectedValues)
      setRegion(selectedValues)
    } else {
      selectedValues = value
    }
  }else{
    selectedValues = value
  }



    return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={optionMap}
      id="CustomCheckboxWidget"
      value={selectedValues}
      isSearchable
      onChange={(data) => {
        onChange(data)
        setCountry(data)
        setRegion(data)
      }}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.value}
      isMulti
      placeholder={null}
      isDisabled={readonly}
    />
  );
  };

export default CustomCheckboxWidget;


