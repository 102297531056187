import {types} from "mobx-state-tree";

const PositionKeyModel = types.model('PositionKeyModel',{
    Bottom: types.optional(types.string, "bottomText"),
    Left: types.optional(types.string, "leftText"),
    Right: types.optional(types.string, "rightText"),
    Top: types.optional(types.string, "topText"),
})
  
const DefaultImageConfigModel = types.model('DefaultImageConfigModel',{
    colorMode: types.optional(types.string,'rgb'),
    dataShape: types.optional(types.integer, 0),
    errorCorrection: types.optional(types.string, 'M'),
    quietZone: types.optional(types.integer, 4),
    resolution: types.optional(types.integer, 1200),
    size: types.optional(types.integer, 1),
    positionKeys: types.optional(PositionKeyModel,{}),
})

export default DefaultImageConfigModel;