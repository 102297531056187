import {getSnapshot, types} from "mobx-state-tree";

const ImageFormat = types.model({
  label: types.string,
  value: types.string,
});

export const QrDownloadState = types.model('QrDownloadState', {
  options: types.optional(types.array(ImageFormat), [
    { label: "PNG", value: "png" },
    { label: "JPG", value: "jpg" },
    { label: "BMP", value: "bmp" },
    { label: "EPS", value: "eps" },
    { label: "GIF", value: "gif" },
    { label: "PDF", value: "pdf" },
    { label: "SVG", value: "svg" },
  ]),
  downloadImageFormat: types.optional(ImageFormat, { label: "PNG", value: "png" }),
  defaultImageFormat: types.optional(ImageFormat, { label: "PNG", value: "png" }),
})
.actions((self) => ({
    handleDownloadImageFormat(imageFormat){
      // const imageFormat = self.determineImageFormat(colorMode, transparentBackgroun)
      const selectedImageFormat = ImageFormat.create({label: imageFormat.label, value: imageFormat.value})
      self.downloadImageFormat = selectedImageFormat
    }, 
    handleOptions(qrConfig){
      let newOptions = [...self.options]; // Create a new array
      const colorMode = qrConfig.colorMode;
      const transparentBackground = qrConfig.transparentBackground;
      let isDefaultDesigner = true;
      if(qrConfig.hasOwnProperty('isDefaultDesigner')){
        isDefaultDesigner = qrConfig.isDefaultDesigner;

      }
      if (colorMode === 'cmyk') {
          newOptions = newOptions.filter(option => (option.value !== 'png' && option.value !== 'gif'));
      } else {
          const pngOption = { label: "PNG", value: "png" };
          const gifOption = { label: "GIF", value: "gif" };
          if (!newOptions.some(option => option.value === pngOption.value)) {
              newOptions = [pngOption, ...newOptions];
          }
          if (!newOptions.some(option => option.value === gifOption.value)) {
              newOptions = [gifOption, ...newOptions];
          }
      }

      if (transparentBackground) {
          newOptions = newOptions.filter(option => option.value !== 'jpg');
      } else {
          const jpgOption = { label: "JPG", value: "jpg" };
          if (!newOptions.some(option => option.value === jpgOption.value)) {
              newOptions = [jpgOption, ...newOptions];
          }
      }

      // if it is v1 config, add AI option
      if (isDefaultDesigner) {
        newOptions = newOptions.filter(option => option.value !== 'ai');
      } else {
          const aiOption = { label: "AI", value: "ai" };
          if (!newOptions.some(option => option.value === aiOption.value)) {
              newOptions = [...newOptions, aiOption];
          }
      }

      self.options = newOptions;
      const selectedImageFormat = ImageFormat.create({label: newOptions[0].label, value: newOptions[0].value})
      self.downloadImageFormat = selectedImageFormat;
      
    },
    handleDefaultImageFormat(colorMode, transparentBackground){
      const imageFormat = self.determineImageFormat(colorMode, transparentBackground)
      self.defaultImageFormat = imageFormat
      // self.downloadImageFormat = imageFormat
    },
    determineImageFormat(colorMode, transparentBackground) {
      if(colorMode === 'cmyk' && !transparentBackground){
        return { label: "JPG", value: "jpg" }
      } else if(colorMode === 'cmyk' && transparentBackground){
        return { label: "EPS", value: "eps" }
      } else {
        return { label: "PNG", value: "png" }
      }
    }


  }))


//
