import {flow, getEnv, types} from "mobx-state-tree";
import Notification from "../../../utils/Notification";

const DeleteState = types.model('DeleteState', {
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
  .volatile(self => ({
    selectedCallback: types.fn,
    selectedSecondCallback: types.fn,
  }))
  .actions((self) => ({
    handleHasDeleteErrors(boolean) {
      self.formHasErrors = boolean
    },
    handleDeleteErrorMessage(message) {
      self.errorMessage = message
    },
    setDeleteStateListener(listener) {
      self.selectedCallback = listener
    },
    setSecondDeleteStateListener(listener) {
      self.selectedSecondCallback = listener
    },

    notifyCallBack() {
      self.selectedCallback()
    },
    notifySecondCallBack(str) {
      self.selectedSecondCallback(str)
    },

    deleteCode: flow(function* () {

      const manager = getEnv(self).codeManager;
      const resp = yield manager.delete(self)

      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} Code deleted`)
        .send();

      !self.formHasErrors && self.notifySecondCallBack(resp.uuid)
      !self.formHasErrors && self.notifyCallBack()
    }),

  }))

export default DeleteState;
