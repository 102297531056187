import React, {useContext} from "react";
import {
  Button,
  Card,
  CardBody, Col, DropdownItem, InputGroup, InputGroupAddon, Row, UncontrolledTooltip, Badge, CustomInput, Input
} from "reactstrap";
import {observer} from "mobx-react-lite";
import {FormattedMessage, useIntl} from "react-intl";
import GroupSelect from "../../../elements/groups/GroupSelect";
import ScanbuyTable, {TableColumn} from "../../../tables/ScanbuyTable";
import {RouterLink, useRouterStore} from "mobx-state-router";
import TableActionsDropdown from "../../../tables/TableActionsDropdown";
import * as Icon from "react-feather";
import DeleteState from "../../../codes/delete/DeleteState";
import DeleteView from "../../../codes/delete/DeleteView";
import DeleteRuleSetView from "../delete/DeleteRuleSetView";
import DeleteRuleSetState from "../delete/DeleteRuleSetState";
import GenericModal from "../../../elements/modal/GenericModal";
import {StateContext} from "../../../../App";
import dayjs from "dayjs";
import TagSelect from "../../../elements/tags/TagSelect";
import {ADVANCED_SETTINGS_MANAGE_SET, formConstants} from "../../../../utils/constants";
import {createdByColumn} from "../../../tables/TableColumns";
import {getSnapshot} from "mobx-state-tree";
import AdvancedConfFilterSelect from "../../../elements/filters/AdvancedConfFilterSelect";
import CodeOwnerFilterSelect from "../../../elements/filters/CodeOwnerFilterSelect";
import {buildDimensionFilters} from "../../../../utils/buildDimensionFilters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";
import TagFilterSelect from "../../../elements/filters/TagFilterSelect";
import GroupFilterSelect from "../../../elements/filters/GroupFilterSelect";
import {requiredRolesCheck} from "../../../../utils/requiredRolesCheck";


export const buildRuleSetsColumns = (routerStore,ruleSetsListViewState ) => {
  const {rootStore} = routerStore.options;

  const c1 = TableColumn.create({ name: 'Name', property: 'name', sortable: true, sortField: 'name' });
  c1.setSelector((row)=> {
    return(
      <>
      <span id={`tooltip-${row.uuid}`}>{row.label}</span>
      <UncontrolledTooltip target={`tooltip-${row.uuid}`} placement="top">{row.label}</UncontrolledTooltip>
      </>
    )});

  const c2 = TableColumn.create({
    name: 'Create Date', property: 'created', width: '15%', hide: 'sm',sortable: true, sortField: 'createdAt',
  });
  c2.setSelector((row) => {
    // console.log(row)
    const {createdAt} = row || {};
    const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedCreateDate;
  });

  const c3 = TableColumn.create({
    name: 'Last Modified', property: 'updated', width: '15%', hide: 'sm', sortable: true, sortField: 'updatedAt',
  });
  c3.setSelector((row) => {
    // console.log(row)
    const { updatedAt } = row || {};
    const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedUpdateDate;
  });
  const c4 = TableColumn.create({
    name: 'Tag(s)', property: 'tags', hide: 'sm'
  });
  c4.setSelector((row) => {
    return row.tags;
  });
  c4.setCell((row) => {
    console.log("row",row)
    const tags = row.tags?.flatMap((t) => {
      return {uuid: t.uuid, name: t.name, color: t.uiStyleConfigs?.color || 'info'}
    });
    return (<div>
      {tags?.map((tag, index) => (<Badge
        key={index}
        color="info"
        className="badge-pill mr-1 mb-1"
      >
        {tag.name}
      </Badge>))}
    </div>)
  })

  const createdBy = createdByColumn();

  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });


  actionColl.setCell((row) => (
    <TableActionsDropdown actionsList={[
      <DropdownItem id="action-btn-view" key={'/configs/ruleSets/view/' + row.uuid} onClick={() => {
        routerStore.goTo('editRuleSet', {
          params: { uuid: row.uuid },
          queryParams: {isEditable: 'false'}
        });
      }}><Icon.FileText/>&nbsp;View</DropdownItem>,
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-edit" key={'/configs/ruleSets/edit/' + row.uuid} onClick={() => {
        routerStore.goTo('editRuleSet', {
          params: { uuid: row.uuid },
        });
      }}><Icon.Edit/>&nbsp;Edit</DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-clone" key={'new'} onClick={() => {
        rootStore.rootUiStore.addCloneToCache(`clone-ruleset:${row.uuid}`,row);
        routerStore.goTo('createRuleSet',{
          queryParams: {q: row.uuid}
        })
      }}><Icon.Copy/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.clone.defaultMessage"/>
      </DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-delete" key={row.uuid} value={"Delete " + row.uuid} className="align-text-bottom"
                    onClick={() => {

                      const userRoles = rootStore?.userRoles
                      if(!requiredRolesCheck(ADVANCED_SETTINGS_MANAGE_SET, userRoles)) return

                      const componentStore = DeleteRuleSetState.create(
                        {
                          // codeListStore: codeListViewState,
                          title: `Delete ${row.name ? row.name : ""}`,
                          uuid: row.uuid
                        });
                      rootStore.rootUiStore.attachPageState('deleteRuleSetStore', componentStore)
                      componentStore.setToggleListener(ruleSetsListViewState.modalStore.toggle)
                      componentStore.setRefreshListener(ruleSetsListViewState.refresh)
                      componentStore.setDeleteRuleSetListener(ruleSetsListViewState.listModel.store.removeRules)
                      ruleSetsListViewState.handleRowClick(row, componentStore, DeleteRuleSetView);
                    }}> <Icon.Delete/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.delete.defaultMessage"/>
      </DropdownItem>]: [])


    ]}/>
  ))
  return [
    c1,
    c4,
    createdBy,
    c2,
    c3,
    actionColl
  ];
}

const RuleSetsListView = observer(({ruleSetsListViewState}) => {
  const routerStore = useRouterStore();
  const intl = useIntl();
  console.log("ruleSetsListViewState.columns",ruleSetsListViewState.columns);
  console.log("ruleSetsListViewState.store",ruleSetsListViewState.listModel.getItems());
  ruleSetsListViewState.setOnSelectChanges()
  ruleSetsListViewState?.advFilterSelectState?.setEmails(ruleSetsListViewState.listModel?.store?.filters?.users);
  ruleSetsListViewState?.tagFilterSelectState?.setTags(ruleSetsListViewState.listModel?.store?.filters?.tags);
  ruleSetsListViewState?.filterGroup && ruleSetsListViewState?.groupFilterSelectState?.setGroups(ruleSetsListViewState.listModel?.store?.filters?.groups);
  ruleSetsListViewState?.dimensionSelectViewState?.setFilteredDimensions(ruleSetsListViewState?.listModel?.store?.filters.dimensions);

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: 'rgb(230, 244, 244)',
        borderBottomColor: '#FFFFFF',
        // borderRadius: '5px',
        outline: '1px solid #FFFFFF',
      },
    },
    pagination: {
      style: {
        border: 'none',
      },
    },
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col className="text-right">
              {ruleSetsListViewState.isFilterVisible ? <Button id="codelist-dropdown-open" color="primary" onClick={()=> {ruleSetsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
                : <Button id="codelist-dropdown-closed" color="primary" onClick={()=> {ruleSetsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>}
            </Col>
          </Row>
          {ruleSetsListViewState?.isFilterVisible && <>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "filterByGroups.defaultMessage"})}
                  </InputGroupAddon>
                  {/*<CodeOwnerFilterSelect codeOwnerFilterSelectState={ruleSetsListViewState.codeOwnerFilterSelectState}/>*/}
                  {/*<GroupSelect groupSelectStore={ruleSetsListViewState?.groupSelectStore} multi={true}/>*/}
                  <GroupFilterSelect groupFilterSelectState={ruleSetsListViewState.groupFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                  </InputGroupAddon>
                  <AdvancedConfFilterSelect advFilterSelectState={ruleSetsListViewState.advFilterSelectState}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              {ruleSetsListViewState?.dimensionSelectViewState?.dimensionsEnabled && ruleSetsListViewState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore))}
            </Row>
            <Row>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="searchInput"
                    placeholder="Search"
                    onChange={ruleSetsListViewState.onSearchChange}
                  />
                </InputGroup>
              </Col>
              <Col sm={5}>
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    {intl.formatMessage({ id: "listCodes.filterBytags.defaultMessage"})}
                  </InputGroupAddon>
                  {/*<TagSelect tagSelectStore={ruleSetsListViewState?.tagSelectStore}/>*/}
                  <TagFilterSelect tagFilterSelectState={ruleSetsListViewState.tagFilterSelectState}/>
                </InputGroup>
              </Col>
              <Col sm = {1}>
                <CustomInput
                  type="switch"
                  id="isFilterTypeOR"
                  name="isFilterTypeOR"
                  label={formConstants.Mode.OR}
                  checked={ruleSetsListViewState.isFilterTypeOR}
                  onChange={() => ruleSetsListViewState.selectFilterMethod()}
                />
              </Col>
              <Col id="create-ruleset-btn" sm={1} className="justify-content-end text-right" >
                <Button color="primary" onClick={() => {
                  routerStore.goTo('createRuleSet', {});
                }}>
                  <FormattedMessage id="qrDesign.manageList.create.defaultMessage"/>
                </Button>
              </Col>
            </Row>
          </>}
          <Row>
            <Col>
              {/*{ruleSetsListViewState.listModel.store.list.length? <ScanbuyTable*/}
              {/*  columns={ruleSetsListViewState.columns}*/}
              {/*  customStyles={customStyles}*/}
              {/*  store={ruleSetsListViewState.listModel}*/}
              {/*/>:*/}
              <ScanbuyTable
                columns={ruleSetsListViewState.columns}
                customStyles={customStyles}
                store={ruleSetsListViewState.listModel}
              />
              {/*}*/}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <GenericModal
        modalStore={ruleSetsListViewState.modalStore}
        componentStore={ruleSetsListViewState.modalComponentStore}
        EmbedComponent={ruleSetsListViewState.modalComponent}
      />
    </>
  )
})
export default RuleSetsListView
