import {getType, resolveIdentifier, types} from "mobx-state-tree";
import lodash from "lodash";
import Parameter from "../../../../models/Parameter";
import CustomParamsStore from "../../../../stores/domain/parameters/CustomParamsStore";

export const CustomParamsTypeSelectState = types.model('CustomParamsTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.reference(CustomParamsStore)),
  selectedCustomParameters: types.optional(types.array(types.safeReference(Parameter)), []),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  setConsumer(f){
    self.notifySelect = f
  },
  selectParameters(e) {
    if (lodash.isArray(e)) {
      self.selectedCustomParameters = e?.map(g => {
        return resolveIdentifier(Parameter, self, g?.uuid || g)
      })
    } else if (e) {
      self.selectedCustomParameters = e
    }
  },
})).views( (self) => ({
  options() {
    return self.store.getCodeParameters() || [];
  },
}))

