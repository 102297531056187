import {types} from 'mobx-state-tree'
import {ExtendedDataType} from "../../models/extendedData/ExtendedDataType";
import ExtendedDataTypeStore from "../../stores/domain/ExtendedDataTypeStore";

export const ExtendedDataTypeSelectStore = types.model("ExtendedDataTypeSelectStore",{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(ExtendedDataTypeStore))),
  selectedExtendedDataType: types.maybe(types.safeReference(ExtendedDataType)),
}).volatile(self => ({
  notifySelect: types.function
})).actions( (self)=>({
  selectExtendedDataType(one){
    self.notifySelect(one);
  },
  setConsumer(f){
    self.notifySelect = f;
  },
  setSelectedExtendedDataType(one) {
    self.selectedExtendedDataType = one;
  },
})).views( (self) => ({
  getSelectedExtendedDataType() {
    return self.selectedExtendedDataType;
  },
  optionValues() {
    return self.store?.getExtendedData() || [];
  },
}));
