import {flow, getEnv, getType, resolveIdentifier, types} from "mobx-state-tree";
import {ExtendedDataType, createExtendedDataType} from "../../models/extendedData/ExtendedDataType";

const ExtendedDataTypeStore = types.model('ExtendedDataTypeStore',{
  storeUuid: types.identifier,
  extendedDataType: types.optional(types.array(ExtendedDataType), []),
}).actions((self) => ({
  setExtendedData(data) {
    self.extendedDataType = data?.map((item) => {return createExtendedDataType(item, self)});
    self.sortExtendedData();
  },
  initWithData(data = []) {
    try {
      if (data) {
        self.setExtendedData(data);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to initWithData`, e);
    }
  },
  init: flow(function* (queryParams = {}) {
    const response = yield getEnv(self).extendedDataManager.fetchAllExtendedData(queryParams, self);
    self.initWithData(response?.results);
  }),
  sortExtendedData() {
    try {
      self.extendedDataType?.sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      console.warn(`${getType(self).name} Warn: Failed to sort`, e);
    }
  }
})).views((self) => ({
  getExtendedData() {
    return self.extendedDataType;
  }
}));

export default ExtendedDataTypeStore;
