import {types} from "mobx-state-tree";
import PublishDomainManager from "src/managers/publishdomain/publishDomainManager";
import Prefix from "./Prefix";
import { flow, getEnv } from "mobx-state-tree";

const DomainSharedModel = types.model('domainSharedModel',{
  groupUuids: types.optional(types.array(types.string),[]),
  tenantUuids: types.optional(types.array(types.string),[]),
});

export const PublishDomain = types.model('PublishDomain',{
  uuid: types.identifier,
  domain: types.maybe(types.string),
  name: types.maybe(types.string),
  label:types.maybe(types.string),
  isDefault: types.maybe(types.boolean),
  isGlobal: types.maybe(types.boolean),
  owner: types.maybe(types.string),
  shared: types.optional(DomainSharedModel,{}),
  prefixes: types.optional(types.array(Prefix),[]),
  selectedPrefix: types.maybeNull(types.safeReference(Prefix)),
  //subgroup??
}).volatile((self)=>({
  notifySelect: types.function,
})).actions((self) => ({
  setConsumer(f){
    self.notifySelect = f
  },
  setPrefixes: flow(function* (params){
    const publishDomainManager = getEnv(self).publishDomainManager;
    const prefixes = yield publishDomainManager.fetchPrefixes(self.uuid, params);
    if(prefixes?.results && prefixes?.results?.length > 0){
      for(let i = 0; i < prefixes.results.length; i++){
        prefixes.results[i].label = prefixes.results[i].prefix;
      }
      // add none option
      prefixes.results.unshift({label: "No Prefix", uuid:"", domainAttachedTo:"", createdAt:"", updatedAt:"", prefix:""})
      self.prefixes = prefixes.results;
      self.selectedPrefix = self.getFirstPrefix();
    } else {
      self.prefixes = [];
      self.selectedPrefix = null;
    }
  }),
  selectPrefix: function (e) {
    self.selectedPrefix = e;
    self.notifySelect(e);
  },
  getFirstPrefix: function () {
    if(self.prefixes.length > 0){
      return self.prefixes[0];
    }
    return {};
  }
})); 
