import { getType, IAnyModelType, types } from "mobx-state-tree";
import { TableColumn } from "../../../tables/ScanbuyTable";
import { GroupSelectState } from "../../../elements/groups/GroupSelectState";
import {PublishDomainsList} from "../../../../models/PublishDomainsList";


const DomainsListViewState = types.model('DomainsListViewState',{
  store: types.late(() =>(PublishDomainsList)),
  columns: types.maybe(types.array(types.late(() => TableColumn))),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),

}).volatile((self)=>({

})).actions((self) => ({
  setColumns(col) {
    self.columns = col;
  },
    setOnSelectChanges() {
      self.groupSelectStore.setConsumer(self.selectGroup)
    },
    selectGroup(obj) {
      self.selectedGroup = obj;
      self.store.addSearchParams({groupUuid: obj?.uuid})
      self.refresh()
    },
    refresh(){
      self.store.fetchAll();
    }
  }))



export default DomainsListViewState
