import {types} from 'mobx-state-tree'

export const Parameter = types.model('Parameter', {
  uuid: types.identifier,
  name: types.string,
  label: types.string,
  subGroup: types.optional(types.string, ""),
}).actions((self) => ({
  getValues() {
  }
}))


const ParameterStore = types.model('ParameterStore', {
  storeUuid: types.identifier,
  codeParameters: types.optional(types.array(Parameter), []),
  scanParameters: types.optional(types.array(Parameter), []),
  // baseDynamicList: types.optional(types.array(Parameter), []),
  // baseStaticList: types.optional(types.array(Parameter), []),
  // countDynamicList: types.optional(types.array(Parameter), []),
  // customerList: types.optional(types.array(Parameter), []),
  // premiumList: types.optional(types.array(Parameter), []),
}).actions((self) => ({

  initWithData(parameters = []) {
    if (parameters) {
      self.setList(parameters)
    } else {
      self.setList("")
    }
  },
  setList(list) {
    if (list) {
      self.codeParameters = list.filter(d => d?.subGroup === "codeParams").map(d => Parameter.create({
        uuid: d?.uuid,
        name: d?.name,
        label: d?.name,
        subGroup: d?.subGroup
      }))
      self.scanParameters = list.filter(d => d?.subGroup === "scanParams").map(d => Parameter.create({
        uuid: d?.uuid,
        name: d?.name,
        label: d?.name,
        subGroup: d?.subGroup
      }))
    } else {
      self.list = []
    }
  }

})).views((self) => ({
  getParameters() {
    return self.list
  }
}))


export default ParameterStore;
