import {types} from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";
import {TagModel} from "../tags/TagModel";

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string)
})

export const Utm = types.model('Utm', {
  uuid: types.identifier,
  name: types.optional(types.string, ""),
  label: types.maybe(types.string),
  campaign: types.optional(types.string, ""),
  source: types.optional(types.string, ""),
  medium: types.optional(types.string, ""),
  term: types.optional(types.string, ""),
  content: types.optional(types.string, ""),
  nameIsInvalid: types.optional(types.boolean, false),
  campaignIsInvalid: types.optional(types.boolean, false),
  sourceIsInvalid: types.optional(types.boolean, false),
  mediumIsInvalid: types.optional(types.boolean, false),
  isEditable: types.optional(types.boolean, false),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
  owner: types.maybe(GroupData),
  _audit: types.maybeNull(AuditMetaData),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  tags: types.optional(types.array(types.safeReference(types.late(() => TagModel))), [])

}).actions((self) => ({
  updateInput(inputType, e) {
    self[inputType] = e.target.value
    if (inputType === 'name' || inputType === 'campaign' || inputType === 'source' || inputType === 'medium') {
      self[`${inputType}IsInvalid`] = !self.doesInputHaveLength(inputType)
    }
  },
  setNameIsInvalid(bool) {
    self.nameIsInvalid = bool
  },
  hydrateUtm(utm) {
    self.name = utm?.name
    self.campaign = utm?.campaign
    self.source = utm?.source
    self.medium = utm?.medium
    self.term = utm?.term
    self.content = utm?.content
    self.campaignIsInvalid = false
    self.sourceIsInvalid = false
    self.mediumIsInvalid = false
  },
  validateUtmModel() {
    let isValid = true;
    if (self.campaign === "") {
      self.campaignIsInvalid = true;
      isValid = false;
    }
    if (self.source === "") {
      self.sourceIsInvalid = true;
      isValid = false;
    }
    if (self.medium === "") {
      self.mediumIsInvalid = true;
      isValid = false;
    }
    return isValid;
  },
})).views((self) => ({
  doesInputHaveLength(inputType) {
    return self[inputType]?.length > 0;
  },
  doesUtmHaveValue() {
    return !!(self.campaign?.length > 0 ||
      self.source?.length > 0 ||
      self.medium?.length > 0 ||
      self.term?.length > 0 ||
      self.content?.length > 0);
  },
  grabUtmForCode() {
    return Utm.create({
      uuid: "utmForCode",
      campaign: self.campaign,
      source: self.source,
      medium: self.medium,
      term: self.term,
      content: self.content,
    })
  },
  isInputInvalid(inputType) {
    return self[`${inputType}IsInvalid`]
  }

}));
