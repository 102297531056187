import React, {useState, useContext} from "react";
import {
  Button, Card,
  CardBody,
  CardHeader, CardFooter,
  CardTitle,
  Form,
  FormGroup, Input, CustomInput,
  Label,
  Nav, NavItem, NavLink,
  TabContent, TabPane,
  Row, Col, InputGroup, InputGroupAddon
} from "reactstrap";
import { observer } from "mobx-react-lite";
import { StyledDropzone } from "../../elements/fileUpload/StyledDropzone";
import TagSelect from "../../elements/tags/TagSelect";
import {useIntl, FormattedMessage } from "react-intl";
import GroupSelect from "../../elements/groups/GroupSelect";
import Select from "react-select";
import PublishDomainSelectView from "../../elements/publishDomain/PublishDomainSelectView";
import {StateContext} from "../../../App";
import QrConfigSelect from "../../elements/qrconfig/QrConfigSelect";
import CodeFilterSelect from "../../elements/filters/CodeFilterSelect";
import BatchSelect from "../../elements/batch/BatchSelect";
import QrDownloadView from "../../qr/qrpreview/qrDownload/QrDownloadView";
import {batchTypesConstatns, codeStatus, formConstants} from "../../../utils/constants";
import GenericModel, {ModalState} from "../../elements/modal/GenericModal";
import ScanbuyModal from "../../elements/modal/GenericModal";
/**
 Form Component to Upload Batch
 */
const BatchForm = observer(({ batchFromState }) => {
  const intl = useIntl();
  const stateContext = useContext(StateContext);
  batchFromState.setOnSelectChanges();
  batchFromState?.codeFilterSelectState.setEmails(batchFromState?.codeModels?.filters?.users);
  batchFromState?.codeFilterSelectState.setCodeTypes(batchFromState?.codeModels?.filters?.types);
  batchFromState?.codeFilterSelectState.setStatusTypes(batchFromState?.codeModels?.filters?.status);
  batchFromState?.codeFilterSelectState.setDomains(batchFromState?.codeModels?.filters?.domains);
  batchFromState?.codeFilterSelectState.setBehaviorTypes(batchFromState?.codeModels?.filters?.behaviors);
  const { title, handleSubmit, uploadFiles, handleCancel, downloadType, batchType } = batchFromState;
  // const [showTagDownloadBox, setShowTagDownloadBox] = useState(false);

  // const [activeTab, setActiveTab] = useState('files');
  // const toggleTab = (tabId) => {
  //   setActiveTab(tabId);
  //   batchFromState.setDownloadType(tabId);
  // }

  // const [buttonText, setButtonText] = useState('Upload');
  // const handleBatchTypeChange = (event) => {
  //   const selectedValue = event.target.value;
  //   batchFromState.setBatchType(selectedValue);
  //   if (selectedValue === 'Download') {
  //     setShowTagDownloadBox(true);
  //     setButtonText('Download');
  //   } else {
  //     setShowTagDownloadBox(false);
  //     setButtonText('Upload');
  //   }
  // };
  const availableSelectedTagOptions = batchFromState.availableNewSelectedTags.map(tag => ({
    value: tag.uuid,
    label: tag.name,
    // isDisabled: batchFromState.selectedTags.includes(tag.uuid) // we let user select everything
  }));
  const availableNewSelectedTagIds = batchFromState.availableNewSelectedTags;
  const updatedSelectedTagsOptions = availableSelectedTagOptions.filter(option => availableNewSelectedTagIds.includes(option.value));
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">{title}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Batch Name:</Label>
            <Input type="text"
                   name="batchName"
                   onChange={batchFromState.handleBatchNameChange}
                   value={batchFromState.batchName}
                   required/>
          </FormGroup>
          <FormGroup>
            <Label>
              Batch Type:
            </Label>
            {/* <Input
              type="select"
              id="batchType"
              name="batchType"
              onChange={handleBatchTypeChange}
            >
              <option>Create</option>
              <option>Download</option>
            </Input> */}
            <Select
              id="batchType"
              name="batchType"
              onChange={(data)=>batchFromState.setBatchType(data.value)}
              value={{ value: batchFromState.batchType, label: batchFromState.batchType }}
              options={[
                { value: batchTypesConstatns.CREATE, label: batchTypesConstatns.CREATE },
                { value: batchTypesConstatns.DOWNLOAD, label: batchTypesConstatns.DOWNLOAD },
                { value: batchTypesConstatns.EDIT, label: batchTypesConstatns.EDIT },
              ]}
              // defaultValue={{ value: batchTypesConstatns.CREATE, label: batchTypesConstatns.CREATE }}
            />
          </FormGroup>
          <hr/>
          {(batchType === batchTypesConstatns.DOWNLOAD || batchType === batchTypesConstatns.EDIT)&& (
            <>
              <Row>
                <Col sm={5}>
                <Label className="text-sm-left"><FormattedMessage id="batchForm.defaultSwitch.defaultMessage"/></Label>
                </Col>
                <Col sm={3}>
                  <CustomInput
                    type="switch"
                    id="isDefaultBatchOperation"
                    name="isDefaultBatchOperation"
                    checked={batchFromState?.isDefaultBatchOperation}
                    onChange={() => batchFromState.toggleIsDefaultBatchOperation()}
                  />
                </Col>
              </Row>
            </>)
          }
          {!batchFromState?.isDefaultBatchOperation || batchType === batchTypesConstatns.CREATE ? (
              <>
                <FormGroup>
                  <Label className="text-sm-right"><FormattedMessage id="createQr.groups.defaultMessage"/>:</Label>
                  <GroupSelect groupSelectStore={batchFromState.groupSelectStore}/>
                </FormGroup>
                {batchFromState?.dimensionSelectViewState?.dimensionsEnabled && batchFromState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => batchFromState.buildDimensions(dimensionSelectStore))}
              </>
            ) : (<></>)
          }

          {batchType === batchTypesConstatns.CREATE && (
            <>
              <FormGroup>
                <Label>
                  Code Experience:
                </Label>
                <Select
                  id="codeExperience"
                  name="codeExperience"
                  onChange={(data)=>batchFromState.setCodeType(data.value)}
                  options={[
                    { value: 'url', label: 'URL' },
                    { value: 'gs1', label: 'GS1' },
                  ]}
                  defaultValue={{ value: 'url', label: 'URL' }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Domain:
                </Label>
                <PublishDomainSelectView publishDomainSelectState={batchFromState.publishDomainSelectStore} configs={ stateContext?.appConfigs?.configs }/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Tags:
                </Label>
                <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
              </FormGroup>
              <FormGroup>
                <Label>
                  Code Status:
                </Label>
                <Select
                  id="codeStatus"
                  name="codeStatus"
                  onChange={(data)=>batchFromState.setCodeStatus(data.value)}
                  options={[
                    { value: 'DRAFT', label: 'DRAFT' },
                    { value: 'PUBLISHED', label: 'PUBLISHED' }
                  ]}
                  defaultValue={{ value: 'PUBLISHED', label: 'PUBLISHED' }}
                />
              </FormGroup>
            </>
          )}
          {(batchType === batchTypesConstatns.DOWNLOAD || batchType === batchTypesConstatns.EDIT) && (
            <>
              {!batchFromState?.isDefaultBatchOperation && (
                  <>
                    <FormGroup>
                      <Label>
                        Tags:
                      </Label>
                      <Row>
                        <Col sm={10}>
                          <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
                        </Col>
                        <Col sm={2}>
                          <CustomInput
                            type="switch"
                            id="isFilterTagTypeOR"
                            name="isFilterTagTypeOR"
                            label={formConstants.Mode.OR}
                            checked={batchFromState.isFilterTagTypeOR}
                            onChange={() => batchFromState.selectFilterTagMethod()}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Filter:
                      </Label>
                      <InputGroup className="mb-3">
                        {/* <InputGroupAddon addonType="prepend">
                          {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                        </InputGroupAddon> */}
                        <CodeFilterSelect codeFilterSelectState={batchFromState.codeFilterSelectState}/>
                      </InputGroup>
                    </FormGroup>
                  </>)}



             {batchFromState?.isDefaultBatchOperation && (
              <>
                <FormGroup>
                  <Label>
                    Batch Name:
                  </Label>
                  <InputGroup className="mb-3">
                    <BatchSelect batchSelectStore={batchFromState.batchSelectStore}/>
                  </InputGroup>
                </FormGroup>
              </>)
              }
              {batchType === batchTypesConstatns.DOWNLOAD && (
                <>
                  <FormGroup>
                      <Label>
                        Designer Template:
                      </Label>
                      <InputGroup className="mb-3">
                        <QrConfigSelect store={batchFromState.qrConfigSelectStore} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Image Format:
                      </Label>
                      {/* <Select
                        id="imageFormat"
                        name="imageFormat"
                        onChange={(data)=>batchFromState.setImageFormat(data.value)}
                        options={[
                          { label: "PNG", value: "png" },
                          { label: "JPG", value: "jpg" },
                          { label: "BMP", value: "bmp" },
                          { label: "EPS", value: "eps" },
                          { label: "GIF", value: "gif" },
                          { label: "PDF", value: "pdf" },
                          { label: "SVG", value: "svg" }
                        ]}
                        defaultValue={{ label: "PNG", value: "png" }}
                      /> */}
                    <QrDownloadView store={batchFromState} />
                  </FormGroup>
                </>
              )}
{/* --------------------------------------------------------------------- Edit Type ------------------------------------------------------------- */}
              {batchType === batchTypesConstatns.EDIT && (
                <>
                  <hr/>
                  <FormGroup>
                    <Label>
                      Edit Type:
                    </Label>
                    <Select id="editType"
                            name="editType"
                            onChange={(data)=>batchFromState.setEditType(data.value)}
                            options={[
                              { value: 'status', label: 'Status' },
                              { value: 'tag', label: 'Tag' },
                              { value: 'url', label: 'URL' },
                            ]}
                            defaultValue={{ value: 'status', label: 'Status' }}
                    />

                  </FormGroup>
                  {batchFromState.editType === 'url' && (
                    <FormGroup>
                      <Label>
                        New URL:
                      </Label>
                      <InputGroup className="mb-3">
                        <Input type="text" name="url" value={batchFromState.editValue} onChange={(e)=>batchFromState.setEditValue(e.target.value)} required/>
                      </InputGroup>
                    </FormGroup>
                  )}
                  {batchFromState.editType === 'status' && (
                    <FormGroup>
                      <Label>
                        Change Status To:
                      </Label>
                      <Select id="status"
                              name="status"
                              onChange={(data)=>batchFromState.setEditValue(data.value)}
                              options={[
                                { value: codeStatus.COMPLETED, label: codeStatus.COMPLETED },
                                { value: codeStatus.PAUSED, label: codeStatus.PAUSED },
                                { value: codeStatus.PUBLISHED, label: codeStatus.PUBLISHED }
                              ]}
                      />
                    </FormGroup>
                  )}
                  {batchFromState.editType === 'tag' && (
                    <>
                        <FormGroup>
                        <Label>
                          Tag Operation:
                        </Label>
                        <Select id="tagOperation"
                                name="tagOperation"
                                onChange={(data)=>batchFromState.setTagOperation(data.value)}
                                options={[
                                  { value: 'ADD', label: 'Add' },
                                  { value: 'REPLACE', label: 'Replace' },
                                  { value: 'REMOVE', label: 'Remove' }
                                ]}
                                defaultValue={{ value: 'ADD', label: 'Add' }}
                                />
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          Tag:
                        </Label>
                        <Select id="newSelectedTags"
                                name="newSelectedTags"
                                onChange={updatedSelectedTagsOptions => {
                                  const updatedSelectedTags = updatedSelectedTagsOptions ? updatedSelectedTagsOptions.map(option => option.value) : [];
                                  batchFromState.setUpdatedSelectedTags(updatedSelectedTags);
                                }}
                                options={availableSelectedTagOptions}
                                isMulti
                                />
                      </FormGroup>
                    </>
                  )}
                </>
              )}
              {!batchFromState?.isDefaultBatchOperation && (
                  <>
                    <FormGroup>
                      <Label>
                        Total Codes:
                      </Label>
                      {batchFromState.downloadCounts}
                    </FormGroup>
                  </>
                )
              }
            </>
          )}
          {!batchFromState.showTagDownloadBox && (
          <div className="d-flex justify-content-between align-items-center">
            <Nav tabs>
              <NavItem>
                <NavLink className={batchFromState.activeTab === 'files' ? 'active' : ''}
                        onClick={() => batchFromState.setActiveTab('files')}>
                  Select CSV File :
                </NavLink>
              </NavItem>
            </Nav>
            <div>
              {(batchFromState?.selectedCodeType === 'gs1') && (
                <>
                  <a href="https://cdn.scanbuy.com/apps/cmp-v2/files/batch_create_gs1_example_file.csv" download>Download Template</a>
                </>
              )}
              {(batchFromState?.selectedCodeType === 'url') && (
                <>
                  <a href="https://cdn.scanbuy.com/apps/cmp-v2/files/batch_create_url_example_file.csv" download>Download Template</a>
                </>
              )}
              
            </div>
          </div>
          )}
          <TabContent activeTab={batchFromState.activeTab}>
            {!batchFromState.showTagDownloadBox && (
            <TabPane tabId="files">
              <FormGroup>
                <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />
              </FormGroup>
            </TabPane>)}
            {/* tag tab */}
            {/* {batchFromState.showTagDownloadBox && (
            <TabPane tabId="tags">
              <FormGroup>
                <Row className="p-1">
                  <Col>
                    <div className="custom-controls-stacked">
                      <TagSelect tagSelectStore={batchFromState.tagSelectStore}/>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </TabPane>)} */}
          </TabContent>
          {/*<FormGroup>*/}
          {/*  <Label>*/}
          {/*    Select File :*/}
          {/*  </Label>*/}
          {/*  <Label>*/}
          {/*    Enter Tags:*/}
          {/*  </Label>*/}
          {/*  <StyledDropzone id="dropzone" name="dropzone" setUploadFiles={uploadFiles} />*/}
          {/*  {showTagDownloadBox && (*/}
          {/*    <FormGroup>*/}
          {/*      <Input type="text" name="tags" />*/}
          {/*    </FormGroup>*/}
          {/*  )}*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <Button color="danger" onClick={handleCancel}>Cancel</Button>
            <Button color="primary" className="float-right" onClick={batchFromState.buttonText === 'edit' ? batchFromState.handleConfigModalPopUp() : handleSubmit}>{batchFromState.buttonText}</Button>
          </FormGroup>
        </Form>
      </CardBody>
      {/* <CardFooter> <FormattedMessage id="batchForm.alertInfo.defaultMessage"/></CardFooter> */}
      {batchFromState.formHasErrors && <div className="alert alert" style={{
          fontSize: "20px",
          backgroundColor: "#faada7",
          width: "80%",
          marginLeft: "10%",
          padding:"5px",
          paddingLeft:"8px",
          paddingRight:"8px"
        }} role="alert">
          <div>{batchFromState.errorMessage}</div>
        </div>}
      {/* <ScanbuyModal
          modalStore={batchFromState.modalStore}
          componentStore={batchFromState.modalComponentStore}
          EmbedComponent={batchFromState.modalComponent}
          size={batchFromState.modalSize}
          className={batchFromState.modalClassName}
        />    */}
    </Card>
  );
});


export default BatchForm;
