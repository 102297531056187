import {types} from "mobx-state-tree";

const CodeType = types.model('CodeType',{
    uuid: types.identifier,
    label: types.string,
    behavior: types.array(types.string),
    icon: types.optional(types.string,""),
    name: types.string,
    schema: types.optional(types.string, ""),
    uiSchema: types.optional(types.string, "")
    //,versionTag: types.optional(types.string,"")
})



export default CodeType;
