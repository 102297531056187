import {flow, types, getEnv} from "mobx-state-tree";

export const DeleteDesignState = types.model('DeleteDesignState', {
  // formHasErrors: types.optional(types.boolean, false),
  // errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
  .volatile(self => ({
    selectedCallback: types.fn,
    selectedSecondCallback: types.fn,
    deleteDesignCallBack: types.fn

  }))
  .actions(self => ({
    setDeleteDesignListener(listener) {
      self.selectedCallback = listener
    },
    setSecondDeleteDesignListener(listener) {
      self.selectedSecondCallback = listener
    },
    setDeleteDesignListenerInConfigStore(fn) {
      self.deleteDesignCallBack = fn
    },
    notifyCallBack() {
      self.selectedCallback()
    },
    notifySecondCallBack(str) {
      self.selectedSecondCallback(str)
    },
    notifyDeleteDesignCallBack(uuid) {
      self.deleteDesignCallBack(uuid)
    },

    deleteDesign: flow(function* f()  {
      const imageConfigManager = getEnv(self).imageConfigManager
      console.log("imageConfigManager",imageConfigManager)
      const res = yield imageConfigManager.delete(self.uuid)
      if (res) {
        self.notifyCallBack()
        self.selectedSecondCallback(self.uuid)
        self.deleteDesignCallBack(self.uuid)
      }
    }),

  }))

