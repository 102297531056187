import {types} from "mobx-state-tree";
import React from "react";
import DimensionStore from "../../../../stores/domain/DimensionStore";
import lodash from "lodash";

export const DimensionsMultiSelectState = types.model('DimensionsMultiSelectState', {
  selectStateUuid: types.maybe(types.identifier),
  dimensionStore: types.maybe(types.late(() => types.reference(DimensionStore))),
  isDisabled: types.optional(types.boolean, false),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
  parentDimensionUuids: types.array,
  transpiledDimensions: types.string,
  filteredDimensionValuesOnGroup: [],
})).actions((self) => ({
  init(){
    self.selected = []
    self.parentDimensionUuids = []
    self.transpiledDimensions = '{}'
  },
  setDefaults(){
    self.setParentDimensionUuids()
    self.selectAllDimensions()
  },
  setIsDisabled(d) {
    self.isDisabled = d;
  },
  reset() {
    self.selected = []
    self.transpiledDimensions = '{}'
    self.setIsDisabled(false);
  },
  selectAllDimensions(){
    const transpiledDimensions = {}
    self.parentDimensionUuids.forEach(uuid => {
      transpiledDimensions[uuid] = [];
    });
    self.transpiledDimensions = JSON.stringify(transpiledDimensions)
  },
  setParentDimensionUuids(){
    const {dimensions} = self.dimensionStore || [];
    const parentDimensionUuids = []
    dimensions?.forEach((dimension) => {
      !parentDimensionUuids.includes(dimension?.uuid) && parentDimensionUuids.push(dimension?.uuid)
    })
    self.parentDimensionUuids = parentDimensionUuids
  },
  getTranspiledDimensions(){
    return JSON.parse(self.transpiledDimensions)
  },
  hydrateDimensions(data){
    const options = self.optionValues();
    const hydratedValues = self.transformToSelectFormat(data, options);
    self.setSelectedDimension(hydratedValues)
  },
  transformToSelectFormat(data, options) {
  const selectedValues = [];

  options.forEach(group => {
    group.options.forEach(option => {
      const dimensionUuid = option.value.split('.')[1].split('_')[0];
      const itemUuid = option.value.split('_')[1];

      if (data[dimensionUuid]?.includes(itemUuid)) {
        selectedValues.push(option);
      }
    });
  });

  return selectedValues;
},
setSelectedDimension(selectedDimension) {
    self.selected = selectedDimension
    const reducedDimensions = {};
    self.parentDimensionUuids.forEach(uuid => {
      reducedDimensions[uuid] = [];
    });
    selectedDimension?.forEach(item => {
      const [_, combinedUuid] = item.value.split('.');
      const [parentUuid, childUuid] = combinedUuid.split('_');
      if (!reducedDimensions[parentUuid]) {
        reducedDimensions[parentUuid] = [];
      }

      if (!reducedDimensions[parentUuid].includes(childUuid)) {
        reducedDimensions[parentUuid].push(childUuid);
      }
    })
    self.transpiledDimensions = JSON.stringify(reducedDimensions)
    if (self.notifySelect) {
      self.notifySelect(reducedDimensions, ["dimensions"]);
    }
  },
  setConsumer(f) {
    self.notifySelect = f
  },
  getListOnGroup(groupUuid) {
    const dimensionUuids = self.dimensionStore?.dimensions
      .filter(dimension => dimension?.type === "CONDITIONAL")
      .map(dimension => dimension?.uuid)

    self.filteredDimensionValuesOnGroup = []
    const conditionalDimensions =  dimensionUuids.map(uuid => self.dimensionStore?.dimensions?.find(item => item?.uuid === uuid))
    conditionalDimensions.forEach(dimension => {
      let filteredValues = dimension?.values?.filter(value => {
        const groupUuids = value?.owner?.groupUuids;
        return groupUuids?.includes(groupUuid) || !groupUuids || groupUuids.length === 0;
      });

      if(lodash.isEmpty(filteredValues)) {
        // find the default value
        filteredValues = dimension?.values?.find(value => value?.isDefault);
      }
      self.filteredDimensionValuesOnGroup.push({
        ...dimension,
        values: filteredValues,
      });
    })
  },
})).views((self) => ({
  getLabels() {
    const dimensions = self?.dimensionStore?.dimensions || [];
    if (dimensions.length) {
      const labels = dimensions.map(d => d?.label);
      const lastIndex = labels.length - 1;
      let last = labels.pop();
      last = lastIndex > 0 ? ' & ' + last : last;
      return labels.join(', ') + last;
    } else {
      return "";
    }
  },
  getLabelsWithPrefix(prefix) {
    const labels = self.getLabels();
    return `${prefix || ""} ${labels ? labels : 'Dimension'}`;
  },
  optionValues() {

    const {dimensions, dimensionsEnabled} = self.dimensionStore || [];
    const dimensionOptions = dimensions?.map((dimension) => {
      let values = dimension?.values || []
      // if the dimension is conditional then filter the values based on the groupUuid
      const filteredDimension = self.filteredDimensionValuesOnGroup?.find(item => item?.uuid === dimension?.uuid);
      if (filteredDimension) {
        values = filteredDimension.values;
        // Ensure values is an array
        if (!Array.isArray(values)) {
          values = [values];
        }
      }

      return {
        uuid: dimension?.uuid,
        label: dimension.label,
        options: values?.map(item => {
          return {
            label: item?.label,
            value: 'dimensions.' + dimension?.uuid + '_' + item?.uuid,
          }
        })
      }
    });


    const options = [
      ...dimensionsEnabled ? [...dimensionOptions] : []
    ];
    return options;
  }
}))
