import React from "react";
import {observer} from "mobx-react-lite";
import Select from "react-select";

const DimensionSelect = observer(({dimensionSelectStore, disabled = false}) => {
  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      defaultValue={dimensionSelectStore?.selectedDimension ? dimensionSelectStore.selectedDimension : {}}
      options={dimensionSelectStore?.dimensionOptionValues()}
      isSearchable
      onChange={(data) => dimensionSelectStore?.setSelectedDimension(data)}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.uuid}
      placeholder="Select"
      isDisabled={disabled}
    />

  );
});

export default DimensionSelect;
