import React from "react";
import {ParameterSetsList} from "../../models/parameterSets/ParameterSetsListModel";
import async from "../../components/Async";
import IosPaper from "react-ionicons/lib/IosPaper";
import {buildParameterSetsColumns} from "../../components/configs/parametersets/list/ParameterSetsListView";
import {ModalState} from "../../components/elements/modal/GenericModal";
import {ParameterSetsListViewState} from "../../components/configs/parametersets/list/ParameterSetsListViewState";
import {RouterState, RouterStore} from "mobx-state-router";
import {CreateUtmState} from "../../components/utms/create/CreateUtmState";
import {Utm} from "../../models/utms/UtmModel";
import {CreateParameterSetState} from "../../components/configs/parametersets/create/CreateParameterSetState";
import {EditUtmState} from "../../components/utms/edit/EditUtmState";
import {EditParameterSetState} from "../../components/configs/parametersets/edit/EditParameterSetState";
import ParameterStore from "../../stores/domain/ParameterStore";
import {CodeParamsTypeSelectState} from "../../components/elements/parameters/codeType/CodeParamsTypeSelectState";
import CodeParamsStore from "../../stores/domain/parameters/CodeParamsStore";
import {ScanParamsTypeSelectState} from "../../components/elements/parameters/scanType/ScanParamsTypeSelectState";
import ScanParamsStore from "../../stores/domain/parameters/ScanParamsStore";
import CustomParamsStore from "../../stores/domain/parameters/CustomParamsStore";
import CountParamsStore from "../../stores/domain/parameters/CountParamsStore";
import PremiumParamsStore from "../../stores/domain/parameters/PremiumParamsStore";
import {CountParamsTypeSelectState} from "../../components/elements/parameters/countType/CountParamsTypeSelectState";
import {
  PremiumParamsTypeSelectState
} from "../../components/elements/parameters/premiumType/PremiumParamsTypeSelectState";
import {CustomParamsTypeSelectState} from "../../components/elements/parameters/customType/CustomParamsTypeSelectState";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";

// Page Async Reference, for lazy loading in UI
const ParameterSetsListPage = async(() => import("../../pages/parameterSets/ParameterSetsListHome"));
const CreateParameterSetHome = async(() => import("../../pages/parameterSets/CreateParameterSetHome"));
const EditParameterSetHome = async(() => import("../../pages/parameterSets/EditParameterSetHome"));
const parametersList = []

export const viewMap = {
  parameterSets: <ParameterSetsListPage/>,
  createParameterSet: <CreateParameterSetHome/>,
  editParameterSet: <EditParameterSetHome/>,
};
export const routeDefinitions = [
  {
    path: "parameterSets",
    name: "Parameter Sets",
    icon: IosPaper,
    component: ParameterSetsListPage,
    children: null,
    // featureFlagRequired: "cmp_ui_parametersets"
  }
];
export const routeTransitions = [
  {
    name: "parameterSets",
    pattern: "/configs/parameterSets",
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const parameterSetStore = rootStore.domainStore.parameterSetStore
      const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
      const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
      const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
      const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
      advFilterSelectState.reset();
      dimensionSelectViewState.reset();
      tagFilterSelectState.reset();
      groupFilterSelectState.reset();
      const parameterSetsList = ParameterSetsList.create({
        store: parameterSetStore
      });
      const modalState = ModalState.create({});

      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      tagSelectStore?.tagStore?.init(); //reset tags
      tagSelectStore?.setSelectedTags([]);

      const parameterSetsListViewState = ParameterSetsListViewState.create({
        listModel: parameterSetsList,
        groupSelectStore: groupSelectState,
        modalStore: modalState,
        advFilterSelectState: advFilterSelectState,
        dimensionSelectViewState: dimensionSelectViewState,
        tagSelectStore: tagSelectStore,
        tagFilterSelectState: tagFilterSelectState,
        groupFilterSelectState: groupFilterSelectState
      });

      parameterSetsListViewState.setColumns(buildParameterSetsColumns(routerStore, parameterSetsListViewState))
      await rootStore.rootUiStore.attachPageState("parameterSetsListViewState", parameterSetsListViewState);
      parameterSetsListViewState.refresh();
      await parameterSetsListViewState?.getFilters();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
    }
  },
  {
    name: 'createParameterSet',
    pattern: '/configs/parameterSets/create',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      let {queryParams} = toState;
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore;
      const tenantStore = rootStore.domainStore.tenantStore;
      groupSelectStore?.selectDefaultGroup(tenantStore.defaultGroupUuid);


      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;

      const codeParamsStore = CodeParamsStore.create({
        storeUuid: "codeParamsStore"
      })
      const scanParamsStore = ScanParamsStore.create({
        storeUuid: "scanParamsStore"
      })
      const countParamsStore = CountParamsStore.create({
        storeUuid: "countParamsStore"
      })
      const premiumParamsStore = PremiumParamsStore.create({
        storeUuid: "premiumParamsStore"
      })
      const customParamsStore = CustomParamsStore.create({
        storeUuid: "customParamsStore"
      })
      await rootStore.rootUiStore.attachPageState('codeParamsStore', codeParamsStore)
      await rootStore.rootUiStore.attachPageState('scanParamsStore', scanParamsStore)
      await rootStore.rootUiStore.attachPageState('countParamsStore', countParamsStore)
      await rootStore.rootUiStore.attachPageState('premiumParamsStore', premiumParamsStore)
      await rootStore.rootUiStore.attachPageState('customParamsStore', customParamsStore)

      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone-parameterSet:${queryParams.q}`]:null
      let createParameterSetState;
      if(snapShot) {
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(snapShot?.owner?.uuid);

        createParameterSetState = CreateParameterSetState.create({
          groupSelectStore: groupSelectStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
          tagSelectStore:tagSelectStore,
          parameterStore: ParameterStore.create({
            storeUuid: "parameterStore"
          }),
          codeParamsTypeSelectState: CodeParamsTypeSelectState.create({
            selectStateUuid: "codeParamsTypeSelectState",
            store: rootStore.rootUiStore.codeParamsStore
          }),
          scanParamsTypeSelectState: ScanParamsTypeSelectState.create({
            selectStateUuid: "scanParamsTypeSelectState",
            store: rootStore.rootUiStore.scanParamsStore
          }),
          countParamsTypeSelectState: CountParamsTypeSelectState.create({
            selectStateUuid: "countParamsTypeSelectState",
            store: rootStore.rootUiStore.countParamsStore
          }),
          premiumParamsTypeSelectState: PremiumParamsTypeSelectState.create({
            selectStateUuid: "premiumParamsTypeSelectState",
            store: rootStore.rootUiStore.premiumParamsStore
          }),
          customParamsTypeSelectState: CustomParamsTypeSelectState.create({
            selectStateUuid: "customParamsTypeSelectState",
            store: rootStore.rootUiStore.customParamsStore
          }),
        })
      } else {
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(groupSelectStore.selectedGroup?.uuid);
        createParameterSetState = CreateParameterSetState.create({
          groupSelectStore: groupSelectStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState,
          tagSelectStore:tagSelectStore,
          parameterStore: ParameterStore.create({
            storeUuid: "parameterStore"
          }),
          codeParamsTypeSelectState: CodeParamsTypeSelectState.create({
            selectStateUuid: "codeParamsTypeSelectState",
            store: rootStore.rootUiStore.codeParamsStore
          }),
          scanParamsTypeSelectState: ScanParamsTypeSelectState.create({
            selectStateUuid: "scanParamsTypeSelectState",
            store: rootStore.rootUiStore.scanParamsStore
          }),
          countParamsTypeSelectState: CountParamsTypeSelectState.create({
            selectStateUuid: "countParamsTypeSelectState",
            store: rootStore.rootUiStore.countParamsStore
          }),
          premiumParamsTypeSelectState: PremiumParamsTypeSelectState.create({
            selectStateUuid: "premiumParamsTypeSelectState",
            store: rootStore.rootUiStore.premiumParamsStore
          }),
          customParamsTypeSelectState: CustomParamsTypeSelectState.create({
            selectStateUuid: "customParamsTypeSelectState",
            store: rootStore.rootUiStore.customParamsStore
          }),
        })
      }

      await rootStore.rootUiStore.attachPageState('createParameterSetState', createParameterSetState);
      await createParameterSetState.fetchAllParameters()
      if(snapShot) {
        await createParameterSetState.fetchParameterSetWithID(snapShot?.uuid)
      }
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'editParameterSet',
    pattern: '/configs/parameterSets/edit/:uuid',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {params, queryParams: {isEditable: isEditableStr}} = toState
      const isEditable = isEditableStr !== 'false';
      const isView = isEditable !== undefined ? !isEditable : false

      const {rootStore} = routerStore.options;

      const groupSelectState = rootStore.rootUiStore.groupSelectStore;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();
      const codeParamsStore = CodeParamsStore.create({
        storeUuid: "codeParamsStore"
      })
      const scanParamsStore = ScanParamsStore.create({
        storeUuid: "scanParamsStore"
      })
      const countParamsStore = CountParamsStore.create({
        storeUuid: "countParamsStore"
      })
      const premiumParamsStore = PremiumParamsStore.create({
        storeUuid: "premiumParamsStore"
      })
      const customParamsStore = CustomParamsStore.create({
        storeUuid: "customParamsStore"
      })
      await rootStore.rootUiStore.attachPageState('codeParamsStore', codeParamsStore)
      await rootStore.rootUiStore.attachPageState('scanParamsStore', scanParamsStore)
      await rootStore.rootUiStore.attachPageState('countParamsStore', countParamsStore)
      await rootStore.rootUiStore.attachPageState('premiumParamsStore', premiumParamsStore)
      await rootStore.rootUiStore.attachPageState('customParamsStore', customParamsStore)


      const editParameterSetState = EditParameterSetState.create({
        uuid: params.uuid,
        groupSelectStore: groupSelectState,
        tagSelectStore: tagSelectStore,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        isView: isView,
        parameterStore: ParameterStore.create({
          storeUuid: "parameterStore"
        }),
        codeParamsTypeSelectState: CodeParamsTypeSelectState.create({
          selectStateUuid: "codeParamsTypeSelectState",
          store: rootStore.rootUiStore.codeParamsStore
        }),
        scanParamsTypeSelectState: ScanParamsTypeSelectState.create({
          selectStateUuid: "scanParamsTypeSelectState",
          store: rootStore.rootUiStore.scanParamsStore
        }),
        countParamsTypeSelectState: CountParamsTypeSelectState.create({
          selectStateUuid: "countParamsTypeSelectState",
          store: rootStore.rootUiStore.countParamsStore
        }),
        premiumParamsTypeSelectState: PremiumParamsTypeSelectState.create({
          selectStateUuid: "premiumParamsTypeSelectState",
          store: rootStore.rootUiStore.premiumParamsStore
        }),
        customParamsTypeSelectState: CustomParamsTypeSelectState.create({
          selectStateUuid: "customParamsTypeSelectState",
          store: rootStore.rootUiStore.customParamsStore
        }),
      });
      await rootStore.rootUiStore.attachPageState('editParameterSetState', editParameterSetState)
      await editParameterSetState.fetchAllParameters()
      await editParameterSetState.fetchWithID()
    }
  },
]

