import React from "react";
import {
  Badge,
  Button,
  Card, CardBody, Col, DropdownItem, InputGroup, InputGroupAddon, Row, UncontrolledTooltip,  CustomInput , Input
} from "reactstrap";
import {observer} from "mobx-react-lite";
import {FormattedMessage, useIntl} from "react-intl";
import * as Icon from "react-feather";
import ScanbuyTable, {TableColumn} from "../../tables/ScanbuyTable";
import TableActionsDropdown from "../../tables/TableActionsDropdown";
import GroupSelect from "../../elements/groups/GroupSelect";
import {useRouterStore} from "mobx-state-router";
import DeleteRuleSetState from "../../configs/rulesets/delete/DeleteRuleSetState";
import DeleteRuleSetView from "../../configs/rulesets/delete/DeleteRuleSetView";
import DeleteUtmState from "../delete/DeleteUtmState";
import DeleteUtmView from "../delete/DeleteUtmView";
import GenericModal from "../../elements/modal/GenericModal";
import dayjs from "dayjs";
import {createdByColumn} from "../../tables/TableColumns";
import {getSnapshot} from "mobx-state-tree";
import AdvancedConfFilterSelect from "../../elements/filters/AdvancedConfFilterSelect";
import CodeOwnerFilterSelect from "../../elements/filters/CodeOwnerFilterSelect";
import {buildDimensionFilters} from "../../../utils/buildDimensionFilters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faAngleDoubleUp} from "@fortawesome/free-solid-svg-icons";

import TagSelect from "../../elements/tags/TagSelect";
import {ADVANCED_SETTINGS_MANAGE_SET, formConstants} from "../../../utils/constants"
import TagFilterSelect from "../../elements/filters/TagFilterSelect";
import GroupFilterSelect from "../../elements/filters/GroupFilterSelect";
import {requiredRolesCheck} from "../../../utils/requiredRolesCheck";

export const buildUtmsColumns = (routerStore,utmsListViewState) => {

  const {rootStore} = routerStore.options;

  const c1 = TableColumn.create({name: 'Utm', property: 'utm', sortable: true, sortField: 'name'});
  c1.setSelector((row) =>{
    return(
      <>
      <span id={`tooltip-${row.uuid}`}>{row.label}</span>
      <UncontrolledTooltip target={`tooltip-${row.uuid}`} placement="top">{row.label}</UncontrolledTooltip>
      </>
    )})
  const c2 = TableColumn.create({name: 'Default', property: 'isDefault'});
  // c2.setSelector((row) => row.isDefault.toString());

  const actionColl = TableColumn.create({
    name: 'Actions', property: 'actions', button: true, allowOverflow: true, ignoreRowClick: true
  });
  const c3 = TableColumn.create({
    name: 'Tag(s)', property: 'tags', hide: 'sm'
  });
  c3.setSelector((row) => {
    return row.tags;
  });
  c3.setCell((row) => {
    const tags = row?.tags?.flatMap((t) => {
      return {uuid: t?.uuid, name: t?.name, color: t?.uiStyleConfigs?.color || 'info'}
    });
    return (<div>
      {tags.map((tag, index) => (<Badge
        key={index}
        color="info"
        className="badge-pill mr-1 mb-1"
      >
        {tag.name}
      </Badge>))}
    </div>)
  })
  const c4 = TableColumn.create({
    name: 'Create Date', property: 'created', width: '15%', hide: 'sm',sortable: true, sortField: 'createdAt',
  });
  c4.setSelector((row) => {
    // console.log(row)
    const {createdAt} = row || {};
    const formattedCreateDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedCreateDate;
  });

  const c5 = TableColumn.create({
    name: 'Last Modified', property: 'updated', width: '15%', hide: 'sm', sortable: true, sortField: 'updatedAt',
  });
  c5.setSelector((row) => {
    // console.log(row)
    const { updatedAt } = row || {};
    const formattedUpdateDate = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
    return formattedUpdateDate;
  });

  const createdBy = createdByColumn();


  actionColl.setCell((row) => (
    <TableActionsDropdown actionsList={[
      <DropdownItem id="action-btn-view" key={'/configs/utms/view/' + row.uuid} onClick={() => {
        routerStore.goTo('editUtm', {
          params: { uuid: row.uuid },
          queryParams: {isEditable: 'false'}
        });
      }}><Icon.FileText/>&nbsp;View</DropdownItem>,
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-edit" key={'/configs/utms/edit/' + row.uuid} onClick={() => {
        routerStore.goTo('editUtm', {
          params: { uuid: row.uuid },
        });
      }}><Icon.Edit/>&nbsp;Edit</DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-clone" key={'new'} onClick={() => {
        const snapshot = getSnapshot(row);
        rootStore.rootUiStore.addCloneToCache(`clone-utm:${row.uuid}`,snapshot);
        routerStore.goTo('createUtm',{
          queryParams: {q: row.uuid}
        })
      }}><Icon.Copy/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.clone.defaultMessage"/>
      </DropdownItem>]: []),
      ...(row.isEditable ? [
      <DropdownItem id="action-btn-delete" key={row.uuid} value={"Delete " + row.uuid} className="align-text-bottom"
                    onClick={() => {

                      const userRoles = rootStore?.userRoles
                      if(!requiredRolesCheck(ADVANCED_SETTINGS_MANAGE_SET, userRoles)) return

                      const componentStore = DeleteUtmState.create(
                        {
                          utmName: row.name,
                          title: `Delete ${row.name ? row.name : ""}`,
                          uuid: row.uuid
                        });
                      rootStore.rootUiStore.attachPageState('deleteUtmState', componentStore)

                      componentStore.setToggleListener(utmsListViewState.modalStore.toggle)
                      componentStore.setRefreshListener(utmsListViewState.refresh)
                      utmsListViewState.handleRowClick(row, componentStore, DeleteUtmView);
                    }}> <Icon.Delete/>&nbsp;<FormattedMessage id="listCodes.actions.dropdown.delete.defaultMessage"/>
      </DropdownItem>]: [])

    ]}/>
  ))
  return [

    c1,
    // c2,
    c3,
    createdBy,
    c4,
    c5,
    actionColl,
  ];
}

const UtmsListView = observer(({utmsListViewState}) => {
  const routerStore = useRouterStore();
  const intl = useIntl();
    console.log("utmStore", utmsListViewState.columns);
    console.log("utmStore", utmsListViewState);
    utmsListViewState.setOnSelectChanges()
    utmsListViewState.listModel.store.setFilterOn(utmsListViewState.groupSelectStore?.selectedGroup?.uuid)
    utmsListViewState?.advFilterSelectState?.setEmails(utmsListViewState.listModel?.filters?.users);
    utmsListViewState?.tagFilterSelectState?.setTags(utmsListViewState.listModel?.filters?.tags);
    utmsListViewState?.filterGroup && utmsListViewState?.groupFilterSelectState?.setGroups(utmsListViewState.listModel?.filters?.groups);
    utmsListViewState?.dimensionSelectViewState?.setFilteredDimensions(utmsListViewState?.listModel?.filters.dimensions);

    const customStyles = {
      headRow: {
        style: {
          border: 'none',
        },
      },
      headCells: {
        style: {
          color: '#202124',
          fontSize: '14px',
        },
      },
      rows: {
        highlightOnHoverStyle: {
          backgroundColor: 'rgb(230, 244, 244)',
          borderBottomColor: '#FFFFFF',
          outline: '1px solid #FFFFFF',
        },
      },
      pagination: {
        style: {
          border: 'none',
        },
      },
    }

    return (
      <>
        <Card>
          <CardBody>
            <Row>
              <Col className="text-right">
                {utmsListViewState.isFilterVisible ? <Button id="codelist-dropdown-open" color="primary" onClick={()=> {utmsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
                  : <Button id="codelist-dropdown-closed" color="primary" onClick={()=> {utmsListViewState.toggleFilterVisible()}}><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>}
              </Col>
            </Row>
            {utmsListViewState.isFilterVisible && <>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "filterByGroups.defaultMessage"})}
                    </InputGroupAddon>
                    {/*<GroupSelect groupSelectStore={utmsListViewState?.groupSelectStore} multi={true}/>*/}
                    <GroupFilterSelect groupFilterSelectState={utmsListViewState.groupFilterSelectState}/>
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.filter.filterName.defaultMessage"})}
                    </InputGroupAddon>
                    <AdvancedConfFilterSelect advFilterSelectState={utmsListViewState.advFilterSelectState}/>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                {utmsListViewState?.dimensionSelectViewState?.dimensionsEnabled && utmsListViewState?.dimensionSelectViewState?.dimensionSelectStates?.map(dimensionSelectStore => buildDimensionFilters(dimensionSelectStore))}
              </Row>
              <Row>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({id: "listCodes.searchInput.defaultMessage"})}
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="searchInput"
                      placeholder="Search"
                      onChange={utmsListViewState.onSearchChange}
                    />
                  </InputGroup>
                </Col>
                <Col sm={5}>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      {intl.formatMessage({ id: "listCodes.filterBytags.defaultMessage"})}
                    </InputGroupAddon>
                    {/*<TagSelect tagSelectStore={utmsListViewState?.tagSelectStore}/>*/}
                    <TagFilterSelect tagFilterSelectState={utmsListViewState.tagFilterSelectState}/>
                  </InputGroup>
                </Col>
                <Col sm = {1}>
                  <CustomInput
                    type="switch"
                    id="isFilterTypeOR"
                    name="isFilterTypeOR"
                    label={formConstants.Mode.OR}
                    checked={utmsListViewState.isFilterTypeOR}
                    onChange={() => utmsListViewState.selectFilterMethod()}
                  />
                </Col>
                <Col  sm={1} className="justify-content-end text-right" >
                  <Button id="create-utm-btn" color="primary" onClick={() => {
                    routerStore.goTo('createUtm', {});
                  }}>
                    <FormattedMessage id="qrDesign.manageList.create.defaultMessage"/>
                  </Button>
                </Col>
              </Row>
            </>}
            <Row>
              <Col>
                {utmsListViewState.listModel.store.list.length ?
                  <ScanbuyTable
                    columns={utmsListViewState.columns}
                    customStyles={customStyles}
                    store={utmsListViewState.listModel}
                  />
                  :
                  <ScanbuyTable
                    columns={utmsListViewState.columns}
                    customStyles={customStyles}
                    store={utmsListViewState.listModel}
                  />
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <GenericModal
          modalStore={utmsListViewState.modalStore}
          componentStore={utmsListViewState.modalComponentStore}
          EmbedComponent={utmsListViewState.modalComponent}
        />
      </>
    )
  }
)

export default UtmsListView
