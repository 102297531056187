import async from "../../components/Async";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";


//TODO: FIX PAGE vs VIEW
const PrefixPage = async(() => import("../../views/configs/prefix/Prefix"));


export const viewMap = {}


export const routeDefinitions = [
  {
    path: "/configs/prefix",
    name: "Prefixes",
    icon: faSitemap,
    component: PrefixPage,
    children: null,
    featureFlagRequired: "cmp_ui_prefixes"
  }
];


export const routeTransitions = [

];
