import HttpClient from "../../services/HttpClient";

class AppConfigsManager {
	constructor(httpClient = null) {
		this.httpClient = httpClient || new HttpClient();
		console.log(this.httpClient);
		this.url = `/appconfigs`;
	}
}

export default AppConfigsManager;
