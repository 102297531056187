export const modules = [
  { key:0,image:"moduleSprite module-0"},
  { key:1,image:"moduleSprite module-1"},
  { key:2,image:"moduleSprite module-2"},
  { key:3,image:"moduleSprite module-3"},
  { key:4,image:"moduleSprite module-4"},
  { key:5,image:"moduleSprite module-5"},
  { key:6,image:"moduleSprite module-6"},
  { key:7,image:"moduleSprite module-7"},
  { key:8,image:"moduleSprite module-8"},
  { key:9,image:"moduleSprite module-9"},
  { key:10,image:"moduleSprite module-10"},
  { key:11,image:"moduleSprite module-11"},
  { key:12,image:"moduleSprite module-12"},
  { key:13,image:"moduleSprite module-13"},
  { key:14,image:"moduleSprite module-14"},
  { key:15,image:"moduleSprite module-15"},
  { key:16,image:"moduleSprite module-16"},
  { key:17,image:"moduleSprite module-17"},
  { key:18,image:"moduleSprite module-18"},
  { key:19,image:"moduleSprite module-19"},
  { key:20,image:"moduleSprite module-20"},
  { key:21,image:"moduleSprite module-21"},
  { key:22,image:"moduleSprite module-22"},

]
export const eyeLids = [
  { key:0,image:"eyeSprite eye-0"},
  { key:1,image:"eyeSprite eye_1"},
  { key:2,image:"eyeSprite eye-2"},
  { key:3,image:"eyeSprite eye-3"},
  { key:4,image:"eyeSprite eye-4"},
  { key:5,image:"eyeSprite eye-5"},
  { key:7,image:"eyeSprite eye-7"},
  { key:8,image:"eyeSprite eye-8"},
  { key:9,image:"eyeSprite eye-9"},
  { key:10,image:"eyeSprite eye-10"},
  { key:11,image:"eyeSprite eye-11"},
  { key:12,image:"eyeSprite eye-12"},
  { key:13,image:"eyeSprite eye-13"},
  { key:14,image:"eyeSprite eye-14"},
]
export const eyeballs = [
  { key:0,image:"ballSprite ball-0"},
  { key:1,image:"ballSprite ball-1"},
  { key:2,image:"ballSprite ball-2"},
  { key:3,image:"ballSprite ball-3"},
  { key:4,image:"ballSprite ball-4"},
  { key:5,image:"ballSprite ball-5"},
  { key:6,image:"ballSprite ball-6"},
  { key:7,image:"ballSprite ball-7"},
  { key:8,image:"ballSprite ball-8"},
  { key:9,image:"ballSprite ball-9"},
  { key:10,image:"ballSprite ball-10"},
  { key:11,image:"ballSprite ball-11"},
  { key:12,image:"ballSprite ball-12"},
  { key:13,image:"ballSprite ball-13"},
  { key:14,image:"ballSprite ball-14"},
  { key:15,image:"ballSprite ball-15"},
  { key:16,image:"ballSprite ball-16"},
  { key:17,image:"ballSprite ball-17"},
  { key:18,image:"ballSprite ball-18"}
]
