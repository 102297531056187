import {flow, getEnv, types} from "mobx-state-tree";
import Notification from "../../../utils/Notification";

const DeleteTagState = types.model('DeleteTagState', {
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
  .volatile(self => ({
    toggleModal: types.fn,
    refreshList: types.fn,
  }))
  .actions((self) => ({
    handleHasDeleteErrors(boolean) {
      self.formHasErrors = boolean
    },
    handleDeleteErrorMessage(message) {
      self.errorMessage = message
    },
    setToggleListener(listener) {
      console.log("Lister",listener)
      self.toggleModal = listener
    },
    setRefreshListener(listener) {
      self.refreshList = listener
    },

    deleteTag: flow(function* () {

      const manager = getEnv(self).tagManager;
      const resp = yield manager.deleteTag(self.uuid)
      !self.formHasErrors && new Notification()
        .setType("success")
        .setMessage(`${self.title} Tag deleted`)
        .send();
      !self.formHasErrors && self.refreshList()
      !self.formHasErrors && self.toggleModal()
    }),

  }))

export default DeleteTagState;
