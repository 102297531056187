import {types} from "mobx-state-tree";
import {TagModel} from "./tags/TagModel";
import {AuditMetaData} from "./AuditMetaData";

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string)
})
export const QrConfiguration = types.model('QrConfiguration', {
  uuid: types.identifier,
  level: types.maybeNull(types.string),
  label: types.string,
  name: types.string,
  qrConfig: types.string,
  image: types.string,
  is_default:types.optional(types.boolean, false),
  isExclusivelyGroupOwned:types.optional(types.boolean, true),
  isEditable:types.optional(types.boolean, true),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  tags: types.optional(types.array(types.safeReference(types.late(() => TagModel))), []),
  owner: types.maybe(GroupData),
  _audit: types.maybeNull(AuditMetaData),
}).actions((self) => ({

  updateConfig(config) {
      config?.name && (self.name = config?.name)
      config?.name && (self.label = config?.name)
      config?.data && (self.qrConfig = config?.data)
      config?.qrPreview && (self.image = config?.qrPreview)
      config?.tags && (self.tags = config?.tags)
  },
  // updateTags(tags) {
  //   self.tags = tags
  // },
  setQrConfig(value) {
    self.qrConfig = value
  }
}))

export default QrConfiguration;
