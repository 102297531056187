import React from "react";
import { CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalHeader
} from "reactstrap";
import {observer} from "mobx-react-lite";

const CMYKModal = observer(({modalCMYKOpen,toggleCMYKModal,cmyk,setCmyk,selectColor,colorPosition,frameAndCaptionsStore}) => {
  return (
    <Modal
      isOpen={modalCMYKOpen}
      toggle={()=> {
        toggleCMYKModal(!modalCMYKOpen)
      }}
      centered
      size={"sm"}
    >
      <ModalHeader toggle={()=>toggleCMYKModal(!modalCMYKOpen)}>
        CMYK
      </ModalHeader>
      <CardBody>
        <Col className="">
          <Form>
            <FormGroup row className="mb-2">
              <Label for="campaign" sm={3} className="text-sm-right">C</Label>
              <Col sm={5}>
                <Input min="0" max="100" type="number" value={cmyk.c}
                       onChange={(e) => {
                         const value = Math.max(0, Math.min(100, Number(e.target.value)));
                         const newColor = {}
                         setCmyk({...cmyk, c: value});
                         newColor.cmyk = {...cmyk, c: value}
                         selectColor(newColor, colorPosition, frameAndCaptionsStore,true)
                       }}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label for="source" sm={3} className="text-sm-right">M</Label>
              <Col sm={5}>
                <Input min="0" max="100" type="number" value={cmyk.m}
                       onChange={(e) => {
                         const value = Math.max(0, Math.min(100, Number(e.target.value)));
                         setCmyk({...cmyk, m: value});
                         const newColor = {}
                         newColor.cmyk = {...cmyk, m: value}
                         selectColor(newColor, colorPosition, frameAndCaptionsStore,true)
                       }}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label for="medium" sm={3} className="text-sm-right">Y</Label>
              <Col sm={5}>
                <Input min="0" max="100"  type="number" value={cmyk.y}
                       onChange={(e) => {
                         const value = Math.max(0, Math.min(100, Number(e.target.value)));
                         setCmyk({...cmyk, y: value});
                         const newColor = {}
                         newColor.cmyk = {...cmyk, y: value}
                         selectColor(newColor, colorPosition, frameAndCaptionsStore,true)

                       }}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label for="term" sm={3} className="text-sm-right">K</Label>
              <Col sm={5}>
                <Input min="0" max="100"  type="number" value={cmyk.k}
                       onChange={(e) => {
                         const value = Math.max(0, Math.min(100, Number(e.target.value)));
                         setCmyk({...cmyk, k: value});
                         // const cmykColor={...cmyk, k: value}
                         const newColor = {}
                         newColor.cmyk = {...cmyk, k: value}
                         selectColor(newColor, colorPosition, frameAndCaptionsStore,true)

                       }}
                />
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </CardBody>
    </Modal>

  );
})

export default CMYKModal;
