//TODO: Use the 'HttpService' instead of this one.


import axios from 'axios'
import queryString from 'querystring'
import config from "../configs";

const webApiUrl = config.API_DOMAIN_NAME;

const instance = axios.create({
  baseURL: webApiUrl,
  timeout: 1000,
  headers: { 'x-sb-context': 'qxYsuUjc45uoiy2EMJp8Bq' } //todo: set from auth login
});

export const get = async (url, queryParams = {}) => {
  try {
    const stringifiedQueryParams = queryString.stringify(queryParams)
    const response = await instance.get(url + '?' + stringifiedQueryParams)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const post = async (url, data = {}) => {
  try {
    const response = await instance.post(url, data)
    return response.data
  } catch (e) {
    console.log(e) //todo: handle serverside rejection
  }
}

export const put = async (url, data = {}) => {
  try {
    const response = await instance.put(url, data)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const destroy = async (url) => {
  try {
    const response = await instance.delete(url)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const httpClient = {
  get,
  post,
  put,
  destroy
};
