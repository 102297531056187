import React from "react";
import {observer} from "mobx-react";
import {Button, Card, CardBody, Col, Form, FormGroup} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { withTheme } from '@rjsf/core'
import { Theme as Bootstrap4Theme } from '@rjsf/bootstrap-4'
import validator from '@rjsf/validator-ajv8';
import { useRouterStore } from "mobx-state-router";
const ThemedForm = withTheme(Bootstrap4Theme);
const CreateQrFromTemplate = observer(({store})=>{
const routerStore = useRouterStore();
  return (
    <>
      <Card>
        <CardBody>
          <Form>
            <FormGroup row>
                  <Col sm={{size: 10, offset: 1}}>
                  <ThemedForm
                      validator={validator}
                      schema={JSON.parse(store.formSchema)}
                      formData={store.experienceDataView()}
                      onChange={(data) =>store.updateExperienceData(data.formData)}>
                    <div></div>
                  </ThemedForm>
                  
          <Button id="submit-btn" color="primary" className="float-right" onClick={async () => {
                const res = await store.saveNew();
                if (res.status === 201) {
                    routerStore.goTo('editCode', {
                    params: {id: res.data.uuid}
                    })
                  }

                }
              }><FormattedMessage id="listCodes.actions.publish.submit.defaultMessage"/></Button>
          </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </>
  )
})
export default CreateQrFromTemplate