import React from "react";
import {observer} from "mobx-react-lite";
import Select from "react-select";


const TagFilterSelect = observer(({tagFilterSelectState}) => {

  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={tagFilterSelectState?.optionValues()}
      placeholder="Select"
      isSearchable
      isDisabled={tagFilterSelectState?.isDisabled || false}
      onChange={tagFilterSelectState?.filterChange}
      value={tagFilterSelectState?.selected ? tagFilterSelectState?.selected : []}
      isMulti
    />
  );
});

export default TagFilterSelect;
