import { types } from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";

const GroupData = types.model('group', {
  groupUuid: types.maybe(types.string)
})

export const TagModel = types
  .model("TagModel", {
    name: types.optional(types.string, ""),
    uuid: types.identifier,
    namespace: types.optional(types.string, ""),
    label: types.optional(types.string, ""),
    tags: types.optional(types.array(types.safeReference(types.late(() => TagModel))), []),
    //types.optional(types.array(types.late(() => TagModel)), [])
    //types.array(types.safeReference(types.late(() => TagModel))),
    //types.optional(types.array(types.safeReference(types.late(() => TagModel))), []),
    //types.array(types.late(() => TagModel)), //
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    owner: types.maybe(GroupData),
    _audit: types.maybeNull(AuditMetaData),
    isExclusivelyGroupOwned: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    update(data) {
      self.name = data.name;
    }
  }));
