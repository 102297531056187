import {types} from "mobx-state-tree";
import {jsx} from "react/cjs/react-jsx-runtime.production.min";


const ColorState = types.model('ColorState', {
  colorMode: types.optional(types.string, "rgb"),
  isCMYK: types.optional(types.boolean, false),
  addGradient: types.optional(types.boolean, false),
  backgroundColor: types.optional(types.string, '#ffffff'),
  transparentBackground: types.optional(types.boolean, false),
  foregroundColor: types.optional(types.string, '#000000'),
  gradientColor1: types.optional(types.string, '#000000'),
  gradientColor2: types.optional(types.string, '#000000'),
  gradientType: types.optional(types.string, 'vertical'),

  colorEyesAndModules: types.optional(types.boolean, false),

  eyelidColor: types.optional(types.string, '#000000'),
  eyeballColor: types.optional(types.string, '#000000'),
  // moduleColor: types.optional(types.string, '#000000'),

  addThreeEyelidColors: types.optional(types.boolean, false),
  eye1Color: types.optional(types.string, '#000000'),
  eye1ColorUseForeground: types.optional(types.boolean, false),

  eye2Color: types.optional(types.string, '#000000'),
  eye2ColorUseForeground: types.optional(types.boolean, false),

  eye3Color: types.optional(types.string, '#000000'),
  eye3ColorUseForeground: types.optional(types.boolean, false),

  addThreeEyeballColors: types.optional(types.boolean, false),
  ball1Color: types.optional(types.string, '#000000'),
  ball1ColorUseForeground: types.optional(types.boolean, false),

  ball2Color: types.optional(types.string, '#000000'),
  ball2ColorUseForeground: types.optional(types.boolean, false),

  ball3Color: types.optional(types.string, '#000000'),
  ball3ColorUseForeground: types.optional(types.boolean, false),

})
  .volatile(self => ({
    notifyDesigner: types.f,
    notifyDesignerDeleteKey: types.f,
    extendedDesignerConfig: types.string
  }))
  .actions((self) => ({
    setConsumer(f) {
      self.notifyDesigner = f
    },
    setDeleteKeyConsumer(f){
      self.notifyDesignerDeleteKey = f
    },
    setConsumeExtendConfig(str){
      self.extendedDesignerConfig = str
    },
    handleColorMode(e) {
      console.log("handleColorMode:", e)
      self.colorMode = e
      self.notifyDesigner("colorMode",e)
    },
    handleIsCMYK(e) {
      self.isCMYK = e
      self.notifyDesigner("isCMYK",e)
    },
    toggleAddGradient(frameAndCaptionsStore) {
      self.addGradient = !self.addGradient
      self.gradientType && self.notifyDesigner("gradientType",self.gradientType)
      !self.addGradient && self.notifyDesignerDeleteKey("gradientType")
      if(self.addGradient){ // toggle of module color same as forground color
        if(frameAndCaptionsStore.modulesUseForegroundColor) {
          self.notifyDesignerDeleteKey("backgroundModulesColor")
        }
      }
      else if(frameAndCaptionsStore.modulesUseForegroundColor){
        self.notifyDesigner("backgroundModulesColor",self.foregroundColor)
      }
      // else{
      //   self.notifyDesigner("backgroundModulesColor",self.backgroundColor)
      // }
    },
    handleSelectColor(selectColor, colorPosition, frameAndCaptionsStore,isCMYK = false) {
      const colorValue = isCMYK?`cmyk(${selectColor.cmyk.c}%, ${selectColor.cmyk.m}%, ${selectColor.cmyk.y}%, ${selectColor.cmyk.k}%)`: selectColor.hex
      if(colorPosition === "eyelidColor"){
        self.notifyDesigner("eye1Color",colorValue)
        self.notifyDesigner("eye2Color",colorValue)
        self.notifyDesigner("eye3Color",colorValue)
      }else if(colorPosition === "eyeballColor"){
        self.notifyDesigner("ball1Color",colorValue)
        self.notifyDesigner("ball2Color",colorValue)
        self.notifyDesigner("ball3Color",colorValue)
      } else if (colorPosition === "foregroundColor") {
        if (frameAndCaptionsStore.fillInColor) {
          self.notifyDesigner("backgroundModulesColor", colorValue)
        }
        self.notifyDesigner(colorPosition,colorValue)
        console.log("handleForegroundColor:", selectColor.cmyk)

        if (frameAndCaptionsStore.modulesUseForegroundColor === true) {
          frameAndCaptionsStore.handleSelectColor(selectColor, "backgroundModulesColor")
        }
      }else {
        self.notifyDesigner(colorPosition,colorValue)
      }
      self[colorPosition] = colorValue
      self[colorPosition+"UseForeground"] = false
      self.notifyDesigner(colorPosition+"UseForeground",false)
    },
    handleForegroundColor(selectColor,  colorPosition, frameAndCaptionsStore) {
      if(frameAndCaptionsStore.fillInColor){
        self.notifyDesigner("backgroundModulesColor",selectColor.hex)
      }
      self.notifyDesigner(colorPosition,selectColor.hex)
      frameAndCaptionsStore.frameColor = selectColor.hex
      frameAndCaptionsStore.handleSelectColor(selectColor, "frameColor")
    },
    handleGradientType(e) {
      self.gradientType = e.target.value
      self.notifyDesigner("gradientType",e.target.value)
    },
    handleTransparentBackground() {
      self.transparentBackground = !self.transparentBackground
      self.notifyDesigner("transparentBackground",self.transparentBackground)
    },
    handleColorEyesAndModules() {
      self.addThreeEyelidColors = false
      self.addThreeEyeballColors = false
      self.colorEyesAndModules = !self.colorEyesAndModules
      if(self.colorEyesAndModules === false){
        self.notifyDesignerDeleteKey("colorEyesAndModules")
        self.notifyDesignerDeleteKey("eye1Color")
        self.notifyDesignerDeleteKey("eye2Color")
        self.notifyDesignerDeleteKey("eye3Color")
        self.notifyDesignerDeleteKey("ball1Color")
        self.notifyDesignerDeleteKey("ball2Color")
        self.notifyDesignerDeleteKey("ball3Color")
      }else if(self.colorEyesAndModules === true){
        self.notifyDesigner("colorEyesAndModules",true)
        self.notifyDesigner("eye1Color",self.eyelidColor)
        self.notifyDesigner("eye2Color",self.eyelidColor)
        self.notifyDesigner("eye3Color",self.eyelidColor)
        self.notifyDesigner("ball1Color",self.eyeballColor)
        self.notifyDesigner("ball2Color",self.eyeballColor)
        self.notifyDesigner("ball3Color",self.eyeballColor)
      }
    },
    toggleAddThreeEyelidColors() {
      self.addThreeEyelidColors = !self.addThreeEyelidColors
      if(self.addThreeEyelidColors === true){
        self.notifyDesigner("addThreeEyelidColors",true)
        self.notifyDesigner("eye1Color",self.eye1Color)
        self.notifyDesigner("eye2Color",self.eye2Color)
        self.notifyDesigner("eye3Color",self.eye3Color)
        self.eye1ColorUseForeground = false
        self.notifyDesigner("eye1ColorUseForeground",false)
        self.eye2ColorUseForeground = false
        self.notifyDesigner("eye2ColorUseForeground",false)
        self.eye3ColorUseForeground = false
        self.notifyDesigner("eye3ColorUseForeground",false)
      }else if(self.addThreeEyelidColors === false){
        self.notifyDesigner("addThreeEyelidColors",false)
        self.notifyDesigner("eye1Color",self.eyelidColor)
        self.notifyDesigner("eye2Color",self.eyelidColor)
        self.notifyDesigner("eye3Color",self.eyelidColor)
      }

    },
    toggleAddThreeEyeballColors() {
      self.addThreeEyeballColors = !self.addThreeEyeballColors
      if(self.addThreeEyeballColors === true){
        self.notifyDesigner("addThreeEyeballColors",true)
        self.notifyDesigner("ball1Color",self.ball1Color)
        self.notifyDesigner("ball2Color",self.ball2Color)
        self.notifyDesigner("ball3Color",self.ball3Color)
        self.ball1ColorUseForeground = false
        self.notifyDesigner("ball1ColorUseForeground",false)
        self.ball2ColorUseForeground = false
        self.notifyDesigner("ball2ColorUseForeground",false)
        self.ball3ColorUseForeground = false
        self.notifyDesigner("ball3ColorUseForeground",false)
      } else if(self.addThreeEyeballColors === false){
        self.notifyDesigner("addThreeEyeballColors",false)
        self.notifyDesigner("ball1Color",self.eyeballColor)
        self.notifyDesigner("ball2Color",self.eyeballColor)
        self.notifyDesigner("ball3Color",self.eyeballColor)
      }
    },
    deleteColor(color){
      self.notifyDesignerDeleteKey(color)
      self[color+"UseForeground"] = true
      self.notifyDesigner(color+"UseForeground",true)
    }
  }))


export default ColorState;
