import HttpClient from "../../services/HttpClient";


class ParametersManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    console.log(this.httpClient);
    this.url = `/parameters`;
  }

  async fetchAll(queryParams) {
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get publish domains", e);
    }
    return null;
  }
  async fetchOne(paramType) {
    try {
      const res = await this.httpClient.get(this.url+"/"+ paramType);
      console.log("response in get request", res);
      if (res.items.length) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get publish domains", e);
    }
    return null;
  }
  // async findByParameterType(queryParams) {
  //   try {
  //     const res = await this.httpClient.get(this.url, queryParams);
  //     console.log("response in get request", res);
  //     if (res && res.results) {
  //       return res;
  //     }
  //   } catch (e) {
  //     console.error("Failed to get publish domains", e);
  //   }
  //   return null;
  // }
  //

}

export default ParametersManager;
