import { getType, types } from "mobx-state-tree";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";
import UserStore from "../../../../../stores/domain/UserStore";


export const DataExportFilterSelectState = types.model('DataExportFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  userStore: types.maybe(types.late(() => types.reference(UserStore))),
  statusTypes: types.optional(types.array(types.string), []),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
  emails: types.array,
  tags: types.array
})).actions((self)=>({
  reset(){
    self.selected = [];
  },
  setEmails(emails) {
    self.emails = emails
  },
  setStatusTypes(status) {
    self.statusTypes = status
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["createdBy"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  optionValues() {

    const options = [];
    if (self.emails && self.emails?.length && self.emails[0]) {
      const userOptions = self.emails.flatMap( (user) => {
        return {
          label: user?.email,
          value: "createdBy."+user.uuid
        }
      });
      options.push({
        label: <FormattedMessage id="listCodes.filter.filterBy.users.defaultMessage"/>,
        options: userOptions
      });
    }

    if (self.statusTypes && self.statusTypes?.length && self.statusTypes[0]) {
      const statusOptions = self.statusTypes.flatMap( (stat) =>{
        return {
          label: stat,
          value: "status."+stat
        }
      });

      options.push({
        label: <FormattedMessage id="listCodes.filter.filterBy.status.defaultMessage"/>,
        options: statusOptions
      });
    }

    return options;
  }
}))
