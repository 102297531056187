import { error } from "logrocket";
import {types} from "mobx-state-tree";
import DefaultImageConfigModel from "./DefaultImageConfig";

const V1ImageConfigModel = types.model('V1ImageConfigModel',{
  barcodeColor: types.optional(types.string, "0,0,0,100"),
  barcodeSize: types.optional(types.string, "1.00"),
  barcodeSizeUnit: types.optional(types.string, "inch"),
  colorMode: types.optional(types.string, "CMYK"),
  data: types.optional(types.string, "Th1s 1s @ s@mpl3 QR v@lu3"),
  imageFormat: types.optional(types.string, "PNG"),
  quietSpace: types.optional(types.string, "4"),
  reduction: types.optional(types.string, "0"),
  reductionUnit: types.optional(types.string, "inch"),
  resolution: types.optional(types.string, "1200")
}).actions(self => ({
  setBarcodeColor(color){
    self.barcodeColor = color;
  },
  setBarcodeSize(size){
    self.barcodeSize = size;
  },
  setBarcodeSizeUnit(unit){
    self.barcodeSizeUnit = unit;
  },
  setColorMode(mode){
    self.colorMode = mode;
  },
  setData(data){
    self.data = data;
  },
  setImageFormat(format){
    self.imageFormat = format;
  },
  setQuietSpace(space){
    self.quietSpace = space;
  },
  setReduction(reduction){
    self.reduction = reduction;
  },
  setReductionUnit(unit){
    self.reductionUnit = unit;
  },
  setResolution(resolution){
    self.resolution = resolution;
  }
}))

const  DashboardModel = types.model('DashboardModel',{
  bulletins: types.array(types.frozen()),
  experiences: types.array(types.frozen()),
  bulletinText: types.optional(types.string,''),
  defaultImageConfig: types.optional(DefaultImageConfigModel, {}),
  v1ImageConfig: types.optional(V1ImageConfigModel, {}),
  bulletinsV2: types.array(types.frozen())
})

export default DashboardModel;
