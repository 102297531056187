import {types} from "mobx-state-tree";

const QrTemplate = types.model('QrTemplate',{
    uuid: types.identifier,
    label: types.string,
    name: types.string,
    inputSchema: types.optional(types.string, "") 
})

export default QrTemplate
