import React from 'react'
import {
  DropdownToggle,
  DropdownMenu,
  InputGroup,
  UncontrolledButtonDropdown
} from "reactstrap";
import { MoreVertical } from 'react-feather'

const TableActionsDropdown = ({ actionsList = [] }) => {
  return (
    <InputGroup className="justify-content-center" >
      <UncontrolledButtonDropdown className="mb-0">
        <DropdownToggle id='table-action-btn' color="primary-outline">
          <MoreVertical />
        </DropdownToggle>
        <DropdownMenu style={{overflow:"scroll"}} positionFixed={true}>
          {actionsList}
        </DropdownMenu>
        </UncontrolledButtonDropdown>
    </InputGroup>
  );
}

export default TableActionsDropdown
