import {flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {GroupSelectState} from "../../../elements/groups/GroupSelectState";
import ParameterStore, {Parameter} from "../../../../stores/domain/ParameterStore";
import Group from "../../../../models/Group";
import lodash from "lodash";
import Notification from "../../../../utils/Notification";
import {CodeParamsTypeSelectState} from "../../../elements/parameters/codeType/CodeParamsTypeSelectState";
import {ScanParamsTypeSelectState} from "../../../elements/parameters/scanType/ScanParamsTypeSelectState";
import {CountParamsTypeSelectState} from "../../../elements/parameters/countType/CountParamsTypeSelectState";
import {PremiumParamsTypeSelectState} from "../../../elements/parameters/premiumType/PremiumParamsTypeSelectState";
import {CustomParamsTypeSelectState} from "../../../elements/parameters/customType/CustomParamsTypeSelectState";
import {DimensionsMultiSelectState} from "../../../elements/dimensions/multi-select/dimensionsMultiSelectState";
import {TagSelectState} from "../../../elements/tags/TagSelectState";

export const CreateParameterSetState = types.model("CreateParameterSetState", {
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  parameterStore: types.maybe(ParameterStore),
  isLoading: types.optional(types.boolean, false),

  selectedGroup: types.maybe(types.reference(Group)),
  name: types.optional(types.string, ""),
  selectedCodeParameters: types.optional(types.array(types.safeReference(Parameter)), []),
  selectedScanParameters: types.optional(types.array(types.safeReference(Parameter)), []),

  //for later
  selectedCountParameters: types.optional(types.array(types.safeReference(Parameter)), []),
  selectedPremiumParameters: types.optional(types.array(types.safeReference(Parameter)), []),
  selectedCustomerParameters: types.optional(types.array(types.safeReference(Parameter)), []),

  //select state for each parameter type
  codeParamsTypeSelectState: types.maybe(CodeParamsTypeSelectState),
  scanParamsTypeSelectState: types.maybe(ScanParamsTypeSelectState),
  countParamsTypeSelectState: types.maybe(CountParamsTypeSelectState),
  premiumParamsTypeSelectState: types.maybe(PremiumParamsTypeSelectState),
  customParamsTypeSelectState: types.maybe(CustomParamsTypeSelectState),
  isExclusivelyGroupOwned: types.optional(types.boolean, true),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
})
  .actions(self => ({
    handleNameChange(e) {
      self.name = e.target.value
    },
    setOnSelectChanges() {
      self.groupSelectStore?.setConsumer(self.selectGroup)
    },
    selectGroup(obj) {
      self.dimensionsMultiSelectStore?.getListOnGroup(obj?.uuid)
    },
    selectParameters(e, selectedSubgroup) {
      if (lodash.isArray(e)) {
        self[selectedSubgroup] = e?.map(g => {
          return resolveIdentifier(Parameter, self, g?.uuid || g)
        })
      } else if (e) {
        self[selectedSubgroup] = e
      }
    },
    validateForm() {
      return true;
    },
    fetchParameterSetWithID: flow(function* f(uuid, mode) {
      const res = yield getEnv(self).parameterSetManager.getParameterSet(uuid)
      self.hydrateForm(res)
    }),
    hydrateForm(parameterSet) {
      self.name = "";
      self.groupSelectStore.selectGroup(parameterSet.owner.groupUuid)
      self.tagSelectStore.setSelectedTags(parameterSet?.tags?.flatMap((tag)=> tag.uuid))
      self.codeParamsTypeSelectState.selectParameters(parameterSet?.codeParamType)
      self.scanParamsTypeSelectState.selectParameters(parameterSet?.scanParamType)
      self.countParamsTypeSelectState.selectParameters(parameterSet?.countParamType)
      self.premiumParamsTypeSelectState.selectParameters(parameterSet?.premiumParamType)
      self.customParamsTypeSelectState.selectParameters(parameterSet?.customParamType)
      self.isExclusivelyGroupOwned = parameterSet?.isExclusivelyGroupOwned;
      self.hydrateDimension(parameterSet?.owner?.dimensions)
    },
    addParameterSet: flow(function* f() {
      const GroupIsSelected = !!self.groupSelectStore.selectedGroup?.uuid
      const DimensionIsSelected = !self.isDimensionSelectVisible || !lodash.isEmpty(self.dimensionsMultiSelectStore.getTranspiledDimensions())
      if (self.validateForm() && GroupIsSelected && DimensionIsSelected) {
        self.isLoading = true
        const manager = getEnv(self).parameterSetManager
        const resp = yield manager.create(self);
        if (resp.status === 201) {
          new Notification()
            .setType("success")
            .setMessage(`${resp?.data?.name} Parameter Set created`)
            .send();
          return resp
        } else {
          new Notification()
            .setType("error")
            .setMessage(`${self?.utm?.name} Parameter Set failed creating`)
            .send();
        }
        self.isLoading = false
      } else {
        !GroupIsSelected && new Notification()
          .setType("error")
          .setMessage("Please select a group")
          .send();
        !DimensionIsSelected && new Notification()
          .setType("error")
          .setMessage("Please select Dimensions")
          .send();
      }
    }),
    //map through each parameter type and populate the respective select state
    mapParametersToSelectState(params) {
      params.forEach(p => {
        switch (p?.paramType) {
          case "codeParams":
            self.codeParamsTypeSelectState.store?.setList(p?.items)
            break;
          case "scanParams":
            self.scanParamsTypeSelectState.store?.setList(p?.items)
            break;
          case "countParams":
            self.countParamsTypeSelectState.store?.setList(p?.items)
            break;
          case "premiumParams":
            self.premiumParamsTypeSelectState.store?.setList(p?.items)
            break;
          case "customParams":
            self.customParamsTypeSelectState.store?.setList(p?.items)
            break;
          default:
            break;
        }
      })
    },
    toggleIsExclusivelyGroupOwned(){
      self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
    },
    toggleDimensionAll(){
      self.dimensionAll = !self.dimensionAll
      self.toggleIsDimensionSelectVisible(!self.dimensionAll)
      self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
    },
    toggleIsDimensionSelectVisible(visible){
      self.isDimensionSelectVisible = visible
    },
    hydrateDimension(dimensions = []){
      const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
      self.dimensionAll = !isDimensionAll
      self.toggleDimensionAll()
      if(!self.dimensionAll){
        self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
      }
    },
    fetchAllParameters: flow(function* f() {
      const manager = getEnv(self).parametersManager
      const resp = yield manager.fetchAll();
      if (resp?.results) {
          self.mapParametersToSelectState(resp?.results)
      }
    }),
  })).views((self) => ({}))
