import {resolveIdentifier, types} from 'mobx-state-tree'
import {ExtendedDataModel} from "../../models/extendedData/ExtendedData";
import ExtendedDataStore from "../../stores/domain/ExtendedDataStore";

export const ExtendedDataSelectState = types.model("ExtendedDataSelectState",{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(ExtendedDataStore))),
  selectedExtendedData: types.optional(types.array(types.safeReference(ExtendedDataModel)), []),
}).volatile(self => ({
  notifySelect: types.function
})).actions( (self)=>({
  selectExtendedData(arr){
    self.notifySelect(arr);
  },
  setConsumer(f){
    self.notifySelect = f;
  },
  setSelectedExtendedData(arr) {
    self.selectedExtendedData = arr?.map((item) => {
      return resolveIdentifier(ExtendedDataModel, self, item.uuid || item);
    });
  },
})).views( (self) => ({
  getSelectedExtendedData() {
    return self.selectedExtendedData;
  },
  optionValues() {
    return self.store?.getExtendedData() || [];
  },
}));
