import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { types } from "mobx-state-tree";
import { observer } from "mobx-react-lite";

export const ModalState = types.model({
  open: types.maybe(types.boolean),
  title: types.maybe(types.string),
  eventUuid: types.maybe(types.string)
}).volatile(self => ({
})).actions( (self)=>({
  toggle(){
    self.open = !self.open
  },
  handleTitle(title){
    self.title = title
  },
  setEvent(e){
    const eventArr = e.target.value.split(" ")
    self.title = eventArr[0]
    self.eventUuid = eventArr[1]
  },
}))

const ScanbuyModal = observer(({modalStore,componentStore,EmbedComponent,size = "",className =""}) => {
 console.log("componentStore",componentStore)
 console.log("modalStore",modalStore)
  return (
    <Modal
      isOpen={modalStore.open}
      toggle={modalStore.toggle}
      centered
      size={size} //options include sm, lg, xl
      contentClassName={className}
      scrollable
    >
      <ModalHeader toggle={modalStore.toggle}>
        {modalStore.title}
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <EmbedComponent store={componentStore}/>
      </ModalBody>
      {/*<ModalFooter>*/}
      {/*  <Button color="secondary" onClick={() => modalStore.toggle()}>*/}
      {/*    Close*/}
      {/*  </Button>{" "}*/}
      {/*  <Button*/}
      {/*    onClick={() => {*/}
      {/*      componentStore.saveAndClose(modalStore)*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Save changes*/}
      {/*  </Button>*/}
      {/*</ModalFooter>*/}
    </Modal>
  );
})

export default ScanbuyModal;
