import HttpClient from "../../services/HttpClient";


class CodeTypeManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient();
    console.log(this.httpClient);
    this.url = `/qr/schemas`;
  }

  async fetchAll(queryParams) {

    try {
      const res = await this.httpClient.get(this.url);
      console.log("response in get request", res);
      if (res && res.results) {
        return res;
      }
    } catch (e) {
      console.error("Failed to get qr types/schemas", e);
    }
    return null;
  }


}

export default CodeTypeManager;
