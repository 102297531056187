import React from "react";
import {Col, Row} from "reactstrap";
import scnqr from "../../../../assets/img/alt/scnqr.png";
import {observer} from "mobx-react-lite";
import "./QrPreviewImage.css"
import { FormattedMessage } from "react-intl";
// import {Document, Page, pdfjs} from 'react-pdf'

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const QrPreviewImageView = observer(({store}) => {
  return (
    <>
      <Row className="mb-3">
        <Col className="text-center">
          {(store.isLoading === true) &&
            <div style={{
              width: "94%",
              height: "280px",
              position: "absolute",
              zIndex: "1",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          {
            <div style={{width: '280px', height: '280px', display: 'inline-block'}}>
              <div style={{ width: '280px', height: '280px', background: 'white', display: 'flex', alignItems: 'center'}}>
                <img
                className={store.previewImage ? "rounded mr-2 mb-2" : "rounded mr-2 mb-2 blur"}
                src={store.previewImage ? store.previewImage : scnqr}
                alt="Placeholder"
                style={{ maxWidth: '280px', maxHeight: '280px'}}
                id="qrDesignPreview"
              />
              </div>
              <div id="reader" width="600px" height="600px" hidden></div>
            </div>
          }
          {/*{(store.imgFormat === "pdf") &&*/}
          {/*  <div style={{ width:"280px", height:"280px", marginLeft:"22%",marginBottom:"5%"}} >*/}
          {/*  <Document file={store.previewImage}>*/}
          {/*    <Page pageNumber={1} width={273}/>*/}
          {/*  </Document>*/}
          {/*  </div>*/}
          {/*}*/}
        </Col>
      </Row>
      <Row>
        <Col>
          {!store.validQr && 
            <div className="text-center">
              <p className="text-danger"><FormattedMessage id="preview.invalidQr.defaultMessage" /></p>
              </div>
          }
        </Col>
      </Row>
    </>
  )
})
export default QrPreviewImageView
