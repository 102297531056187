import {flow, getEnv, types} from "mobx-state-tree";
import Notification from "../../../utils/Notification";

const ModalActionState = types.model('ModalActionState', {
  name: types.optional(types.string, ""),
  resourceName: types.optional(types.string, ""),
  manager: types.optional(types.string, ""),
  action: types.optional(types.string, ""),
  actionTitle: types.optional(types.string, ""),
  onSuccessMessage: types.optional(types.string, ""),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
  title: types.maybe(types.string),
  uuid: types.maybe(types.string)
})
  .volatile(self => ({
    toggleModal: types.fn,
    refreshList: types.fn,
  }))
  .actions((self) => ({
    handleHasDeleteErrors(boolean) {
      self.formHasErrors = boolean;
    },
    handleDeleteErrorMessage(message) {
      self.errorMessage = message;
    },
    setToggleListener(listener) {
      self.toggleModal = listener;
    },
    setRefreshListener(listener) {
      self.refreshList = listener;
    },
    setOnSuccessMessage(message) {
      self.onSuccessMessage = message;
    },
    getOnSuccessMessage() {
      return self.onSuccessMessage ? self.onSuccessMessage : `${self.name} ${self.resourceName} ${self.actionTitle}`;
    },
    sendAction: flow(function* () {
      if (self.manager && self.action) {
        try {
          const resp = yield getEnv(self)[self.manager][self.action](self.uuid, self);
          if (!self.formHasErrors) {
            self.refreshList();
            new Notification()
              .setType("success")
              .setMessage(self.getOnSuccessMessage())
              .send();
          }
        } catch (e) {
          console.error(e);
        }
      }
      self.toggleModal();
    }),
  }));

export default ModalActionState;
