import {types} from "mobx-state-tree";
import CreateCode from "../create/CreateCodeDomain";
import {flow} from "mobx";

const PublishState = types.model('PublishState', {
  title: types.maybe(types.string),
  code: types.maybe(types.late(()
:
IAnyModelType => CreateCode
)),
uuid: types.maybe(types.string),
formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, ""),
})
.volatile(self => ({
  selectedCallback: types.fn,
  selectedSecondCallback: types.fn,
  selectedThirdCallback: types.fn,
}))
  .actions((self) => ({
    handleHasPublishErrors(boolean) {
      self.formHasErrors = boolean
    },
    handlePublishErrorMessage(message) {
      self.errorMessage = message
    },
    setSecondPublishStateListener(listener) {
      self.selectedSecondCallback = listener
    },
    setPublishStateListener(listener) {
      self.selectedCallback = listener
    },
    setThirdPublishStateListener(listener) {
      self.selectedThirdCallback = listener
    },
    notifyCallBack(){
      self.selectedCallback()
    },
    notifySecondCallBack(str) {
      self.selectedSecondCallback(str)
    },
    notifyThirdCallBack(str) {
      self.selectedThirdCallback(str)
    },
    publishCode: flow(function* () {
      self.notifySecondCallBack(self);
    }),
  }))

export default PublishState;
