import HttpClient from "../../services/HttpClient";
import AwsHttpClient from "../../services/AwsHttpClient"

class BulkManager {
  constructor(httpClient = null) {
    this.httpClient = httpClient ||  new HttpClient();
    this.awsHttpClient = new AwsHttpClient();
  }
  // function for bulk/download
  handleBulkDownloadOrEdit = async (data, instance, queryParams, batchType) => {
    try {
      let res = null;
      res = await this.httpClient.post(`bulk/${batchType}`, data);
      return res;
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
    }
  }
  // function for bulk/publish | bulk/create | bulk/download/csvDownload uploading csv file
  handleCsvUpload = async (data, instance) => {
    try {
      const { uploadFiles, batchType, totalItems, batchName, details } = data;
      const file = uploadFiles[0];
      // "codeType": "url",
      //   "codeStatus":"PUBLISHED",
      //   "owner": {
      //       "dimensions": {
      //           "c7efadbd-e7d3-4c34-b71e-074e3d915353": "cd899ab7-827a-4e61-ba3e-add9024face5",
      //           "ffc04d3e-ed61-45ea-a7e3-9f05b81c8e0d": "0fce9995-abfa-4bfe-9e66-562c2485d506"
      //       },
      //       "groupUuid":"3899f435-4ba9-4742-a59d-66632e923a74"
      //   },
      //   "tags": [],
      //   "shortUrl": {
      //       "domain": "http://scn.by"
      //   }
      console.log("details: ", details)
      const signedUrlData = {
        fileName: file.name,
        action: batchType.toUpperCase(),
        totalItems: totalItems || 0,
        batchType: batchType.toLowerCase(),
        batchName: batchName,
        details: details
      };
      console.log("signedUrlData: ", signedUrlData)
      const signedUrl = await this.getSignedUrl(signedUrlData);
      const s3response = await this.awsHttpClient.put(signedUrl, file);
      console.log("s3response: ", s3response)
      return {"success": true}
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.error("Error processing upload", e)
    }
  }

  getSignedUrl = async (signedUrlData) => {
    let subPath = signedUrlData.batchType
    console.log(subPath);
    if(signedUrlData.batchType === "download") {
      subPath = `${signedUrlData.batchType}/csvDownload`
    }
    const res = await this.httpClient.post(`bulk/${subPath}`, signedUrlData);
    console.log("getSignUrl: ", res)
    return res?.data.uploadUrl;
  }

  countBatchQrcodes = async (queryParams) => {
		try {
      console.log("countBatchQrcodes:", queryParams)
			const res = await this.httpClient.post("bulk/count", queryParams);
			if (res) {
				return res;
			}
		} catch (e) {
			console.log("Failed to get batch count", e)
		}
		return null;
	}


}

export default BulkManager;
