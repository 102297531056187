import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import { FormattedMessage } from "react-intl";

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const formatOptionLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/*<span style={groupBadgeStyles}></span>*/}
  </div>
);


const CodeFilterSelect = observer(({ codeFilterSelectState }) => {

//https://react-select.com/advanced
  return (
    <Select
      id="code-filter-select"
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={codeFilterSelectState.optionValues()}
      isSearchable
      isDisabled={codeFilterSelectState?.isDisabled || false}
      onChange={codeFilterSelectState?.filterChange}
      value={codeFilterSelectState.selected}
      // getOptionLabel={option => option.label}
      // getOptionValue={option => option.uuid}
      isMulti
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      //closeMenuOnSelect={false}
      placeholder="Select"
    />
  );
});

export default CodeFilterSelect;
