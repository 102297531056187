import React from "react";
import { observer } from "mobx-react-lite";
import {
  CardSubtitle,
  CardText,
} from "reactstrap";

const TextField = observer(({style, labelStyle, valueStyle, label, value="value"}) => {

  return (
    <>
      <CardSubtitle
        style={{
          fontSize: "14px",
          ...style,
          ...labelStyle,
        }}
      >
        {label}
      </CardSubtitle>
      <CardText
        style={{
          fontSize: "14px",
          ...style,
          ...valueStyle,
        }}
      >
        {value}
      </CardText>
    </>
  );
});

export default TextField;
