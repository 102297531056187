import { flow, getEnv, types } from "mobx-state-tree";
import { Batch } from "../../models/batch/Batch";

const BatchStore = types.model('BatchStore',{
  storeUuid: types.maybe(types.identifier),
  items: types.optional(types.array(Batch),[]),
}).volatile(self => ({
  notifySelect: types.f,

})).actions((self)=>({
  getItems() {
    return self.items
  },
  searchFor(txt){
    
  },
  // fetchAll: flow(function* fetchAll(params = {}) {
  //   const manager = getEnv(self).batchManager
  //   const response = yield manager.fetchBatches(params);
  //   self.items = response.results
  //   console.log('getBatches:',self.items)
  // }),
  initWithData() {

  },
  downloadBatchOptionsValue() {
    return self.items
        .filter(row => (row.status === 'DONE' || row.status === 'DONE_WITH_ERRORS'))
        .map(row => {
            return {uuid: row.uuid, fileName: row.fileName};
        });
  },
  setItems(items) {
    self.items = items
  }
})).views((self)=>({
  filteredList() {
    return self.items.slice() || []
  }
}));

export default BatchStore
