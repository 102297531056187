import React from "react";
import { observer } from "mobx-react-lite";

const LayoutBox = observer(({
  height="100%",
  width="100%"
}) => {
  return (
    <div style={{
      backgroundColor: "rgba(211,211,211,0.4)",
      height,
      width,
      borderRadius: '4px'
    }}>&nbsp;</div>
  );
});

export default LayoutBox;
