import React from "react";
import {observer} from "mobx-react-lite";
import {Toast} from "react-bootstrap";
import "./GenericToastStyle.scss"

const GenericToastView = observer(({genericToastState}) => {
  return (
      <Toast className="toastView" show={genericToastState.showToast} onClose={genericToastState.toggleShowToast}
             style={{position: "fixed", top: "5%", right: "5%"}}>
        <Toast.Header closeButton={true}>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">{genericToastState.toastHeader}</strong>
          <small style={{position: "absolute", right: "3rem"}}>{genericToastState.smallText}</small>
        </Toast.Header>
        <Toast.Body>{genericToastState.toastBody}</Toast.Body>
      </Toast>
  );
});

export default GenericToastView;
