import {flow, getEnv, resolveIdentifier, types} from "mobx-state-tree";
import {ParameterSet} from "../../models/parameterSets/ParameterSetModel";
import {TagModel} from "../../models/tags/TagModel";

const ParameterSetStore = types.model('ParameterSetStore',{
    storeUuid: types.identifier,
    list: types.optional(types.array(ParameterSet), []),
    flatList: types.optional(types.array(types.frozen()), []), //for list component, //because list can't access nested MST tags model
    //isLoading..
}).actions((self)=>({
    initWithData(items = []) {
        if (items) {
          let nestedTags = [];
          self.list = items.map(item => {
            if(item.tags && item.tags.length > 0){
              nestedTags = item.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
            }
              return ParameterSet.create({
                uuid: item.uuid,
                name: item.name,
                label: item.name,
                _audit: item?._audit,
                createdAt: item?.createdAt,
                updatedAt: item?.updatedAt,
                tags: nestedTags
                //todo complete object
              })
          });
        } else {
          self.list = [];
        }
    },
    init: flow(function* f(params = {}) {
        console.log("load parameterSetStore from API");
        const resp = yield self.getParameterSets(params);
        console.log(resp);
        self.list = resp.results.map(el => ParameterSet.create({ ...el}))
    }),
    getParameterSets: flow(function* getParameterSets(params = {}) {
             const res = yield getEnv(self).parameterSetManager.fetchAll(params);
             if(res !== null){
                 self.setParameterSets(res.results);
             }
    }),
    setParameterSets(ParameterSets){
        let nestedTags = []
        const flatList = ParameterSets.map(item => {
          if(item.tags && item.tags.length > 0) {
            nestedTags = item.tags.map(t => resolveIdentifier(TagModel, self, t?.uuid))
          }
            return {
              uuid: item.uuid,
              name: item.name,
              label: item.name,
              _audit: item?._audit,
              owner: item.owner,
              createdAt: item?.createdAt,
              updatedAt: item?.updatedAt,
              isExclusivelyGroupOwned: item?.isExclusivelyGroupOwned,
              isEditable: item?.isEditable,
              tags: nestedTags,
            }
          }
        )
      self.flatList = flatList;
        console.log("self.flatList", self.flatList);
    },
    searchFor(txt){
        //TODO: search for groups with name like 'txt'
    }


}))



export default ParameterSetStore;
