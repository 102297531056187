import { faList } from "@fortawesome/free-solid-svg-icons";
import Blank from "../../pages/misc/Blank";


export const viewMap = {};


export const routeDefinitions = [
  {
    path: "/configs/templates",
    name: "Templates",
    icon: faList,
    component: Blank,
    children: null,
    featureFlagRequired: "cmp_ui_qrtemplates"
  }
];


export const routeTransitions = [];





