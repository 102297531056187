import { getType, resolveIdentifier, types } from 'mobx-state-tree';
import UtmStore from "../../../stores/domain/UtmStore";
import {Utm} from "../../../models/utms/UtmModel";

export const UtmSelectState = types.model('UtmSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(UtmStore))),
  selected: types.maybe(types.safeReference(Utm)),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  search(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
    //self.groupStore.groups
  },
  setConsumer(f){
    self.notifySelect = f
  },
  select(utm) {
    //console.log("*****")
    //console.log(utm)
    if(utm && utm.uuid) {
      self.notifySelect(utm.uuid)
      self.selected = resolveIdentifier(UtmStore, self.store, utm.uuid)
    }
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => { //TODO
      console.log("placeholder notifySelect")
    }

    self.selectedSchema = {}
  },
})).views( (self) => ({
  options() {
    const list = self.store.filteredList() || [];
    list.sort((a,b) => a?.label?.localeCompare(b?.label))
    return list
  },
  // selectedSchemaView(){
  //   console.log(self.selectedSchema)
  //   return JSON.parse(self.selectedSchema)
  // }
}))


