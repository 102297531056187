export const frames = [
    { key:3,image:"frameSprite frame-0"},
    { key:4,image:"frameSprite frame-1"},
    { key:5,image:"frameSprite frame-2"},
    { key:6,image:"frameSprite frame-3"},
    { key:7,image:"frameSprite frame-4"},
    { key:8,image:"frameSprite frame-5"},
    { key:9,image:"frameSprite frame-6"},
    { key:10,image:"frameSprite frame-7"},
    { key:11,image:"frameSprite frame-8"},
    { key:12,image:"frameSprite frame-9"},
    { key:13,image:"frameSprite frame-10"},
    { key:1,image:"frameSprite frame-11"},
]