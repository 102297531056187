import {types} from "mobx-state-tree";


export const  Rule = types.model('Group',{
  uuid: types.identifier,
  label: types.string,
  name: types.string,
  type: types.string,
  // ruleSchema: object
})
