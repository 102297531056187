import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { types } from "mobx-state-tree";
import { observer } from "mobx-react-lite";

export const Modal2State = types.model({
  open: types.maybe(types.boolean),
  title: types.maybe(types.string),
  eventUuid: types.maybe(types.string)
}).volatile(self => ({
})).actions( (self)=>({
  toggle(){
    self.open = !self.open
  },
  handleTitle(title){
    self.title = title
  },
  setEvent(e){
    const eventArr = e.target.value.split(" ")
    self.title = eventArr[0]
    self.eventUuid = eventArr[1]
  },
}))

const ScanbuyModal2 = observer(({modal2Store,componentStore,EmbedComponent,size = "",className =""}) => {
 console.log("componentStore",componentStore)
 console.log("modal2Store",modal2Store)
  return (
    <Modal
      isOpen={modal2Store.open}
      toggle={modal2Store.toggle}
      centered
      size={size} //options include sm, lg, xl
      contentClassName={className}
      scrollable
    >
      <ModalHeader toggle={modal2Store.toggle}>
        {modal2Store.title}
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <EmbedComponent store={componentStore}/>
      </ModalBody>
      {/*<ModalFooter>*/}
      {/*  <Button color="secondary" onClick={() => modal2Store.toggle()}>*/}
      {/*    Close*/}
      {/*  </Button>{" "}*/}
      {/*  <Button*/}
      {/*    onClick={() => {*/}
      {/*      componentStore.saveAndClose(modal2Store)*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Save changes*/}
      {/*  </Button>*/}
      {/*</ModalFooter>*/}
    </Modal>
  );
})

export default ScanbuyModal2;
