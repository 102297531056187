import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import {IconOption, Option} from "./IconOption";

const CodeTypeSelect = observer(({ codeTypeSelectStore, isEditMode = false, disabled = false }) => {

  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={codeTypeSelectStore?.options()}
      id="selected"
      value={codeTypeSelectStore.selected}
      // inputValue={createCodeInput.groupSelected}
      //isSearchable
      onInputChange={(data) => codeTypeSelectStore?.search(data)}
      onChange={(data) => codeTypeSelectStore.select(data)}
      getOptionLabel={option => option.label}
      getOptionValue={option => option.uuid}
      placeholder="Select"
      components={{Option: IconOption}}
      isDisabled={disabled}
    />
  );
});

export default CodeTypeSelect;
