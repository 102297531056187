import {types} from "mobx-state-tree";

export const SupersetUserModal = types
	.model({
		username: types.maybeNull(types.string),
		first_name: types.maybeNull(types.string),
		last_name: types.maybeNull(types.string)
	})
	.actions((self) => ({
		updateStatus(newStatus) {
			self.status = newStatus
		},
		addTag(newTag) {
			self.tags.push(newTag)
		},
		removeTag(oldTag) {
			//remove from self.tags
		},
		update(data) {

		},
		refresh(){

		},
		save() {
			//? PUT / ...
		}
	}))
