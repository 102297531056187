export const createTransitions =(codeTypesWithBehavior) => {
  const keys = Object.keys(codeTypesWithBehavior);
  return keys.map(key => {
    return {
      name: codeTypesWithBehavior[key],
      from: keys.map(k => codeTypesWithBehavior[k]),
      to: codeTypesWithBehavior[key]
    };
  });
}
