/**
 * Codes
 *
 * Attributes/params
 *
 * UTM
 *
 * Short Url
 *
 * Url's
 *
 * Applinks
 *
 * Ref parameters
 */
import { types } from "mobx-state-tree";
import { TagModel } from "../tags/TagModel";
import { AuditMetaData } from "../AuditMetaData";
import Parameters from "../Parameters";

export const ShortUrl = types
  .model({
    domain: types.maybeNull(types.string),
    uri: types.maybeNull(types.string),
    prefix: types.maybeNull(types.string),
  });

const GroupData = types.model('group', {
  //createdAt: types.maybeNull(types.string),
  //updatedAt: types.maybeNull(types.string)
  //other _audit fields
  groupUuid: types.maybe(types.string)
})
export const CodeModel = types
  .model({
    uuid: types.string,
    name: types.maybeNull(types.string),
    type: types.string, //types.maybe(types.safeReference(CodeType)),
    behavior: types.string, //types.maybe(types.safeReference(CodeBehavior)),
    status: types.string,
    dataDetail: types.maybe(types.frozen()),
    data: types.maybe(types.frozen()),
    extendedData: types.maybe(types.frozen()),
    shortUrl: types.maybeNull(ShortUrl),
    tags: types.maybe(types.array(TagModel)), //May have to softReference ...
    owner: types.maybe(GroupData),
    utm: types.maybe(types.frozen()),
    parameters:  types.maybe(Parameters),
    rules: types.maybe(types.frozen()),
    images: types.maybe(types.frozen()),
    // ,
    // attributes: types.array(types.string), //parameters
    // rules: types.array(RuleModel),
    // shortUrl: types.reference(ShortUrlModel)
    _audit: types.maybeNull(AuditMetaData),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string)
  })
  .actions((self) => ({
    updateStatus(newStatus) {
      self.status = newStatus;
    },
    addTag(newTag) {
      self.tags.push(newTag);
    },
    removeTag(oldTag) {
      //remove from self.tags
    },
    update(data) {

    },
    refresh() {

    },
    save() {
      //? PUT / ...
    }
  }));
