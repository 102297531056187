import HttpClient from "../../services/HttpClient";

class RuleSetManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/rulesets`
  }

  async create(mobxState) {
    const {data,primaryUrl,isPrimaryUrlDefault,ruleSetName,groupSelectStore,tagSelectStore, isExclusivelyGroupOwned} = mobxState

    const dimensions = mobxState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: mobxState.dimensionsMultiSelectStore.getTranspiledDimensions()}
    console.log("save ruleset")
    try {
      const rules = JSON.parse(data);
      const payload = {
        rules: rules,
        primaryUrl: primaryUrl,
        isPrimaryUrlDefault:isPrimaryUrlDefault,
        name:ruleSetName,
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid, "dimensions":  dimensions?.dimensions},
        isExclusivelyGroupOwned: isExclusivelyGroupOwned,
        tags : tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
      }
      const res = await this.httpClient.post(this.url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to save ruleset: ", e.message);
      return e
    }
  }

  async getRuleSet(uuid) {
    console.log("get ruleset")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.get(url);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to get ruleset: ", e.message);
      return e
    }
  }

  async fetchAll(queryParams) {
    console.log("Rule manager fetchAllRulesets")
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res)
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get rule", e);
    }
    return null;
  }

  async fetchFilters (queryParams) {
    const url = '/sites/filters/ruleSets'
    try {
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }
    return null;
  }

  async update(mobxState,uuid) {
    console.log("update ruleset")
    const {data,primaryUrl,isPrimaryUrlDefault,ruleSetName,groupSelectStore,tagSelectStore, isExclusivelyGroupOwned} = mobxState

    const dimensions = mobxState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: mobxState.dimensionsMultiSelectStore.getTranspiledDimensions()}

    try {
      const rules = JSON.parse(data);
      const payload = {
        rules: rules,
        primaryUrl: primaryUrl,
        isPrimaryUrlDefault:isPrimaryUrlDefault,
        name:ruleSetName,
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid, "dimensions": dimensions?.dimensions},
        tags : tagSelectStore.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        isExclusivelyGroupOwned: isExclusivelyGroupOwned,
      }
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.put(url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to update rule sets: ", e.message);
      return e
    }
  }

  async delete(state) {
    const {uuid}= state
    try {
      console.log("delete ruleset")
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
      return response
    } catch (e) {
      console.error("Failed to delete ruleset", e.message)
    }
  }

  /**
   * deprecated
   * Transform rules factvalue array to match the backend format i.e. [ { "value": "US", "label": "United States"} ] to [ "US" ]
   * @param rules
   * @returns {*}
   */
  transformRules(rules) {
    if(rules?.length > 0 ){
      rules.forEach((rule) => {
        if(rule?.factValue && rule.factValue.length > 0) {
          rule.factValue = rule.factValue.flatMap((r) => r?.value);
        }
      })
    }
    return rules
  }
}

export default RuleSetManager;
