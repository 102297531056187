import {Button} from "reactstrap";
import {getPassPreviewComponent} from "../walletPass/utils";
import {FormattedMessage} from "react-intl";
import React from "react";
import Notification from "../../../utils/Notification";
import {observer} from "mobx-react-lite";

const PreviewMobileWalletPass = observer(({passType, showModalPopUp}) => {

  if (!showModalPopUp || !passType) {
    console.warn("PreviewMobileWalletPass: Invalid 'passType' or 'showModalPopUp'");
    return;
  }

  const selectPassTypeMessage = new Notification()
    .setType("info")
    .setMessage(`Please select Mobile Wallet Pass Type`);

  return (
    <div
      className="d-flex mt-4 mb-4"
    >
      <Button
        className="mr-1 flex-fill"
        color="primary"
        onClick={() => {
          if (!passType) {
            selectPassTypeMessage.send();
            return;
          }
          const applePreviewPass = getPassPreviewComponent(passType, "apple");
          if (applePreviewPass) {
            showModalPopUp(applePreviewPass, `Apple Pass Preview`, "experienceData", "sm", "");
          }
        }}
      >
        <FormattedMessage id="preview.passpage.provider.apple.defaultMessage" />
      </Button>
      <Button
        className="ml-1 flex-fill"
        color="primary"
        onClick={() => {
          if (!passType) {
            selectPassTypeMessage.send();
            return;
          }
          const googlePreviewPass = getPassPreviewComponent(passType, "google");
          if (googlePreviewPass) {
            showModalPopUp(googlePreviewPass, `Google Pass Preview`, "experienceData", "sm", "");
          }
        }}
      >
        <FormattedMessage id="preview.passpage.provider.google.defaultMessage" />
      </Button>
    </div>
  );
});

export default PreviewMobileWalletPass;
