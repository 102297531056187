import React, { createContext } from 'react';
import { flattenMessages } from '../utils/flattenMessages';

export const DocumentationContext = createContext(flattenMessages(import('../lang/en.json')));

export const DocumentationProvider = ({ links, children }) => {
  return (
    <DocumentationContext.Provider value={links}>
      {children}
    </DocumentationContext.Provider>
  );
};
