import {resolveIdentifier, types} from "mobx-state-tree";
import {AuditMetaData} from "../../AuditMetaData";
import {TagModel} from "../../tags/TagModel";

export function create(item, target) {
  return DataExports.create({
    uuid: item?.uuid,
    name: item?.name,
    status: item?.status,
    type: item?.type,
    format: item?.format,
    tags: item?.tags?.map(tag => {
      return resolveIdentifier(TagModel, target, tag?.uuid);
    }),
    _audit: item?._audit,
    definition: item?.definition,
    owner: item?.owner,
    updatedAt: item?.updatedAt,
    hideActions: item?.hideActions,
  });
}

const OwnerModel = types.model("OwnerModel", {
  groupUuid: types.maybe(types.string),
  dimensions: types.optional(types.frozen(),{}),
  groups: types.optional(types.array(types.string), []),
});

export const DataExports = types.model('DataExports', {
  uuid: types.identifier,
  name: types.string,
  status: types.string,
  format: types.string,
  type: types.string,
  owner: types.maybe(OwnerModel),
  _audit: types.maybeNull(AuditMetaData),
  definition: types.optional(types.frozen(), {}),
  tags: types.optional(types.array(types.reference(TagModel)), []),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  hideActions: types.optional(types.array(types.string), [])
});
