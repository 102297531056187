import React from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";

const PageTemplateSelect  = observer(({ store }) => {

  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={store?.configOptionValues()}
      id="selectedGroup"
      value={store?.selectedConfig}
      //inputValue={createCodeInput.selectedGroup}
      isSearchable
      // onInputChange={(data) => store?.searchGroup(data)}
      onChange={(data) => store?.selectConfig(data)}
      getOptionLabel={option => option?.label}
      getOptionValue={option => option?.uuid}
    />
  );
});

export default PageTemplateSelect;
