import {flow, getEnv, types} from "mobx-state-tree";
import {RuleType} from "../../models/RuleType";
// import { ObjectFieldTemplateProps } from "@rjsf/utils";

const geoPointRule = RuleType.create({
  uuid: "geoPoint",
  label: "Geo Point",
  name: "geoPoint",
  type: "geoPoint",
  ruleSchema: JSON.stringify( {
    "definitions": {
      "points": {
        "type": "object",
        "properties": {
          "Latitude": {
            "type": "number"
          },
          "longitude": {
            "type": "number"
          },
          "radius": {
            "type": "number",
            minimum: 0,
          }
        }
      }
    },
    "type": "object",
    "properties": {
      "operator": {
        "title": "Operator",
        "type": "string",
        "enum": [
          "inside",
          "outside",
        ],
        enumNames: ["Inside", "Outside"]
      },
      "factValue": {
        "$ref": "#/definitions/points",
      },
      "action": {
        "type": "object",
        "properties": {
          "url": {
            "title":"Redirect To",
            "type": "string"
          }
        }
      },
    },
  }),
  ruleUiSchema: JSON.stringify({

      // "ui:field": "googleMap"

      // "locationRadio": {
      //   "ui:widget": "RadioWidget"
      // },
      // "checkboxes": {
      //   "ui:widget": "CheckboxesWidget"
      // }
    }
  ),
})


const geoFenceRule = RuleType.create({
  uuid: "geoFence",
  label: "Geo Fence",
  name: "geoFence",
  type: "geoFence",
  ruleSchema: JSON.stringify({
    "definitions": {
      "points": {
        type: "array",
        minimum: 3,
        items: {
          type: "object",
          properties: {
            latitude: {
              type: "number"
            },
            longitude: {
              type: "number"
            }
          }
        }
      }
    },
    "type": "object",
    "properties": {
      "operator": {
        "title": "Operator",
        "type": "string",
        "enum": [
          "within",
          "outside",
        ],
        enumNames: ["Inside", "Outside"]
      },
      "factValue": {
        "title": "Points",
        "$ref": "#/definitions/points",
      },
      "action": {
        "type": "object",
        "properties": {
          "url": {
            "title": "Redirect To",
            "type": "string"
          }
        },
        "required": ["url"]
      },
    },
    "required": ["operator", "action"]
  }),
  ruleUiSchema: JSON.stringify({
    "operator": {
      "ui:classNames": "col-5",
    },
    "factValue": {
      "ui:classNames": "col-12",
      "ui:widget": "CustomCheckboxWidget",
    },
  })
})
const countryOptions = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "UK" },
  { label: "Canada", value: "CA" },
  // Add more countries as needed
];

const tryThis ={
  uuid: "tryThis",
  label: "Try This",
  name: "region",
  type: "region",
  ruleSchema: JSON.stringify( {
    "definitions": {
      "region": {
        "type": "array",
        "title": "Region",
        "items": {
          "type": "string",
          "enum": [
            "NY",
            "CA",
            "FL"
          ],
          "enumNames": ["New York", "California", "Florida"]
        }
      }
    },
    "type": "object",
    "properties": {
      "operator": {
        "title": "Operator",
        "type": "string",
        "enum": [
          "equals",
          "notEquals"
        ],
        "enumNames": ["Equals", "Not Equals"]
      },
      "factValue": {
        "$ref": "#/definitions/region"
      },
      "action": {
        "type": "object",
        "properties": {
          "url": {
            "title":"Redirect To",
            "type": "string"
          }
        },
        "required": ["url"]
      }
    },
    "required": [ "factValue","action"]
  },
),
  ruleUiSchema: JSON.stringify({
    "operator": {
      "ui:classNames": "col-5",
    },
    "factValue": {
      // "ui:classNames": "col-12",
      "ui:widget": "CustomCheckboxWidget",
    },
  }

  )

}


const defaultTypes = [ geoPointRule, geoFenceRule,tryThis]


const RuleTypeStore = types.model("RuleTypeStore", {
  storeUuid: types.identifier,
  ruleTypes: types.optional(types.array(RuleType), []),
}).actions((self) => ({
  initWithData(ruleType = [], enabledRuleTypes = []) {
    try {
      if (ruleType) {
          ruleType.forEach((t) => {
            if (enabledRuleTypes.includes(t.type)) {
              const newRule = RuleType.create(
                {
                  uuid: t.uuid,
                  label: t?.label,
                  labelGroup: t?.labelGroup,
                  type: t?.type,
                  name: t?.type,
                  ruleSchema: JSON.stringify(t?.ruleSchema),
                  ruleUiSchema: JSON.stringify(t?.ruleUiSchema),
                  data: JSON.stringify(t?.data),
                })
              self.ruleTypes.push(newRule)
              self.addRuleTypeData(newRule) //THIS IS REQUIRED FOR EDIT RULESET TO WORK. otherwise schema.items are not populated with data!!!!
            }
          }
        )
        self.ruleTypes.sort((a,b)=> a.label.localeCompare(b.label))
      }
    } catch (e) {
      console.warn("Failed to initWithData", e);
    }

  },
  init: flow(function* f(params = {}) {
    console.log("load RuleTypeStore from API");
    //TODO: this method should not be used, we should use the initWithData method instead, PLEASE REMOVE!

    // yield self.getFromServer(params);
    // const response = yield self.getFromServer(params) || {results: []};
    // self.ruleTypes = response.results.map(t => RuleType.create(
    //   {
    //     uuid: t.uuid,
    //     label: t?.label,
    //     type: t?.type,
    //     name: t?.type,
    //     ruleSchema: JSON.stringify(t?.ruleSchema),
    //     ruleUiSchema: JSON.stringify(t?.ruleUiSchema)}))
  }),
  getFromServer: flow(function* getTags(params = {}) {
    try {
      const response = yield getEnv(self).ruleTypeManager.fetchAll(params);
      if (response && response.results && response.results.length > 0) {
        return response;
      }
      return null;
    } catch (error) {
      console.error("Failed to fetch rules", error);
      return null;
    }
  }),
  addRuleTypeData: flow(function* fetchRuleType(ruleType) {
    // if (ruleType.uuid.split("/")[0].length){return}
    const id = (typeof ruleType === "string")? ruleType : ruleType.uuid.split("/")[0]
    try {
      const rule = self.ruleTypes.find((r) => r.type === id);
      const response = JSON.parse(rule?.data);
      if (response && ruleType.ruleSchema) {
        const type = ruleType.type
        const schema = JSON.parse(ruleType.ruleSchema)
        schema.definitions[type].items = response?.values
        ruleType.addDataToSelectedRuleType(schema)
        return ruleType;
      }
      return null;
    } catch (error) {
      console.log("This rule has no data to add", error);
      return null;
    }
  })
}))


export default RuleTypeStore;
