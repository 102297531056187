import HttpClient from "../../services/HttpClient";

class ParameterSetManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `/parametersets`
  }

  async create(componentState) {
    const {selectedCodeParameters} = componentState.codeParamsTypeSelectState
    const {selectedScanParameters} = componentState.scanParamsTypeSelectState
    const {selectedCountParameters} = componentState.countParamsTypeSelectState
    const {selectedPremiumParameters} = componentState.premiumParamsTypeSelectState
    const {selectedCustomParameters} = componentState.customParamsTypeSelectState
    const {groupSelectStore} = componentState
    const {isExclusivelyGroupOwned} = componentState
    const {tagSelectStore} = componentState
    const dimensions = componentState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: componentState.dimensionsMultiSelectStore.getTranspiledDimensions()}

    try {
      const payload = {
        name:componentState.name,
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid, "dimensions":  dimensions?.dimensions},
        isExclusivelyGroupOwned: isExclusivelyGroupOwned,
        tags : tagSelectStore?.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        codeParamType: selectedCodeParameters.map((parameter) => { return parameter.uuid }),
        scanParamType: selectedScanParameters.map((parameter) => { return parameter.uuid }),
        countParamType: selectedCountParameters.map((parameter) => { return parameter.uuid }),
        premiumParamType: selectedPremiumParameters.map((parameter) => { return parameter.uuid }),
        customParamType: selectedCustomParameters.map((parameter) => { return parameter.uuid }),
        status: "CREATED",
      }
      const res = await this.httpClient.post(this.url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to save parameterSet: ", e.message);
      return e
    }
  }

  async getParameterSet(uuid) {
    console.log("get parameterSet")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.get(url);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to get parameterSet: ", e.message);
      return e
    }
  }

  async fetchAll(queryParams) {
    console.log("parameterSet manager fetchAll")
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      console.log("response in get request", res)
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get ParameterSet", e);
    }
    return null;
  }

  async fetchFilters (queryParams) {
    const url = '/sites/filters/parameterSets'
    try {
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }
    return null;
  }

  async update(componentState) {
    console.log("update ParameterSet")
    const {groupSelectStore} = componentState
    const {selectedCodeParameters} = componentState.codeParamsTypeSelectState
    const {selectedScanParameters} = componentState.scanParamsTypeSelectState
    const {selectedCountParameters} = componentState.countParamsTypeSelectState
    const {selectedPremiumParameters} = componentState.premiumParamsTypeSelectState
    const {selectedCustomParameters} = componentState.customParamsTypeSelectState
    const {isExclusivelyGroupOwned} = componentState
    const {tagSelectStore} = componentState
    const dimensions = componentState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: componentState.dimensionsMultiSelectStore.getTranspiledDimensions()}

    try {
      const payload = {
        name:componentState.name,
        isExclusivelyGroupOwned: isExclusivelyGroupOwned,
        tags : tagSelectStore?.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid, "dimensions": dimensions.dimensions},
        codeParamType: selectedCodeParameters.map((parameter) => { return parameter.uuid }),
        scanParamType: selectedScanParameters.map((parameter) => { return parameter.uuid }),
        countParamType: selectedCountParameters.map((parameter) => { return parameter.uuid }),
        premiumParamType: selectedPremiumParameters.map((parameter) => { return parameter.uuid }),
        customParamType: selectedCustomParameters.map((parameter) => { return parameter.uuid }),
      }
      const url = `${this.url}/${componentState.uuid}`;
      const res = await this.httpClient.put(url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to update ParameterSet: ", e.message);
      return e
    }
  }

  async delete(state) {
    const {uuid}= state
    try {
      console.log("delete ParameterSet")
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
      return response
    } catch (e) {
      console.error("Failed to delete ParameterSet", e.message)
    }
  }
}

export default ParameterSetManager;
