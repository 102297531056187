import {detach, flow, getEnv, getType, types} from "mobx-state-tree";
import {TreeNode} from "../../rulesets/create/CreateRuleForm";
import lodash from "lodash";
import {RuleTypeSelectState} from "../../../elements/ruleType/RuleTypeSelectState";

const DomainDetailViewState = types.model('DomainDetailViewState',{
  rootNode: types.maybe(types.late(() => (TreeNode))),
  ruleTypeSelectState: types.maybe(types.late(() => types.reference(RuleTypeSelectState))),
  formHasErrors: types.optional(types.boolean, false),
  isLoading: types.optional(types.boolean, false),
  readonly: types.optional(types.boolean, false),
}).volatile((self)=>({

})).actions((self) => ({
  handleHasErrors(boolean) {
    self.formHasErrors = boolean;
  },
  fetchModelAndHydrate: flow(function* (id, queryParams={}){
    if (!id) {
      console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
    }
    let response;
    self.isLoading = true;
    try {
      if (self.rootNode.children.length) {
        self.rootNode.clearChildren();
      }
      response = yield getEnv(self).publishDomainManger.fetchByUUID(id, queryParams, self);
      if (response) {
        self.hydrateForm(response);
        self.handleHasErrors(false);
      } else {
        self.handleHasErrors(true);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to Fetch and Hydrate data for '${id}'`, e);
    }
    self.isLoading = false;
    return response;
  }),
  hydrateTree(rules) {
    detach(self.rootNode.children);
    const recurseToGenerateNodes = (arr, currentNode=self.rootNode) => {
      if (!lodash.isEmpty(arr)) {
        for (const [i, node] of arr.entries()) {
          currentNode.addNewChild(self.ruleTypeSelectState.store, node, self.readonly);
          if (!lodash.isEmpty(node.rules)) {
            recurseToGenerateNodes(node.rules, currentNode.children[i]);
          }
        }
      }
    };
    recurseToGenerateNodes(rules);
  },
  hydrateForm(item){
    self.isLoading = true;
    self.hydrateTree(item?.rules);
    self.isLoading = false;
  },
}));

export default DomainDetailViewState;
