import React from "react";
import {observer} from "mobx-react-lite";
import "./ModulesSprite.css"
import "./EyeSprite.css"
import "./BallSprite.css"
const LidAndBallListItem = observer(({EyesAndModulesStore, imageClass, shape, group, isPrimary=false}) => {
  return (

    <div
      onClick={() => {
        EyesAndModulesStore.handleShapeChange(group, shape)
        isPrimary &&  EyesAndModulesStore.applyToAll()
      }}
      style={{
        height: "50px",
        width: "50px",
        marginRight: "20px",
        cursor: "pointer",
        border: (shape === EyesAndModulesStore[group])
          ? "solid 2px red"
          : ""
      }}
    >
      <div  className={`${imageClass}`}></div>
    </div>
  )
})

export default LidAndBallListItem;
