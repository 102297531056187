import { detach, flow, types, getEnv, resolveIdentifier } from 'mobx-state-tree';
import {TagModel as Tag, TagModel} from "../../models/tags/TagModel";
import lodash from "lodash";


const TagStore = types.model('TagStore',{
  storeUuid: types.identifier,
  tags: types.optional(types.array(TagModel), []),
  flatTags: types.optional(types.array(types.frozen()), []), //because taglist can't access nested MST tags model
  selectedGroup: types.maybe(types.string)
}).volatile((self)=>({
  filterListOn:types.fn,
  hydrateFunction: types.fn,
})).actions((self)=>({
  setFilterOn(groupUuid) {
    self.filterListOn = (item) => {   //function used to filter tag options by selected group
      return (item.owner.groupUuid === groupUuid || item?.isExclusivelyGroupOwned === false)
    }
  },
  // setHydrateFuntion(f){
  //   self.hydrateFunction = f
  // },
  setSelectedGroup(uuid) {
    self.selectedGroup = uuid
  },
  initWithData(tags = []) {
    if(tags) {
      const obj = {results: tags};
      self.setTags(obj);
    }
  },
  init: flow(function* f(params = {}) {
    console.log("load tagStore from API");
    yield self.getTags({
      sort: 'updatedAt',
      order: 'desc',
      ...params,
    });
    // const tagsResponse = yield self.getTags(params);
    // console.log(tagsResponse);
    // self.tags = tagsResponse.results.map(t => TagModel.create({ _tenantUuid: t?._tenantUuid, uuid: t?.uuid, name: t?.name, label: t?.name, tags: t?.tags }))
  }),
  setTags(tags) {
    const flatTags = [];
    self.tags = tags.results.map(t => {
      let nestedTags = [];
      let nestedFlatTags = [];
      if(t.tags && t.tags.length > 0) {
        //console.log("### nested tags",t.tags);
        nestedTags = t.tags.map(n => {
          const childTtag = resolveIdentifier(TagModel, self, n?.uuid);
          nestedFlatTags.push({
            _tenantUuid: n?._tenantUuid,
            uuid: n?.uuid,
            name: n?.name,
            label: n?.name,
            tags: [],
            createdAt: n?.createdAt,
            updatedAt: n?.updatedAt,
            _audit: n?._audit,
            isExclusivelyGroupOwned: n?.isExclusivelyGroupOwned
          });
          if (childTtag) return childTtag;
          //console.log("### childTtag",childTtag);
          //return childTtag;

          return TagModel.create({ _tenantUuid: n?._tenantUuid, uuid: n?.uuid, name: n?.name, label: n?.name, tags: [], createdAt: n?.createdAt, updatedAt: n?.updatedAt, isExclusivelyGroupOwned: n?.isExclusivelyGroupOwned })
        })//ignore nested tags
        //console.log("### nestedTags2",nestedFlatTags);
      }
      const newTagModel = TagModel.create({ _tenantUuid: t?._tenantUuid, uuid: t?.uuid, name: t?.name, label: t?.name, tags: nestedTags, createdAt: t?.createdAt, updatedAt: t?.updatedAt, owner: t?.owner, isExclusivelyGroupOwned: t?.isExclusivelyGroupOwned })
      flatTags.push({
        _tenantUuid: t?._tenantUuid,
        uuid: t?.uuid,
        name: t?.name,
        label: t?.name,
        tags: nestedFlatTags,
        owner: t?.owner,
        createdAt: t?.createdAt,
        updatedAt: t?.updatedAt,
        _audit: t?._audit,
        isExclusivelyGroupOwned: t?.isExclusivelyGroupOwned,
      });
      //console.log("### newTagModel",newTagModel);
      return newTagModel;
    })
    self.tags.sort((a, b) => a.label.localeCompare(b.label));
    console.log("### self tags ");
    console.log(flatTags);
    self.flatTags = flatTags;
  },
  setFlatTags(tags) {//method to only modify FlatTags
    const flatTags = [];
    tags.results.map(t => {
      let nestedTags = [];
      let nestedFlatTags = [];
      if(t.tags && t.tags.length > 0) {
        nestedTags = t.tags.map(n => {
          const childTtag = resolveIdentifier(TagModel, self, n?.uuid);
          nestedFlatTags.push({
            _tenantUuid: n?._tenantUuid,
            uuid: n?.uuid,
            name: n?.name,
            label: n?.name,
            tags: [],
            createdAt: n?.createdAt,
            updatedAt: n?.updatedAt,
            isExclusivelyGroupOwned: n?.isExclusivelyGroupOwned,
            isEditable: n?.isEditable,
            _audit: n?._audit
          });
          if(childTtag) return childTtag;

          return TagModel.create({ _tenantUuid: n?._tenantUuid, uuid: n?.uuid, name: n?.name, label: n?.name, tags: [], createdAt: n?.createdAt, updatedAt: n?.updatedAt, isExclusivelyGroupOwned: n?.isExclusivelyGroupOwned })
        })
      }
      flatTags.push({
        _tenantUuid: t?._tenantUuid,
        uuid: t?.uuid,
        name: t?.name,
        label: t?.name,
        owner: t?.owner,
        tags: nestedFlatTags,
        createdAt: t?.createdAt,
        updatedAt: t?.updatedAt,
        isExclusivelyGroupOwned: t?.isExclusivelyGroupOwned,
        isEditable: t?.isEditable,
        _audit: t?._audit
      });
    })
    self.flatTags=flatTags;
  },
  appendTagModel: function(tag) {
    console.log("Tag",tag)
    const newTagModel = TagModel.create({
      _tenantUuid: tag?._tenantUuid,
      uuid: tag?.uuid,
      name: tag?.name,
      label: tag?.name,
      tags: [],
      owner: tag?.owner,
      createdAt: tag?.createdAt,
      updatedAt: tag?.updatedAt,
      _audit: tag?._audit,
      isExclusivelyGroupOwned: tag?.isExclusivelyGroupOwned
    });
    self.tags.push(newTagModel);
  },
  removeTag(uuid) {
    self.tags = self.tags.filter(tag => tag.uuid !== uuid);
  },
  getTags: flow(function* getTags(params = {}) {
    try {

      //only if its not a group query add isExclusivelyGroupOwned
      params = Object.assign({
          ...params,
        },
        lodash.isEmpty(params.groupUuid) && {isExclusivelyGroupOwned: false}
      )

      const tagsResponse = yield getEnv(self).tagManager.fetchAllTags(params);
      if(tagsResponse && tagsResponse.results && tagsResponse.results.length > 0) {
        self.setFlatTags(tagsResponse)
        //self.tags = tagsResponse.results.map(t => TagModel.create({ _tenantUuid: t?._tenantUuid, uuid: t?.uuid, name: t?.name, label: t?.name, tags: t?.tags }))

      }
      return tagsResponse;
    } catch (error) {
      console.error("Failed to fetch tags", error);
      return null;
    }
  }),
  // clearTagStore(tagsToKeep = []) {
  //   self.tags.forEach(tag => {
  //     if (!tagsToKeep.find(tk => tk.uuid === tag.uuid)) {
  //       detach(tag);
  //       self.tags.remove(tag);
  //     }
  //   });
  // },

})).views((self) => ({
  filteredList() {
    if(self.filterListOn) {
      //return self.tags.filter((item) => item.isExclusivelyGroupOwned === false);
      let result = self.tags.filter((item) => self.filterListOn(item));
      result = Array.from(new Set([...result, ...self.tags.filter((item) => !item.isExclusivelyGroupOwned)]));
      return result;
    }
    else {
      // convert observable arrays to plain arrays
      return self.tags.slice() || [];
    }
  }
}));

export default TagStore;
