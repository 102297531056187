import {observer} from "mobx-react-lite";
import {Button, Col, InputGroup} from "reactstrap";
import React, {useContext} from "react";
import {FormattedMessage} from "react-intl";
import {StateContext} from "../../../../App";
import {useRouterStore} from "mobx-state-router";

const ExportButton = observer(({viewState}) => {
  const stateContext = useContext(StateContext);
  const routerStore = useRouterStore();

  return stateContext?.appConfigs?.features?.cmp_ui_data_exports?.enabled && (
    <>
      <Col sm={1}>
        <div></div>
      </Col>
      <Col sm={0.5} style={{ marginBottom: "16px" }}>
        <InputGroup>
          <Button id="create-data-export-button" color="primary" onClick={() => viewState?.handleExportClick(routerStore)}>
            <FormattedMessage id="listCodes.export.defaultMessage"/>
          </Button>
        </InputGroup>
      </Col>
    </>
  );
});

export default ExportButton;
