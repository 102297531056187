import React from "react";
import Blank from "../pages/misc/Blank";


import {
  routeDefinitions as codeRouteDefinitions,
  routeTransitions as codeRoutes,
  viewMap as codeViewMap
} from "./codes";

import {
  routeDefinitions as batchDefinitions,
  routeTransitions as batchRoutes,
  viewMap as batchViewMap
} from "./batches";

import {
  routeDefinitions as tagDefinitions,
  routeTransitions as tagRoutes,
  viewMap as tagViewMap
} from "./tags";

import {
  routeDefinitions as reportDefinitions,
  routeTransitions as reportRoutes,
  viewMap as reportViewMap
} from "./reports";

import {
  routeDefinitions as appLinkDefinitions,
  routeTransitions as applinkRoutes,
  viewMap as applinkViewMap
} from "./applinks";

import {
  routeDefinitions as qrDesignerDefinitions,
  routeTransitions as designerRoutes,
  viewMap as qrDesignerViewMap
} from "./designer";

import {
  routeDefinitions as referenceDefinitions,
  routeTransitions as referenceRoutes,
  viewMap as referenceViewMap
} from "./references";

import {
  routeDefinitions as dashboardDefinitions,
  routeTransitions as dashboardRoutes,
  viewMap as dashboardViewMap
} from "./dashboard";

import {
  routeDefinitions as qrtemplateDefinitions,
  routeTransitions as qrtemplateRoutes,
  viewMap as qrtemplateViewMap
} from "./qrtemplates";

import {
  routeDefinitions as prefixDefinitions,
  routeTransitions as prefixRoutes,
  viewMap as prefixViewMap
} from "./prefixes";

import {
  routeDefinitions as domainDefinitions,
  routeTransitions as domainRoutes,
  viewMap as domainViewMap
} from "./domains";

import {
  routeDefinitions as extendedDataDefinitions,
  routeTransitions as extendedDataRoutes,
  viewMap as extendedDataViewMap
} from "./extendedData";

import {
  routeDefinitions as rulesetDefinitions,
  routeTransitions as rulesetRoutes,
  viewMap as rulesetViewMap
} from './rulesets'

// import {
//   routeDefinitions as parameterSetDefinitions,
//   viewMap as parameterSetViewMap
// } from './parametersets';

import {
  routeDefinitions as ruleDefinitions,
  viewMap as ruleViewMap
} from './rules';

import {
  routeDefinitions as utmDefinitions,
  routeTransitions as utmRoutes,
  viewMap as utmViewMap
} from "./utms"

import {
  routeDefinitions as parameterSetDefinitions,
  routeTransitions as parameterSetRoutes,
  viewMap as parameterSetViewMap
} from "./parameterSets"
import {
  routeDefinitions as dataExportDefinitions,
  routeTransitions as dataExportRoutes,
  viewMap as dataExportViewMap
} from "./data/exports";
/*--YEOMAN-ROUTE-IMPORTS--*/


// Menu Routes.  **! ORDER MATTERS HERE !**
export const menuroutes = [
  ...dashboardDefinitions,
  ...codeRouteDefinitions,
  ...batchDefinitions,
  ...reportDefinitions,
  ...appLinkDefinitions,
  ...dataExportDefinitions,
  ...qrDesignerDefinitions,
  ...domainDefinitions,
  ...extendedDataDefinitions,
  ...parameterSetDefinitions,
  ...prefixDefinitions,
  ...referenceDefinitions,
  ...ruleDefinitions,
  ...rulesetDefinitions,
  ...qrtemplateDefinitions,
  ...tagDefinitions,
  ...utmDefinitions,
  /*--YEOMAN-ROUTEDEFS--*/
];


export const viewMap = {
  notFound: <Blank/>,
  ...dashboardViewMap,
  ...codeViewMap,
  ...batchViewMap,
  ...tagViewMap,
  ...reportViewMap,
  ...applinkViewMap,
  ...dataExportViewMap,
  ...qrDesignerViewMap,
  ...referenceViewMap,
  ...qrtemplateViewMap,
  ...utmViewMap,
  ...prefixViewMap,
  ...domainViewMap,
  ...extendedDataViewMap,
  // ...parameterSetViewMap,
  ...ruleViewMap,
  ...rulesetViewMap,
  ...parameterSetViewMap,
  /*--YEOMAN-VIEWMAPS--*/

};


export const routes = [
  ...codeRoutes,
  ...designerRoutes,
  ...tagRoutes,
  ...applinkRoutes,
  ...dataExportRoutes,
  ...batchRoutes,
  ...domainRoutes,
  ...reportRoutes,
  ...dashboardRoutes,
  ...prefixRoutes,
  ...extendedDataRoutes,
  ...qrtemplateRoutes,
  ...referenceRoutes,
  ...rulesetRoutes,
  ...utmRoutes,
  ...parameterSetRoutes,
  /*--YEOMAN-ROUTETRANSITION--*/
  {
    name: 'notFound',
    pattern: '/notfound'
  },
  {
    name: 'settings',
    pattern: '/settings'
  },
];








