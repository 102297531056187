import {types} from "mobx-state-tree";


const  PageTemplateModel = types.model('PageTemplateModel',{
  uuid: types.identifier,
  label: types.string,
  name: types.string,
  pageType: types.string,
  configs: types.frozen()
})

export default PageTemplateModel;
