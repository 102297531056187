import { getType, types } from "mobx-state-tree";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";

const TagModel = types.model('TagModel',{
  uuid: types.identifier,
  name: types.maybe(types.string),
})
export const TagFilterSelectState = types.model('TagFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  tagList: types.optional(types.array(TagModel), []),
  isDisabled: types.optional(types.boolean, false),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
})).actions((self)=>({
  reset(){
    self.selected = []
    self.setIsDisabled(false);
  },
  setTags(tags){
    self.tagList = tags
    self.tagList.sort((a, b) => a?.name?.localeCompare(b?.name));
  },
  setIsDisabled(d) {
    self.isDisabled = d;
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["tags"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  optionValues() {
    const tagOptions = (self.tagList || []).flatMap( (tag) => {
      return {
        label: tag?.name,
        value: "tags."+tag.uuid
      }
    });

    const options = [
      {
        label: "",
        options: tagOptions
      },
    ];
    return options;
  }
}))
