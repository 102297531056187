import {flow, getEnv, types} from "mobx-state-tree";
import {Utm} from "../../../models/utms/UtmModel";
import QrPreviewState from "../../qr/qrpreview/QrPreviewState";
import DesignerState from "../../qr/designer/DesignerState";
import {QrConfigSelectState} from "../../elements/qrconfig/QrConfigSelectState";
import QrConfiguration from "../../../models/QrConfiguration";
import CreateCode from "../create/CreateCodeDomain";
import {EditCodeState} from "../edit/EditCodeState";
import {CodeModel} from "../../../models/codes/CodeModel";
import {TagSelectState} from "../../elements/tags/TagSelectState";
import { DashboardStore } from "src/stores/domain/DashboardStore";

export const PreviewCodeAndEditDesignState = types.model('PreviewCodeAndEditDesignState',{
  isLoading: types.optional(types.boolean, false),
  noDesignOpen:types.optional(types.boolean, false),
  customOpen:types.optional(types.boolean, true),
  editMode:types.optional(types.boolean, false),
  designerViewStore:types.maybe(types.late(() => DesignerState)),
  codeUuid: types.optional(types.string, ""),
  editCodeState: types.maybe(types.late(() => EditCodeState)),
  groupUuid: types.optional(types.string, ""),
  dashboardStore: types.maybe(types.reference(DashboardStore)),
  // row:types.maybeNull(types.reference(CodeModel))

  // designerViewStore:types.maybe(types.late(() => DesignerState)),
  // qrPreviewStore:types.maybe(types.late(() => QrPreviewState)),
  // qrConfigSelectStore:types.maybe(types.reference(QrConfigSelectState)),
}).volatile((self)=>({
  codeListModalSizeListener: types.fn,
  row: types.object,
})).actions((self) => ({
  setCodeListModalSizeListener(fn) {
    self.codeListModalSizeListener = fn
  },
  handleIsDefault() {
    self.isMadeDefault = !self.isMadeDefault
    console.log(self)
  },
  setNoneOpen() {
    self.noDesignOpen = true
    self.customOpen = false
  },
  handleEditMode() {
    self.editMode = !self.editMode
    self.codeListModalSizeListener(self.editMode)
  },
  setCustomOpen() {
    self.noDesignOpen = false
    self.customOpen = true
  },
  updateDesign: flow(function* f(designerStore,uuid,configStore,qrConfigSelectStore,codeListViewState) {
// if noDesignOpen is true, then call on the codeManger and update imageUuid to ""
    if(self.noDesignOpen){
      //fetch the code from the codeManager and update the imageUuid to ""
      // qrConfigSelectStore.selectConfig(qrConfigSelectStore.configStore?.defaultConfiguration[1])
      // qrConfigSelectStore.getDefault()

      configStore.removeImageConfig()

      const codeManager = getEnv(self).codeManager

      // const code = yield codeManager.fetchQrcodeWithId(self.codeUuid)
      const data = {uuid:self.codeUuid}
      yield codeManager.removeDesign(data)
      codeListViewState.refresh()
      return "noDesignOpen"
    }
    const {extendConfig} = designerStore
    const imageConfigManager = getEnv(self).imageConfigManager
    console.log("Image",imageConfigManager)
    const res = yield imageConfigManager.updateApplyToCode(extendConfig,uuid, self.dashboardStore.apps.defaultImageConfig, self.dashboardStore.apps.v1ImageConfig)
    return  res?.uuid
  })
})).views( (self) => ({
}))


