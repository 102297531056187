import React from "react";
import {observer} from "mobx-react-lite";
import Select from "react-select";


const GroupFilterSelect = observer(({groupFilterSelectState}) => {

  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={groupFilterSelectState?.optionValues()}
      placeholder="Select"
      isSearchable
      isDisabled={groupFilterSelectState?.isDisabled || false}
      onChange={groupFilterSelectState?.filterChange}
      value={groupFilterSelectState?.selected ? groupFilterSelectState?.selected : []}
      isMulti
      // dropdown placement relative to viewport
      menuPortalTarget={document.querySelector('#root .wrapper .main')}
      menuPlacement="auto"
      menuPosition="fixed"
    />
  );
});

export default GroupFilterSelect;
