import { flow, getEnv, types } from 'mobx-state-tree'
import QrTemplate from '../../models/QrTemplates'
import {getTemplate} from '@rjsf/utils';
import lodash from 'lodash';

const QrTemplateStore = types.model('QrTemplateStore',{
  storeUuid: types.identifier,
  qrtemplates: types.optional(types.array(QrTemplate), []),
  }).actions((self)=>({
    initWithData(qrtemplates = []) {
      console.log("enetered into init TemplateStprrr",qrtemplates)
      try {
        if (!lodash.isEmpty(qrtemplates) && lodash.isArray(qrtemplates)) {
          self.qrtemplates = qrtemplates.map(qrtemplate => QrTemplate.create({
            uuid: qrtemplate?.uuid,
            name: qrtemplate?.name,
            label: qrtemplate?.name,
            inputSchema: JSON.stringify(qrtemplate?.inputSchema),
          })
        )
        }
      } catch (e) {
        console.error("Failed to init TemplateStore with data",e);
      }
    },
  }))

  export default QrTemplateStore;
   