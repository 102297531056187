import React from "react";
import {observer} from "mobx-react-lite";
import "./FrameSprite.css"

const FrameShapeListItem = observer(({FrameAndCaptionsStore, shape, group, imageClass, imageCharsStore, isPrimary=false}) => {
    return (
        <div
        onClick={() => {
            const isCurrentlyClicked = shape === FrameAndCaptionsStore[group];
            if (isCurrentlyClicked) {
                // Unclick action (revert to the unclicked state)
                FrameAndCaptionsStore.handleShapeChange(group, null, imageCharsStore);
              } else {
                // Click action (set it to the clicked state)
                FrameAndCaptionsStore.handleShapeChange(group, shape, imageCharsStore);
              }
            // FrameAndCaptionsStore.handleShapeChange(group, shape, imageCharsStore)
            isPrimary &&  FrameAndCaptionsStore.applyToAll()
        }}
        style={{
            marginRight: "20px",
            cursor: "pointer",
            border: (shape === FrameAndCaptionsStore[group])
            ? "solid 2px red"
            : "",
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            width: "60px",
            height: "60px",
        }}
        >
            <div  className={`${imageClass}`}></div>
        </div>
    )
})

export default FrameShapeListItem;
