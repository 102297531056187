import {flow, getEnv, types} from "mobx-state-tree";
import {AuditMetaData} from "../AuditMetaData";
import {SuperSetInfoModel} from "./SuperSetInfo";

export const ReportModel = types.model({
		uuid: types.string,
		name: types.maybeNull(types.string),
		superSetInfo: types.maybeNull(SuperSetInfoModel),
		_audit: types.maybeNull(AuditMetaData),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
	}).volatile(self => ({
	searchParams: {}
}))
	.actions((self) => ({
		updateStatus(newStatus) {
			self.status = newStatus
		},
		addTag(newTag) {
			self.tags.push(newTag)
		},
		removeTag(oldTag) {
			//remove from self.tags
		},
		update(data) {

		},
		refresh() {

		},
		save() {
			//? PUT / ...
		},
		clearSearchParams(){
			self.searchParams = {}
		},
		addSearchParams(param){
			self.searchParams = {...self.searchParams, ...param}
		},
	}))

export const ReportModelList = types.model({
	items: types.optional(types.array(ReportModel), []),
	isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
	page: types.optional(types.integer, 1),
	perPage: types.optional(types.integer, 10),
	totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
	searchParams: {}
})).actions((self) => ({
  getItems() {
    return self.items
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection
  },
  setSortField(sortField){
    self.sortField = sortField
  },
	fetchAll: flow(function* fetchAll(sortParams = {}) {
		self.items = []
		self.isLoading = true
    if (Object.keys(sortParams).length === 0) {
      sortParams = {
        sort: 'updatedAt',
        order: self.sortOrder
      };
    }
		try {
			const queryParams = {
				page: self.page,
				perPage: self.perPage,
				...sortParams,
				...self.searchParams
			}

      const manager = getEnv(self).reportManager;
			const response = yield manager.fetchAllReports(queryParams)

      if(response != null) {
        console.log("response.results", response.results);
        self.items = response.results;
        self.totalRows = response._meta.itemCount;
        self.isLoading = false;
      } else {
        self.isLoading = false;
      }

		} catch (error) {
			console.error('Failed to fetch tags', error)
		}
	}),
	addItems(item) {
		self.items.push(item)
	},
	updatePage: function (page) {
		self.page = page
	},
	updatePerPage: function (perPage) {
		self.perPage = perPage
	},
	addSearchParams(param) {
		self.searchParams = {...self.searchParams, ...param}
	},
	removeItem(uuid) {
		self.items = self.items.filter(item => item.uuid !== uuid)
	},
	afterCreate() {
		console.log("Init Search Params")
		self.searchParams = {}
	}
}))
