import { RouterState, RouterStore } from "mobx-state-router";
import async from "../../components/Async";
import {DashboardViewState} from "../../components/dashboard/dashboardViewState";
import { faHome } from "@fortawesome/free-solid-svg-icons";


const Dashboard = async(() => import("../../pages/dashboards"));


export const viewMap = {
  dashboard: <Dashboard/>
}

export const routeDefinitions = [
  {
    path: "dashboard",
    name: "Home",
    icon: faHome,
    component: Dashboard,
    children: null
  }
];


export const routeTransitions = [
  {
    name: 'dashboard',
    pattern: '/',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {

      if (window.location.hash) {
        // Remove the hash from the URL without causing a page refresh
        const currentUrlWithoutHash = window.location.href.split('#')[0];
        window.history.replaceState({}, document.title, currentUrlWithoutHash);
      }

      const {rootStore} = routerStore.options;
      const dashboardStore = rootStore.domainStore.dashboardStore
      const dashboardViewState = DashboardViewState.create({
        appsStore: dashboardStore
      })
      await rootStore.rootUiStore.attachPageState("dashboardViewState", dashboardViewState);
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      console.log("exit", fromState)
      console.log("exit", toState)
      console.log("exit", routerStore)

    }
  },
  {
    name: 'Dashboard',
    pattern: '/dashboard',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      routerStore.goTo('dashboard')
    }
  }
]

