import { faLink } from "@fortawesome/free-solid-svg-icons";
import Blank from "../../pages/misc/Blank";


export const viewMap = {

}


export const routeDefinitions = [
  {
    path: "/configs/references",
    name: "References",
    icon: faLink,
    component: Blank,
    children: null,
    featureFlagRequired: "cmp_ui_references"
  }
];


export const routeTransitions = [];
