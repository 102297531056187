import { getType, types } from "mobx-state-tree";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";
import GroupStore from "../../../stores/domain/GroupStore";
import DimensionStore from "../../../stores/domain/DimensionStore";

export const CodeOwnerFilterSelectState = types.model('CodeOwnerFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  groupStore: types.maybe(types.late(() => types.reference(GroupStore))),
  dimensionStore: types.maybe(types.late(() => types.reference(DimensionStore))),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array
})).actions((self)=>({
  reset(){
    self.selected = []
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["groupUuid"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    // console.log("Instantiated " + getType(self).name)
    // self.notifySelect = (data) => {
    //   console.log("placeholder notifySelect")
    // }
  }

})).views( (self) => ({
  optionValues() {
    const { groups } = self.groupStore || [];

    const groupOptions = groups.flatMap( (group) => {
      return {
        label: group.label,
        value: "groupUuid."+group.uuid,
      }
    });

    const { dimensions, dimensionsEnabled } = self.dimensionStore || [];

    const dimensionOptions = dimensions?.map( (dimension) => {
      return {
        uuid: dimension?.uuid,
        label: dimension.label,
        options: dimension?.values?.map(item => {
          return {
            label: item?.label,
            value: 'dimensions.' + dimension?.uuid + '_' + item?.uuid,
          }
        })
      }
    });


    const options = [
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.groups.defaultMessage"/>,
        options: groupOptions
      },
        ...dimensionsEnabled ? [...dimensionOptions] : []
    ];
    return options;
  }
}))
