class PageTemplateManager {

  getCodeState(rootStore) {
    let fromState = window.location.pathname
    fromState = fromState.includes('codes/edit') ? 'codes/edit' : fromState
    let codeState,styles
    switch (fromState) {
      case '/codes':
        codeState = rootStore?.codeListViewState
        styles = codeState?.pageTemplateSelectStore?.getStyles()
        return {codeState, experienceData: null, styles}
      case '/codes/new':
        codeState = rootStore?.createCodeViewState
        return {codeState, experienceData: this.validateExperienceData(codeState?.experienceDataView()), styles: null}
      case 'codes/edit':
        codeState = rootStore?.editCodeState
        styles = codeState?.pageTemplateSelectStore?.getStyles()
        return {codeState, experienceData: this.validateExperienceData(codeState?.experienceDataView()),styles}
      default:
        return {codeState: null, experienceData: null, styles:null}
    }
  }

  validateExperienceData(data = {}) {
    if (data.phoneNumbers && data.phoneNumbers instanceof Array) {
      data.phoneNumbers = data.phoneNumbers.filter(item => item.hasOwnProperty('phoneType'))
    }
    if (data.emails && data.emails instanceof Array) {
      data.emails = data.emails.filter(item => item.hasOwnProperty('emailType'))
    }
    if (data.addresses && data.addresses instanceof Array) {
      data.addresses = data.addresses.filter(item => item.hasOwnProperty('addressType'))
    }
    return data
  }

}


export default PageTemplateManager;
