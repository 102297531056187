import React from "react";
import {observer} from "mobx-react";
import {Button, Card, CardBody, Col, Form, FormGroup} from "reactstrap";
import { FormattedMessage } from "react-intl";
const PublishView = observer(({store})=>{
  return (
    <>
      <Card>
        <CardBody>
          <Form>
            <FormGroup row>
              <Col sm={{size: 10, offset: 2}}>
                <p className="float-left" style={{marginLeft: "-5rem"}}>
                <FormattedMessage id="listCodes.actions.publish.message.defaultMessage"/>
                  <br/> <b><font size="+1"></font></b>
                </p>
                <Button id="publish-btn" color="primary" className="float-right" onClick={async () => {
                   await store.publishCode();
                  // window.location.reload(false)
                }}><FormattedMessage id="listCodes.actions.publish.submit.defaultMessage"/></Button>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </>
  )
})
export default PublishView
