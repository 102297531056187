/**
 *
 *
 */

const loadLanguage = async (locale) => {
  return import(`../lang/${locale}.json`)
}

export async function getLanguageFromLocale(locale) {
  const l = await loadLanguage(locale) || await import('../lang/en-US.json');
  return l.default;

}
