import { resolveIdentifier, types} from "mobx-state-tree";
import lodash from "lodash";
import ScanParamsStore from "../../../../stores/domain/parameters/ScanParamsStore";
import Parameter from "../../../../models/Parameter";

export const ScanParamsTypeSelectState = types.model('ScanParamsTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.reference(ScanParamsStore)),
  selectedScanParameters: types.optional(types.array(types.safeReference(Parameter)), []),
}).volatile((self)=>({
  notifySelect: types.str,
})).actions((self) => ({
  setConsumer(f){
    self.notifySelect = f
  },
  selectParameters(e) {
    if (lodash.isArray(e)) {
      self.selectedScanParameters = e?.map(g => {
        return resolveIdentifier(Parameter, self, g?.uuid || g)
      })
    } else if (e) {
      self.selectedScanParameters = e
    }
  },
})).views( (self) => ({
  options() {
    return  self.store.getScanParameters() || [];

  },

}))


