import {flow, getEnv, getType, types} from "mobx-state-tree";
import {GroupSelectState} from "../elements/groups/GroupSelectState";
import {TagSelectState} from "../elements/tags/TagSelectState";
import Group from "../../models/Group";
import {DimensionsMultiSelectState} from "../elements/dimensions/multi-select/dimensionsMultiSelectState";
import lodash from "lodash";
import Notification from "../../utils/Notification";
import {DimensionSelectViewState} from "../elements/dimensions/dimensionSelectViewState";
import {ExtendedDataSelectState} from "./ExtendedDataSelectState";

const ExtendedDataSetFormState = types.model("ExtendedDataSetFormState", {
  name: types.optional(types.string, ""),
  isLoading: types.optional(types.boolean, false),
  isNameInvalid: types.optional(types.boolean, false),
  extendedDataSelectStore: types.maybe(types.late(() => types.reference(ExtendedDataSelectState))),
  tagSelectStore: types.maybe(types.late(() => types.reference(TagSelectState))),
  selectedTags: types.optional(types.array(types.string), []),
  groupSelectStore: types.maybe(types.late(() => types.reference(GroupSelectState))),
  selectedGroup: types.maybe(types.safeReference(Group)),
  formHasErrors: types.optional(types.boolean, false),
  errorMessage: types.optional(types.string, "Null"),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  dimensionsMultiSelectStore: types.maybeNull(types.late(() => types.reference(DimensionsMultiSelectState))),
  dimensionAll: types.optional(types.boolean, true),
  isDimensionSelectVisible: types.optional(types.boolean, false),
  isExclusivelyGroupOwned: types.optional(types.boolean, false),
}).volatile((self)=>({

})).actions(self => ({
  setDefaults() {
    self.isLoading = true;
    self?.setOnSelectChanges();
    self?.groupSelectStore?.selectGroup(self?.groupSelectStore?.groupStore?.groups[0] || undefined);
    self?.tagSelectStore?.setSelectedTags([]);
    self?.selectExtendedData([]);
    self?.dimensionSelectViewState?.reset();
    self.isLoading = false;
  },
  handleNameChange(e) {
    self.name = e?.target?.value;
    if (self.name) {
      self.isNameInvalid = false;
    }
  },
  handleHasErrors(boolean) {
    self.formHasErrors = boolean;
  },
  handleErrorMessage(message) {
    self.errorMessage = message;
  },
  setOnSelectChanges() {
    self.groupSelectStore?.setConsumer(self.selectGroup);
    self.tagSelectStore?.setConsumer(self.selectTag);
    self.extendedDataSelectStore?.setConsumer(self.selectExtendedData);
    self.dimensionsMultiSelectStore?.setConsumer(self.groupOrDimensionOnChange);
  },
  selectExtendedData(arr) {
    self.extendedDataSelectStore?.setSelectedExtendedData(arr);
  },
  groupOrDimensionOnChange() {
    self.tagSelectStore?.tagStore?.setFilterOn(self?.selectedGroup?.uuid);
    self.extendedDataSelectStore?.store?.setFilterOn((item) => {
      // let validDimensionScopes = [];
      // const selectedDim = self.getSelectedDimensions();
      // if (!self.dimensionAll && selectedDim && Object.keys(item?.owner?.dimensions || {}).length) {
      //   Object.entries(item.owner.dimensions).forEach(([key, values]) => {
      //     validDimensionScopes.push(
      //       selectedDim.hasOwnProperty(key) &&
      //       selectedDim[key].length === values.length &&
      //       selectedDim[key].every(val => values.includes(val))
      //     );
      //   });
      // }

      let groupCondition = (item.owner.groupUuid === self?.selectedGroup?.uuid || item?.isExclusivelyGroupOwned === false);
      // return validDimensionScopes.length ?
      //   validDimensionScopes.every(element => element === true) && groupCondition:
      //   groupCondition;

      return groupCondition;
    });
  },
  selectGroup(one) {
    self.selectedGroup = one;
    self.groupOrDimensionOnChange();
  },
  selectTag(arr) {
    self.selectedTags = arr.flatMap((tag) => tag.uuid);
  },
  toggleIsExclusivelyGroupOwned(){
    self.isExclusivelyGroupOwned = !self.isExclusivelyGroupOwned
  },
  toggleDimensionAll(){
    self.dimensionAll = !self.dimensionAll
    self.toggleIsDimensionSelectVisible(!self.dimensionAll)
    self.dimensionAll ? self.dimensionsMultiSelectStore.selectAllDimensions() : self.dimensionsMultiSelectStore.reset()
  },
  toggleIsDimensionSelectVisible(visible){
    self.isDimensionSelectVisible = visible
  },
  transformErrors(errors) {
    return errors.map(error => {
      return error;
    });
  },
  validateForm() {
    const selected = self?.extendedDataSelectStore?.getSelectedExtendedData()?.length > 0;
    if (lodash.isEmpty(self.name)) {
      self.isNameInvalid = true;
    }
    return !self.isNameInvalid && selected;
  },
  update: flow(function* (uuid) {
    self.isLoading = true;
    self.handleHasErrors(false);
    let response;
    try {
      if (self.validateForm()) {
        response = yield getEnv(self).extendedDataManager.updateExtendedDataSet(uuid, self);
        if (self.formHasErrors) {
          new Notification()
            .setType("error")
            .setMessage(`${self.name} Extended Data Set failed updating`)
            .send();
        } else {
          new Notification()
            .setType("success")
            .setMessage(`${self.name} Extended Data Set updated`)
            .send();
          self.handleHasErrors(false);
        }
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to updateExtendedDataSet`, e);
    }
    self.isLoading = false;
    return response;
  }),
  getSelectedDimensions() {
    const dimensions = self.dimensionsMultiSelectStore.getTranspiledDimensions();
    return Object.values(dimensions).every(l => !l.length) ? undefined : dimensions;
  },
  saveNew: flow(function* () {
    self.isLoading = true;
    self.handleHasErrors(false);

    let response;
    try {
      if (self.validateForm()) {
        response = yield getEnv(self).extendedDataManager.saveExtendedDataSet(self);
        if (self.formHasErrors) {
          new Notification()
            .setType("error")
            .setMessage(`${self.name} Extended Data Set failed creating`)
            .send();
        } else {
          new Notification()
            .setType("success")
            .setMessage(`${self.name} Extended Data Set created`)
            .send();
          self.handleHasErrors(false);
        }
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to saveExtendedDataSet`, e);
    }
    self.isLoading = false;
    return response;
  }),
  fetchModelAndHydrate: flow(function* (id, queryParams={}){
    if (!id) {
      console.warn(`${getType(self).name} Warn: ID is 'undefined' or 'null'`);
    }
    let response;
    self.isLoading = true;
    self?.setOnSelectChanges();
    try {
      response = yield getEnv(self).extendedDataManager.fetchExtendedDataSetByUUID(id, queryParams, self);
      if (response) {
        self.hydrateForm(response);
        self.handleHasErrors(false);
      } else {
        self.handleHasErrors(true);
      }
    } catch (e) {
      console.error(`${getType(self).name} Error: Failed to Fetch and Hydrate data for '${id}'`, e);
    }
    self.isLoading = false;
    return response;
  }),
  hydrateDimension(dimensions = {}){
    const isDimensionAll = Object.values(dimensions).every(item => lodash.isEmpty(item));
    self.dimensionAll = !isDimensionAll
    self.toggleDimensionAll()
    if (!self.dimensionAll) {
      self.dimensionsMultiSelectStore.hydrateDimensions(dimensions)
    }
  },
  hydrateForm(item){
    self.isLoading = true;
    try {
      self.name = item?.name;
      self.groupSelectStore?.selectGroup(item?.owner?.groupUuid);
      self.selectExtendedData(item?.extendedData?.flatMap(a => a?.uuid || a) || []);
      self.tagSelectStore?.setSelectedTags(item?.tags?.flatMap(t => t?.uuid || t) || []);
      self.isExclusivelyGroupOwned = item?.isExclusivelyGroupOwned;
      self.hydrateDimension(item?.owner?.dimensions || {});
    } catch (err) {
      console.error(`${getType(self).name} Error: Failed to populate fields to view`, err);
    }
    self.isLoading = false;
  },
}));

export default ExtendedDataSetFormState;
