import HttpClient from "../../services/HttpClient";
import Notification from "../../utils/Notification";
import lodash from "lodash";

class UtmManager {

  constructor(httpClient = null) {
    this.httpClient = httpClient || new HttpClient()
    console.log(this.httpClient)
    this.url = `utms`
  }

  async create(componentState) {
    const {groupSelectStore} = componentState
    const {tagSelectStore} = componentState
    const {isExclusivelyGroupOwned} = componentState;
    const {name,campaign,source,medium,term,content} = componentState.utm
    const dimensions = componentState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: componentState.dimensionsMultiSelectStore.getTranspiledDimensions()}
    try {
      const payload = {
        name:name,
        campaign:campaign,
        source:source,
        medium:medium,
        term:term,
        content:content,
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid,"dimensions": dimensions.dimensions},
        status: "CREATED",
        tags : tagSelectStore?.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        isExclusivelyGroupOwned: isExclusivelyGroupOwned
      }
      const res = await this.httpClient.post(this.url, payload);
      console.log("res success", res)
      return res
    } catch (e) {
      console.log("failed to save utm: ", e.message);
      return e
    }
  }

  async getUtm(uuid) {
    console.log("get utm")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.get(url);
      console.log("res success", res)
      return res
    } catch (e) {
      console.log("failed to get utm: ", e.message);
      return e
    }
  }

  async fetchAll(queryParams) {
    console.log("utm manager fetchAll")
    try {
      const res = await this.httpClient.get(this.url, queryParams);
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get Utm", e);
    }
    return null;
  }

  async fetchFilters (queryParams) {
    const url = '/sites/filters/utms'
    try {
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }
    return null;
  }

  async update(componentState) {
    console.log("update Utm")
    const {groupSelectStore} = componentState
    const {isExclusivelyGroupOwned} = componentState;
    const {name,campaign,source,medium,term,content} = componentState.utm
    const {tagSelectStore} = componentState
    const dimensions = componentState.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: componentState.dimensionsMultiSelectStore.getTranspiledDimensions()}

    try {
      const payload = {
        name: name,
        campaign: campaign,
        source: source,
        medium: medium,
        term: term,
        content: content,
        owner: {"groupUuid":groupSelectStore?.selectedGroup?.uuid, "dimensions":  dimensions?.dimensions},
        tags : tagSelectStore?.selectedTags.flatMap((tag)=>{ return tag.uuid}),
        isExclusivelyGroupOwned: isExclusivelyGroupOwned
      }
      const url = `${this.url}/${componentState.uuid}`;
      const res = await this.httpClient.put(url, payload);
      return res
    } catch (e) {
      new Notification()
        .setType("error")
        .setMessage(`${name} UTM failed updating`)
        .send();
      // return e
    }
  }

  async delete(state) {
    const {uuid}= state
    try {
      console.log("delete Utm")
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
      return response
    } catch (e) {
      console.error("Failed to delete Utm", e.message)
    }
  }
}

export default UtmManager;
