import async from "../../components/Async";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {RouterState, RouterStore} from "mobx-state-router";
import { RuleSetsListModel } from "../../models/RuleSetsListModel";
import { RuleSetsListViewState } from "../../components/configs/rulesets/list/RuleSetsListViewState";
import { TreeNode } from "../../components/configs/rulesets/create/CreateRuleForm";
import { CreateRuleSetState } from "../../components/configs/rulesets/create/CreateRuleSetState";
import {buildRuleSetsColumns} from "../../components/configs/rulesets/list/RuleSetsListView";
import {RuleSetsStore} from "../../stores/domain/RuleSetsStore";
import {ModalState} from "../../components/elements/modal/GenericModal";
import EditRuleSetHome from "../../pages/ruleset/EditRuleSetHome";
import {checkUserPermissions} from "../../utils/requiredRolesCheck";

const RuleSetsHomePage = async(() => import("../../pages/ruleset/RuleSetsHome"));
const CreateRuleFormPage = async(() => import("../../pages/ruleset/CreateRuleSetHome"));

export const viewMap = {
  ruleSets: <RuleSetsHomePage/>,
  createRuleSet: <CreateRuleFormPage/>,
  editRuleSet:<EditRuleSetHome />
}

export const routeDefinitions = [
  {
    path: "ruleSets",
    name: "Rule Sets",
    icon: faServer,
    component: RuleSetsHomePage,
    children: null,
    // featureFlagRequired: "cmp_ui_rules"
  },
];


export const routeTransitions = [
  {
    name: 'ruleSets',
    pattern: '/configs/ruleSets',
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      //if(!rootStore.rootUiStore.ruleSetsListViewState) {

        const groupSelectStore = rootStore.rootUiStore.groupSelectStore;
        const ruleSetsStore = rootStore.domainStore.ruleSetStore;
        const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
        const codeOwnerFilterSelectState = rootStore.rootUiStore.codeOwnerFilterSelectState;
        const advFilterSelectState = rootStore.rootUiStore.advFilterSelectState;
        const dimensionSelectViewState = rootStore.rootUiStore.dimensionSelectViewState;
        const tagFilterSelectState = rootStore.rootUiStore.tagFilterSelectState;
        const groupFilterSelectState = rootStore.rootUiStore.groupFilterSelectState;
        advFilterSelectState.reset();
        codeOwnerFilterSelectState.reset();
        dimensionSelectViewState.reset();
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]);
        tagFilterSelectState.reset();
        groupFilterSelectState.reset();
        // const ruleSetsStore = resolveIdentifier(RuleSetsStore, root, 'rss');
        // const ruleSetsStore = RuleSetsStore.create({
        //   storeUuid: "rss"
        // })

        const ruleSetsListModel = RuleSetsListModel.create({
          store: ruleSetsStore,

        })
        const modalState = ModalState.create({});

        const ruleSetsListViewState = RuleSetsListViewState.create({
          listStateUuid: "rlss",
          listModel: ruleSetsListModel,
          groupSelectStore: groupSelectStore,
          advFilterSelectState: advFilterSelectState,
          codeOwnerFilterSelectState: codeOwnerFilterSelectState,
          dimensionSelectViewState: dimensionSelectViewState,
          modalStore: modalState,
          tagSelectStore: tagSelectStore,
          tagFilterSelectState: tagFilterSelectState,
          groupFilterSelectState: groupFilterSelectState
        })
        ruleSetsListViewState.setColumns(buildRuleSetsColumns(routerStore, ruleSetsListViewState))
        await rootStore.rootUiStore.attachPageState('ruleSetsListViewState', ruleSetsListViewState);
      //}
      await rootStore.rootUiStore.ruleSetsListViewState.refresh()
      await  rootStore.rootUiStore.ruleSetsListViewState?.getFilters();
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    }
  },
  {
    name: 'createRuleSet',
    pattern: '/configs/ruleSets/create',
    beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      let {queryParams} = toState;
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore;
      const ruleTypeSelectState = rootStore.rootUiStore.ruleTypeSelectState;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      const tenantStore = rootStore.domainStore.tenantStore;
      groupSelectStore?.selectDefaultGroup(tenantStore.defaultGroupUuid);

      const ruleSetsListViewStore = rootStore.rootUiStore.ruleSetsListViewState;

      const snapShot = (queryParams.q)?rootStore.rootUiStore?.globalCache[`clone-ruleset:${queryParams.q}`]:null;

      let createRuleSetState;

      if(snapShot) {

        groupSelectStore?.selectGroup(snapShot?.owner?.uuid);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(snapShot?.owner?.uuid);

        const rootNode = TreeNode.create({
          nodeUuid: '3',
          parent: null,
          order: 0,
          children: [],
          showIndent: false,
          showUnindent: false,
          position: '0',
          volatileRuleTypeStore: ruleTypeSelectState.store
          // ruleTypeSelectState:createRuleSetState.createRuleSetState
        })

        createRuleSetState = CreateRuleSetState.create({
          ruleTypeSelectState:ruleTypeSelectState,
          groupSelectStore:groupSelectStore,
          rootNode:rootNode,
          tagSelectStore: tagSelectStore,
          ruleSetsListViewStore: ruleSetsListViewStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState
        })
      } else {
        tagSelectStore?.tagStore?.init(); //reset tags
        tagSelectStore?.setSelectedTags([]);
        dimensionsMultiSelectState.init();
        dimensionsMultiSelectState.setDefaults();
        dimensionsMultiSelectState.getListOnGroup(groupSelectStore.selectedGroup);

        const rootNode = TreeNode.create({
          nodeUuid: '1',
          parent: null,
          order: 0,
          children: [],
          showIndent: false,
          showUnindent: false,
          position: '0',
          volatileRuleTypeStore: ruleTypeSelectState.store
          // ruleTypeSelectState:createRuleSetState.createRuleSetState
        })


        createRuleSetState = CreateRuleSetState.create({
          ruleTypeSelectState:ruleTypeSelectState,
          groupSelectStore:groupSelectStore,
          rootNode:rootNode,
          tagSelectStore: tagSelectStore,
          ruleSetsListViewStore: ruleSetsListViewStore,
          dimensionsMultiSelectStore: dimensionsMultiSelectState
        })

      }




      // const ruleSetsListModel = RuleSetsListModel.create({})
      // const ruleSetsListViewState = RuleSetsListViewState.create({
      //   store:ruleSetsListModel,
      //   groupSelectStore:groupSelectStore
      // })
      await rootStore.rootUiStore.attachPageState('createRuleSetState', createRuleSetState);
      if(snapShot) {
        await createRuleSetState.fetchRuleSetWithID(snapShot?.uuid, 'clone')
      }
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    }
  },
{
    name: 'editRuleSet',
    pattern: '/configs/ruleSets/edit/:uuid',
  beforeEnter: checkUserPermissions,
    onEnter: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
      const {params, queryParams: {isEditable: isEditableStr}} = toState
      const isEditable = isEditableStr !== 'false';
      const isView = isEditable !== undefined ? !isEditable : false
      const groupSelectStore = rootStore.rootUiStore.groupSelectStore;
      const ruleTypeSelectState = rootStore.rootUiStore.ruleTypeSelectState;
      const tagSelectStore = rootStore.rootUiStore.tagSelectStore;
      const dimensionsMultiSelectState = rootStore.rootUiStore.dimensionsMultiSelectState;
      dimensionsMultiSelectState.init();
      dimensionsMultiSelectState.setDefaults();
      const rootNode = TreeNode.create({
        nodeUuid: '2',
        parent: null,
        order: 0,
        children: [],
        showIndent: false,
        showUnindent: false,
        position: '0',
        volatileRuleTypeStore: ruleTypeSelectState.store

        // ruleTypeSelectState:createRuleSetState.createRuleSetState
      })

      const createRuleSetState = CreateRuleSetState.create({
        ruleTypeSelectState:ruleTypeSelectState,
        groupSelectStore:groupSelectStore,
        tagSelectStore: tagSelectStore,
        selectedRuleSetUUID: params.uuid,
        rootNode:rootNode,
        dimensionsMultiSelectStore: dimensionsMultiSelectState,
        isView: isView
      })
      // const ruleSetsListModel = RuleSetsListModel.create({})
      // const ruleSetsListViewState = RuleSetsListViewState.create({
      //   store:ruleSetsListModel,
      //   groupSelectStore:groupSelectStore
      // })

      await rootStore.rootUiStore.attachPageState('createRuleSetState', createRuleSetState);
      await createRuleSetState.fetchRuleSetWithID(params.uuid)
    },
    onExit: async (
      fromState: RouterState,
      toState: RouterState,
      routerStore: RouterStore
    ) => {
      const {rootStore} = routerStore.options;
    }
  },
];
