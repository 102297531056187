import {flow, types} from "mobx-state-tree";
import { TableColumn } from "../../../tables/ScanbuyTable";
import { GroupSelectState } from "../../../elements/groups/GroupSelectState";
import {TagFilterSelectState} from "../../../elements/filters/TagFilterSelectState";
import Group from "../../../../models/Group";
import {TagModel as Tag} from "../../../../models/tags/TagModel";
import {TagSelectState} from "../../../elements/tags/TagSelectState";
import {CodeFilterSelectState} from "../../../elements/filters/CodeFilterSelectState";
import {DataExportListModel} from "../../../../models/data/exports/DataExportListModel";
import lodash from "lodash";
import {formConstants} from "../../../../utils/constants";
import {DimensionSelectViewState} from "../../../elements/dimensions/dimensionSelectViewState";
import Notification from "../../../../utils/Notification";
import {DataExportFilterSelectState} from "../../../elements/filters/data/exports/DataExportFilterSelectState";
import {ModalState} from "../../../elements/modal/GenericModal";


const DataExportListViewState = types.model('DataExportListViewState',{
  store: types.late(() => DataExportListModel),
  columns: types.maybeNull(types.array(types.late(() => TableColumn))),
  modalStore: types.maybe(ModalState),
  tagSelectStore: types.maybeNull(types.reference(TagSelectState)),
  groupSelectStore: types.maybeNull(types.reference(GroupSelectState)),
  tagFilterSelectState: types.maybeNull(types.reference(TagFilterSelectState)),
  dataExportTypeFilterSelectState: types.maybeNull(types.reference(CodeFilterSelectState)),
  dataExportFilterSelectState: types.maybeNull(types.reference(DataExportFilterSelectState)),
  selectedGroups: types.optional(types.array(types.safeReference(Group)), []),
  selectedTags: types.optional(types.array(types.reference(Tag, {acceptsUndefined: true})), []),
  experienceData: types.optional(types.string, '{}'),
  dimensionSelectViewState: types.maybeNull(types.reference(DimensionSelectViewState)),
  isFilterVisible: types.optional(types.boolean, true),
  isFilterTypeOR: types.optional(types.boolean, true),
}).volatile((self)=>({
  modalComponent: types.object,
  modalComponentStore: types.object,
})).actions((self) => ({
  async fetchAndInitialize() {
    self?.dimensionSelectViewState?.reset();
    self?.tagFilterSelectState?.reset();
    self?.dataExportFilterSelectState?.reset();
    self?.groupSelectStore?.clearSelected();

    await self?.fetchFilters();
    await self?.refresh();
  },
  handleRowClick(row, componentStore, component) {
    self.modalComponent = component;
    self.modalComponentStore = componentStore;
    self.modalStore.handleTitle(componentStore.title);
    self.modalStore.toggle();
  },
  setColumns(col) {
    self.columns = col;
  },
  setOnSelectChanges() {
    self.groupSelectStore.setMultiSelectConsumer(self.selectGroup);
    self.tagSelectStore?.setConsumer(self.selectTag);
    self.tagFilterSelectState?.setConsumer(self.selectFilter);
    self.dimensionSelectViewState?.setFilterConsumer(self.selectFilter);
    self.dataExportFilterSelectState?.setConsumer(self.selectFilter);
  },
  selectGroup(obj) {
    obj = obj || [];
    self.store.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")});
    self.refresh();
  },
  selectTag(obj) {
    obj = obj || [];
    self.store.addSearchParams({tags: obj?.flatMap((row) => row?.uuid).join(",")});
    self.refresh();
  },
  selectFilterMethod() {
    if (!self.isFilterTypeOR) {
      self.isFilterTypeOR = !self.isFilterTypeOR;
      self.store.addSearchParams({tagFilterType: formConstants.Mode.OR});
    } else {
      self.isFilterTypeOR = !self.isFilterTypeOR;
      self.store.addSearchParams({tagFilterType: formConstants.Mode.AND});
    }
    self.refresh();
  },
  selectFilter(obj, covered){

    const shouldRemoveDimensions = covered.includes("dimensionUuid")
    const regex = shouldRemoveDimensions ? /^dimension/ : undefined;
    self.store.removeSearchParams(covered,regex);

    if (obj) {
      const transpiledDimensions = {};
      if (!lodash.isEmpty(obj.dimensions)) {
        obj.dimensions.forEach(dimension => {
          const [uuid, value] = dimension.split('_');
          if (transpiledDimensions.hasOwnProperty(`dimension[${uuid}]`)) {
            transpiledDimensions[`dimension[${uuid}]`] += ',' + value;
          } else {
            transpiledDimensions[`dimension[${uuid}]`] = value;
          }
        });
        delete obj.dimensions;
      }
      const params = lodash.mapValues(obj,(value, key) => {
        return {}[key] = value.join(',');
      });
      self.store.addSearchParams({...params,...transpiledDimensions});
      self.refresh();
    }
  },
  onSearchChange(txt){
    const searchText = txt?.currentTarget?.value;
    if (searchText) {
      if (searchText.length > 2) {
        self.store.removeSearchParams(["q"]);
        self.store.addSearchParams({ q: searchText });
        self.refresh();
      } else {
        self.store.removeSearchParams(["q"]);
      }
    } else {
      self.store.removeSearchParams(["q"]);
      self.refresh();
    }
  },
  toggleFilterVisible() {
    self.isFilterVisible = !self.isFilterVisible;
  },
  handleAction: flow(function* (uuid, action) {
    const item = self.store.getItem(uuid);
    // get the latest status
    switch (action) {
      case 'retry':
        if (item.status === "PENDING" || item.status === "QUEUED" || item.status === "PROCESSING") {
          new Notification()
            .setType("info")
            .setTitle("Export in Progress")
            .setMessage(`Please wait for it to finish before performing '${action.toUpperCase()}'.`)
            .send();
        } else if (item.status === "FAILED_PROCESS") {
          new Notification()
            .setType("error")
            .setMessage("Your export process has been failed. Please check export definition.")
            .send();
        } else {
          // try again for "FAILED", "ABORTED", "FINISHED"
          const res = yield self.store.performAction(uuid, action);
          if (res) {
            new Notification()
              .setType("success")
              .setTitle("Export Started...")
              .setMessage("Your export has started.")
              .send();
          } else {
            new Notification()
              .setType("error")
              .setMessage("Your export process could not be started.")
              .send();
          }
        }
        break;
      default:
    };
    self.refresh();
  }),
  downloadRow: flow(function* (uuid) {
    const item = self.store.getItem(uuid);
    if (item.status === "PENDING" || item.status === "QUEUED" || item.status === "PROCESSING") {
      new Notification()
        .setType("info")
        .setTitle("Export in Progress")
        .setMessage("The download link is not ready yet. Please wait for it to finish before downloading.")
        .send();
    } else if (item.status === "FAILED" || item.status === "FAILED_PROCESS") {
      new Notification()
        .setType("error")
        .setMessage("Unfortunately, the export failed. Could not create a download link.")
        .send();
    } else if (item.status === "ABORTED") {
      new Notification()
        .setType("error")
        .setMessage("Your export process has been aborted. Could not create a download link.")
        .send();
    } else {
      const {url} = yield self.store.getDownloadLink(uuid);
      if (url) {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.click();
      }
    }
  }),
  deleteRow: flow(function* (uuid) {
    yield self.store.delete(uuid);
    self.store.removeItem(uuid);
    self.refresh();
  }),
  refresh() {
    self.store.fetchAll();
  },
  fetchFilters() {
    self.store.fetchAllFilters();
  }
}));

export default DataExportListViewState;
