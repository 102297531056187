import React from "react";
import { observer } from "mobx-react-lite";
import AppleFooter from "../components/AppleFooter";
import CouponPass from "./CouponPass";

const AppleCouponPass = observer(({store}) => {
  if (typeof store === "string"){
    store = JSON.parse(store);
  }
  const data = store?.coupon;

  return (
    <CouponPass
      store={{
        data,
        minHeight: "400px",
        footer: <AppleFooter barcodeType={data?.barcodeType} mobileWalletCode={data?.mobileWalletCode} />
      }}
    />
  );
});

export default AppleCouponPass;
