import AppleCouponPass from "./coupon/AppleCouponPass";
import GoogleCouponPass from "./coupon/GoogleCouponPass";
import AppleStorePass from "./storeCard/AppleStorePass";
import GoogleStorePass from "./storeCard/GoogleStorePass";
import AppleGenericPass from "./generic/AppleGenericPass";
import GoogleGenericPass from "./generic/GoogleGenericPass";
import AppleEventPass from "./event/AppleEventPass";
import GoogleEventPass from "./event/GoogleEventPass";
import {codeTypes} from "../../../utils/constants";

export const walletExperienceDataBuilder = (experienceType, data) => {
  return {
    [codeTypes.EVENT]: {
      "eventTicket": {
        ...data,
        "headerTitle": data?.eventName,
        "title": data?.eventName,
        "headerLogo": data?.eventLogo,
      },
      "passType": "Event"
    },
    [codeTypes.VCARD]: {
      "generic": {
        ...data,
        "headerLogo": data?.profilePicture,
        "headerTitle": `${data?.firstName || ''} ${data?.lastName || ''}`,
        "title": data?.organization,
        "shortDescriptionOne": data?.jobTitle,
        "shortDescriptionTwo": data?.jobRole,
      },
      "passType": "Generic"
    }
  }[experienceType];
};

export const getLabelColor = (hexColor) => {
  hexColor = hexColor.replace(/^#/, "");

  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 128 ? "#000000" : "#FFFFFF";
};

export const getPassPreviewComponent = (passType, provider) => {
  const component = {
    "Event": {
      apple: AppleEventPass,
      google: GoogleEventPass
    },
    "Coupon": {
      apple: AppleCouponPass,
      google: GoogleCouponPass
    },
    "Loyalty Card": {
      apple: AppleStorePass,
      google: GoogleStorePass
    },
    "Generic": {
      apple: AppleGenericPass,
      google: GoogleGenericPass
    }
  }[passType];

  if (component) {
    return component[provider];
  }
};
