import React from "react";
import {observer} from "mobx-react-lite";
import Select from "react-select";


const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};


const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const formatOptionLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);


const AdvancedConfFilterSelect = observer(({advFilterSelectState}) => {

  return (
    <Select
      sm={10}
      className="react-select-container"
      classNamePrefix="react-select"
      options={advFilterSelectState?.optionValues()}
      placeholder="Select"
      isSearchable
      onChange={advFilterSelectState?.filterChange}
      value={advFilterSelectState?.selected ? advFilterSelectState?.selected : []}
      isMulti
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
    />
  );
});

export default AdvancedConfFilterSelect;
