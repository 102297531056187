import { getType, types } from "mobx-state-tree";
import CodeTypeStore from "../../../stores/domain/CodeTypeStore";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";
import {UserModel} from "../../../models/users/userModel";
import UserStore from "../../../stores/domain/UserStore";


export const AdvancedConfFilterSelectState = types.model('AdvancedConfFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  userStore: types.maybe(types.late(() => types.reference(UserStore))),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
  emails: types.array,
  tags: types.array
})).actions((self)=>({
  reset(){
    self.selected = [];
  },
  setEmails(emails) {
    self.emails = emails
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["createdBy"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  optionValues() {

    const userEmails = self?.emails || [];
    const userOptions = userEmails
    .slice()
    .sort((a, b) => a?.email?.localeCompare(b?.label))
    .flatMap( (user) => {
      return {
        label: user?.email,
        value: "createdBy."+user.uuid
      }
    });



    const options = [
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.users.defaultMessage"/>,
        options: userOptions
      }
    ];
    return options;
  }
}))
