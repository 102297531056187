import HttpClient from '../../services/HttpClient'
import lodash from "lodash";

class TagManager {
  constructor(httpClient= null) {
    this.httpClient = httpClient || new HttpClient()
    console.log("tag manager construct")
    console.log(this.httpClient)
    this.url = `/tags`
  }

  /**
   * Convert the frontend object into the API Resource for QRCode
   * @param instance
   * @returns {Promise<void>}
   */
  async buildCodeResource(instance){

    return {}
  }

  async fetchAllTags(queryParams) {
    console.log("tag manager fetchAllTags")
    try {
      const res = await this.httpClient.get(this.url,queryParams);
      console.log("response in get request", res)
      if (res && res.results) {
        return res
      }
    } catch (e) {
      console.error("Failed to get code", e);
    }
    return null;
  }

  async fetchFilters (queryParams) {
    const url = '/sites/filters/tags'
    try {
      const res = await this.httpClient.get(url, queryParams);
      console.log("response in get request", res)
      if (res) {
        return res
      }
    } catch (e) {
      console.error("Failed to get designs", e);
    }
    return null;
  }


  async createTag(data, instance) {

    const {isExclusivelyGroupOwned} = instance
    const dimensions = instance.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: instance.dimensionsMultiSelectStore.getTranspiledDimensions()}
    data.owner.dimensions = dimensions?.dimensions;
    try {
      const payload = {
        ...data,
        isExclusivelyGroupOwned: isExclusivelyGroupOwned
      }
      const res = await this.httpClient.post(this.url, payload);
      console.log("res success",   res)
      return res
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.log("failed to save tag: ", e.message);
      return e
    }
  }

  async getTag(uuid) {
    console.log("get tag")
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.get(url);
      console.log("res success",   res)
      return res
    } catch (e) {
      // instance.handleHasErrors(true)
      // instance.handleErrorMessage(e.message)
      console.log("failed to save tag: ", e.message);
      return e
    }
  }

  async updateTag(uuid, data, instance) {
    console.log("update tag")
    const {isExclusivelyGroupOwned} = instance
    const dimensions = instance.dimensionsMultiSelectStore.dimensionStore.dimensionsEnabled && {dimensions: instance.dimensionsMultiSelectStore.getTranspiledDimensions()}
    data.owner.dimensions = dimensions?.dimensions;
    data.isExclusivelyGroupOwned = isExclusivelyGroupOwned;
    try {
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.put(url, data);
      console.log("res success",   res)
      return res
    } catch (e) {
      instance.handleHasErrors(true)
      instance.handleErrorMessage(e.message)
      console.log("failed to update tag: ", e.message);
      return e
    }
  }

  async deleteTag(uuid) {
    try {
      console.log("delete tag")
      const url = `${this.url}/${uuid}`;
      const res = await this.httpClient.destroy(url);
      const response = {}
      response.status = res.status
      response.uuid = res.config.url.split("/").pop()
      return response
    } catch (e) {
      console.error("Failed to delete tag", e.message)
    }
  }
}

export default TagManager;
