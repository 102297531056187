import { RouterState, RouterStore } from "mobx-state-router";
import { PublishDomainsList } from "../../models/PublishDomainsList";
import DomainsListViewState from "../../components/configs/domains/list/DomainsListViewState";
import { buildDomainColums } from "../../components/configs/domains/list/DomainsListView";
import async from "../../components/Async";
import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import DomainDetailViewState from "../../components/configs/domains/detail/DomainDetailViewState";
import {TreeNode} from "../../components/configs/rulesets/create/CreateRuleForm";
const { v4: uuidv4 } = require('uuid');

const DomainsListPage = async(()=> import("../../pages/domains/DomainsList"));
const DomainDetail = async(()=> import("../../pages/domains/DomainDetail"));

export const viewMap = {
  domains: <DomainsListPage/>,
  "/domains/view/:id": <DomainDetail/>,
  "/domains/edit/:id": <DomainDetail/>
}
export const routeDefinitions = [
  {
    path: "domains",
    name: "Domains",
    icon: faSitemap,
    component: DomainsListPage,
    children: null,
    featureFlagRequired: "cmp_ui_domains"
  }
];

const createDomainDetailViewState = (rootUiStore, readonly=false) => {
  const {
    ruleTypeSelectState
  } = rootUiStore;

  return DomainDetailViewState.create({
    ruleTypeSelectState: ruleTypeSelectState,
    rootNode: TreeNode.create({
      nodeUuid: uuidv4(),
      parent: null,
      order: 0,
      children: [],
      showIndent: false,
      showUnindent: false,
      position: '0',
      volatileRuleTypeStore: ruleTypeSelectState.store
    }),
    readonly: readonly
  });
};

export const routeTransitions = [
  {
  name: 'domains',
  pattern: '/domains',
  onEnter: async (
    fromState: RouterState,
    toState: RouterState,
    routerStore: RouterStore
  ) => {
    const {rootStore} = routerStore.options;
    const groupSelectState = rootStore.rootUiStore.groupSelectStore;
    const publishDomainStore = rootStore.rootUiStore.publishDomainSelectStore.publishDomainStore
    const publishDomainsList = PublishDomainsList.create({
      publishDomainStore:publishDomainStore
    });

    const domainsListViewState = DomainsListViewState.create({
      store: publishDomainsList,
      groupSelectStore: groupSelectState,
    });

    await rootStore.rootUiStore.attachPageState('domainsListViewState', domainsListViewState);
    domainsListViewState?.setColumns(buildDomainColums(routerStore, domainsListViewState));
    domainsListViewState.refresh()
  }
},
  {
    name: '/domains/view/:id',
    pattern: '/domains/view/:id',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const domainDetailViewState = createDomainDetailViewState(rootStore.rootUiStore, true);

      console.log("Attaching Page State : domainDetailViewState");
      await rootStore.rootUiStore.attachPageState('domainDetailViewState', domainDetailViewState);
      const {params} = toState;
      await domainDetailViewState?.fetchModelAndHydrate(params.id);
    }
  },
  {
    name: '/domains/edit/:id',
    pattern: '/domains/edit/:id',
    onEnter: async (fromState, toState, routerStore) => {
      const {rootStore} = routerStore.options;

      const domainDetailViewState = createDomainDetailViewState(rootStore.rootUiStore);

      console.log("Attaching Page State : domainDetailViewState");
      await rootStore.rootUiStore.attachPageState('domainDetailViewState', domainDetailViewState);
      const {params} = toState;
      await domainDetailViewState?.fetchModelAndHydrate(params.id);
    }
  }
]

