import {Col, InputGroup, InputGroupAddon} from "reactstrap";
import DimensionFilterSelect from "../components/elements/filters/DimensionFilterSelect";
import React from "react";

export function buildDimensionFilters(dimensionSelectStore, colWidth=5) {
  return (
    <Col sm={colWidth} key={dimensionSelectStore?.dimension?.label}>
      <InputGroup className="mb-3">
        <InputGroupAddon addonType="prepend">
          {dimensionSelectStore?.dimension?.label}
        </InputGroupAddon>
        <DimensionFilterSelect dimensionSelectStore={dimensionSelectStore}/>
      </InputGroup>
    </Col>
  )
}
