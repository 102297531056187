import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";

//Our Custom Components
import ScanbuyTable from "../../tables/ScanbuyTable";
import BatchFilterSelect from "../../elements/filters/BatchFilterSelect";
import GroupSelect from "../../elements/groups/GroupSelect";
import {useIntl} from "react-intl";
import {StateContext} from "../../../App";

/**
 *
 * @param batchListState - BatchListState
 */
const BatchListView = observer(({ batchListState }) => {
  const intl = useIntl();
  batchListState.setOnSelectChanges()
  console.log("BatchListView: batchListState: ", batchListState)
  // useEffect(() => {
  //   batchListState?.handleFilterChange({
  //     groupUuid: batchListState?.groupSelectStore?.selectedGroup?.uuid,
  //   })
  // }, [batchListState])
  useEffect(() => {
    batchListState.refresh()
  }, [batchListState])
  // batchListState.setOnSelectChanges();
  return (
    <Card>
      <CardBody>
        <Row>
          {/*TODO: need add group filter for batches*/}
          {/*<Col sm={4}>*/}
          {/*  <InputGroup className="mb-3">*/}
          {/*    <InputGroupAddon addonType="prepend">*/}
          {/*      {intl.formatMessage({ id: "filterByGroups.defaultMessage"})}*/}
          {/*    </InputGroupAddon>*/}
          {/*    <GroupSelect groupSelectStore={batchListState.groupSelectStore}/>*/}
          {/*  </InputGroup>*/}
          {/*</Col>*/}
          <Col sm={4}>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                Filter
              </InputGroupAddon>
              <BatchFilterSelect batchFilterSelectState={batchListState.batchFilterSelectState}/>
            </InputGroup>
          </Col>
        </Row>
        <ScanbuyTable
          columns={batchListState.columns}
          customStyles={batchListState.customStyles}
          store={batchListState.store}
        />
      </CardBody>
    </Card>
  );
})

export default BatchListView;
