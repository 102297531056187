import {flow, getEnv, getType, types} from "mobx-state-tree";
import {RuleType} from "../../../models/RuleType";
import RuleTypeStore from "../../../stores/domain/RuleTypeStore";
import {FormattedMessage} from "react-intl";
import React from "react";

export const RuleTypeSelectState = types.model('ruleTypeSelectState',{
  selectStateUuid: types.identifier,
  store: types.maybe(types.late(() => types.reference(RuleTypeStore))),
  nodeStore: types.maybe((RuleTypeStore)),
  selected: types.maybe(types.safeReference(RuleType)),
  isDisabled: types.optional(types.boolean, false),
}).volatile((self)=>({
  addDataInStore: types.function,
  notifySelect: types.function,
  updateInputDataInForm: types.function,
})).actions((self) => ({
  search(txt){
    //self.groupOptions = self.groupStore.searchFor(txt)
    //self.groupStore.groups
  },
  setConsumer(f){
    self.addDataInStore = f
  },
  select: flow (function* f(ruleType) {
    console.log("*****")
    console.log(ruleType)
    const resp = yield self.addDataInStore(ruleType)
    if (resp){ // only if there is data to load into schema
      self.selected = resp
    }else{
      self.selected = ruleType
    }
  }),
  setSelect(data){
    self.selected = data
  },
  setClearInputData(f){
    self.updateInputDataInForm = f
  },
  clearInputData(){
    self.updateInputDataInForm({"factValue": null})
  },
  getRuleData: flow(function* f(ruleType) {
    // TODO: validate Input
    const manager = getEnv(self).ruleTypeDataManager
    return yield manager.getRuleData(ruleType);
  }),
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => { //TODO
      console.log("placeholder notifySelect")
    }
    self.selectedSchema = {}
  }
})).views( (self) => ({
  options() {
    const { ruleTypes } = self.nodeStore || {};
    return ruleTypes || []
  },
  selectedSchemaView(){
    console.log(self.selectedSchema)
    return JSON.parse(self.selectedSchema)
  },
  optionValues() {
    const { ruleTypes } = self.nodeStore || {};
    const activityOptions = []
    const dateTimeOptions = []
    const locationOptions = []
    const phoneSettingsOptions = []
      ruleTypes.forEach( (ruleType) => {
      if(ruleType.labelGroup === "activity") {
        activityOptions.push(ruleType)
      }
      if(ruleType.labelGroup === "dateTime") {
        dateTimeOptions.push(ruleType)
      }
      if(ruleType.labelGroup === "location") {
        locationOptions.push(ruleType)
      }
      if(ruleType.labelGroup === "phoneSettings"){
        phoneSettingsOptions.push(ruleType)
      }
    });


    const options = [
      {
        label: "Activity",// <FormattedMessage id="rules.ruletypes.dropdown.activity.defaultMessage"/>,
        options: activityOptions
      },
      {
        label: "Date & Time",// <FormattedMessage id="rules.ruletypes.dropdown.dateTime.defaultMessage"/>,
        options: dateTimeOptions
      },
      {
        label: "Location",//<FormattedMessage id="rules.ruletypes.dropdown.location.defaultMessage"/>,
        options: locationOptions
      },
      {
        label: "Phone Settings", //<FormattedMessage id="rules.ruletypes.dropdown.phoneSettings.defaultMessage"/>,
        options: phoneSettingsOptions
      }
    ];
    return options;
  }
}))


