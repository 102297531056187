import { flow, getEnv, types } from "mobx-state-tree";
import TagStore from "../../stores/domain/TagStore";

const TreeNode = types
  .model("TreeNode", {
    nodeUuid: types.identifier,
    parent: types.maybeNull(types.safeReference(types.late( ()=> TreeNode))),
    children: types.array(types.safeReference(types.late(() => TreeNode))),
    order: types.integer
  })


export const TagListModel = types
  .model("TagListModel", {
    tagStore: types.maybe(types.late(() => types.reference(TagStore))),
    //items: types.array(types.safeReference(TagModel), []), //types.optional(types.array(TagModel), []),
    isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
    page: types.optional(types.integer, 1),
    perPage: types.optional(types.integer, 10),
    totalRows: types.optional(types.integer, 0),
    sortOrder: types.optional(types.string, "desc"),
    sortField:  types.optional(types.string, "updatedAt"),
  }).volatile(self => ({
    searchParams: {},
    filters: {}
  })).actions((self) => ({
    setSortOrder(sortDirection){
      self.sortOrder = sortDirection
    },
    setSortField(sortField){
      self.sortField = sortField
    },
    fetchAll: flow(function* fetchAll(sortParams = {}) {
      // self.tagStore.setTags([]);
      if (Object.keys(sortParams).length === 0) {
        sortParams = {
          sort: 'updatedAt',
          order: self.sortOrder
        };
      }
      self.isLoading = true;
      try {
        const queryParams = {
          page: self.page,
          perPage: self.perPage,
          ...sortParams,
          ...self.searchParams
        };
        const response = yield self.tagStore.getTags(queryParams);
        // const manager = getEnv(self).tagManager
        // // ... yield can be used in async/await style
        // //const response = yield get(url, queryParams)
        // const response = yield manager.fetchAllTags(queryParams)
        //console.log(response)
        if (response) {
          //self.tagStore?.setTags(response);
          self.tagStore?.setFlatTags(response);
          // self.tagStore.tags = response.results;
          self.totalRows = response._meta.itemCount;
        }
        self.isLoading = false;
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch tags", error);
      }
    }),
    fetchFilters: flow(function* fetchFilters(sortParams = {}) {
      self.isLoading = true;

      try {
        const queryParams = {
          ...self.searchParams
        }

        const tagManager = getEnv(self).tagManager
        const response = yield tagManager.fetchFilters(queryParams);
        if(response !=null) {
          self.filters = response
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }

      } catch (error) {
        console.error('Failed to fetch all', error)
      }

    }),
    updatePage: function(page) {
      self.page = page;
    },
    updatePerPage: function(perPage) {
      self.perPage = perPage;
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names,regex = ''){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
      if(regex){
        const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
        keysToDelete.forEach(key => delete self.searchParams[key]);
      }
    },
    add: flow(function* f(data, self) {
      return yield getEnv(self).tagManager.createTag(data, self)
    }),
    get: flow(function* f(uuid) {
      return yield getEnv(self).tagManager.getTag(uuid)
    }),
    put: flow(function* f(uuid,data, self) {
      return yield getEnv(self).tagManager.updateTag(uuid, data, self)
    }),
    delete: flow(function* f(uuid) {
      return yield getEnv(self).tagManager.deleteTag(uuid)
    }),
    // put: async function(uuid, data) {
    //   try {
    //     return await getEnv(self).httpClient.put(url + "/" + uuid, data);
    //   } catch (e) {
    //     console.error("Failed to edit tag", e);
    //   }
    // },
  }))
  .views( (self) => ({
    getItems() {
      const { flatTags } = self.tagStore || {};
      return flatTags || []
    },
    getSearchParams() {
      return self.searchParams;
    }
  }))
;
