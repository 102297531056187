import {types} from "mobx-state-tree";
import Group from "../../models/Group";
import {GroupSelectState} from "../elements/groups/GroupSelectState";
import {ReportModelList} from "../../models/reports/Report";
import {TableColumn} from "../tables/ScanbuyTable";

import {ModalState} from "../elements/modal/GenericModal";
import {GenericToastState} from "../elements/toast/GenericToastState";



const ReportsState = types.model('ReportsState', {
	store: types.maybe(types.late(() => ReportModelList)), // <-- hold the store
	columns: types.maybe(types.array(types.late(() => TableColumn))),
	groupSelectStore: types.maybe(types.reference(GroupSelectState)),
	selectedGroup: types.maybe(types.reference(Group)),
	modalStore: types.maybe(ModalState),
	toastStore: types.maybe(GenericToastState),

}).volatile((self) => ({
	modalComponent: types.string,
	modalComponentStore: types.string,
	toastComponentStore: types.string
}))
	.actions((self) => ({
		setOnSelectChanges() {
			self.groupSelectStore.setMultiSelectConsumer(self.selectGroup)
		},
    handleFilterChange(e) {
      console.log("ReportsState handleFilterChange")
      self.store.fetchAll(e);
    },
		selectGroup(obj) {
			//self.selectedGroup = obj;
			obj = obj || []
			self.store.addSearchParams({groupUuid: obj.flatMap((row) => row.uuid).join(",")})
			self.refresh()
		},
		setColumns(col) {
			self.columns = col
		},
		handleRowClick(row, componentStore, component) {
			self.modalComponent = component;
			self.modalComponentStore = componentStore
			self.modalStore.handleTitle(componentStore.title)
			self.modalStore.toggle()
		},
		refresh() {
			self.store.fetchAll()
		},
		addRow(data) {
			//? P / ...
		},
		deleteRow(uuid) {
			// self.store.items = self.store.items.filter(item => item.uuid !== uuid)
			// commented out above is a filter that is not remounting component properly, having something to do with mobX,
			// therefore we fetchAll for now
			self.store.fetchAll()
		},
		afterCreate() {
			self.modalComponent = (<></>)
			self.modalComponentStore = {}

		}
	}))


export default ReportsState;
