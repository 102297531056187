import {types} from "mobx-state-tree";


const  Group = types.model('Group',{
    uuid: types.identifier,
    label: types.string,
    name: types.string

    //subgroup??
})


//GroupList Model




export default Group;
