import {types} from "mobx-state-tree";


const ImageAndCharacteristicsState = types.model('ImageAndCharacteristicsState', {
  imageFormat: types.optional(types.string, 'png'),
  isCMYK: types.optional(types.boolean, false),
  size: types.optional(types.number, 1.0),
  isValidSize: types.optional(types.boolean, true),
  unitsOfMeasure: types.optional(types.string, 'inch'),
  resolution: types.optional(types.integer, 1200),
  isValidResolution: types.optional(types.boolean, true),
  // bwr: types.optional(types.string, ''),  We don't support BWR yet but here is the code for when we do
  errorCorrection: types.optional(types.string, 'M'),
  quietZone: types.optional(types.integer, 4),
  bwr: types.optional(types.number, 0),
}).volatile(self => ({
  notifyDesigner: types.f,
  logoListener: types.f,
})).actions((self) => ({
  setConsumer(f) {
    self.notifyDesigner = f
  },
  handleFormatSelect(e) {
    self.imageFormat = e.target.value
    self.notifyDesigner("imageFormat",e.target.value)
  },
  handleUnitOfMeasure(e) {
    self.unitsOfMeasure = e.target.value
    self.notifyDesigner("unitsOfMeasure",e.target.value)

  },
  handleIntegerChange(e) {
    if (e.target.value >= 0) {
      self[e.target.id] = +e.target.value
      self.notifyDesigner(e.target.id,+e.target.value)
    }
  },
  handleSizeChange(e){
    const value = +e.target.value
    if (value > 5) {
      self.isValidSize = false
      return
    }
    self.isValidSize = true
    self.size = value
    self.notifyDesigner("size",value)
  },
  handleResolutionChange(e){
    const value = +e.target.value
    if (value > 1200) {
      self.isValidResolution = false
      return
    }
    self.isValidResolution = true
    self.resolution = value
    self.notifyDesigner("resolution",value)
  },
  //We don't support BWR yet but here is the code when we do
  // handleBwr(e) {
  //   self.bwr = e.target.value
  // },
  handleErrorCorrection(e) {
    self.errorCorrection = e.target.value
    self.notifyDesigner("errorCorrection",e.target.value)
  },
  consumeLogoCorrection(key,value){
    self[key] = value
  },
  handleQuiteZone(e) {
    self.quietZone = +e.target.value
    self.notifyDesigner("quietZone",+e.target.value)
  },
  changeQuietZone(value){
    self.quietZone = value
    self.notifyDesigner("quietZone",value)
  },
  handleBwrChange(e) {
    self.bwr = +e.target.value
    self.notifyDesigner("bwr",+e.target.value)
  }

}))

export default ImageAndCharacteristicsState;
