import React, {useContext} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Collapse, InputGroup, InputGroupAddon, Input, CustomInput, Label, FormGroup
} from "reactstrap";
import {observer} from "mobx-react-lite";
import * as Icon from "react-feather";
import EyesAndModulesView from "./eyesAndModules/EyesAndModulesView";
import Logo from "./logo/LogoView";
import ColorView from "./color/ColorView";
import ImageAndCharacteristicsView from "./imageAndCharacteristics/ImageAndCharacteristicsView";
import GroupSelect from "../../elements/groups/GroupSelect";
import imageAndCharacteristicsState from "./imageAndCharacteristics/ImageAndCharacteristicsState";
import { FormattedMessage,useIntl } from "react-intl";
import FrameAndCaptionsView from "./frameAndCaption/FrameAndCaptionsView";
import DimensionsMultiSelect from "../../elements/dimensions/multi-select/dimensionsMultiSelect";
import TagSelect from "../../elements/tags/TagSelect";
import DocLink from "../../DocLink";
import {StateContext} from "../../../App";
import DisableWrapper from "../../../utils/disableWrapper";

const DesignerView = observer(({store,isNotCodeModal= true}) => {
  const stateContext = useContext(StateContext);
  const intl = useIntl();
  store.setOnSelectChanges()
  store.logoStore.setNotifyCharacteristics(store.imageCharsStore.consumeLogoCorrection)
  const isDisabled = store.isView;
  // {store?.isTemplateForm &&  store.tagSelectStore.tagStore.setFilterOn(store?.groupSelectStore?.selectedGroup?.uuid)}
  // {store?.isTemplateForm && store.tagSelectStore.tagStore?.setHydrateFuntion(store?.tagSelectStore.configOptionLoadValues)}
  return (
    <Card>
    {/*<CardHeader>*/}
    {/*  /!*<Row>*!/*/}
    {/*  /!*  <Col className="text-right">*!/*/}
    {/*  /!*    <Button className="align-content-end" onClick={store.handleUpdateImage}>*!/*/}
    {/*  /!*    <FormattedMessage id="qrDesign.create.previewImage.defaultMessage"/>*!/*/}
    {/*  /!*    </Button>*!/*/}
    {/*  /!*  </Col>*!/*/}
    {/*  /!*</Row>*!/*/}


    {/*</CardHeader>*/}
      <DisableWrapper isDisabled={isDisabled}>

        <CardHeader>
        <Row className="p-1 text-sm-right">
          <Col sm={12}>
            <Button id="preview-btn" className="align-content-end" onClick={store.handleUpdateImage}>
              <FormattedMessage id="qrDesign.create.previewImage.defaultMessage"/>
            </Button>
            {stateContext?.appConfigs?.features?.cmp_ui_docs?.enabled &&
              <DocLink id="docs.qrDesigner"/>
            }
          </Col>
        </Row>
        {store.isTemplateForm &&
          <>
        <Row className="p-1">
          <Label className="text-sm-left"><FormattedMessage id="filterByGroups.defaultMessage"/></Label>
          <Col sm={3}>
              {store.groupSelectStore?.selectedGroup?.uuid? <GroupSelect groupSelectStore={store.groupSelectStore}/>:<GroupSelect groupSelectStore={store.groupSelectStore}/>}
          </Col>
          <Col sm={2} style={{alignContent:"center", marginTop:"5px"}}>
            <CustomInput
              type="switch"
              id="isGroupGlobal"
              name="isGroupGlobal"
              label={intl.formatMessage({id: 'qrDesign.create.groupOnly.defaultMessage',defaultMessage:"Only"})}
              checked={store?.isExclusivelyGroupOwned}
              onChange={() => store.toggleIsExclusivelyGroupOwned()}
            />
          </Col>
          {store?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled && <Col sm={2} style={{alignContent:"center", marginTop:"5px"}}>
            <CustomInput
              type="switch"
              id="isDimensionAll"
              name="isDimensionAll"
              label={store?.dimensionsMultiSelectStore?.getLabelsWithPrefix(intl.formatMessage({id: 'qrDesign.create.dimensionAll.defaultMessage'}))}
              checked={store?.dimensionAll}
              onChange={() => store.toggleDimensionAll()}
            />
          </Col>}
          {store?.dimensionsMultiSelectStore?.dimensionStore?.dimensionsEnabled && store.isDimensionSelectVisible && <>
            <Col>
              <DimensionsMultiSelect dimensionMultiSelectState={store.dimensionsMultiSelectStore}/>
            </Col>
          </>}
        </Row>
        <Row className="p-1">
          <Label className="text-sm-left"><FormattedMessage id="createQr.tags.defaultMessage"/></Label>
          <Col>
              <TagSelect tagSelectStore={store?.tagSelectStore}/>
          </Col>
        </Row>
        </>
        }
      </CardHeader>
      <CardBody>
        <hr/>
        <Row className="p-1">
          <Col sm={2}>
          <Label className="text-sm-left"><FormattedMessage id="qrDesign.create.legacyDesgin.defaultMessage"/></Label>
          </Col>
          <Col sm={3}>
            <CustomInput
              type="switch"
              id="isDefaultDesigner"
              name="isDefaultDesigner"
              checked={store?.isDefaultDesigner}
              onChange={() => store.toggleIsDefaultDesigner()}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {store.isDefaultDesigner &&
            <>
            <hr/>
            <Row className="p-1">
              <Col>
                <Row className="logo text-left">
                  <Col sm={12} className="text-left">
                    <Button id="image-toggle-btn" data-testid="logo-open" onClick={() => {
                      store.toggleLogoOpen();
                    }}>{store.logoOpen ? <Icon.MinusCircle/> : <Icon.PlusCircle/>}</Button>
                    <span className="p-2">  <FormattedMessage id="qrDesign.create.image.defaultMessage"/></span>
                  </Col>
                  {/*<Col sm={2}>LOGO</Col>*/}
                </Row>
                <Row>
                  <Col sm={1}></Col>
                  <Col sm={11}>
                    <Collapse className="text-left" isOpen={store.logoOpen}>
                      <Logo store={store.logoStore} eyesAndModulesStore={store.eyeStore} isDisabled={isDisabled}/>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
            </>
            }

            {store.isDefaultDesigner &&
            <>
            <hr/>
            <Row className="p-1">
              <Col>
                <Row className="eyesAndModule">
                  <Col sm={12} className="text-left"><Button id="eyes-module-btn" data-testid="eyes-open" onClick={() => {
                    store.toggleEyeOpen();
                  }}>{store.eyeOpen ? <Icon.MinusCircle/> : <Icon.PlusCircle/>}</Button>
                    <span className="p-2">  <FormattedMessage id="qrDesign.create.eyesAndModules.defaultMessage"/></span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={1}></Col>
                  <Col>
                    <Collapse className="text-left" isOpen={store.eyeOpen}>
                      <EyesAndModulesView EyesAndModulesStore={store.eyeStore} logoStore={store.logoStore} isDisabled={isDisabled}/>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
            </>
            }
            <hr/>

            <Row className="p-1">
              <Col>
                <Row className="color">
                  <Col sm={12} className="text-left"><Button id="color-toggle-btn" onClick={() => {
                    store.toggleColorOpen();
                  }}>{store.colorOpen ? <Icon.MinusCircle/> : <Icon.PlusCircle/>}</Button>
                    <span className="p-2"><FormattedMessage id="qrDesign.create.color.defaultMessage"/></span>
                  </Col>

                </Row>
                <Row>
                  <Col sm={1}></Col>
                  <Col>
                    <Collapse className="text-left" isOpen={store.colorOpen}>
                      <ColorView store={store.colorStore} frameAndCaptionsStore={store.frameAndCaptionsStore} isDefaultDesigner={store.isDefaultDesigner} isDisabled={isDisabled}/>
                    </Collapse>
                  </Col>
                </Row>

              </Col>
            </Row>
            {store.isDefaultDesigner &&
            <>
            <hr/>
            <Row className="p-1">
              <Col>
                <Row className="frameAndCaptions">
                  <Col sm={12} className="text-left">
                    <Button id="frame-toggle-btn" disabled={store.logoStore.fullBackground ? true : false} onClick={() => {
                      store.toggleFrameOpen();
                    }}>{store.frameOpen ? <Icon.MinusCircle/> : <Icon.PlusCircle/>}</Button>
                    <span className="p-2"><FormattedMessage id="qrDesign.create.frameCaptions.defaultMessage"/></span>
                  </Col>
                </Row>
                <Row>
                  <Col sm={1}></Col>
                  <Col>
                    <Collapse className="text-left" isOpen={store.frameOpen}>
                      <FrameAndCaptionsView frameAndCaptionsStore={store.frameAndCaptionsStore} extendConfig={store.updateExtendConfig} imageCharsStore={store.imageCharsStore} colorStore={store.colorStore} isDisabled={isDisabled}/>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
            </>
            }
            <hr/>
            <Row className="p-1">
              <Col>
                <Row className="imageAndCharacteristics">
                  <Col sm={12} className="text-left"><Button id="imagechar-toggle-btn" onClick={() => {
                    store.toggleImageOpen();
                  }}>{store.imageOpen ? <Icon.MinusCircle/> : <Icon.PlusCircle/>}</Button>
                    <span className="p-2"><FormattedMessage id="qrDesign.create.imageCharacteristics.defaultMessage"/></span>
                  </Col>

                </Row>
                <Row>
                  <Col sm={1}></Col>
                  <Col>
                    <Collapse className="text-left" isOpen={store.imageOpen}>
                      <ImageAndCharacteristicsView store={store.imageCharsStore} isDefaultDesigner={store.isDefaultDesigner}/>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr/>

          </Col>
        </Row>
        {isNotCodeModal &&
          <>
            <Row>
              <Col sm={2}>
                <Label>
                  <FormattedMessage id="qrDesign.create.configurationName.defaultMessage"/>
                </Label>
              </Col>
              <Col sm={10}>
                <Input
                  type="input"
                  id="configurationName"
                  name="configurationName"
                  onChange={store.handleConfigNameChange}
                  value={store.configurationName}
                />

              </Col>
                  {/*<div className="custom-controls-stacked">*/}

                  {/*</div>*/}
            </Row>
          </>
        }


          {/*save code below for setting default and group*/}


          {/*<Row>*/}
          {/*  <Col sm={2} className="text-right p-2">Group</Col>*/}
          {/*  <Col sm={2}>*/}
          {/*    <Select*/}
          {/*      className="react-select-container"*/}
          {/*      classNamePrefix="react-select"*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <Col sm={2} className="text-right p-2">Make Default</Col>*/}
          {/*  <Col sm={2} className="pt-5">*/}
          {/*    <CustomInput*/}
          {/*      type="switch"*/}
          {/*      id="default"*/}
          {/*      onChange={store.setMakeDefault}*/}
          {/*    />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
      </CardBody>
      </DisableWrapper>

    </Card>);
});

export default DesignerView;
