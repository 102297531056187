import {types} from "mobx-state-tree";

export const ResourcesModal = types
	.model({
		type: types.string,
		id: types.string,
	})
	.actions((self) => ({
		updateStatus(newStatus) {
			self.status = newStatus
		},
		addTag(newTag) {
			self.tags.push(newTag)
		},
		removeTag(oldTag) {
			//remove from self.tags
		},
		update(data) {

		},
		refresh(){

		},
		save() {
			//? PUT / ...
		}
	}))
