import {flow, types} from "mobx-state-tree";
import * as pdfjsLib from 'pdfjs-dist';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import Notification from "../../../../utils/Notification";

const LogoState = types.model('LogoState', {
  logoPercentageWidth: types.optional(types.integer, 27),
  selectedFromAssets: types.optional(types.string, ''),
  logo: types.optional(types.string, ''),
  logoType:types.optional(types.string, ''),
  logoMargin: types.optional(types.integer, 1),
  logoMarginWarn: types.optional(types.boolean, false),
  logoFormat: types.optional(types.string, ''),
  pdfLogoPng: types.optional(types.string, ''),
  logoOffsetX: types.optional(types.integer, 0),
  logoOffsetXWarn: types.optional(types.boolean, false),
  logoOffsetY: types.optional(types.integer, 0),
  logoOffsetYWarn: types.optional(types.boolean, false),
  cropAroundImage: types.optional(types.boolean, false),
  backgroundImage: types.optional(types.string, ''),
  backgroundImageFormat: types.optional(types.string, ''),
  fullBackground: types.optional(types.boolean, false),
  logoFileIsCorrectSize: types.optional(types.boolean, true),
  logoFileIsCorrectType: types.optional(types.boolean, true),
  logoSizeWarn: types.optional(types.boolean, false),
  activeTab:types.optional(types.string, '1'),
  openBackgroundImage: types.optional(types.boolean, false),
})
  .volatile(self => ({
    notifyDesigner: types.f,
    notifyDesignerDeleteKey:types.f,
    notifyPreview:types.f,
    notifyCharacteristics:types.f,
  }))
  .actions((self) => ({
    setConsumer(f) {
      self.notifyDesigner = f
    },
    setDeleteKeyConsumer(f){
      self.notifyDesignerDeleteKey = f
    },
    setPreviewConsumer(f){
      self.notifyPreview = f
    },
    setNotifyCharacteristics(f){
      self.notifyCharacteristics = f
    },
    sideEffectMobXUpdateImage(base64) {
      self.logo = base64
      self.notifyDesigner("logo", base64)
      self.notifyDesigner("logoPercentageWidth",self.logoPercentageWidth)
      self.notifyDesigner("logoMargin",self.logoMargin)

      // if(self.logoPercentageWidth === 33){
      //   self.notifyDesigner("errorCorrection","H")
      //   self.notifyCharacteristics("errorCorrection","H")
      // }else{
      //   self.notifyDesigner("errorCorrection","Q")
      //   self.notifyCharacteristics("errorCorrection","Q")
      // }
    },

    toggleBackgroundImage(base64){
      self.backgroundImage = base64
      self.notifyDesigner("backgroundImage", base64)
    },
    async setSelectedImage(file, isLogo, eyesAndModulesStore) {
      const fileIsCorrectSize = file?.size < 1000000
      let fileType = file?.type.split("/")[1].toUpperCase()
      const fileIsCorrectType = (
        fileType === "PNG" ||
        fileType === "BMP" ||
        fileType === "GIF" ||
        fileType === "TIF" ||
        fileType === "JPEG"||
        fileType === "SVG+XML"||
        fileType === "PDF"||
        fileType === "EPS"||
        fileType === "POSTSCRIPT")
      if (fileIsCorrectSize && fileIsCorrectType) {
        self.logoFileIsCorrectSize = fileIsCorrectSize
        self.logoFileIsCorrectType = fileIsCorrectType
        self.logoFormat = file?.type
        if(fileType === "JPEG") {
          fileType = "JPG"
          self.logoFormat = fileType
        }
        self.notifyDesigner("imageFormat", fileType.toLowerCase())

        let reader = new FileReader()
        let pdfLogoPng = ""
        reader.onload = async function (event) {
          if(isLogo){
            self.sideEffectMobXUpdateImage(reader.result.split("base64,")[1])
            let errorCorrection;

            if (self.logoPercentageWidth > 25) {
              errorCorrection = "H";
            } else if (self.logoPercentageWidth >= 15) {
              errorCorrection = "Q";
            } else {
              errorCorrection = "M";
            }

            self.notifyDesigner("errorCorrection", errorCorrection);
            self.notifyCharacteristics("errorCorrection", errorCorrection);

          }else{
            self.toggleBackgroundImage(reader.result.split("base64,")[1])
            eyesAndModulesStore.handleShapeChange("dataShape", 2, self)
          }
          if(fileType === "PDF"){
            const base64Pdf = reader.result.split("base64,")[1];
            pdfLogoPng = await self.convertBase64PdfToPng(base64Pdf)
            self.setPdfLogoPng(pdfLogoPng)
          }

        }.bind(this)

        if (file) {
          reader.readAsDataURL(file);
        }

        // self.notifyPreview()
      }else{
        // !fileIsCorrectSize && new Notification()
        //   .setType("error")
        //   .setMessage(`File size: ${file?.size / 1000} MB \n File can not exceed 3MB`)
        //   .send();
        // !fileIsCorrectType && new Notification()
        //   .setType("error")
        //   .setMessage(`File type: ${fileType} is not accepted \n Accepted formats: PNG, JPEG, BMP, GIF, TIF, SVG, PDF, EPS`)
        //   .send();
        self.logoFileIsCorrectSize = fileIsCorrectSize
        self.logoFileIsCorrectType = fileIsCorrectType
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    setPdfLogoPng(pngBase64){
      self.pdfLogoPng = pngBase64
      self.notifyDesigner("pdfLogoPng",pngBase64)
    },
    handleLogoSize(e) {
      self.logoPercentageWidth = +e.currentTarget.value
      self.notifyDesigner("logoPercentageWidth",self.logoPercentageWidth)
      if(+e.currentTarget.value > 33) {
        self.logoSizeWarn = true
      }else{
        self.logoSizeWarn = false
      }
      if(+e.currentTarget.value > 25 && self.logo !== ""){
        self.notifyDesigner("errorCorrection","H")
        self.notifyCharacteristics("errorCorrection","H")
      }else if( +e.currentTarget.value >= 15 && +e.currentTarget.value <= 25 && self.logo !== ""){
        self.notifyDesigner("errorCorrection","Q")
        self.notifyCharacteristics("errorCorrection","Q")
      } else if(+e.currentTarget.value < 15 && self.logo !== ""){
        self.notifyDesigner("errorCorrection","M")
        self.notifyCharacteristics("errorCorrection","M")
      }
    },
    handleLogoMargin(e){
      if(e.currentTarget.value === ""){
        return
      }
      const value = parseInt(e.currentTarget.value)
      if(value >= -1 && value <= 3){
        self.logoMargin = value
        self.logoMarginWarn = false
        self.notifyDesigner("logoMargin",self.logoMargin)
      } else {
        self.logoMargin = value
        self.logoMarginWarn = true
      }
    },
    handleSelectedFromAssets(e) {
      self.selectedFromAssets = e.target.value
    },
    removeLogo(){
      self.notifyDesignerDeleteKey("logo")
      self.logo = ''
      self.notifyPreview()
      self.notifyDesigner("errorCorrection","M")
      self.notifyCharacteristics("errorCorrection","M")
      self.notifyDesignerDeleteKey("pdfLogoPng")
    },
    async convertBase64PdfToPng(base64Pdf) {
      var pdfData = atob(base64Pdf);
      // Asynchronous download of PDF
      if(pdfData === "") return
      var loadingTask = pdfjsLib.getDocument({data: pdfData});
      let pngBase64 = ""
      var pdf = await loadingTask.promise

      // Fetch the first page
      var pageNumber = 1;
      var page = await pdf.getPage(pageNumber);

      var scale = 1;
      var viewport = page.getViewport({scale: scale});

      // Prepare canvas using PDF page dimensions
      const canvas = document.createElement('canvas');
      document.body.appendChild(canvas)
      var context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);
      await renderTask.promise
      pngBase64 = canvas.toDataURL();
      return pngBase64


    },
    handleLogoOffsetX(e){
      const value = parseInt(e.currentTarget.value)
      if(value >= -15 && value <= 15){
        self.logoOffsetX = value
        self.notifyDesigner("logoOffsetX",self.logoOffsetX)
        self.logoOffsetXWarn = false
      } else {
        self.logoOffsetX = value
        self.logoOffsetXWarn = true
        // new Notification()
        //   .setType("error")
        //   .setMessage(`Right range is from -15 to 15`)
        //   .send();
      }
    },
    handleLogoOffsetY(e){
      const value = parseInt(e.currentTarget.value)
      if(value >= -15 && value <= 15){
        self.logoOffsetY = +e.currentTarget.value
        self.notifyDesigner("logoOffsetY",(self.logoOffsetY))
        self.logoOffsetYWarn = false
      } else {
        self.logoOffsetY = value
        self.logoOffsetYWarn = true
        // new Notification()
        //   .setType("error")
        //   .setMessage(`Down range is from -15 to 15`)
        //   .send();
      }
    },
    toggleCropAroundImage(){
      self.cropAroundImage = !self.cropAroundImage
      if(self.cropAroundImage){
        self.notifyDesigner("stripLogoBackground", 1)
      }else{
        self.notifyDesignerDeleteKey("stripLogoBackground")
        // self.notifyDesigner("stripLogoBackground", 0)
      }
    },
    removeBackgroundImage(){
      self.notifyDesignerDeleteKey("backgroundImage")
      self.backgroundImage = ''
    },
    toggleFullBackground(){
      self.fullBackground = !self.fullBackground
      self.notifyDesigner("fullBackground", self.fullBackground)
    },
    toggleEmptyLogoTab(){
      self.logoType = 'emptyImage'
      self.notifyDesigner("logoType", self.logoType)
      self.notifyDesigner("logoPercentageWidth",self.logoPercentageWidth)
      self.notifyDesigner("logoMargin",self.logoMargin)
      self.notifyPreview()
    },
    toggleLogoTab(){
      self.logoType = 'logoImage'
      self.notifyDesigner("logoType", self.logoType)
      self.notifyPreview()
    },
    toggleBackgroundImageTab(){
      self.logoType = 'backgroundImage'
      self.notifyDesigner("logoType", self.logoType)
      self.notifyPreview()
    },
    setOpenBackgroundImage(bool){
      self.openBackgroundImage = !self.openBackgroundImage
    },
   toggleTab(tab) {
      console.log('tab', tab)
        if(tab === '1') {
          self.activeTab = '1'
          self.toggleLogoTab()
        }  else if(tab === '2') {
          self.activeTab = '2'
          self.toggleEmptyLogoTab()
        }
    }
  })).views(self => ({
    doesLogoExist(){
      return self.logo !== ''
    }
  }))

export default LogoState;
//
// const [logoSize , setLogoSize] = useState(3)
// const [logo, setSelectedImage] = useState(null);
