import {flow, getEnv, getType, types} from "mobx-state-tree";
import CodeTypeStore from "../../../stores/domain/CodeTypeStore";
import PublishDomainStore from "../../../stores/domain/PublishDomainStore";
import { FormattedMessage } from "react-intl";
import React from "react";
import lodash from "lodash";
import {UserModel} from "../../../models/users/userModel";

export const CodeFilterSelectState = types.model('CodeFilterSelectState',{
  selectStateUuid: types.maybe(types.identifier),
  // codeTypeStore: types.maybe(types.late(() => types.reference(CodeTypeStore))),
  // publishDomainStore:types.maybe(types.late(() => types.reference(PublishDomainStore))),
  statusTypes: types.optional(types.array(types.string), []),
  userEmails: types.optional(types.array(UserModel), []),
  isDisabled: types.optional(types.boolean, false),
}).volatile(self => ({
  notifySelect: types.f,
  selected: types.array,
  behaviorTypes: types.array,
  codeTypes: types.array,
  domains: types.array
})).actions((self)=>({
  populateAllFilters: flow(function* (sortParams = {}) {
    try {
      const queryParams = {
        ...sortParams
      };

      const response = yield getEnv(self).codeManager.fetchAllFilters(queryParams);
      if (response) {
        self.setEmails(response?.users || []);
        self.setCodeTypes(response?.types || []);
        self.setStatusTypes(response?.status || []);
        self.setDomains(response?.domains || []);
        self.setBehaviorTypes(response?.behaviors || []);
      }
    }
    catch (error) {
      console.error('Failed to populateAllFilters: ', error);
    }
  }),
  reset(){
    self.selected = []
    self.setIsDisabled(false);
  },
  setIsDisabled(d) {
    self.isDisabled = d;
  },
  setEmails(users) {
    self.userEmails = users
  },
  setCodeTypes(types) {
    self.codeTypes = types
  },
  setStatusTypes(status) {
    self.statusTypes = status
  },
  setDomains(domains) {
    self.domains = domains
  },
  setBehaviorTypes(behaviors) {
    self.behaviorTypes = behaviors
  },
  filterChange(data){
    self.selected = data;
    self.notifySelect(lodash.reduce(data, function(result, value, key) {
      const parts = value.value.split('.');
      const pushValue = parts[parts.length-1];
      const fieldName = value.value.substr(0, value.value.length - pushValue.length - 1 );
      (result[fieldName] || (result[fieldName] = [])).push(pushValue);
      return result;
    }, {}), ["type","status","domain", "createdBy"]);
  },
  setConsumer(f){
    self.notifySelect = f
  },
  afterCreate() {
    console.log("Instantiated " + getType(self).name)
    self.notifySelect = (data) => {
      console.log("placeholder notifySelect")
    }
  }

})).views( (self) => ({
  optionValues() {
    const experiences = self.codeTypes || [];

    const expOptions = experiences
      .sort((a, b) => a?.label?.localeCompare(b?.label))
      .flatMap( (exp) => {
      return {
        label: exp.label,
        value: "type."+exp.type
      }
    });
    //TODO: inject Intl context into the ENV

    const statusOptions = self.statusTypes
      .slice()
      .sort((a, b) => a?.localeCompare(b))
      .flatMap( (stat) =>{
      return {
        label: stat,
        value: "status."+stat
      }
    })

    const publishDomainOptions = (self?.domains || [])
      .sort((a, b) => a?.domain?.localeCompare(b?.domain))
      .flatMap( (domain) => {
      return {
        label: domain.domain,
        value: "domain."+domain.uuid
      }
    });

    const userEmails = self?.userEmails || [];
    const userOptions = userEmails
      .slice()
      .sort((a, b) => a?.email?.localeCompare(b?.email)) // Sort the copied array by email
      .flatMap((user) => ({
        label: user?.email,
        value: "createdBy." + user.uuid
      }));

    const behaviorOptions = (self.behaviorTypes?.length >= 1 ? self.behaviorTypes : [])
      .slice()
      .sort((a, b) => a?.localeCompare(b))
      .flatMap( (behavior) =>{
      return {
        label: behavior,
        value: "behavior."+behavior
      }
    })

    const options = [
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.experience.defaultMessage"/>,
        options: expOptions
        //   [
        //   { label: <FormattedMessage id="listCodes.filter.experienceOptions.url.defaultMessage"/>, value: "experience.1" },
        //   { label: <FormattedMessage id="listCodes.filter.experienceOptions.vCard.defaultMessage"/>, value: "experience.2" }
        // ]
      },
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.status.defaultMessage"/>,
        options: statusOptions
        //   [
        //   { label: <FormattedMessage id="listCodes.filter.statusOptions.active.defaultMessage"/>, value: "status.1" },
        //   { label: <FormattedMessage id="listCodes.filter.statusOptions.draft.defaultMessage"/>, value: "status.2" }
        // ]
      },
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.behavior.defaultMessage"/>,
        options: behaviorOptions
        //   [
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.scn.by.defaultMessage"/>, value: "domain.1" },
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.my.dm.defaultMessage"/>, value: "domain.4" }
        // ]
      },
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.domain.defaultMessage"/>,
        options: publishDomainOptions
        //   [
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.scn.by.defaultMessage"/>, value: "domain.1" },
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.my.dm.defaultMessage"/>, value: "domain.4" }
        // ]
      },
      {
        label: <FormattedMessage id="listCodes.filter.filterBy.users.defaultMessage"/>,
        options: userOptions
        //   [
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.scn.by.defaultMessage"/>, value: "domain.1" },
        //   { label: <FormattedMessage id="listCodes.filter.domainOptions.my.dm.defaultMessage"/>, value: "domain.4" }
        // ]
      }
    ];
    return options;
  }
}))
