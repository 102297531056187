import Ajv from "ajv";
import addFormats from "ajv-formats"
const ajv = new Ajv({allErrors: true, validateFormats: false});
addFormats(ajv);

ajv.addVocabulary(["enumNames"]);

export {
    ajv
};
