import {flow, getEnv, types} from "mobx-state-tree";
import {GroupSelectState} from "../../elements/groups/GroupSelectState";
import {UtmSelectState} from "../../elements/utms/UtmSelectState";
import {Utm} from "../../../models/utms/UtmModel";


export const UtmModalState = types.model("UtmModalState", {
  utm: types.maybe(Utm),
  isLoading: types.optional(types.boolean, false),
  isMadeDefault:types.optional(types.boolean, false),
  groupSelectStore: types.maybe(types.reference(GroupSelectState)),
  noUtmOpen:types.optional(types.boolean, false),
  customOpen:types.optional(types.boolean, true),
  utmSelectState:types.maybe(types.reference(UtmSelectState)),
  isView: types.optional(types.boolean, false),
})
  .actions(self => {
    return ({
      handleIsDefault() {
        self.isMadeDefault = !self.isMadeDefault
        console.log(self)
      },
      setNoneOpen() {
        self.noUtmOpen = true
        self.presetsOpen = false
        self.customOpen = false
      },
      setCustomOpen() {
        self.noUtmOpen = false
        self.presetsOpen = false
        self.customOpen = true
      },
      consumeUtmSelect() {
        self.utmSelectState.setConsumer(self.fetchUtmWithID)
      },
      fetchUtmWithID: flow(function* f(uuid) {
        const utmManager = getEnv(self).utmManager
        const res = yield utmManager.getUtm(uuid)
        self.utm.hydrateUtm(res)
      }),
    });
  }).views((self) => ({}))
// toastr.error(`Failure`, `${self.errorMessage}`)
