import React from "react";
import {observer} from "mobx-react-lite";
import { FormattedMessage } from "react-intl";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup, Row,
} from "reactstrap";


const DeleteRuleSetView = observer(({store}) => {
  return (
    <>
      <Card>
        <CardBody>
          <Form>
            <FormGroup row>
              <Col sm={{size: 10, offset: 2}}>
                <Row>
                  <p className="float-left" style={{marginLeft: "-5rem"}}>
                  <FormattedMessage id="deleteAction.confirmMessage.ruleSet.defaultMessage"/>
                  </p>
                </Row>
                <Button id="delete-btn" color="danger" className="float-right" onClick={async () => {
                  await store.deleteRuleSet()
                  // window.location.reload(false)
                }}><FormattedMessage id="deleteAction.submit.defaultMessage"/></Button>

              </Col>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter> <FormattedMessage id="deleteAction.alertInfo.ruleSet.defaultMessage"/></CardFooter>
        {store.formHasErrors && <div className="alert alert" style={{
          fontSize: "20px",
          backgroundColor: "#faada7",
          width: "80%",
          marginLeft: "10%",
          padding:"5px",
          paddingLeft:"8px",
          paddingRight:"8px"
        }} role="alert">
          <div>{store.errorMessage}</div>
        </div>}
      </Card>
    </>

  )
})
export default DeleteRuleSetView;
