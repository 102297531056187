import {flow, getEnv, types} from "mobx-state-tree";
import PublishDomainStore from "../stores/domain/PublishDomainStore";


export const PublishDomainsList = types.model('PublishDomainsList', {
  publishDomainStore: types.maybe(types.late(() => types.reference(PublishDomainStore))),
  isLoading: types.optional(types.boolean, false), //types.enumeration('State', ['pending', 'done', 'error']),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
})
  .volatile(self => ({
    searchParams: {}
  })).actions((self) => ({
    getItems() {
      return self.items
    },
    setSortOrder(sortDirection){
      self.sortOrder = sortDirection
    },
    setSortField(sortField){
      self.sortField = sortField
    },
    fetchAll: flow(function* fetchAll(sortParams = {}) {
      self.items = []
      self.isLoading = true
      if (Object.keys(sortParams).length === 0) {
        sortParams = {
          sort: 'updatedAt',
          order: self.sortOrder
        };
      }
      try {
        const queryParams = {
          page: self.page,
          perPage: self.perPage,
          ...sortParams,
          ...self.searchParams
        }

        const manager = getEnv(self).publishDomainManager
        console.log("queryParams in fetchAll domains",queryParams)
        const response = yield manager.fetchAll(queryParams);

        if(response != null) {
          console.log("response.results", response.results);

          self.publishDomainStore.setPublishDomains(response?.results)
          self.totalRows = response._meta.itemCount;
          self.isLoading = false;
        } else {
          self.isLoading = false;
        }
      } catch (error) {
        console.error('Failed to fetch domains', error)
      }
    }),
    updatePage: function (page) {
      self.page = page
    },
    updatePerPage: function (perPage) {
      self.perPage = perPage
    },
    addSearchParams(param){
      self.searchParams = {...self.searchParams, ...param}
    },
    removeSearchParams(names){
      if(names){
        names.map((name) => delete self.searchParams[name])
      }
    },
})).views( (self) => ({
  getItems() {
    const { publishDomains } = self.publishDomainStore || {};
    return publishDomains || []
  }
}))
