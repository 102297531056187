import {types} from "mobx-state-tree";


export const DimensionValues = types.model('DimensionValues',{
  uuid: types.identifier,
  label: types.string,
  name: types.string,
  isDefault : types.boolean,
  owner: types.optional(types.model({
    groupUuids: types.array(types.string)
  }), {})
})

const  Dimension = types.model('Dimension',{
  uuid: types.identifier,
  label: types.string,
  name: types.string,
  type: types.string,
  values: types.optional(types.array(DimensionValues), [])
})



export default Dimension;
