import {flow, getEnv, types} from "mobx-state-tree";
import ExtendedDataStore from "../../stores/domain/ExtendedDataStore";

export const ExtendedDataListModel = types.model("ExtendedDataListModel", {
  store: types.maybe(types.late(() => types.reference(ExtendedDataStore))),
  isLoading: types.optional(types.boolean, false),
  page: types.optional(types.integer, 1),
  perPage: types.optional(types.integer, 10),
  totalRows: types.optional(types.integer, 0),
  sortOrder: types.optional(types.string, "desc"),
  sortField:  types.optional(types.string, "updatedAt"),
}).volatile(self => ({
  searchParams: {},
  filters: {}
})).actions((self) => ({
  getItems() {
    return self.store.extendedData || [];
  },
  setSortOrder(sortDirection){
    self.sortOrder = sortDirection;
  },
  setSortField(sortField){
    self.sortField = sortField;
  },
  fetchAll: flow(function* (sortParams = {}) {
    self.isLoading = true;
    try {
      const queryParams = {
        page: self.page,
        perPage: self.perPage,
        ...sortParams,
        ...self.searchParams
      };
      console.log("ExtendedDataListModel.fetchAll: ", queryParams);

      const response = yield getEnv(self).extendedDataManager.fetchAllExtendedData(queryParams, self);

      if (response != null) {
        console.log("ExtendedDataListModel.fetchAll: ", response.results);
        self.store.setExtendedData(response?.results);
        self.totalRows = response._meta?.itemCount;
      }
      self.isLoading = false;
    } catch (error) {
      console.error("Error in ExtendedDataListModel.fetchAll: ", error?.message);
      console.error(error);
    }
  }),
  fetchAllFilters: flow(function* fetchAllFilters(sortParams = {}) {
    self.isLoading = true
    try {
      const queryParams = {
        ...self.searchParams
      };
      console.log("ExtendedDataListModel.fetchAllFilters: queryParams", queryParams);

      const response = yield getEnv(self).extendedDataManager.fetchAllExtendedDataFilters(queryParams, self);
      console.log("ExtendedDataListModel.fetchAllFilters: response", response);
      if (response != null) {
        self.filters = response;
      }
      self.isLoading = false;
    }
    catch (error) {
      console.error("Error in ExtendedDataListModel.fetchAllFilters: ", error?.message);
      console.error(error);
    }
  }),
  updatePage(page) {
    self.page = page;
  },
  updatePerPage(perPage) {
    self.perPage = perPage;
  },
  addSearchParams(param){
    self.searchParams = {...self.searchParams, ...param};
  },
  removeSearchParams(names, regex = ''){
    if (names) {
      names.map((name) => delete self.searchParams[name]);
    }
    if (regex) {
      const keysToDelete = Object.keys(self.searchParams).filter(key => regex.test(key));
      keysToDelete.forEach(key => delete self.searchParams[key]);
    }
  },
  afterCreate() {
    console.log("ExtendedDataListModel.afterCreate");
    self.searchParams = {};
  },
})).views((self) => ({
  getSearchParams() {
    return self.searchParams;
  }
}));
