import {types} from 'mobx-state-tree'
import Dimension, {DimensionValues} from "../../models/Dimension";
import _ from 'lodash';

const DimensionStore = types.model('dimensionStore', {
  storeUuid: types.identifier,
  dimensions: types.optional(types.array(Dimension), []),
  dimensionsEnabled : types.optional(types.boolean,false)
}).actions((self) => ({

  initWithData(dimensions = [],dimensions_enabled = false) {
    if (dimensions) {
      self.dimensions = dimensions.map(d => Dimension.create({
        uuid: d?.uuid,
        name: d?.name,
        label: d?.label,
        type : d?.type || "DEFAULT",
        values: !_.isEmpty(d?.values) && d?.values.map(val=> DimensionValues.create({
          uuid: val?.uuid,
          name: val?.name,
          label: val?.label,
          isDefault: val?.isDefault,
          owner: val?.owner || { groupUuids: [] }
        }))
      }))
    } else {
      self.dimensions = []
    }
    self.dimensionsEnabled = dimensions_enabled
  }

})).views( (self) => ({
  getDimensions(){
    return self.dimensions
  }
}))


export default DimensionStore;
