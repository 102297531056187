import {types} from "mobx-state-tree";

export function createExtendedDataType(item) {
  return ExtendedDataType.create({
    label: item?.label,
    type: item?.type,
    schema: JSON.stringify(item?.schema),
    uiSchema: JSON.stringify(item?.uiSchema),
  });
}

export const ExtendedDataType = types.model('ExtendedDataType', {
  label: types.identifier,
  type: types.string,
  schema: types.optional(types.string, ""),
  uiSchema: types.optional(types.string, ""),
});
