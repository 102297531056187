import React from "react";
import { observer } from "mobx-react-lite";
import AppleFooter from "../components/AppleFooter";
import StorePass from "./StorePass";

const AppleStorePass = observer(({store}) => {
  if (typeof store === "string"){
    store = JSON.parse(store);
  }
  const data = store?.storeCard;

  return (
    <StorePass
      store={{
        data,
        minHeight: "400px",
        footer: <AppleFooter barcodeType={data?.barcodeType} mobileWalletCode={data?.mobileWalletCode} />
      }}
    />
  );
});

export default AppleStorePass;
